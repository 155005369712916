import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
  fill?: string;
}

export default class SVGIconCross extends React.Component<Props> {
  /**
   * Sets colour depending on user input, if no colour given then set to a default of black.
   *
   * @private
   * @memberof SVGIconCross
   */
  private fillColor = (): string => {
    // If no colour provided then default to black.
    if (!this.props.fill) {
      return "#000000";
    }
    // If we have a valid colour pass down.
    return this.props.fill;
  };

  /**
   * Render
   */
  public render(): ReactNode {
    return (
      <SVGWrapper
        id="crossIcon"
        height="100%"
        fillColor={this.fillColor()}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 9.9 9.9"
      >
        <title>Cross Icon</title>
        <polygon points="9.9 0.85 9.05 0 4.95 4.1 0.85 0 0 0.85 4.1 4.95 0 9.05 0.85 9.9 4.95 5.8 9.05 9.9 9.9 9.05 5.8 4.95 9.9 0.85" />
      </SVGWrapper>
    );
  }
}

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
