import { useState } from "react";
import { FetchError, OrganisationType } from "./../../types";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { getBaseUrl } from "./../../../services";

interface UseOrganisationProps {
    error: FetchError;
    isLoading: boolean;
    organisations: Array<OrganisationType>;
    organisation?: OrganisationType;
    setNewOrganisation: (parentId: string, name: string) => Promise<void>;
    editOrganisation: (parentId: string, id: string, name: string) => Promise<void>;
    deleteOrganisation: (id: string) => Promise<void>;
    getOrganisations: (customerId: string, includeChildren?: boolean) => Promise<void>;
    getOrganisation: (customerId: string) => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapOrganisationType = (orgs: any): Array<OrganisationType> => {
    const orgsList = [];
    orgsList.push({ id: orgs.customerId, name: orgs.customerName } as OrganisationType);

    const children = orgs?.children?.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (o: any) =>
            ({
                id: o.customerId,
                name: o.customerName,
            } as OrganisationType),
    );

    if (children) orgsList.push(...children);

    return orgsList;
};

const url = `${getBaseUrl}/caas/organisation`;

const useOrganisation = (): UseOrganisationProps => {
    const [error, setError] = useState({} as FetchError);
    const [isLoading, setIsLoading] = useState(false);
    const [organisation, setOrganisation] = useState<OrganisationType>();
    const [organisations, setOrganisations] = useState(Array<OrganisationType>());

    const setNewOrganisation = async (parentId: string, name: string) => {
        setIsLoading(true);

        const options = {
            params: {
                customerId: parentId,
            },
        };

        const requestBody = {
            customerName: name,
            customerId: uuidv4(),
            parent: parentId,
            alias: "TS",
        };

        try {
            await axios.post(url, requestBody, options);
            setError({});
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                setError({
                    status: error?.response?.status,
                    statusText: error?.response?.statusText,
                    message: "Add new organisation error",
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const editOrganisation = async (parentId: string, id: string, name: string) => {
        setIsLoading(true);

        const requestOptions = {
            params: {
                customerId: parentId,
            },
        };

        const requestBody = {
            customerName: name,
            customerId: id,
            parent: parentId,
        };

        try {
            await axios.put(url, requestBody, requestOptions);
            setError({});
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                setError({
                    status: error?.response?.status,
                    statusText: error?.response?.statusText,
                    message: "Edit organisation error",
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const deleteOrganisation = async (id: string) => {
        setIsLoading(true);

        const requestOptions = {
            params: {
                customerId: id,
            },
        };

        try {
            await axios.delete(url, requestOptions);
            setError({});
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                setError({
                    status: error?.response?.status,
                    statusText: error?.response?.statusText,
                    message: "Delete organisation error",
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const getOrganisations = async (customerId: string, includeChildren = true) => {
        setIsLoading(true);
        setOrganisations([]);

        const requestOptions = {
            params: {
                includeChildren,
            },
        };

        try {
            const response = await axios.get(`${url}/${customerId}`, requestOptions);
            setOrganisations(mapOrganisationType(response.data));
            setError({});
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                setError({
                    status: error?.response?.status,
                    statusText: error?.response?.statusText,
                    message: "Get organisations error",
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const getOrganisation = async (customerId: string) => {
        setIsLoading(true);
        setOrganisation(undefined);

        const requestOptions = {
            params: {
                customerId,
            },
        };

        try {
            const { data } = await axios.get(`${url}/${customerId}`);
            setOrganisation({ id: data.customerId, name: data.customerName } as OrganisationType);
            setError({});
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                setError({
                    status: error?.response?.status,
                    statusText: error?.response?.statusText,
                    message: "Get organisation error",
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    return {
        error,
        isLoading,
        organisations,
        organisation,
        setNewOrganisation,
        editOrganisation,
        deleteOrganisation,
        getOrganisations,
        getOrganisation,
    };
};

export default useOrganisation;
