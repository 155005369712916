import { useState, useRef, MutableRefObject } from "react";
import { Device, DeviceConnectionStatus, DeviceType, FetchError } from "@sv/types";
import strings from "../../strings/strings.json";
import { v4 as uuidv4 } from "uuid";
import axios, { AxiosError } from "axios";
import { mockGetConnectionStatusTrue } from "../../mocks/responses";
import { getBaseUrl } from "./../../../services/Login/IdpInstance";

const { defaultErrorMsg } = strings.hooks.Device;

export interface DeviceLoadingState {
    getDevices: boolean;
    getDevice: boolean;
    addDevice: boolean;
    editDevice: boolean;
    getDeviceConnectionStatus: boolean;
    deleteDevice: boolean;
}

interface UseDeviceReturn {
    error: FetchError;
    loading: DeviceLoadingState;
    device: Device;
    devices: Array<Device>;
    deviceConnectionStatus: MutableRefObject<DeviceConnectionStatus>;
    controller: AbortController;

    addDevice: (
        name: string,
        siteId: string,
        type: DeviceType,
        edgeDevice: string,
        moduleName: string,
        url?: string,
        username?: string,
        password?: string,
    ) => Promise<void>;
    getDevices: (siteId?: string, deviceId?: string, type?: DeviceType) => Promise<void>;
    getDevice: (deviceId: string) => Promise<void>;
    getDeviceConnectionStatus: (deviceId: string) => Promise<void>;
    editDevice: (
        name: string,
        siteId: string,
        deviceId: string,
        type: DeviceType,
        moduleName: string,
        edgeDevice?: string,
        url?: string,
        username?: string,
        password?: string,
    ) => Promise<void>;
    deleteDevice: (deviceId: string) => Promise<void>;
    resetError: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDevice = (devices: any, type?: DeviceType): Array<Device> =>
    devices
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ?.filter((d: any) => (type ? d.type === type : true))
        .map(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (d: any) =>
                ({
                    id: d.deviceId,
                    name: d.name,
                    siteId: d.siteId,
                    type: d.type,
                    edgeDevice: d.edgeDevice,
                    edgeCapable: d.edgeCapable,
                    active: d.active,
                    moduleName: d.moduleName,
                    url: d.metadata?.url,
                    username: d.metadata?.username,
                    password: d.metadata?.password,
                    connectionString: d.metadata?.auth?.iotHubConnectionString,
                } as Device),
        );

const initialLoadingState: DeviceLoadingState = {
    getDevices: false,
    getDevice: false,
    addDevice: false,
    editDevice: false,
    getDeviceConnectionStatus: false,
    deleteDevice: false,
};

const prepareError = (error: AxiosError, defaultMessage: string) => {
    return {
        status: error.response?.status,
        statusText: error.response?.data?.statusText,
        message: error.response?.data?.message || defaultMessage,
    };
};

const baseUrl = `${getBaseUrl()}/caas/devices`;

const useDevice = (): UseDeviceReturn => {
    const [error, setError] = useState({} as FetchError);
    const [loading, setLoading] = useState(initialLoadingState);
    const [devices, setDevices] = useState<Array<Device>>([]);
    const [device, setDevice] = useState<Device>({} as Device);

    const deviceConnectionStatus = useRef<DeviceConnectionStatus>({} as DeviceConnectionStatus);
    const controller = new AbortController();

    const addDevice = async (
        name: string,
        siteId: string,
        type: DeviceType,
        edgeDevice: string,
        moduleName: string,
        url?: string,
        username?: string,
        password?: string,
    ) => { 
        setLoading((current) => ({ ...current, addDevice: true }));
        setDevice({} as Device);
        const deviceId = uuidv4();

        const requestOptions = {
            params: {
            },
        };
        const requestBody = {
            deviceId: deviceId,
            name: name,
            siteId: siteId,
            type: type,
            edgeDevice: type === "gateway" ? deviceId : edgeDevice,
            edgeCapable: type === "gateway" ? true : false,
            moduleName,
            metadata: {
                url: url,
                username: username,
                password: password,
            },
        };

        try {
            let responseData: Array<Device> = [];
            const res = await axios.post(type === "gateway" ? baseUrl + "/edge-device" : baseUrl + "/camera", requestBody, requestOptions);
            // response may be a single object or array of objects
            if (
                typeof res.data === 'object' &&
                !Array.isArray(res.data) &&
                res.data !== null
            ) {
                responseData.push(res.data);
            } else {
                responseData = res.data;
            }
            const mappedDevice = mapDevice(responseData)[0];
            setDevice(mappedDevice);
            setError({});
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) setError(prepareError(error, defaultErrorMsg));
            throw error;
        } finally {
            setLoading((current) => ({ ...current, addDevice: false }));
        }
    };

    const getDevices = async (siteId?: string, deviceId?: string, type?: DeviceType) => {
        setLoading((current) => ({ ...current, getDevices: true }));
        setDevices([]);

        const requestOptions = {
            params: {
                ...(siteId && { siteId }),
            },
        };

        try {
            let responseData: Array<Device> = [];
            const res = await axios.get(deviceId ? `${baseUrl}/${deviceId}` : baseUrl, requestOptions);
            // response may be a single object or array of objects
            if (
                typeof res.data === 'object' &&
                !Array.isArray(res.data) &&
                res.data !== null
            ) {
                responseData.push(res.data);
            } else {
                responseData = res.data;
            }
            setDevices(mapDevice(responseData, type));
            setError({});
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) setError(prepareError(error, defaultErrorMsg));
        } finally {
            setLoading((current) => ({ ...current, getDevices: false }));
        }
    };

    const getDevice = async (deviceId: string) => {
        setLoading((current) => ({ ...current, getDevice: true }));
        setDevice({} as Device);

        const requestOptions = {
            params: {
            },
        };
        try {
            let responseData: Array<Device> = [];
            const res = await axios.get(`${baseUrl}/${deviceId}`, requestOptions);
            // response may be a single object or array of objects
            if (
                typeof res.data === 'object' &&
                !Array.isArray(res.data) &&
                res.data !== null
            ) {
                responseData.push(res.data);
            } else {
                responseData = res.data;
            }
            setDevice(mapDevice(responseData)[0]);
            setError({});
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) setError(prepareError(error, defaultErrorMsg));
        } finally {
            setLoading((current) => ({ ...current, getDevice: false }));
        }
    };

    const getDeviceConnectionStatus = async (deviceId: string) => {
        setLoading((current) => ({ ...current, getDeviceConnectionStatus: true }));

        const requestOptions = {
            signal: controller.signal,
            params: { deviceId },
        };

        try {
            // Below API URL returns fake API response so it is now hardcoded at UI front
            // const res = mockGetConnectionStatusTrue; // await axios.get(deviceConnectionStatusUrl, requestOptions);
            const res = await axios.get(`${getBaseUrl}/caas/healthStatus`, requestOptions);
            deviceConnectionStatus.current = {
                connected: res.data.code === "GREEN",
            };
            setError({});
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) setError(prepareError(error, defaultErrorMsg));
        } finally {
            setLoading((current) => ({ ...current, getDeviceConnectionStatus: false }));
        }
    };

    const editDevice = async (
        name: string,
        siteId: string,
        deviceId: string,
        type: DeviceType,
        moduleName: string,
        edgeDevice?: string,
        url?: string,
        username?: string,
        password?: string,
    ) => {
        setLoading((current) => ({ ...current, editDevice: true }));

        const requestOptions = {
            params: {
                // customerId: parentId,
            },
        };

        try {
            const response = await axios.put((type === "gateway" ? baseUrl + "/edge-device" : baseUrl + "/camera") + "/" + deviceId, {
                deviceId: deviceId,
                name: name,
                // customerId: parentId,
                siteId: siteId,
                type: type,
                tags: { site: siteId },
                edgeDevice: type === "gateway" ? deviceId : edgeDevice,
                edgeCapable: type === "gateway" ? true : false,
                moduleName,
                metadata: {
                    url: url,
                    username: username,
                    password: password,
                },
                requestOptions,
            });
            const mappedDevice = mapDevice([response.data])[0];
            setDevice(mappedDevice);
            setError({});
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) setError(prepareError(error, defaultErrorMsg));
            throw error;
        } finally {
            setLoading((current) => ({ ...current, editDevice: false }));
        }
    };

    const deleteDevice = async (deviceId: string) => {
        setLoading((current) => ({ ...current, deleteDevice: true }));
        setDevice({} as Device);

        try {
            const requestOptions = {
                params: {
                },
            };
            const response = await axios.delete(`${baseUrl}/${deviceId}`, requestOptions);
            setDevice(mapDevice([response.data])[0]);
            setError({});
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) setError(prepareError(error, defaultErrorMsg));
        } finally {
            setLoading((current) => ({ ...current, deleteDevice: false }));
        }
    };

    const resetError = () => {
        setError({});
    };

    return {
        error,
        loading,
        devices,
        device,
        deviceConnectionStatus,
        controller,
        addDevice,
        getDevices,
        getDevice,
        getDeviceConnectionStatus,
        editDevice,
        deleteDevice,
        resetError,
    };
};

export default useDevice;
