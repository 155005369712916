import React, { ReactElement } from "react";
import styled from "styled-components";

interface Props {
    fill?: string;
}

const SVGNotificationIcon = ({ fill }: Props): ReactElement => {
    return (
        <SVGWrapper
            id="notificationIcon"
            height="100%"
            fillColor={fill || ""}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 19.92 23.23"
        >
            <title>Notification Icon</title>
            <path
                d="M19.92,20.21a16,16,0,0,1-2.46-5.68h0a24.94,24.94,0,0,1-.9-5.43,6.91,6.91,0,0,0-4-6.62,2.59,2.59,0,0,0-5.18,0,6.91,6.91,0,0,0-4,6.62,24.21,24.21,0,0,1-.92,5.41h0A16.09,16.09,0,0,1,0,20.21l.46,1h7a2.59,2.59,0,0,0,5,0h7Zm-10-19a1.41,1.41,0,0,1,1.31.92,7.21,7.21,0,0,0-2.62,0A1.41,1.41,0,0,1,10,1.2Zm6.59,14.5A20.14,20.14,0,0,0,18.27,20H1.61a20.14,20.14,0,0,0,1.76-4.3l.33-1.19A25.52,25.52,0,0,0,4.56,9.1c0-2.19,1.14-5.9,5.4-5.9s5.4,3.71,5.4,5.9a25.42,25.42,0,0,0,.86,5.41ZM10,22a1.39,1.39,0,0,1-1.26-.8h2.52A1.39,1.39,0,0,1,10,22Z"
                transform="translate(0 0.11)"
            />
        </SVGWrapper>
    );
};

export default SVGNotificationIcon;

const SVGWrapper = styled.svg<{ fillColor: string }>`
    fill: ${(props): string => props.fillColor};
`;
