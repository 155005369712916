import * as yup from "yup";
import strings from "../strings/strings.json";

const {
    requiredField,
    requiredAction,
    validCharactersMessage,
    maximumCharacters,
    minimumCharacters,
    maximumCharactersLimit,
} = strings.components.Forms.Validation;

const insertInTemplate = (template: string, value: string) => {
    return template.includes("{}") ? template.replace("{}", value) : template;
};

export const nameCharacterLimit = 60;
export const passwordMinimumCharactersRequired = 8;

export const validCharacters = /^([a-zA-Z0-9+_-]+ ?)*$/g;

export const baseGatewaySchema = {
    gatewayName: yup
        .string()
        .min(1)
        .required(requiredField)
        .matches(validCharacters, validCharactersMessage)
        .max(nameCharacterLimit, maximumCharacters),
};

export const validationSchemas = {
    organisation: yup.object().shape({
        parentId: yup.string().min(1).required(requiredField),
        organisationName: yup.string().min(1).required(requiredField),
    }),
    site: yup.object().shape({
        site: yup.object().shape({
            name: yup
                .string()
                .min(1)
                .required(requiredField)
                .matches(validCharacters, validCharactersMessage)
                .max(nameCharacterLimit, maximumCharacters),
            location: yup.object().shape({
                id: yup.string().required(),
                address: yup.string().required(),
                geoLocation: yup.object().shape({
                    latitude: yup.number().required(),
                    longitude: yup.number().required(),
                }),
            }),
        }),
    }),
    siteTag: yup.object().shape({
        variable: yup.string().min(1).required(requiredField),
        value: yup
            .string()
            .min(1)
            .required(requiredField)
            .matches(validCharacters, validCharactersMessage)
            .max(nameCharacterLimit, maximumCharacters),
    }),
    cameraClass: yup.object().shape({
        variable: yup.string().min(1).required(requiredField),
    }),
    cameraConfig: yup.object().shape({
        tripwire: yup.array().of(
            yup.object().shape({
                name: yup
                    .string()
                    .min(1)
                    .required(requiredField)
                    .matches(validCharacters, validCharactersMessage)
                    .max(nameCharacterLimit, insertInTemplate(maximumCharactersLimit, nameCharacterLimit.toString())),
            }),
        ),
        zone: yup.array().of(
            yup.object().shape({
                name: yup
                    .string()
                    .min(1)
                    .required(requiredField)
                    .matches(validCharacters, validCharactersMessage)
                    .max(nameCharacterLimit, insertInTemplate(maximumCharactersLimit, nameCharacterLimit.toString())),
            }),
        ),
    }),
    gateway: yup.object().shape({
        ...baseGatewaySchema,
        isGatewayCreateButtonClicked: yup.boolean().test("buttonClicked", requiredAction, (value) => !!value),
    }),
    editGateway: yup.object().shape(baseGatewaySchema),
    device: yup.object().shape({
        deviceName: yup
            .string()
            .min(1)
            .required(requiredField)
            .max(nameCharacterLimit, maximumCharacters)
            .matches(validCharacters, validCharactersMessage),
        deviceType: yup.string().min(1).required(requiredField),
        deviceUsername: yup.string().min(1).required(requiredField),
        devicePassword: yup
            .string()
            .min(8, insertInTemplate(minimumCharacters, passwordMinimumCharactersRequired.toString()))
            .required(requiredField),
        deviceUrl: yup.string().min(1).required(requiredField).max(512, maximumCharacters),
    }),
    blueprint: yup.object().shape({
        blueprintType: yup.string().min(1).required(requiredField),
    }),
};
