import moment from "moment";

import { DropdownSingleSelectOption, DropdownMultiSelectOption, GranularityMinutes } from "./Reporting";
import { Reports } from "../../../services/WebService/Reporting/ReportingService";

type DateRange = {
    startDate: moment.Moment;
    endDate: moment.Moment;
};

// See: https://stackoverflow.com/a/39637877
function roundDate(date: moment.Moment, duration: moment.Duration, method: "ceil" | "floor") {
    return moment(Math[method]((+date) / (+duration)) * (+duration));
}

export const getBucketStartDate = (
    selectedDateRange: DateRange,
    datapointPrecisionMinutes: GranularityMinutes,
) => 
    datapointPrecisionMinutes === 44640
    ? moment(selectedDateRange.startDate).startOf("month")
    : datapointPrecisionMinutes === 10080
    ? (() => {
        const startDate = selectedDateRange.startDate;
        const diffFromStandardStartOfWeek = startDate.diff(moment(startDate).startOf("week"), "days");
        const keyDate = moment(selectedDateRange.startDate).startOf("week").add(diffFromStandardStartOfWeek, "days");
        return keyDate;
    })()
    : moment(selectedDateRange.startDate)

export const timestampForIndex = (
    index: number,
    selectedDateRange: DateRange,
    datapointPrecisionMinutes: GranularityMinutes,
) => datapointPrecisionMinutes === 44640
    ? moment(getBucketStartDate(selectedDateRange, datapointPrecisionMinutes))
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .add(index, "months")
        .format()
    : datapointPrecisionMinutes === 10080
    ? moment(getBucketStartDate(selectedDateRange, datapointPrecisionMinutes))
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .add(index, "weeks")
        // .add(index * datapointPrecisionMinutes, "minutes")
        .format()
    : datapointPrecisionMinutes === 1440
    ? moment(getBucketStartDate(selectedDateRange, datapointPrecisionMinutes))
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .add(index, "days")
        // .add(index * datapointPrecisionMinutes, "minutes")
        .format()
    : moment(getBucketStartDate(selectedDateRange, datapointPrecisionMinutes))
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .add(index * datapointPrecisionMinutes, "minutes")
        .format()

const getTimestampKey = (
    timestamp: string,
    selectedDateRange: DateRange,
    datapointPrecisionMinutes: GranularityMinutes,
) => {
    if (datapointPrecisionMinutes === 1440) {
        const keyDate = moment(timestamp).startOf("day");
        return keyDate.format();
    } else if (datapointPrecisionMinutes === 10080) {
        const startDate = getBucketStartDate(selectedDateRange, datapointPrecisionMinutes);
        const diffFromStandardStartOfWeek = startDate.diff(moment(startDate).startOf("week"), "days");
        const keyDate = moment(timestamp).startOf("week").add(diffFromStandardStartOfWeek, "days");

        return keyDate.format();
    } else if (datapointPrecisionMinutes === 44640) {
        const keyDate = moment(timestamp).startOf("month");
        return keyDate.format();
    } else {
        const keyDate = roundDate(moment(timestamp), moment.duration(datapointPrecisionMinutes, "minutes"), "floor")
        return keyDate.format();
    }
};

export const aggregateTriplineTelemetry = (
    selectedDateRange: DateRange,
    selectedDateRangeLength: number,
    datapointPrecisionMinutes: GranularityMinutes,
    telemetryRaw: Array<Reports.Web.TelemetryPacketTripline>,
    objectsLookup: { [id: string]: DropdownMultiSelectOption },
    locationsLookup: { [id: string]: DropdownSingleSelectOption },
    selectedMetric: string | null,
    selectedObjects: Array<string>,
    selectedLocation: string | null,
    selectedAggregateObjects: Array<string>,
    selectedAggregateTripLines: Array<string>,
) => {
    // TODO: Add more unit test coverage. Use the following to extract inputs/outputs.
    // console.log("input", JSON.stringify({
    //     selectedDateRange,
    //     selectedDateRangeLength,
    //     datapointPrecisionMinutes,
    //     telemetryRaw,
    //     objectsLookup,
    //     locationsLookup,
    //     selectedMetric,
    //     selectedObjects,
    //     selectedLocation,
    //     selectedAggregateObjects,
    //     selectedAggregateTripLines,
    // }));
    const aggregatedObjectsIds = selectedAggregateObjects.length > 0
        ? selectedAggregateObjects.map(e => objectsLookup[e].itemId).join(", ")
        : null
    const aggregatedLocationsIds = selectedAggregateTripLines.length > 0
        ? selectedAggregateTripLines.map(e => locationsLookup[e].itemId).join(", ")
        : null
    const aggregatedObjectsKey = selectedAggregateObjects.length > 0
        ? selectedAggregateObjects.map(e => objectsLookup[e].displayText).join(", ")
        : null
    const aggregatedLocationsKey = selectedAggregateTripLines.length > 0
        ? selectedAggregateTripLines.map(e => locationsLookup[e].displayText).join(", ")
        : null

    const aggregated = telemetryRaw
        // TODO: add site checks.
        .filter(e => (
            moment(e.timestamp).isSameOrAfter(selectedDateRange.startDate, "date") &&
            moment(e.timestamp).isSameOrBefore(selectedDateRange.endDate, "date") &&
            (
                selectedAggregateObjects.length > 0
                    ? selectedAggregateObjects.includes(e.className)
                    : selectedObjects.includes(e.className)
            ) &&
            (
                selectedAggregateTripLines.length > 0
                    ? selectedAggregateTripLines.includes(e.location)
                    : e.location === selectedLocation
            )
        ))
        .reduce((a, e) => ({
            ...a,
            [aggregatedObjectsKey || e.className]: {
                object: {
                    id: aggregatedObjectsIds || objectsLookup[e.className]?.itemId,
                    displayName: aggregatedObjectsKey || objectsLookup[e.className]?.displayText,
                },
                location: {
                    id: aggregatedLocationsIds || locationsLookup[e.location]?.itemId,
                    displayName: aggregatedLocationsKey || locationsLookup[e.location]?.displayText,
                },
                data: (a[aggregatedObjectsKey || e.className]?.data || []).concat(e),
            },
        }), {} as { [className: string]: {
            object: {
                id: string;
                displayName: string;
            };
            location: {
                id: string;
                displayName: string;
            };
            data: Array<Reports.Web.TelemetryPacketTripline>;
        } });

    const output = Object.keys(aggregated)
        .map(e => {
            const dataByTimestamp = aggregated[e].data
                .reduce((a, e) => {
                    const key = getTimestampKey(e.timestamp, selectedDateRange, datapointPrecisionMinutes);
                    return {
                        ...a,
                        [key]: (a[key] || []).concat(e),
                    };
                }, {} as { [timestamp: string]: Array<Reports.Web.TelemetryPacketTripline> })

            return {
                object: aggregated[e].object,
                location: aggregated[e].location,
                data: new Array(Math.ceil(selectedDateRangeLength * 24 * 60 / datapointPrecisionMinutes)).fill(0)
                    .map((_, i) => {
                        // TODO: discuss with Product average of averages intricacies and inconsistency at different granularity levels, as there are many periods which are being inconsistently excluded (due to no data).
                        const timestamp = timestampForIndex(i, selectedDateRange, datapointPrecisionMinutes);
                        const plusEventCount = dataByTimestamp[timestamp]?.filter(e => isNaN(e.plusCount) === false).length;
                        const minusEventCount = dataByTimestamp[timestamp]?.filter(e => isNaN(e.minusCount) === false).length;
                        const plusCountHasValidCount = plusEventCount > 0;
                        const minusCountHasValidCount = minusEventCount > 0;
                        const plusCount = plusCountHasValidCount
                            ? dataByTimestamp[timestamp]?.reduce((a, e, i) => a + (e.plusCount || 0), 0) ?? NaN
                            : NaN;
                        const minusCount = minusCountHasValidCount
                            ? dataByTimestamp[timestamp]?.reduce((a, e, i) => a + (e.minusCount || 0), 0) ?? NaN
                            : NaN
                        const value = selectedMetric === "trafficin"
                            ? Math.abs(plusCount)
                            : selectedMetric === "trafficout"
                            ? Math.abs(minusCount)
                            : selectedMetric === "totaltraffic"
                            ? Math.abs(plusCount) + Math.abs(minusCount)
                            : null
                        return {
                            timestamp,
                            value,
                        };
                    })
            };
        }, [])


    // TODO: Add more unit test coverage. Use the following to extract inputs/outputs.
    // console.log("output", JSON.stringify(output));

    return output;
};

export const aggregatePolygonTelemetry = (
    selectedDateRange: DateRange,
    selectedDateRangeLength: number,
    datapointPrecisionMinutes: GranularityMinutes,
    telemetryRaw: Array<Reports.Web.TelemetryPacketPolygon>,
    objectsLookup: { [id: string]: DropdownMultiSelectOption },
    locationsLookup: { [id: string]: DropdownSingleSelectOption },
    selectedMetric: string | null,
    selectedObjects: Array<string>,
    selectedLocation: string | null,
    selectedAggregateObjects: Array<string>,
    selectedAggregatePolygons: Array<string>,
) => {
    // TODO: Add more unit test coverage. Use the following to extract inputs/outputs.
    // console.log("input", JSON.stringify({
    //     selectedDateRange,
    //     selectedDateRangeLength,
    //     datapointPrecisionMinutes,
    //     telemetryRaw,
    //     objectsLookup,
    //     locationsLookup,
    //     selectedMetric,
    //     selectedObjects,
    //     selectedLocation,
    //     selectedAggregateObjects,
    //     selectedAggregatePolygons,
    // }));
    const aggregatedObjectsIds = selectedAggregateObjects.length > 0
        ? selectedAggregateObjects.map(e => objectsLookup[e].itemId).join(", ")
        : null
    const aggregatedLocationsIds = selectedAggregatePolygons.length > 0
        ? selectedAggregatePolygons.map(e => locationsLookup[e].itemId).join(", ")
        : null
    const aggregatedObjectsKey = selectedAggregateObjects.length > 0
        ? selectedAggregateObjects.map(e => objectsLookup[e].displayText).join(", ")
        : null
    const aggregatedLocationsKey = selectedAggregatePolygons.length > 0
        ? selectedAggregatePolygons.map(e => locationsLookup[e].displayText).join(", ")
        : null

    const aggregated = telemetryRaw
        // TODO: add site checks.
        .filter(e => (
            moment(e.timestamp).isSameOrAfter(selectedDateRange.startDate, "date") &&
            moment(e.timestamp).isSameOrBefore(selectedDateRange.endDate, "date") &&
            (
                selectedAggregateObjects.length > 0
                    ? selectedAggregateObjects.includes(e.className)
                    : selectedObjects.includes(e.className)
            ) &&
            (
                selectedAggregatePolygons.length > 0
                    ? selectedAggregatePolygons.includes(e.location)
                    : e.location === selectedLocation
            )
        ))
        .reduce((a, e) => ({
            ...a,
            [aggregatedObjectsKey || e.className]: {
                object: {
                    id: aggregatedObjectsIds || objectsLookup[e.className]?.itemId,
                    displayName: aggregatedObjectsKey || objectsLookup[e.className]?.displayText,
                },
                location: {
                    id: aggregatedLocationsIds || locationsLookup[e.location]?.itemId,
                    displayName: aggregatedLocationsKey || locationsLookup[e.location]?.displayText,
                },
                data: (a[aggregatedObjectsKey || e.className]?.data || []).concat(e),
            },
        }), {} as { [className: string]: {
            object: {
                id: string;
                displayName: string;
            };
            location: {
                id: string;
                displayName: string;
            };
            data: Array<Reports.Web.TelemetryPacketPolygon>;
        } });

    const output = Object.keys(aggregated)
        .map(e => {
            const dataByTimestamp = aggregated[e].data
                .reduce((a, e) => {
                    const key = getTimestampKey(e.timestamp, selectedDateRange, datapointPrecisionMinutes);
                    return {
                        ...a,
                        [key]: (a[key] || []).concat(e),
                    };
                }, {} as { [timestamp: string]: Array<Reports.Web.TelemetryPacketPolygon> })

            return {
                object: aggregated[e].object,
                location: aggregated[e].location,
                data: new Array(Math.ceil(selectedDateRangeLength * 24 * 60 / datapointPrecisionMinutes)).fill(0)
                    .map((_, i) => {
                        const timestamp = timestampForIndex(i, selectedDateRange, datapointPrecisionMinutes);

                        // for dwell time, ignore any zero counts.
                        // TODO: discuss with Product average of averages intricacies and inconsistency at different granularity levels, as there are many periods which are being inconsistently excluded (due to no data).

                        // Get the first count so we can offset the total occupancy value by entries, for our dwell-time weighted-average calculations and total-occupancy aggregation calculations.
                        // Note: this is primarily due to the total occupancy metric being defined with a cumulative quantity that needs to be considered for correction (we don't want to double count)
                        // Note: Total Occupancy (Total Traffic) = Total number of objects *entered* the polygon in any time window + *number of occupants* from previous time frame.
                        // See: https://confluence.tools.telstra.com/display/IOTSV/Polygon+Aggregation
                        const firstBucketDatapoint = dataByTimestamp[timestamp]?.sort((a, b) => new Date(a.timestamp).valueOf() - new Date(b.timestamp).valueOf())?.[0];

                        const totalOccupancyEventCount = dataByTimestamp[timestamp]?.filter(e => isNaN(e.totalOccupancy) === false).length;
                        const dwellTimeEventCount = dataByTimestamp[timestamp]?.filter(e => isNaN(e.dwellTime) === false && e.dwellTime !== 0).length;
                        const totalOccupancyCountHasValidCount = totalOccupancyEventCount > 0;
                        const dwellTimeCountHasValidCount = dwellTimeEventCount > 0;
                        const totalOccupancy = totalOccupancyCountHasValidCount
                            ? (dataByTimestamp[timestamp]?.reduce((a, e, i) => a + (e.entries), 0) ?? NaN) + (
                                (firstBucketDatapoint?.totalOccupancy ?? NaN)
                                - (firstBucketDatapoint?.entries ?? NaN)
                            )
                            : NaN;
                        const dwellTime = dwellTimeCountHasValidCount
                            // ? (dataByTimestamp[timestamp]?.reduce((a, e, i) => a + ((e.dwellTime || 0) * ((e.totalOccupancy || 0) - (e.entries || 0))), 0) / dataByTimestamp[timestamp]?.reduce((a, e, i) => a + (e.totalOccupancy || 0), 0)) ?? NaN // Note: `e.dwellTime` represents the bucket average, so need to applying normalisation + weighting to not lead to an "average of averages" problem.
                            ? ( // Note: `e.dwellTime` represents the bucket average, so need to applying normalisation + weighting to not lead to an "average of averages" problem.
                                dataByTimestamp[timestamp]?.reduce((a, e, i) => a + ((e.dwellTime || 0) * (e.totalOccupancy || 0)), 0)
                                / (
                                    ((dataByTimestamp[timestamp]?.reduce((a, e, i) => a + (e.entries || 0), 0)) ?? NaN)
                                    + (
                                        (firstBucketDatapoint?.totalOccupancy ?? NaN)
                                        - (firstBucketDatapoint?.entries ?? NaN)
                                    )
                                )
                            )
                            : NaN;

                        const value = selectedMetric === "totaloccupancy"
                            ? Math.abs(totalOccupancy)
                            : selectedMetric === "dwelltime"
                            ? Math.abs(dwellTime)
                            : null
                        return {
                            timestamp,
                            value,
                        };
                    })
            };
        }, [])


    // TODO: Add more unit test coverage. Use the following to extract inputs/outputs.
    // console.log("output", JSON.stringify(output));

    return output;
};
