import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { Body16, Body18, Heading20, greyL1, greyL2, Spinner } from "telstra-components";
import { Button } from "telstra-ui/components/button/Button";
import { FormData } from "./ConfigureCameraModal";
import { GenericAlert } from "telstra-ui/components/generic-alert/GenericAlert";
import CustomTextField from "../../../smart-video/components/Fields/CustomTextField";

type ConnectCameraType = {
    formData: FormData;
    isLoading: boolean;
    gatewayError: boolean;
    deviceName: string;
    onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onConnectCamera: () => void;
};

const ConnectCamera: React.FC<ConnectCameraType> = ({
    formData,
    isLoading,
    gatewayError,
    deviceName,
    onInputChange,
    onConnectCamera,
}) => {
    const disableNext = () => {
        return formData.deviceName && formData.rtspStream && formData.username && formData.password ? false : true;
    };

    return (
        <Container>
            <FormContainer>
                <Heading20 style={{ marginBottom: 10 }}>Enter camera details to connect it to the gateway</Heading20>
                <DescriptionContainer>
                    <Body16>
                        Information needed below to connect the camera to the Gateway device can be found in the Local
                        Camera Portal.
                    </Body16>
                </DescriptionContainer>
                <TextFieldRowContainer>
                    <GatewayContainer>
                        <Body18 regular color={greyL1}>
                            Gateway device:
                        </Body18>
                        <GatewayText aria-label={`Gateway device ${formData.gatewayDevice}`} color={greyL2}>
                            {formData.gatewayDevice}
                        </GatewayText>
                    </GatewayContainer>
                    <CameraContainer>
                        <InputTextContainer>
                            <Body18 regular color={greyL1}>
                                <label htmlFor="camera-name-input">Camera name:</label>
                            </Body18>
                        </InputTextContainer>
                        <InputContainer>
                            <CustomTextField
                                id="camera-name-input"
                                prefixLabel={deviceName}
                                placeholder={""}
                                defaultValue={formData.deviceName}
                                helperText={`E.g Camera 1 - Main entrance north`}
                                onChange={onInputChange}
                                name="deviceName"
                            />
                        </InputContainer>
                    </CameraContainer>
                    <StreamContainer>
                        <InputTextContainer>
                            <Body18 regular color={greyL1}>
                                <label htmlFor="rtsp-stream-input">RTSP stream:</label>
                            </Body18>
                        </InputTextContainer>
                        <InputContainer>
                            <CustomTextField
                                id="rtsp-stream-input"
                                placeholder={"rtsp://"}
                                defaultValue={formData.rtspStream}
                                onChange={onInputChange}
                                name="rtspStream"
                            />
                        </InputContainer>
                    </StreamContainer>
                    <UsernameContainer>
                        <InputTextContainer>
                            <Body18 regular color={greyL1}>
                                <label htmlFor="username-input">User name:</label>
                            </Body18>
                        </InputTextContainer>
                        <InputContainer>
                            <CustomTextField
                                id="username-input"
                                placeholder={"Enter..."}
                                defaultValue={formData.username}
                                onChange={onInputChange}
                                name="username"
                            />
                        </InputContainer>
                    </UsernameContainer>
                    <PasswordContainer>
                        <InputTextContainer>
                            <Body18 regular color={greyL1}>
                                <label htmlFor="password-input">Password:</label>
                            </Body18>
                        </InputTextContainer>
                        <InputContainer>
                            <CustomTextField
                                id="password-input"
                                placeholder={"Enter..."}
                                defaultValue={formData.password}
                                onChange={onInputChange}
                                name="password"
                            />
                        </InputContainer>
                    </PasswordContainer>
                </TextFieldRowContainer>
                <ConnectCameraContainer>
                    <ConnectButtonContainer>
                        <Button
                            iconPosition="right"
                            icon={!isLoading ? "icon-system-arrow" : "none"}
                            variant="primary"
                            size="medium"
                            label={"Connect camera"}
                            onClick={onConnectCamera}
                            disabled={disableNext()}
                        />
                    </ConnectButtonContainer>
                    <SpinnerContainer>
                        <Spinner isVisible={isLoading} size="small" />
                    </SpinnerContainer>
                </ConnectCameraContainer>
            </FormContainer>
            {gatewayError && (
                <AlertContainer>
                    <GenericAlert
                        name="warning"
                        description="Camera could not be connected to the gateway. Please check information entered and network connection and try again."
                        showBackground={true}
                        customStyle={{ height: "48px", fontSize: "14px", marginTop: "20px" }}
                        isMobileView={false}
                    />
                </AlertContainer>
            )}
        </Container>
    );
};

export default ConnectCamera;

const Container = styled.div`
    margin-top: -13px;
`;

const InputTextContainer = styled.div`
    margin-top: 15px;
`;

const InputContainer = styled.div`
    width: 362px;
`;

const SpinnerContainer = styled.div`
    position: absolute;
    top: -14px;
    right: -25px;
`;

const AlertContainer = styled.div`
    margin-top: -14px;

    .tl-generic-alert-container .tl-alert-box .tl-container-wrapper {
        padding: 12px 0px 0px 20px;
        width: 910px;
    }

    .tl-generic-alert-container .tl-alert-box .tl-generic-body-small {
        margin-top: 0px;
    }
`;

const DescriptionContainer = styled.p`
    width: 544px;
`;

const GatewayContainer = styled.div`
    margin-left: -13px;
    margin-top: 35px;
`;

const ConnectCameraContainer = styled.div`
    position: relative;
    padding-left: 565px;
`;

const FormContainer = styled.div`
    margin-top: 54px;
    margin-left: 184px;
`;

const TextFieldRowContainer = styled.div`
    margin-left: 12px;
    width: 544px;
`;

const CameraContainer = styled.div`
    margin-left: -12px;
    margin-top: 29px;
    display: flex;
    justify-content: space-between;
`;

const StreamContainer = styled.div`
    margin-top: 9px;
    margin-left: -12px;
    display: flex;
    justify-content: space-between;
`;

const UsernameContainer = styled.div`
    margin-top: 36px;
    margin-left: -12px;
    display: flex;
    justify-content: space-between;
`;

const PasswordContainer = styled.div`
    margin-top: 35px;
    margin-left: -12px;
    display: flex;
    justify-content: space-between;
`;

const ConnectButtonContainer = styled.div`
    margin-top: 10px;
    .tl-button-primary-disabled,
    .tl-button-secondary-disabled {
        color: #aaa;
    }

    .tl-button-icon-color-Disabled {
        fill: #aaa;
        padding-bottom: 2px;
    }
`;

const GatewayText = styled.span<{ $textColor?: string }>`
    margin-left: 70px;
    font-size: 16px;
    color: ${(props) => (props.$textColor ? `${props.$textColor}` : "black")};
    font-weight: 300;
`;
