/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseProps, EntryType, ExtraProps, TreeDataProps } from "./types";
import { flatten, merge } from "./utils";

interface DiveProps<T extends BaseProps> {
  items: Array<T>;
  level: number;
  ancestors: Array<{
    id: string;
    type: EntryType;
  }>;
  parentId?: string;
}

const getTypeHierarchyLevel = (type: EntryType) =>
  type === "organisation"
  ? 0
  : type === "site"
  ? 1
  : type === "gateway"
  ? 2
  : type === "camera"
  ? 3
  : -1

/**
 * A recursive dive function for creating an ordered array of data with extra props.
 * @returns An ordered array of data with extra props
 */
const dive = <Entry extends TreeDataProps>({
  items,
  level,
  ancestors,
  parentId,
}: DiveProps<Entry>): Array<Entry & ExtraProps> => {
  // Checking the type as well as the parentId ensures that items with duplicate IDs across different item types do not cause stack overflow due to infinite recursion.
  const children = items.filter((item) => item.parentId === parentId && getTypeHierarchyLevel(item.type) === level + 1);

  if (level === 0) {
    return flatten(
      children
        .sort((a, b) => {
          const nameA = a.text?.toUpperCase() || ""; // ignore upper and lowercase
          const nameB = b.text?.toUpperCase() || ""; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        })
        .map((child) =>
          dive({
            items,
            level: level + 1,
            ancestors,
            parentId: child.id,
          }),
        ),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const selected: Entry = items.find((item) => item.id === parentId && getTypeHierarchyLevel(item.type) === level)!;

  const descendants: Array<Entry & ExtraProps> = flatten(
    children
      .sort((a, b) => {
        const nameA = a.text?.toUpperCase() || ""; // ignore upper and lowercase
        const nameB = b.text?.toUpperCase() || ""; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      })
      .map((child) =>
        dive({
          items,
          level: level + 1,
          ancestors: [...ancestors, { id: selected.id, type: selected.type }],
          parentId: child.id,
        }),
      ),
  );

  const extraProps: ExtraProps = {
    level,
    hasChildren: children.length > 0,
    descendants: descendants.map(({ id, type }) => ({ id, type })),
    ancestors,
  };

  return [merge(selected, extraProps), ...descendants];
};

const sortTreeData = <Entry extends BaseProps>(data: Array<Entry>): Array<Entry & ExtraProps> => {
  return dive({ items: data, level: 0, ancestors: [] });
};

export default sortTreeData;
