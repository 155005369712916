import { SmartSpaces } from "./SmartSpaces";
import {
    createRequest,
    errorCallback,
    maxContinuousWaitTime,
    continuousWaitTimeIncrease,
    createVideoRequest,
} from "./ServiceHandlers";
import { CaasOrgOverviewObject } from "./wca-api/Site";
/**
 * Get customer data, if request fails then retry with an exponentially increasing timeout (capped).
 * @param waitTime the amount the wait time before the request for more information will be sent out.
 * @param authToken the amount the wait time will be increased by.
 */
export const getCustomerWithRetry = async (
    waitTime: number,
    authToken: string,
): Promise<SmartSpaces.WebApp.Customer> => {
    // Wait for given timeout
    return new Promise((resolve) =>
        setTimeout(async () => {
            // Attempt to get the data
            await getCustomer(authToken).then(
                (success) => {
                    resolve(success);
                },
                async (error) => {
                    // Check if we have a 401 unauthorized.
                    await errorCallback(error);

                    // Calculate wait (1 = 1 second).
                    const wait = Math.min(waitTime + continuousWaitTimeIncrease, maxContinuousWaitTime);

                    // If fail attempt to check the magnitude of issue and handle
                    resolve(getCustomerWithRetry(wait, authToken));
                },
            );
        }, waitTime * 1000),
    );
};

/**
 * Get customer data, if request fails then retry with an exponentially increasing timeout (capped).
 * @param waitTime the amount the wait time before the request for more information will be sent out.
 * @param authToken the amount the wait time will be increased by.
 */
export const getPBIReportDataWithRetry = async (
    waitTime: number,
    authToken: string,
): Promise<{ expires_on: string; resource: string; access_token: string }> => {
    // Wait for given timeout
    return new Promise((resolve) =>
        setTimeout(async () => {
            // Attempt to get the data
            await getPBIReportData(authToken).then(
                (success) => {
                    resolve(success);
                },
                async (error) => {
                    // Check if we have a 401 unauthorized.
                    await errorCallback(error);

                    // Calculate wait (1 = 1 second).
                    const wait = Math.min(waitTime + continuousWaitTimeIncrease, maxContinuousWaitTime);

                    // If fail attempt to check the magnitude of issue and handle
                    resolve(getPBIReportDataWithRetry(wait, authToken));
                },
            );
        }, waitTime * 1000),
    );
};

/**
 * Gets the details of the customer
 *
 * @param token - the auth token
 */
const getCustomer = async (token: string): Promise<SmartSpaces.WebApp.Customer> => {
    const { body }: { body: string | null } = await createRequest({
        apiPath: `/customer-info`,
        method: "GET",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
    });

    const customer: SmartSpaces.WebApp.Customer = typeof body === "object" ? body : JSON.parse(body);

    // Explicitly pull out payload properties to avoid unexpected data from being passed back into the app.
    return {
        name: customer.name,
        logo: customer.logo,
        recentOccupancyDuration: customer.recentOccupancyDuration,
        pbiReports: customer.pbiReports,
    };
};

/**
 * Gets the details of the customer
 *
 * @param token - the auth token
 */
const getPBIReportData = async (
    token: string,
): Promise<{ expires_on: string; resource: string; access_token: string }> => {
    const { body }: { body: string | null } = await createVideoRequest({
        apiPath: `/reporting/pbi-embed-config`,
        method: "GET",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
    });

    const customer: { expires_on: string; resource: string; access_token: string } =
        typeof body === "object" ? body : JSON.parse(body);

    // Explicitly pull out payload properties to avoid unexpected data from being passed back into the app.
    return {
        expires_on: customer.expires_on,
        resource: customer.resource,
        access_token: customer.access_token,
    };
};

export const getCustomerOrgTreeObjects = async (token: string): Promise<Array<CaasOrgOverviewObject>> => {
    const { body } = await createVideoRequest({
        apiPath: `/caas/organisations/overview`,
        method: "GET",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
    });

    const orgOverviewTreeObjects: Array<CaasOrgOverviewObject> = typeof body === "object" ? body : JSON.parse(body);
    return orgOverviewTreeObjects;
};

/**
 * API Implementation - Customer Subscription Data
 * @param token - the auth token
 */
export const getCustomerFeatures = async (
    authToken: string,
    userTenancy: "DEFAULT" | "WCC" | "TRR" | "CCRC",
): Promise<Array<SmartSpaces.WebApp.Features.Navigation>> => {
    const subscriptionData: Array<SmartSpaces.WebApp.Features.Navigation> = [];

    subscriptionData.push({
        label: "Administration",
        path: "/administration",
        enabled: true,
        message: "You need to have a device subscription",
    });

    subscriptionData.push({
        label: "Reporting",
        path: "/reporting",
        enabled: true,
        message: "You need to have a access to reports",
    });

    return subscriptionData;
};
