import React, { FC } from "react";
import { DropdownOption } from "@sv/types";
import { Box } from "telstra-components";
import DropDownRow from "./DropDownRow";
import strings from "../../strings/strings.json";

const { bpDeviceDropDownLabel, bpBluePrintTypeDropDownLabel } = strings.components.Forms.BlueprintForm;

interface BlueprintFormProps {
    devicesOptions: Array<DropdownOption>;
}

const BlueprintForm: FC<BlueprintFormProps> = ({ devicesOptions }: BlueprintFormProps) => {
    return (
        <Box>
            <DropDownRow label={bpDeviceDropDownLabel} fieldName="deviceBlueprint" options={devicesOptions} />
            <Box marginTop="s">
                <DropDownRow
                    label={bpBluePrintTypeDropDownLabel}
                    fieldName="blueprintType"
                    options={[{ id: "1", label: "Object Detection", value: "Object Detection" }]}
                    initialValue="Object Detection"
                />
            </Box>
        </Box>
    );
};

export default BlueprintForm;
