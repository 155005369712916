import React, { FC, useState } from "react";
import { cameraClassVariableOptions, cameraTagVariableOptions, maxTagNumber } from "../../../config";
import { AddTag, Box } from "telstra-components";
import ClassTagList from "../../Tag/ClassTagList";
import { DropdownOption, ClassTag } from "../../../types";
import TagModal from "../../Modals/TagModal";
import strings from "../../../strings/strings.json";
import ClassModal from "../../Modals/ClassModal/ClassModal";

const { addTagButtonLabel, addClassButtonLabel } = strings.views.CameraConfig;
const { description, heading, valueTooltip, variableTooltip } = strings.components.Modals.TagModal.AnalyticsTag;

interface ClassTagPanelProps {
    tags: Array<ClassTag>;
    classes: Array<string>;
    onSubmitNewTag?: (tag: ClassTag) => void;
    onTagClick?: (tag: ClassTag) => void;
    onSubmitNewClass?: (value: string) => void;
    onClassClick?: (value: string) => void;
}

const ClassTagPanel: FC<ClassTagPanelProps> = ({
    tags,
    classes,
    onSubmitNewTag,
    onTagClick,
    onSubmitNewClass,
    onClassClick,
}) => {
    const [tagModalVisible, setTagModalVisible] = useState<boolean>(false);
    const [classModalVisible, setClassModalVisible] = useState<boolean>(false);

    const handleTagClick = (tag: ClassTag) => {
        onTagClick && onTagClick(tag);
    };

    const handleClassClick = (value: string) => {
        onClassClick && onClassClick(value);
    };
    const handleAddTagClick = () => setTagModalVisible(true);

    const handleClassTagClick = () => setClassModalVisible(true);

    const handleTagModalToggle = () => setTagModalVisible(false);
    const handleClassModalToggle = () => setClassModalVisible(false);

    const handleSubmitNewTag = (tag: ClassTag) => {
        onSubmitNewTag && onSubmitNewTag(tag);
    };

    const handleSubmitNewClass = (value: string) => {
        onSubmitNewClass && onSubmitNewClass(value);
    };

    const filterCameraClassesVariableOptions = (option: DropdownOption) => {
        return !classes?.includes(option.value);
    };

    const remainingClasses = cameraClassVariableOptions.filter(filterCameraClassesVariableOptions);

    const hasAddTag = tags.length < maxTagNumber;
    const hasAddClass = remainingClasses.length > 0;

    return (
        <>
            <ClassTagList tags={tags} classes={classes} onTagClick={handleTagClick} onClassClick={handleClassClick} />
            <Box display="flex" gap="xxxs">
                {hasAddTag && <AddTag onClick={handleAddTagClick} label={addTagButtonLabel} />}
                {hasAddClass && (
                    <AddTag ariaLabel="Add Class" onClick={handleClassTagClick} label={addClassButtonLabel} />
                )}
            </Box>
            {tagModalVisible && (
                <TagModal
                    description={description}
                    heading={heading}
                    valueTooltip={valueTooltip}
                    variableTooltip={variableTooltip}
                    onTagModalToggle={handleTagModalToggle}
                    onSubmit={handleSubmitNewTag}
                    variableOptions={cameraTagVariableOptions}
                />
            )}
            {classModalVisible && (
                <ClassModal
                    onToggle={handleClassModalToggle}
                    onSubmit={handleSubmitNewClass}
                    variableOptions={remainingClasses}
                />
            )}
        </>
    );
};

export default ClassTagPanel;
