import React, { ReactElement } from "react";
import styled from "styled-components";

interface Props {
  fill?: string;
}

const SVGIconOrderTrackerTodo = ({ fill }: Props): ReactElement => {
  return (
    <SVGWrapper
      id="orderTrackerDone"
      height="100%"
      fillColor={fill || ""}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <title>Order Tracker Todo</title>
      <g fill="#FFF" stroke={fill}>
        <g transform="translate(-202.000000, -486.000000) translate(170.000000, 91.000000) translate(32.000000, 160.000000) translate(0.000000, 235.000000) translate(5.000000, 5.000000)">
          <circle cx="7" cy="7" r="7" />
        </g>
      </g>
    </SVGWrapper>
  );
};

export default SVGIconOrderTrackerTodo;

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
