import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button } from "telstra-ui/components/button/Button";
import { Heading24, Modal as TriplineModal, greyL1 } from "telstra-components";
import { DropdownMultiSelect, IDropdownOption } from "../../../../views/Report/Reporting/DropdownMultiSelect";
import { TextField, ConfirmationDestructiveDialog, useToggle } from "@able/react";
import IconButton from "telstra-ui/components/button/IconButton";
import { FormData } from "../ConfigureCameraModal";
import TagInfoModal from "../../TagInfoModal/TagInfoModal";
import { TagInfoModalContent } from "../../../../content/TagInfoModalContent";
import { TagManagerService } from "../../../../services/WebService/TagManagerService";
import { Tag } from "@sv/types";
import { CameraObjectTripwire, CameraObjectZone } from "./CameraConfigView";
import LoadingModal from "../../Loading/LoadingModal";

export type TriplineConfig = {
    id: string;
    name: string;
    objectClasses: Array<string>;
    largeAreaTags: Array<Tag>;
    crossingTags: Array<Tag>;
};

export type EditTripwireModalProps = {
    isOpen: boolean;
    formData: FormData;
    isEditing: boolean;
    selectedObject?: CameraObjectTripwire | CameraObjectZone;
    onCancel: () => void;
    onDelete: () => void;
    onSubmit: (tripline: TriplineConfig) => void;
};

const EditTripwireModal: React.FC<EditTripwireModalProps> = ({
    isOpen,
    formData,
    isEditing,
    selectedObject,
    onCancel,
    onDelete,
    onSubmit,
}) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const focusableElements = useRef<Array<HTMLElement | null>>([]);
    const [showLargeAreaTagsInfoModal, setShowLargeAreaTagsInfoModal] = useState(false);
    const [showCrossingTagsInfoModal, setShowCrossingTagsInfoModal] = useState(false);
    interface SelectedData {
        selectedObjects: Array<string>;
        selectedLargeAreaTags: Array<string>;
        selectedCrossingTags: Array<string>;
    }

    const [selectedDropdownData, setSelectedDropdownData] = useState<SelectedData>({
        selectedObjects: [],
        selectedLargeAreaTags: [],
        selectedCrossingTags: [],
    });
    const [triplineName, setTriplineName] = useState("");
    const [customerTags, setCustomerTags] = useState<Array<Tag>>([]);
    const [deleteModalIsOpen, toggleDeleteModalIsOpen] = useToggle();
    const [timerComplete, setTimerComplete] = useState(false);
    const [deletingTripline, setDeletingTripline] = useState(false);

    useEffect(() => {
        if (modalRef.current) {
            focusableElements.current = Array.from(
                modalRef.current.querySelectorAll(
                    'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select',
                ),
            );
        }

        if (isOpen && focusableElements.current.length > 0) {
            focusableElements.current[0]?.focus();
        }
    }, [isOpen]);

    const generateObjectOptions = () => {
        const objectOptions: Array<IDropdownOption> = formData.detectionObjects.map((obj) => {
            const isActive = selectedObject && selectedObject?.objectClasses.includes(obj.itemId);
            const objectDropdownOption = {
                displayText: obj.displayText,
                itemId: obj.itemId,
                active: isActive || false,
            };
            return objectDropdownOption;
        });
        return objectOptions;
    };

    const updateFilterSelections = (updatedSelections: {
        updatedSelectedObjects?: Array<string>;
        updatedSelectedLargeAreaTags?: Array<string>;
        updatedSelectedCrossingTags?: Array<string>;
    }) => {
        if (updatedSelections.updatedSelectedObjects) {
            setSelectedDropdownData({
                ...selectedDropdownData,
                selectedObjects: updatedSelections.updatedSelectedObjects || selectedDropdownData.selectedObjects,
            });
        } else if (updatedSelections.updatedSelectedLargeAreaTags) {
            setSelectedDropdownData({
                ...selectedDropdownData,
                selectedLargeAreaTags:
                    updatedSelections.updatedSelectedLargeAreaTags || selectedDropdownData.selectedLargeAreaTags,
            });
        } else if (updatedSelections.updatedSelectedCrossingTags) {
            setSelectedDropdownData({
                ...selectedDropdownData,
                selectedCrossingTags:
                    updatedSelections.updatedSelectedCrossingTags || selectedDropdownData.selectedCrossingTags,
            });
        }
    };

    useEffect(() => {
        if (selectedObject?.type === "tripwire" && isEditing === true) {
            const initSelectedObjects = generateObjectOptions()
                .filter((option) => option.active)
                .map((option) => option.itemId.toString());

            const largeAreaTagIds = selectedObject.largeAreaTags.map((tag) => {
                return tag.id.toString();
            });

            const crossingTagIds = selectedObject.crossingTags.map((tag) => {
                return tag.id.toString();
            });

            setSelectedDropdownData({
                ...selectedDropdownData,
                selectedObjects: initSelectedObjects,
                selectedLargeAreaTags: largeAreaTagIds,
                selectedCrossingTags: crossingTagIds,
            });
        }

        if (!isEditing) {
            setSelectedDropdownData({ selectedObjects: [], selectedLargeAreaTags: [], selectedCrossingTags: [] });
            setTriplineName("");
        }
    }, [isOpen]);

    useEffect(() => {
        // -- force focus to info modal when opening for accessibility
        if (showLargeAreaTagsInfoModal || showCrossingTagsInfoModal) {
            const closeButton = document.querySelector(".info-modal") as HTMLDivElement | null;
            if (closeButton) {
                closeButton.focus();
            }
        }
    }, [showLargeAreaTagsInfoModal, showCrossingTagsInfoModal]);

    const handleSaveAndClose = () => {
        const triplineData: TriplineConfig = {
            id: isEditing && selectedObject ? selectedObject?.id : triplineName,
            name: triplineName,
            objectClasses: selectedDropdownData.selectedObjects,
            largeAreaTags: customerTags.filter((tag) =>
                selectedDropdownData.selectedLargeAreaTags.includes(tag.id.toString()),
            ),
            crossingTags: customerTags.filter((tag) =>
                selectedDropdownData.selectedCrossingTags.includes(tag.id.toString()),
            ),
        };

        onSubmit(triplineData);
        setTriplineName("");
        setSelectedDropdownData({ selectedObjects: [], selectedLargeAreaTags: [], selectedCrossingTags: [] });
        onCancel();
    };

    const onUpdateTriplineName = {
        onChange: (element) => {
            setTriplineName(element.target.value);
        },
    };

    useEffect(() => {
        let isCancelled = false;

        TagManagerService()
            .getTags()
            .then((newTags) => {
                if (!isCancelled) {
                    setCustomerTags(newTags);
                }
            });

        return () => {
            isCancelled = true;
        };
    }, [isOpen]);

    const buttonEvents = {
        onClick: () => {
            setDeletingTripline(true);
            setTimerComplete(false);
            setSelectedDropdownData({ selectedObjects: [], selectedLargeAreaTags: [], selectedCrossingTags: [] });
        },
    };

    useEffect(() => {
        if (timerComplete) {
            setTriplineName("");
            onDelete();
        }
    }, [timerComplete]);

    useEffect(() => {
        if (isEditing && selectedObject?.name) {
            setTriplineName(selectedObject.name);
        }
    }, [isEditing, selectedObject]);

    const onCloseModal = () => {
        setTriplineName("");
        onCancel();
    };

    return (
        <Container>
            <TriplineModal isOpen={isOpen} onClose={() => onCloseModal()} showCloseButton={false} size="medium">
                {showLargeAreaTagsInfoModal && (
                    <TagInfoModal
                        heading={TagInfoModalContent[0].name}
                        description={TagInfoModalContent[0].helperDescription}
                        exampleText={TagInfoModalContent[0].helperExample}
                        imgSrc={TagInfoModalContent[0].helperVisualSrc}
                        isOpen={showLargeAreaTagsInfoModal}
                        onShowModal={setShowLargeAreaTagsInfoModal}
                    />
                )}

                {showCrossingTagsInfoModal && (
                    <TagInfoModal
                        heading={TagInfoModalContent[1].name}
                        description={TagInfoModalContent[1].helperDescription}
                        exampleText={TagInfoModalContent[1].helperExample}
                        imgSrc={TagInfoModalContent[1].helperVisualSrc}
                        isOpen={showCrossingTagsInfoModal}
                        onShowModal={setShowCrossingTagsInfoModal}
                    />
                )}

                {deleteModalIsOpen && (
                    <DialogueContainer>
                        <ConfirmationDestructiveDialog
                            isShowing={deleteModalIsOpen}
                            setHideDialog={toggleDeleteModalIsOpen}
                            title="Are you sure you want to delete this trip line?"
                            description={
                                <span>
                                    <br />
                                    Once a trip line is deleted, it cannot be undone. All data linked to this trip line
                                    will be permanently deleted, affecting reporting and data analysis.
                                    <br />
                                    <br />
                                    You will not be able to see any past, current, or future data linked to this trip
                                    line once it is deleted.
                                    <br />
                                    <br />
                                </span>
                            }
                            stackButtonOnVXS={false}
                            confirmButtonLabel="Delete"
                            confirmButtonEvents={buttonEvents}
                            cancelButtonLabel="Cancel"
                            cancelButtonEvents={{}}
                            developmentUrl={`http://${window.location.host}/able-sprites.svg`}
                        />
                    </DialogueContainer>
                )}

                {deletingTripline && (
                    <LoadingModal
                        heading="Deleting Trip Line"
                        text={selectedObject?.name}
                        isOpen={deletingTripline}
                        progressTimeMs={1000}
                        onComplete={() => {
                            setDeletingTripline(false);
                            setTimerComplete(true);
                        }}
                    />
                )}

                <ModalContainer ref={modalRef}>
                    <ContentContainer>
                        <CloseButtonContainer aria-label="close trip line modal" data-testid="closeModalButton">
                            <Button
                                iconPosition="left"
                                icon={"icon-system-close"}
                                variant="primary"
                                size="medium"
                                onClick={() => {
                                    onCloseModal();
                                }}
                            />
                        </CloseButtonContainer>
                        <Heading24>{isEditing ? "Edit trip line settings" : "Create new trip line"}</Heading24>
                        <DescriptionContainer>
                            Trip-lines are used to count objects going in and out. If linked to enclosed areas they can
                            also be used to estimate occupancy.
                        </DescriptionContainer>
                        <FormContainer>
                            <TextFieldContainer>
                                <TextField
                                    id="tripline-form"
                                    name="tripline form"
                                    label="Enter the name of this trip line"
                                    helpText="E.g. ‘George St entrance’"
                                    events={onUpdateTriplineName}
                                    value={triplineName}
                                />
                            </TextFieldContainer>

                            <InputHeader>Select the object(s) which this trip line will detect</InputHeader>
                            <DropdownMultiSelect
                                id="object-detection-selector"
                                height={52}
                                options={generateObjectOptions().map((e) => ({
                                    ...e,
                                    active: selectedDropdownData.selectedObjects.includes(e.itemId.toString()),
                                }))}
                                onUpdateOptions={(options) => {
                                    updateFilterSelections({
                                        updatedSelectedObjects: options
                                            .filter((e) => e.active)
                                            .map((e) => e.itemId.toString()),
                                    });
                                }}
                            />

                            <InputHeader>
                                <HighlightText>(Optional)&nbsp;</HighlightText>
                                Select Large Area Tags linked to this trip line
                                <IconButtonContainer data-testid="largeAreaTagInfo">
                                    <IconButton
                                        icon="icon-system-information-solid"
                                        onClick={() => setShowLargeAreaTagsInfoModal(true)}
                                    />
                                </IconButtonContainer>
                            </InputHeader>
                            <SubLabel>Tags can be created and edited via the Administration Menu</SubLabel>
                            <DropdownMultiSelect
                                id="large-area-tags-selector"
                                height={52}
                                options={customerTags
                                    .filter((e) => e.category === "large area")
                                    .map((e) => ({
                                        displayText: e.name.toUpperCase(),
                                        itemId: e.id,
                                        active: selectedDropdownData.selectedLargeAreaTags.includes(e.id.toString()),
                                    }))}
                                onUpdateOptions={(options) => {
                                    updateFilterSelections({
                                        updatedSelectedLargeAreaTags: options
                                            .filter((e) => e.active)
                                            .map((e) => e.itemId.toString()),
                                    });
                                }}
                            />
                            <InputHeader>
                                <HighlightText>(Optional)&nbsp;</HighlightText>
                                Select Crossing Tags linked to this trip line
                                <IconButtonContainer data-testid="crossingTagInfo">
                                    <IconButton
                                        icon="icon-system-information-solid"
                                        onClick={() => setShowCrossingTagsInfoModal(true)}
                                    />
                                </IconButtonContainer>
                            </InputHeader>
                            <SubLabel>Tags can be created and edited via the Administration Menu</SubLabel>

                            <DropdownMultiSelect
                                id="crossing-tags-selector"
                                height={52}
                                options={customerTags
                                    .filter((e) => e.category === "crossing")
                                    .map((e) => ({
                                        displayText: e.name.toUpperCase(),
                                        itemId: e.id,
                                        active: selectedDropdownData.selectedCrossingTags.includes(e.id.toString()),
                                    }))}
                                onUpdateOptions={(options) => {
                                    updateFilterSelections({
                                        updatedSelectedCrossingTags: options
                                            .filter((e) => e.active)
                                            .map((e) => e.itemId.toString()),
                                    });
                                }}
                            />
                        </FormContainer>

                        <ButtonsContainer isEditing={isEditing}>
                            {isEditing && (
                                <Button variant="super" state="warning" onClick={() => toggleDeleteModalIsOpen()}>
                                    Delete trip line
                                </Button>
                            )}
                            <Button onClick={handleSaveAndClose}>Save and close</Button>
                        </ButtonsContainer>
                    </ContentContainer>
                </ModalContainer>
            </TriplineModal>
        </Container>
    );
};

export default EditTripwireModal;

const Container = styled.div``;

const DialogueContainer = styled.div`
    max-width: 560px;
`;

const IconButtonContainer = styled.div`
    margin-top: -15px;
    margin-bottom: -6px;
    margin-left: -4px;
`;

const FormContainer = styled.div`
    height: 443px;
    overflow: auto;
    margin-right: -15px;
`;

const SubLabel = styled.div`
    color: #707070;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: -15px;
`;

const TextFieldContainer = styled.div`
    width: 595px;
    margin-bottom: 16px;
`;

const ModalContainer = styled.div`
    max-width: 752px;
    height: 794px;
`;

const ContentContainer = styled.div`
    width: 620px;
    margin-top: 59px;
    margin-left: 48px;
`;

const CloseButtonContainer = styled.div`
    position: absolute;
    top: 18px;
    right: 18px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        fill: ${greyL1};
        margin: 0px !important;
        left: 0px !important;
    }
    button {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    span {
        width: 24px;
        height: 24px;
    }
`;

const ButtonsContainer = styled.div<{ isEditing: boolean }>`
    margin-top: 60px;
    margin-right: 28px;
    display: flex;
    justify-content: ${(props) => (props.isEditing ? "space-between" : "end")};
`;

const InputHeader = styled.div`
    color: #282828;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 2px;
    margin-top: 17px;
    display: flex;
`;

const HighlightText = styled.div`
    color: #0064d2;
`;

const DescriptionContainer = styled.div`
    color: #414141;
    font-family: Telstra Akkurat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.13px;
    margin-bottom: 32px;
`;
