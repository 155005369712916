import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
    fill?: string;
    title?: string;
}

export default class SVGIconSiteMonitor extends React.Component<Props> {
    /**
     * Sets colour depending on user input, if no colour given then set to a default of black.
     *
     * @private
     * @memberof SVGIconSiteMonitor
     */
    private fillColor = (): string => {
        // If no colour provided then default to black.
        if (!this.props.fill) {
            return "#000000";
        }
        // If we have a valid colour pass down.
        return this.props.fill;
    };

    /**
     * Render
     */
    public render(): ReactNode {
        return (
            <SVGWrapper
                width="100%"
                height="100%"
                fillColor={this.fillColor()}
                id="SiteMonitorIcon"
                data-name="Layer 5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 17.94 17.27"
            >
                <title>{this.props.title}</title>
                <path
                    d="M20.55,6H18.41V6A1.45,1.45,0,0,0,17,4.51H4.91A1.45,1.45,0,0,0,3.46,6v5.36a1.45,1.45,0,0,0,1.45,1.44H17a1.45,1.45,0,0,0,1.44-1.44v-.07h2.14a.85.85,0,0,0,.84-.84V6.87A.85.85,0,0,0,20.55,6Zm-3.64,5.22h0L5,11.31l0-5.3,12-.06v5.29Zm3-1.5H18.41V7.52h1.48Z"
                    transform="translate(-3.45 -4.51)"
                />
                <rect x="2.83" y="14.67" width="9.55" height="1.5" transform="translate(-12.1 5.33) rotate(-44.79)" />
                <path
                    d="M4.2,21.78A.76.76,0,0,1,3.45,21V15A.75.75,0,0,1,5,15v6A.76.76,0,0,1,4.2,21.78Z"
                    transform="translate(-3.45 -4.51)"
                />
            </SVGWrapper>
        );
    }
}

const SVGWrapper = styled.svg<{ fillColor: string }>`
    fill: ${(props): string => props.fillColor};
`;
