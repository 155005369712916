import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button } from "telstra-ui/components/button/Button";
import { Heading24, Modal as PolygonModal, greyL1 } from "telstra-components";
import { DropdownMultiSelect, IDropdownOption } from "../../../../views/Report/Reporting/DropdownMultiSelect";
import { ConfirmationDestructiveDialog, TextField, useToggle } from "@able/react";
import IconButton from "telstra-ui/components/button/IconButton";
import { FormData } from "../ConfigureCameraModal";
import TagInfoModal from "../../TagInfoModal/TagInfoModal";
import { TagInfoModalContent } from "../../../../content/TagInfoModalContent";
import { TagManagerService } from "../../../../services/WebService/TagManagerService";
import { Tag } from "@sv/types";
import { CameraObjectTripwire, CameraObjectZone } from "./CameraConfigView";
import LoadingModal from "../../Loading/LoadingModal";

export type PolygonConfig = {
    id: string;
    name: string;
    objectClasses: Array<string>;
    dwellAreaTags: Array<Tag>;
};

export type EditPolygonModalProps = {
    isOpen: boolean;
    formData: FormData;
    isEditing: boolean;
    selectedObject?: CameraObjectTripwire | CameraObjectZone;
    onCancel: () => void;
    onDelete: () => void;
    onSubmit: (polygon: PolygonConfig) => void;
};

const EditPolygonModal: React.FC<EditPolygonModalProps> = ({
    isOpen,
    formData,
    isEditing,
    selectedObject,
    onCancel,
    onDelete,
    onSubmit,
}) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const focusableElements = useRef<Array<HTMLElement | null>>([]);
    const [showDwellAreaTagsModal, setShowDwellAreaTagsModal] = useState(false);
    const [polygonName, setPolygonName] = useState("");
    const [customerTags, setCustomerTags] = useState<Array<Tag>>([]);
    interface SelectedData {
        selectedObjects: Array<string>;
        selectedDwellAreaTags: Array<string>;
    }

    const [selectedDropdownData, setSelectedDropdownData] = useState<SelectedData>({
        selectedObjects: [],
        selectedDwellAreaTags: [],
    });

    const [deleteModalIsOpen, toggleDeleteModalIsOpen] = useToggle();
    const [timerComplete, setTimerComplete] = useState(false);
    const [deletingPolygon, setDeletingPolygon] = useState(false);

    useEffect(() => {
        if (modalRef.current) {
            focusableElements.current = Array.from(
                modalRef.current.querySelectorAll(
                    'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select',
                ),
            );
        }

        if (isOpen && focusableElements.current.length > 0) {
            focusableElements.current[0]?.focus();
        }
    }, [isOpen]);

    const generateObjectOptions = () => {
        const objectOptions: Array<IDropdownOption> = formData.detectionObjects.map((obj) => {
            const isActive = selectedObject && selectedObject?.objectClasses.includes(obj.itemId);
            const objectDropdownOption = {
                displayText: obj.displayText,
                itemId: obj.itemId,
                active: isActive || false,
            };
            return objectDropdownOption;
        });
        return objectOptions;
    };

    const updateFilterSelections = (updatedSelections: {
        updatedSelectedObjects?: Array<string>;
        updatedSelectedDwellAreaTags?: Array<string>;
    }) => {
        if (updatedSelections.updatedSelectedObjects) {
            setSelectedDropdownData({
                ...selectedDropdownData,
                selectedObjects: updatedSelections.updatedSelectedObjects || selectedDropdownData.selectedObjects,
            });
        } else if (updatedSelections.updatedSelectedDwellAreaTags) {
            setSelectedDropdownData({
                ...selectedDropdownData,
                selectedDwellAreaTags:
                    updatedSelections.updatedSelectedDwellAreaTags || selectedDropdownData.selectedDwellAreaTags,
            });
        }
    };

    useEffect(() => {
        if (selectedObject?.type === "zone" && isEditing === true) {
            const initSelectedObjects = generateObjectOptions()
                .filter((option) => option.active)
                .map((option) => option.itemId.toString());

            const dwellAreaTagIds = selectedObject.dwellAreaTags.map((tag) => {
                return tag.id.toString();
            });

            setSelectedDropdownData({
                ...selectedDropdownData,
                selectedObjects: initSelectedObjects,
                selectedDwellAreaTags: dwellAreaTagIds,
            });
        }

        if (!isEditing) {
            setSelectedDropdownData({ selectedObjects: [], selectedDwellAreaTags: [] });
            setPolygonName("");
        }
    }, [isOpen]);

    useEffect(() => {
        // -- force focus to info modal when opening for accessibility
        if (showDwellAreaTagsModal) {
            const closeButton = document.querySelector(".info-modal") as HTMLDivElement | null;
            if (closeButton) {
                closeButton.focus();
            }
        }
    }, [showDwellAreaTagsModal]);

    const handleSaveAndClose = () => {
        const polygonData: PolygonConfig = {
            id: isEditing && selectedObject ? selectedObject?.id : polygonName,
            name: polygonName,
            objectClasses: selectedDropdownData.selectedObjects,
            dwellAreaTags: customerTags.filter((tag) =>
                selectedDropdownData.selectedDwellAreaTags.includes(tag.id.toString()),
            ),
        };

        onSubmit(polygonData);
        setPolygonName("");
        setSelectedDropdownData({ selectedObjects: [], selectedDwellAreaTags: [] });
        onCancel();
    };

    const onUpdatePolygonName = {
        onChange: (element) => {
            setPolygonName(element.target.value);
        },
    };

    useEffect(() => {
        let isCancelled = false;

        TagManagerService()
            .getTags()
            .then((newTags) => {
                if (!isCancelled) {
                    setCustomerTags(newTags);
                }
            });

        return () => {
            isCancelled = true;
        };
    }, [isOpen]);

    const buttonEvents = {
        onClick: () => {
            setDeletingPolygon(true);
            setTimerComplete(false);
            setSelectedDropdownData({ selectedObjects: [], selectedDwellAreaTags: [] });
        },
    };

    useEffect(() => {
        if (timerComplete) {
            setPolygonName("");
            onDelete();
        }
    }, [timerComplete]);

    useEffect(() => {
        if (isEditing && selectedObject?.name) {
            setPolygonName(selectedObject.name);
        }
    }, [isEditing, selectedObject]);

    const onCloseModal = () => {
        setPolygonName("");
        onCancel();
    };

    return (
        <Container>
            <PolygonModal isOpen={isOpen} onClose={() => onCloseModal()} showCloseButton={false} size="medium">
                {showDwellAreaTagsModal && (
                    <TagInfoModal
                        heading={TagInfoModalContent[2].name}
                        description={TagInfoModalContent[2].helperDescription}
                        exampleText={TagInfoModalContent[2].helperExample}
                        imgSrc={TagInfoModalContent[2].helperVisualSrc}
                        isOpen={showDwellAreaTagsModal}
                        onShowModal={setShowDwellAreaTagsModal}
                    />
                )}

                {deleteModalIsOpen && (
                    <DialogueContainer>
                        <ConfirmationDestructiveDialog
                            isShowing={deleteModalIsOpen}
                            setHideDialog={toggleDeleteModalIsOpen}
                            title="Are you sure you want to delete this polygon?"
                            description={
                                <span>
                                    <br />
                                    Once a polygon is deleted, it can not be undone. All data linked to this polygon
                                    will be permanently deleted, affecting reporting and data analysis.
                                    <br />
                                    <br />
                                    You will not be able to see any past, current or future data linked to this polygon
                                    once it is deleted.
                                    <br />
                                    <br />
                                </span>
                            }
                            stackButtonOnVXS={false}
                            confirmButtonLabel="Delete"
                            confirmButtonEvents={buttonEvents}
                            cancelButtonLabel="Cancel"
                            cancelButtonEvents={{}}
                            developmentUrl={`http://${window.location.host}/able-sprites.svg`}
                        />
                    </DialogueContainer>
                )}

                {deletingPolygon && (
                    <LoadingModal
                        heading="Deleting Polygon"
                        text={selectedObject?.name}
                        isOpen={deletingPolygon}
                        progressTimeMs={1000}
                        onComplete={() => {
                            setDeletingPolygon(false);
                            setTimerComplete(true);
                        }}
                    />
                )}

                <ModalContainer ref={modalRef}>
                    <ContentContainer>
                        <CloseButtonContainer aria-label="close trip line modal" data-testid="closeModalButton">
                            <Button
                                iconPosition="left"
                                icon={"icon-system-close"}
                                variant="primary"
                                size="medium"
                                onClick={() => {
                                    onCloseModal();
                                }}
                            />
                        </CloseButtonContainer>
                        <Heading24>{isEditing ? "Edit polygon settings" : "Create new polygon"}</Heading24>
                        <DescriptionContainer>
                            Polygons are shapes with at least 3 sides which are used to collect the dwell time and
                            occupancy of objects within their perimeter.
                        </DescriptionContainer>
                        <FormContainer>
                            <TextFieldContainer>
                                <TextField
                                    id="polygon-form"
                                    name="polygon form"
                                    label="Enter the name of this polygon"
                                    helpText="E.g. Cash register 1"
                                    events={onUpdatePolygonName}
                                    value={polygonName}
                                />
                            </TextFieldContainer>

                            <InputHeader>Select the object(s) which this polygon will detect</InputHeader>
                            <DropdownMultiSelect
                                id="object-detection-selector"
                                height={52}
                                options={generateObjectOptions().map((e) => ({
                                    ...e,
                                    active: selectedDropdownData.selectedObjects.includes(e.itemId.toString()),
                                }))}
                                onUpdateOptions={(options) => {
                                    updateFilterSelections({
                                        updatedSelectedObjects: options
                                            .filter((e) => e.active)
                                            .map((e) => e.itemId.toString()),
                                    });
                                }}
                            />

                            <InputHeader>
                                <HighlightText>(Optional)&nbsp;</HighlightText>
                                Select Dwell Area Tags linked to this polygon
                                <IconButtonContainer data-testid="dwellAreaTagInfo">
                                    <IconButton
                                        icon="icon-system-information-solid"
                                        onClick={() => setShowDwellAreaTagsModal(true)}
                                    />
                                </IconButtonContainer>
                            </InputHeader>
                            <SubLabel>Tags can be created and edited via the Administration Menu</SubLabel>
                            <DropdownMultiSelect
                                id="dwell-area-tags-selector"
                                height={52}
                                options={customerTags
                                    .filter((e) => e.category === "dwell area")
                                    .map((e) => ({
                                        displayText: e.name.toUpperCase(),
                                        itemId: e.id,
                                        active: selectedDropdownData.selectedDwellAreaTags.includes(e.id.toString()),
                                    }))}
                                onUpdateOptions={(options) => {
                                    updateFilterSelections({
                                        updatedSelectedDwellAreaTags: options
                                            .filter((e) => e.active)
                                            .map((e) => e.itemId.toString()),
                                    });
                                }}
                            />
                        </FormContainer>

                        <ButtonsContainer isEditing={isEditing}>
                            {isEditing && (
                                <Button variant="super" state="warning" onClick={() => toggleDeleteModalIsOpen()}>
                                    Delete polygon
                                </Button>
                            )}
                            <Button onClick={handleSaveAndClose}>Save and close</Button>
                        </ButtonsContainer>
                    </ContentContainer>
                </ModalContainer>
            </PolygonModal>
        </Container>
    );
};

export default EditPolygonModal;

const Container = styled.div``;

const DialogueContainer = styled.div`
    max-width: 560px;
`;

const IconButtonContainer = styled.div`
    margin-top: -15px;
    margin-bottom: -6px;
    margin-left: -4px;
`;

const FormContainer = styled.div`
    height: 443px;
    overflow: auto;
    margin-right: -15px;
`;

const SubLabel = styled.div`
    color: #707070;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: -15px;
`;

const TextFieldContainer = styled.div`
    width: 595px;
    margin-bottom: 16px;
`;

const ModalContainer = styled.div`
    max-width: 752px;
    height: 794px;
`;

const ContentContainer = styled.div`
    width: 620px;
    margin-top: 59px;
    margin-left: 48px;
`;

const CloseButtonContainer = styled.div`
    position: absolute;
    top: 18px;
    right: 18px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        fill: ${greyL1};
        margin: 0px !important;
        left: 0px !important;
    }
    button {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    span {
        width: 24px;
        height: 24px;
    }
`;

const ButtonsContainer = styled.div<{ isEditing: boolean }>`
    margin-top: 60px;
    margin-right: 28px;
    display: flex;
    justify-content: ${(props) => (props.isEditing ? "space-between" : "end")};
`;

const InputHeader = styled.div`
    color: #282828;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 2px;
    margin-top: 17px;
    display: flex;
`;

const HighlightText = styled.div`
    color: #0064d2;
`;

const DescriptionContainer = styled.div`
    color: #414141;
    font-family: Telstra Akkurat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.13px;
    margin-bottom: 32px;
`;
