import {
    getAdminBuilding,
    getAdminBuildings,
    getAdminFloor,
    getAssetFeatures,
    getCsvBookings,
    getCustomerBookings,
    getCustomerBookingsCount,
    getCustomerFloors,
    getCustomerLocationStates,
    getCustomerMetaData,
    getCustomerResources,
    RootStore,
    setAssetBookable,
    setFloorBookable,
    submitEditedAssetDetails,
} from "../..";
import { EditedAssetDetails } from "../../../views/Management/Assets/Assets";
import { Admin } from "../../WebService/Admin/ApiDefinition";
import { callFnWithWaitTime } from "../../WebService/Bookings";

class Management {
    private _accessToken: string;
    private _rootStore: RootStore | undefined;

    constructor(accessToken: string, rootStore: RootStore) {
        this._accessToken = accessToken;
        this._rootStore = rootStore ?? undefined;
    }

    public async getCustomerBookings(
        buildingId: number,
        floorId?: number,
        assetId?: number,
        page?: number,
        pageSize?: number,
    ): Promise<Admin.Bookings> {
        const customerBookings = await callFnWithWaitTime(
            getCustomerBookings,
            1,
            this._accessToken,
            buildingId,
            floorId,
            assetId,
            page,
            pageSize,
        );
        return customerBookings;
    }

    public async getCustomerBookingsCount(buildingId: number, floorId?: number, assetId?: number): Promise<number> {
        const customerBookingsCount = await callFnWithWaitTime(
            getCustomerBookingsCount,
            1,
            this._accessToken,
            buildingId,
            floorId,
            assetId,
        );
        return customerBookingsCount;
    }

    public async getCsvBookings(
        buildingId: number,
        floorId?: number,
        resourceId?: number,
    ): Promise<Array<Admin.UserBooking>> {
        const userBookings = await callFnWithWaitTime(
            getCsvBookings,
            1,
            this._accessToken,
            buildingId,
            floorId,
            resourceId,
        );
        return userBookings;
    }

    public async getCustomersResources(
        buildingId: number,
        floorId?: number,
        features?: boolean,
        iconType?: string,
        resourceType?: string,
        page?: number,
        pageSize?: number,
    ): Promise<void> {
        const maxPageSize = pageSize ?? Math.pow(2, 16) - 1;
        const resources: Admin.Resources = await callFnWithWaitTime(
            getCustomerResources,
            1,
            this._accessToken,
            buildingId,
            page,
            maxPageSize,
            floorId,
            resourceType ? resourceType : "Unspecified",
            features,
            iconType,
        );
        this._rootStore?.data.management.setCustomerResources(resources);
    }

    public async getCustomerAssetFeatureGroups(iconType?: string): Promise<void> {
        const features: Array<Admin.AssetFeatureGroups> = await callFnWithWaitTime(
            getAssetFeatures,
            1,
            this._accessToken,
            iconType ? iconType : "None",
        );
        this._rootStore?.data.management.setCustomerAssetFeatureGroups(features);
    }

    public async getCustomerLocationStates(): Promise<void> {
        const customerLocationStates = await callFnWithWaitTime(getCustomerLocationStates, 1, this._accessToken);
        this._rootStore?.data.management.setCustomerLocationStates(customerLocationStates);
    }

    public async getCustomerFloorData(buildingId: number | undefined): Promise<void> {
        const customerFloorData = await callFnWithWaitTime(
            getCustomerFloors,
            1,
            this._accessToken,
            buildingId,
            1,
            undefined,
            true,
        );

        this._rootStore?.data.management.setAdminFloorsData(customerFloorData);
    }

    public async getAllCustomerMetaData(state = "", buildingId?: number, floorId?: number): Promise<void> {
        const timeStamp = Date.now();
        const customerMetaData = await callFnWithWaitTime(
            getCustomerMetaData,
            1,
            this._accessToken,
            state,
            buildingId,
            floorId,
        );
        this._rootStore?.data.management.setCustomerMetaDataData(customerMetaData, timeStamp);
    }

    public async getAdminBuilding(buildingId: number): Promise<void> {
        const buildingDataFromApi = await callFnWithWaitTime(getAdminBuilding, 1, this._accessToken, buildingId);
        this._rootStore?.data.management.setAdminBuildingData(buildingDataFromApi);
    }

    public async getAdminFloor(buildingId: number, floorId: number): Promise<void> {
        const floorDataFromApi = await callFnWithWaitTime(getAdminFloor, 1, this._accessToken, buildingId, floorId);
        this._rootStore?.data.management.setAdminFloorData(floorDataFromApi);
    }

    public async getAllAdminBuildings(state = ""): Promise<void> {
        const buildingsAdmin = await callFnWithWaitTime(
            getAdminBuildings,
            1,
            this._accessToken,
            state,
            "",
            1,
            undefined,
            true,
        );

        this._rootStore?.data.management.setAdminBuildingsData(buildingsAdmin);
    }

    public async setAssetBookable(
        resourceType: string,
        buildingId: number,
        floorId: number,
        assetId: number,
        status: boolean,
    ): Promise<boolean> {
        const booked = await setAssetBookable(this._accessToken, resourceType, buildingId, floorId, assetId, status);
        return booked;
    }

    public async setFloorBookable(floorId: number, status: boolean): Promise<boolean> {
        const booked = await setFloorBookable(this._accessToken, floorId, status);
        return booked;
    }

    public async submitEditedAssetDetails(editedAssetDetails: EditedAssetDetails): Promise<Admin.Resource> {
        const updatedAssetDetails = await submitEditedAssetDetails(this._accessToken, editedAssetDetails);
        return updatedAssetDetails;
    }
}

export default Management;
