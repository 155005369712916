import React, { FC } from "react";
import { Box, SettingsPanel, useFormikContext, FieldArray } from "telstra-components";
import { Button } from "telstra-ui/components/button/Button";
import ClassTagPanel from "../TagPanel/ClassTagPanel";
import { ClassTag } from "@sv/types";
import strings from "../../../../smart-video/strings/strings.json";
import { Tripwire, Zone } from "telstra-components/build/components/CameraConfig/CameraConfigView";

const { addNew, addNewLimitReached } = strings.views.CameraConfig;

export interface ConfigItem {
    id: string;
    name: string;
    type: Tripwire | Zone;
    active?: boolean;
    tags: Array<ClassTag>;
    classes: Array<string>;
}

interface ConfigPanelProps {
    title: string;
    description: string;
    items: Array<ConfigItem>;
    addButtonDisabled?: boolean;
    onAddClick?: () => void;
    onDeleteClick?: (id: string) => void;
    onNameChange?: (id: string, value: string) => void;
    onClickEdit?: (id: string) => void;
    onAddTag?: (id: string, tag: ClassTag) => void;
    onRemoveTag?: (id: string, tag: ClassTag) => void;
    onAddClass?: (id: string, value: string) => void;
    onRemoveClass?: (id: string, value: string) => void;
}

const ConfigPanel: FC<ConfigPanelProps> = ({
    title,
    description,
    items,
    addButtonDisabled,
    onAddClick,
    onDeleteClick,
    onNameChange,
    onClickEdit,
    onAddTag,
    onRemoveTag,
    onAddClass,
    onRemoveClass,
}: ConfigPanelProps) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { values, touched, errors } = useFormikContext<any>();
    const polygon: string = title.toLowerCase();
    values[polygon] = [];

    const getErrorMessage = (index: number) => {
        if (touched[polygon] && errors[polygon]) {
            const touchedArray = [...(touched[polygon] as Array<{ name: boolean }>)];
            const errorsArray = [...(errors[polygon] as Array<{ name: string }>)];
            if (touchedArray[index] && errorsArray[index]) {
                return errorsArray[index].name as string;
            }
        }
    };

    const handleDeleteClick = (id: string, index: number) => () => {
        values[polygon].splice(index, 1);
        if (errors[polygon]) {
            const errorsArray = [...(errors[polygon] as Array<string>)];
            errorsArray.splice(index, 1);
            errors[polygon] = errorsArray;
        }
        onDeleteClick && onDeleteClick(id);
    };

    const handleSubmitNewTag = (id: string) => (tag: ClassTag) => {
        onAddTag && onAddTag(id, tag);
    };

    const handleTagClick = (id: string) => (tag: ClassTag) => {
        onRemoveTag && onRemoveTag(id, tag);
    };

    const handleSubmitNewClass = (id: string) => (value: string) => {
        onAddClass && onAddClass(id, value);
    };

    const handleClassClick = (id: string) => (value: string) => {
        onRemoveClass && onRemoveClass(id, value);
    };

    return (
        <Box marginTop="xxs">
            <span className="heading16">{title}</span>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <p className="body16">{description}</p>
                <Button
                    variant="primary"
                    size="small"
                    icon="icon-system-plus2"
                    iconPosition="right"
                    disabled={addButtonDisabled}
                    onClick={onAddClick}
                >
                    {addButtonDisabled ? addNewLimitReached : addNew}
                </Button>
            </Box>
            {items && items.length > 0 && (
                <FieldArray name={`${title.toLowerCase()}`}>
                    {() => (
                        <Box testId={`config-panel_items`}>
                            {items.map((item, index) => {
                                if (values[polygon]) {
                                    if (values[polygon][index]) {
                                        values[polygon][index].name = item.name;
                                    } else {
                                        values[polygon].push({ name: item.name });
                                    }
                                }
                                return (
                                    <Box key={index} testId={`config-panel_item_${index}`} marginTop="xxs">
                                        <SettingsPanel
                                            id={item.id}
                                            name={item.name}
                                            fieldName={`${title.toLowerCase()}.${index}.name`}
                                            errorMessage={getErrorMessage(index) || ""}
                                            active={item.active}
                                            onClickDelete={handleDeleteClick(item.id, index)}
                                            onNameChange={onNameChange}
                                            onClickEdit={onClickEdit}
                                            inputLabel={`${title} name`}
                                        >
                                            <ClassTagPanel
                                                tags={item.tags}
                                                classes={item.classes}
                                                onSubmitNewTag={handleSubmitNewTag(item.id)}
                                                onTagClick={handleTagClick(item.id)}
                                                onSubmitNewClass={handleSubmitNewClass(item.id)}
                                                onClassClick={handleClassClick(item.id)}
                                            />
                                        </SettingsPanel>
                                    </Box>
                                );
                            })}
                        </Box>
                    )}
                </FieldArray>
            )}
        </Box>
    );
};

export default ConfigPanel;
