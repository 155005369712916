import { makeAutoObservable } from "mobx";
import {
    getCustomerWithRetry,
    getSpacesWithRetry,
    getPlansWithRetry,
    getSpaceInsightsWithRetry,
    RootStore,
    callFnWithWaitTime,
    getCustomerFeatures,
} from "./../../services";
import Management from "./Subscription/Management";
import Reports from "./Subscription/Report";
import Spaces from "./Subscription/Spaces";
import { UIView } from "./UIStore";
import UserStore from "./UserStore";

class SubscriptionStore {
    private _accessToken: string;
    private _rootStore: RootStore | undefined;
    private _apiInitialised: boolean;

    public management: Management;
    public spaces: Spaces;
    public reports: Reports;
    
    public userTenancy: "DEFAULT" | "WCC" | "TRR" | "CCRC";

    constructor(rootStore: RootStore) {
        this._accessToken = UserStore.getAccessToken();
        this._rootStore = rootStore ?? undefined;
        this._apiInitialised = false;

        // Subscription class members objects
        this.management = new Management(this._accessToken, this._rootStore);
        this.spaces = new Spaces(this._accessToken, this._rootStore);
        this.reports = new Reports(this._accessToken);

        const tokenValues: {email: string | undefined} = parseJwt(this._accessToken);
        const userTenancy = tokenValues.email?.toLowerCase().match(/^(wcc-.*)@(telstrasmartspacesdemo\.onmicrosoft\.com)$/g)
            ? "WCC"
            : tokenValues.email?.toLowerCase().match(/^(trr-.*)@(telstrasmartspacesdemo\.onmicrosoft\.com)$/g)
            ? "TRR"
            // This addresses new mapping (changed from CCSC to CCRC)
            : tokenValues.email?.toLowerCase().match(/^(ccsc-.*)@(telstrasmartspacesdemo\.onmicrosoft\.com)$/g)
            ? "CCRC"
            : tokenValues.email?.toLowerCase().match(/^(ccrc-.*)@(telstrasmartspacesdemo\.onmicrosoft\.com)$/g)
            ? "CCRC"
            : "DEFAULT";
        this.userTenancy = userTenancy;

        makeAutoObservable(this);
    }

    public async initApis(): Promise<void> {
        // Fetch customer features and update feature store
        if (!this._apiInitialised) {
            const navigationFeature = await callFnWithWaitTime(getCustomerFeatures, 1, this._accessToken, this.userTenancy);
            this._rootStore?.features.setFeature("Navigation", navigationFeature);
            this._apiInitialised = true;
        }
    }

    public async callApis(setProgress: (progress: number) => void): Promise<void> {
        // Tab availability
        const spacesTab = this._rootStore?.ui.getTabByPath("/spaces");
        const managementTab = this._rootStore?.ui.getTabByPath("/building-management");

        if (spacesTab?.enabled) {
            const customer = await getCustomerWithRetry(0, this._accessToken);
            this._rootStore?.data.spaces.setCustomerData(customer);
            setProgress(0.2);
            const buildings = await getSpacesWithRetry(0, this._accessToken);
            this._rootStore?.data.spaces.setBuildingsData(buildings);
            setProgress(0.4);
            const floors = await getPlansWithRetry(0, this._accessToken, { includePlanImage: false });
            this._rootStore?.data.spaces.setFloorsData(floors);
            setProgress(0.6);
            const floorInsights = await getSpaceInsightsWithRetry(0, this._accessToken, {});
            this._rootStore?.data.spaces.setFloorInsightsData(floorInsights);
            setProgress(0.8);
            !this.spaces._isInsightSubscribed && this.spaces.subscribeToSpaceInsights();
        }

        if (managementTab?.enabled) {
            this.management.getAllAdminBuildings();
            this.management.getAllCustomerMetaData();
            this.management.getCustomerLocationStates();
        }

        setProgress(1);
    }

    public updateSubscription = (): void => {
        const currentView = this._rootStore?.ui.currentView;
        const selectedLocation = this._rootStore?.ui.selectedLocation;
        const selectedBuildingId = this._rootStore?.ui.selectedBuilding?.id;
        const selectedFloorId = this._rootStore?.ui.selectedFloor?.id;
        const managementTab = this._rootStore?.ui.getTabByPath("/building-management");

        if (currentView === UIView.Portfolio) {
            this.spaces._isTelemetrySubscribed && this.spaces.cancelTelemetrySubscription();
            !this.spaces._isInsightSubscribed && this.spaces.subscribeToSpaceInsights();
        } else if (currentView === UIView.BuildingEfficiency) {
            this.spaces._isTelemetrySubscribed && this.spaces.cancelTelemetrySubscription();
            !this.spaces._isInsightSubscribed && this.spaces.subscribeToSpaceInsights();
        } else if (currentView === UIView.RealtimeFloor) {
            !this.spaces._isTelemetrySubscribed &&
                this.spaces.subscribeOnFloorSelect(selectedBuildingId ?? 0, selectedFloorId ?? 0);
        } else if (currentView === UIView.Report) {
            this.spaces._isTelemetrySubscribed && this.spaces.cancelTelemetrySubscription();
            this.spaces._isInsightSubscribed && this.spaces.cancelSpaceInsightsSubscription();
        } else if (currentView === UIView.BuildingManagement) {
            this.spaces._isTelemetrySubscribed && this.spaces.cancelTelemetrySubscription();
            this.spaces._isInsightSubscribed && this.spaces.cancelSpaceInsightsSubscription();

            if (managementTab?.enabled) {
                this.management.getAllCustomerMetaData(selectedLocation, selectedBuildingId, selectedFloorId);
                this.management.getAllAdminBuildings();
                this.management.getCustomerLocationStates();
            }
        } else if (currentView === UIView.FloorManagement) {
            this.spaces._isTelemetrySubscribed && this.spaces.cancelTelemetrySubscription();
            this.spaces._isInsightSubscribed && this.spaces.cancelSpaceInsightsSubscription();
            this.management.getCustomerFloorData(selectedBuildingId ?? 0);
            this.management.getAdminBuilding(selectedBuildingId ?? 0);
        } else if (currentView === UIView.AssetManagement) {
            this.spaces._isTelemetrySubscribed && this.spaces.cancelTelemetrySubscription();
            this.spaces._isInsightSubscribed && this.spaces.cancelSpaceInsightsSubscription();
            this.management.getCustomersResources(selectedBuildingId ?? 0, selectedFloorId ?? 0, true, "SVG");
            this.management.getCustomerAssetFeatureGroups();
        }
    };
}

// https://stackoverflow.com/a/38552302/699963
function parseJwt(token: string) {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } catch {
        return {};
    }
}

export default SubscriptionStore;
