import { getPBIReportDataWithRetry } from "../../WebService/CustomerInfo";
import { getTelemetryV2 } from "../../WebService/Telemetry";

class Reports {
    private _accessToken: string;

    constructor(accessToken: string) {
        this._accessToken = accessToken;
    }

    public getPbiReport = async (): Promise<{
        reportId: string;
        token: string | undefined;
        url: string | undefined;
    }> => {
        const customer = await getPBIReportDataWithRetry(0, this._accessToken);
        const pbiReport = { reportId: "", token: customer.access_token, url: customer.resource };
        return pbiReport;
    };

    public getTelemetryV2 = async (start: number, end: number, siteIds: string, deviceIdsLookupBySiteId: { [deviceId: string]: string }) => {
        const report = await getTelemetryV2(this._accessToken, start, end, siteIds, deviceIdsLookupBySiteId);
        return report;
    };
}

export default Reports;
