import React from "react";
import { Group, Circle, Line } from "react-konva";

type CrossCircleProps = {
    x: number;
    y: number;
};

const CrossCircle: React.FC<CrossCircleProps> = ({ x, y }) => {
    const circleRadius = 6;
    const lineLength = 8;

    return (
        <Group x={x} y={y}>
            <Circle radius={circleRadius} fill="#A8CEE5" strokeWidth={2} x={0} y={0} listening={false} />
            {/* Horizontal Line */}
            <Line
                points={[-lineLength / 2, 0, lineLength / 2, 0]}
                stroke="#001D81"
                strokeWidth={1}
                closed
                listening={false}
            />
            {/* Vertical Line */}
            <Line
                points={[0, -lineLength / 2, 0, lineLength / 2]}
                stroke="#001D81"
                strokeWidth={1}
                closed
                listening={false}
            />
            <Circle radius={circleRadius + 1} stroke="#ECF7FE" strokeWidth={1} x={0} y={0} listening={false} />
        </Group>
    );
};

export default CrossCircle;
