import { MappedTags, ClassTag } from "@sv/types";

export const transformMappedTags = (tags: Array<ClassTag>): MappedTags => {
    return tags.reduce((map, tag) => {
        if (map[tag.variable]) {
            map[tag.variable] = map[tag.variable].concat(tag.value);
        } else {
            map[tag.variable] = [tag.value];
        }

        return map;
    }, {} as MappedTags);
};

export const transformFromMappedTags = (tags?: MappedTags): Array<ClassTag> => {
    if (!tags) return [];

    return Object.entries(tags).reduce((arr, [variable, values]) => {
        const variableTags = values.map((value) => ({ variable, value }));
        return arr.concat(variableTags);
    }, [] as Array<ClassTag>);
};
