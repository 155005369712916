import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { logout, isAuthenticatedWithMsal, getBaseUrl } from "./../../services";

const hostUrl = process.env.REACT_APP_SPACES_API;
const basePath = "";
const VERSION = process.env.REACT_APP_SPACES_API_VERSION;
export const maxContinuousWaitTime = 10;
export const continuousWaitTimeIncrease = 2;

type ContentType = "application/json" | "application/x-www-form-urlencoded";
type Method = "GET" | "POST" | "PATCH" | "PUT" | "DELETE";

/**
 * An HTTP Request
 */
export interface Request {
    apiPath: string;
    method: Method;
    authorization?: string;
    contentType?: ContentType;
    headers?: {};
    body?: {};
}

const serialize = (obj: { [s: string]: string }, prefix?: string): string => {
    const str: Array<string> = [];
    for (const p in obj) {
        // Should come up with better solution to avoid disable eslint line
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(p)) {
            const k = prefix ? prefix + "[" + p + "]" : p;
            const v = obj[p];
            const param =
                v !== null && typeof v === "object"
                    ? serialize(v, k)
                    : encodeURIComponent(k) + "=" + encodeURIComponent(v);
            str.push(param);
        }
    }
    return str.join("&");
};

/**
 * Creates and executes an HTTP Request, and returns its response
 *
 * @param requestInfo - the request
 * @returns response headers and body
 */
export async function createRequest(requestInfo: Request): Promise<{ response: AxiosResponse; body: string | null }> {
    const postData =
        requestInfo.body &&
        (requestInfo.contentType === "application/x-www-form-urlencoded"
            ? serialize(requestInfo.body)
            : JSON.stringify(requestInfo.body));

    const options: AxiosRequestConfig = {
        url: `${hostUrl}${basePath}${requestInfo.apiPath}${
            requestInfo.body && requestInfo.method === "GET" ? `?${serialize(requestInfo.body)}` : ""
        }`,
        method: requestInfo.method,
        headers: {
            ...((requestInfo.authorization && {
                Authorization: requestInfo.authorization,
            }) ||
                {}),
            "Content-Type": requestInfo.contentType || "application/json",
            ...(requestInfo.headers || {}),
        },
        ...(requestInfo.method === "GET"
            ? {}
            : {
                  body: postData,
              }),
    };

    // Should come up with better solution to avoid disable eslint line
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        try {
            if (VERSION) {
                const queryPos = options.url?.indexOf("?") || "";
                options.url =
                    queryPos > -1 ? `${options.url}&api-version=${VERSION}` : `${options.url}?api-version=${VERSION}`;
            }
            const response = await axios(options);
            if (response.status >= 200 && response.status < 300) {
                resolve({ body: response.data, response });
            } else {
                reject(response.status);
            }
        } catch (error) {
            reject(error);
        }
    });
}

/**
 * Checks for a 401 unauthorized and if found pushes the user back to the login page.
 * Also clears active subscriptions.
 *
 * @param e Error given from Catch.
 * @param subscriptionId The subscription to clear interval for.
 */
export const errorCallback = async (e: number, subscriptionId?: number): Promise<undefined> => {
    if (e === 401) {
        // TODO: Replace with isAuthenticated() once backend supports CAIMAN tokens
        if (isAuthenticatedWithMsal())
        {
            logout();
        }
        return undefined;
    }
};

//=======================================================================================================================
// -- Copy of CreatRequest() function accessing different env variables.
// -- This function will be used for new API requests.
// -- It is created separately so the other can easily be removed when old API's are deprecated.

const adminHostUrl = process.env.REACT_APP_MANAGEMENT_API;
const adminBasePath = "";
const ADMINVERSION = process.env.REACT_APP_MANAGEMENT_API_VERSION;

export async function createAdminRequest(requestInfo: Request): Promise<{ response: AxiosResponse; body: string | null }> {
    const postData =
        requestInfo.body &&
        (requestInfo.contentType === "application/x-www-form-urlencoded"
            ? serialize(requestInfo.body)
            : JSON.stringify(requestInfo.body));

    const options: AxiosRequestConfig = {
        url: `${adminHostUrl}${adminBasePath}${requestInfo.apiPath}${
            requestInfo.body && requestInfo.method === "GET" ? `?${serialize(requestInfo.body)}` : ""
        }`,
        method: requestInfo.method,
        headers: {
            ...((requestInfo.authorization && {
                Authorization: requestInfo.authorization,
            }) ||
                {}),
            "Content-Type": requestInfo.contentType || "application/json",
            ...(requestInfo.headers || {}),
        },
        ...(requestInfo.method === "GET"
            ? {}
            : {
                  body: postData,
              }),
    };

    // Should come up with better solution to avoid disable eslint line
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        try {
            if (ADMINVERSION) {
                const queryPos = options.url?.indexOf("?") || "";
                options.url =
                    queryPos > -1
                        ? `${options.url}&api-version=${ADMINVERSION}`
                        : `${options.url}?api-version=${ADMINVERSION}`;
            }
            const response = await axios(options);
            if (response.status >= 200 && response.status < 300) {
                resolve({ body: response.data, response });
            } else {
                reject(response.status);
            }
        } catch (error) {
            reject(error);
        }
    });
}

//=======================================================================================================================
// -- Copy of CreatRequest() function accessing different env variables.
// -- This function will be used for new API requests.
// -- It is created separately so the other can easily be removed when old API's are deprecated.

const videoBasePath = "";
const VIDEOVERSION = process.env.REACT_APP_DEVICES_API_VERSION;

export async function createVideoRequest(requestInfo: Request): Promise<{ response: AxiosResponse; body: string | null }> {
    const postData =
        requestInfo.body &&
        (requestInfo.contentType === "application/x-www-form-urlencoded"
            ? serialize(requestInfo.body)
            : JSON.stringify(requestInfo.body));

    const options: AxiosRequestConfig = {
        url: `${getBaseUrl()}${videoBasePath}${requestInfo.apiPath}${
            requestInfo.body && requestInfo.method === "GET" ? `?${serialize(requestInfo.body)}` : ""
        }`,
        method: requestInfo.method,
        headers: {
            ...((requestInfo.authorization && {
                Authorization: requestInfo.authorization,
            }) ||
                {}),
            "Content-Type": requestInfo.contentType || "application/json",
            ...(requestInfo.headers || {}),
        },
        ...(requestInfo.method === "GET"
            ? {}
            : {
                  body: postData,
              }),
    };

    // Should come up with better solution to avoid disable eslint line
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        try {
            if (VIDEOVERSION) {
                const queryPos = options.url?.indexOf("?") || "";
                options.url =
                    queryPos > -1
                        ? `${options.url}&api-version=${VIDEOVERSION}`
                        : `${options.url}?api-version=${VIDEOVERSION}`;
            }
            const response = await axios(options);
            if (response && response.status >= 200 && response.status < 300) {
                resolve({ body: response.data, response });
            } else {
                reject(response?.status);
            }
        } catch (error) {
            reject(error);
        }
    });
}
