import axios from "axios";
import { getBaseUrl } from "../..";
import { TagManager } from "./Types";

// See: https://confluence.tools.telstra.com/pages/viewpage.action?pageId=1527074460

export const TagManagerService = () => {
    const baseUrl = `${getBaseUrl()}`;

    const getTags = async (): Promise<Array<TagManager.Web.Tag>> => {
        try {
            const res = await axios.get(`${baseUrl}/caas/tag-manager`);
            const responseData: Array<TagManager.Web.Tag> = res.data;
            return responseData;
        } catch (error: unknown) {
            console.error(error);
            return [];
        }
    };

    const createTag = async (newTag: {
        name: string;
        type: TagManager.Web.Tag["type"];
        category: TagManager.Web.Tag["category"];
    }): Promise<TagManager.Web.Tag | null> => {
        try {
            const res = await axios.post<number>(`${baseUrl}/caas/tag-manager`, [newTag]);
            const responseData: TagManager.Web.Tag = {
                ...(newTag as TagManager.Web.Tag),
                id: res.data,
            };
            return responseData;
        } catch (error: unknown) {
            console.error(error);
            return null;
        }
    };

    const updateTag = async (tag: TagManager.Web.Tag): Promise<TagManager.Web.Tag | null> => {
        try {
            const res = await axios.post<TagManager.Web.Tag>(`${baseUrl}/caas/tag-manager`, [tag]);
            const responseData: TagManager.Web.Tag = res.data;
            return responseData;
        } catch (error: unknown) {
            console.error(error);
            return null;
        }
    };

    const deleteTag = async (tag: TagManager.Web.Tag) => {
        // TODO: implement once deletion is in scope.
    };

    return {
        getTags,
        createTag,
        updateTag,
        deleteTag,
    };
};
