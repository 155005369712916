import React, { ReactElement } from "react";
import styled from "styled-components";

interface Props {
  fill?: string;
  title?: string;
}

const SVGIconOffline = ({ fill, title }: Props): ReactElement => {
  return (
    <SVGWrapper
      id="offlineIcon"
      height="100%"
      fillColor={fill || ""}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29.5 29.5"
    >
      <title>{title}</title>
      <clipPath id="offline-icon-clip-path" transform="translate(2.75 2.75)">
        <path
          fill="none"
          clipRule="evenodd"
          d="M15.18,7.7a1.06,1.06,0,0,0-.8.33L12,10.41,9.61,8a1,1,0,0,0-.79-.33A1.06,1.06,0,0,0,8,8a1.1,1.1,0,0,0-.33.79,1.14,1.14,0,0,0,.33.8L10.41,12,8,14.39a1.1,1.1,0,0,0-.33.79,1.13,1.13,0,0,0,1.13,1.13A1.1,1.1,0,0,0,9.61,16L12,13.59,14.38,16a1.14,1.14,0,0,0,.8.33,1.14,1.14,0,0,0,1.13-1.13,1.07,1.07,0,0,0-.34-.79L13.59,12,16,9.62a1.1,1.1,0,0,0,.34-.8A1.07,1.07,0,0,0,16,8l-.09-.09A1,1,0,0,0,15.18,7.7ZM16,2.25a.16.16,0,0,1,.11,0L21.7,7.92a.16.16,0,0,1,.05.11v8a.16.16,0,0,1-.05.1l-5.62,5.63a.21.21,0,0,1-.11,0H8a.21.21,0,0,1-.1,0L2.29,16.08a.21.21,0,0,1,0-.1V8s0-.06,0-.07L7.92,2.33a.14.14,0,0,1,.1,0h8Z"
        />
      </clipPath>
      <g clipPath="url(#offline-icon-clip-path)">
        <rect width="29.5" height="29.5" />
      </g>
    </SVGWrapper>
  );
};

export default SVGIconOffline;

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
