import { makeAutoObservable, action } from "mobx";
import { SmartSpaces } from "../WebService/SmartSpaces";
import { RootStore } from "./../../services";

/**
 * Set currently visible view
 *
 * @description
 * Unauthenticated views - login and incompatible browser warning views
 * Portfolio View - list of buildings visible to current user
 * Building Efficiency - list of floors in selected building
 * Realtime Floor View - display WRLD map of selected floor
 * Report View - display PowerBI report for current user
 */
export enum UIView {
    Unauthenticated,
    Portfolio,
    BuildingEfficiency,
    RealtimeFloor,
    Report,
    Bookings,
    Notifications,
    Settings,
    BuildingManagement,
    FloorManagement,
    AssetManagement,
}

/**
 * Set role of the current user
 *
 * @description
 * SuperAdmin - SuperAdmin can add Admin users, can (read/write) capacity of floors and meeting rooms, can decide which buildings/floors should be exposed to Admin Users and Manager Users
 * Admin - Admin can read capacity of floors and meeting rooms, can choose (select/deselect) which floors and/or buildings should be exposed on navigating to the real time view
 * Manager - Can access spaces and reports tab
 */
export enum Role {
    SuperAdmin,
    Admin,
    Manager,
}

export type OccupancyMode = "Vacant" | "Occupied";

export type Breadcrumb = {
    to: string;
    label: string;
};

export interface Building {
    id: number;
    label: string;
}

export interface Floor {
    id: number;
    label: string;
}

class UIStore {
    currentView: UIView;
    loadingProgress: number;
    isContinueIncompatibleBrowser: boolean;
    occupancyMode: OccupancyMode;
    selectedLocation: string;
    selectedBuilding: Building | null;
    selectedFloor: Floor | null;
    /**
     * Captures the current role of the user from RBAC
     */
    role: Role;
    /**
     * Captures whether Bookings tab is purchased by the current Admin/SuperAdmin user
     */

    private _rootStore: RootStore | undefined;
    private _uiLoaded: boolean;

    constructor(
        rootStore: RootStore,
        rootStoreData?: UIStore,
        selectedLocation?: string,
        selectedBuilding?: Building,
        selectedFloor?: Floor,
        role?: Role,
    ) {
        this.currentView = rootStoreData?.currentView ?? UIView.Unauthenticated;
        this.loadingProgress = rootStoreData?.loadingProgress ?? 0;
        this.isContinueIncompatibleBrowser = rootStoreData?.isContinueIncompatibleBrowser ?? false;
        this.occupancyMode = rootStoreData?.occupancyMode ?? "Occupied";
        this._rootStore = rootStore ?? undefined;
        this._uiLoaded = false;
        this.selectedLocation = selectedLocation ?? "";
        this.selectedBuilding = selectedBuilding ?? null;
        this.selectedFloor = selectedFloor ?? null;
        this.role = role ?? Role.SuperAdmin;

        makeAutoObservable(this, {
            setOccupancyMode: action,
            setSelectedLocation: action,
            setSelectedBuilding: action,
            setSelectedFloor: action,
            setCurrentView: action,
            setRole: action,
        });
    }
    private _updateLoadingProgress(progress: number): void {
        this.loadingProgress = progress;
    }

    public init(): void {
        // Initial timeout of 1s is set for the logo animation
        // This should be refactored in future
        setTimeout(async () => {
            await this._rootStore?.subscription.initApis();
            this._rootStore?.subscription.callApis((progress: number): void => {
                if (progress !== this.loadingProgress && !this._uiLoaded) {
                    this._updateLoadingProgress(progress);
                    if (progress === 1) {
                        this._uiLoaded = true;
                    }
                }
            });
        }, 1500);
    }

    public setOccupancyMode(mode: "Vacant" | "Occupied"): void {
        this.occupancyMode = mode;
    }

    public setSelectedLocation(location: string): void {
        this.selectedLocation = location;
    }

    public setSelectedBuilding(building: Building | null = null): void {
        this.selectedBuilding = building;
    }

    public setSelectedFloor(floor: Floor | null = null): void {
        this.selectedFloor = floor;
    }

    public setCurrentView(view: UIView): void {
        this.currentView = view;
        this._rootStore?.subscription.updateSubscription();
    }

    public setRole(role: Role): void {
        this.role = role;
    }

    public getTabByPath(path: string): SmartSpaces.WebApp.Features.Navigation | null {
        const menu = this._rootStore?.features.getFeature("Navigation") ?? [];
        const index = menu?.findIndex((item) => item.path === path);

        return index >= 0 ? menu[index] : null;
    }

    public getHomeTabPath(): string {
        const menu = this._rootStore?.features.getFeature("Navigation") ?? [];

        return menu.find((tab) => tab.enabled === true)?.path ?? "";
    }

    public toJSON(): {} {
        return {
            currentView: this.currentView,
            loadingProgress: this.loadingProgress,
            isContinueIncompatibleBrowser: this.isContinueIncompatibleBrowser,
            occupancyMode: this.occupancyMode,
            selectedLocation: this.selectedLocation,
            selectedBuilding: this.selectedBuilding,
            selectedFloor: this.selectedFloor,
            role: this.role,
        };
    }

    static generateBreadcrumbs(
        currentView: UIView,
        selectedBuilding: Building,
        selectedFloor?: Floor,
    ): Array<Breadcrumb> {
        let basePath = "/";
        let crumbs = [{ to: `${basePath}/buildings`, label: "Buildings" }];

        switch (currentView) {
            case UIView.Portfolio:
            case UIView.BuildingEfficiency:
            case UIView.RealtimeFloor:
                basePath = "/spaces";
                crumbs = [{ to: `${basePath}/buildings`, label: "Buildings" }];
                break;
            case UIView.Report:
                basePath = "/reports";
                break;
            case UIView.Bookings:
                basePath = "/bookings";
                break;
            case UIView.BuildingManagement:
                basePath = "/building-management";
                crumbs = [{ to: `${basePath}`, label: "Buildings" }];
                break;
            default:
                basePath = "/";
                break;
        }

        if (selectedBuilding) {
            crumbs.push({ to: `${basePath}/buildings/${selectedBuilding.id}`, label: selectedBuilding.label });
        }

        if (selectedFloor) {
            crumbs.push({
                to: `${basePath}/buildings/${selectedBuilding.id}/floors/${selectedFloor.id}`,
                label: selectedFloor.label,
            });
        }

        return crumbs;
    }
}

export default UIStore;
