import { msalInstance, msalLogin, msalLogout, msalRefreshToken, msalIsAuthenticated } from "./MsalIdpHandler";
import { caimanInstance, caimanLogin, caimanLogout, caimanRefreshToken, caimanIsAuthenticated } from "./CaimanIdpHandler";

export const refreshToken = async (): Promise<unknown> => {
    try {
        await msalRefreshToken();
        await caimanRefreshToken();
    } catch (err) {
        console.error("Error:", err);
    }
    return null;
};

export const login = async (domainHint: string, loginHint: string): Promise<void> => {
    if ((domainHint.toLowerCase() === "team.telstra.com") ||
        (domainHint.toLowerCase() === "telstrasmartspacesdemo.onmicrosoft.com")) {
        await msalLogin(domainHint, loginHint);
    } else {
        await caimanLogin(domainHint, loginHint);
    }
};

export const logout = async (): Promise<void> => {
    try {
        if (msalIsAuthenticated()) {
            await msalLogout();
        }
        if (caimanIsAuthenticated()) {
            await caimanLogout();
        }
    } catch (err) {
        console.error("Error:", err);
    }
};

export const getUsersName = async (): Promise<string | undefined> => {
    const caimanUser = await caimanInstance?.getUser();
    if (caimanUser !== null)
    {
        return caimanUser.profile.name ?
               caimanUser.profile.name : 
               `${caimanUser.profile.family_name}, ${caimanUser.profile.given_name}`;
    }

    const msalUser = msalInstance?.getAccount();
    if (msalUser !== null)
    {
        return msalUser.idToken.name;
    }

    return undefined;
};

export const getUsersEmail = async (): Promise<string | undefined> => {
    const caimanUser = await caimanInstance?.getUser();
    if (caimanUser !== null)
    {
        return caimanUser.profile.preferred_username;
    }

    const msalUser = msalInstance?.getAccount();
    if (msalUser !== null)
    {
        return Array.isArray(msalUser.idToken.emails) ? msalUser.idToken.emails[0] : msalUser.idToken.email;
    }

    return undefined;
};

export const getUsersAccountIdentifier = async (): Promise<string | undefined> => {
    const caimanUser = await caimanInstance?.getUser();
    if (caimanUser !== null)
    {
        return caimanUser.profile.sub;
    }

    const msalUser = msalInstance?.getAccount();
    if (msalUser !== null)
    {
        return msalUser.accountIdentifier;
    }

    return undefined;
};

export const getAccessToken = (): string => {
    return sessionStorage?.getItem("oidc.access_token") ||
           sessionStorage?.getItem("msal.idtoken") ||
           "";
};

export const isAuthenticated = (): boolean => {
    return caimanIsAuthenticated() || 
           msalIsAuthenticated();
};

export const isAuthenticatedWithMsal = (): boolean => {
    return msalIsAuthenticated();
};

export const isAuthenticatedWithCaiman = (): boolean => {
    return caimanIsAuthenticated();
};

export const getBaseUrl = (): string => {
    return caimanIsAuthenticated() ?
           process.env.REACT_APP_DEVICES_API_CAIMAN || "" :
           process.env.REACT_APP_DEVICES_API || "";
};
