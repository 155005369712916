import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { ISearchItem, Search as TelstraSearch } from "telstra-ui/components/search/Search";
import { errorMessage } from "telstra-components";

interface Props {
    searchValue: string;
    options: Array<ISearchItem>;
    onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSearchOptionClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSearchClear: () => void;
    error?: string;
    name?: string;
}

const minimumSearchCharacters = 3;

const Search = ({
    searchValue,
    options,
    onSearchChange: handleSearchChange,
    onSearchOptionClick: handleSearchOptionClick,
    onSearchClear: handleSearchClear,
    error,
    name,
}: Props): ReactElement => {
    const [hasFocus, setHasFocus] = useState(false);

    useEffect(() => {
        if (!hasFocus) return;

        if (searchValue) {
            document.getElementById("populated-search-search")?.focus();
        } else {
            document.getElementById("empty-search-search")?.focus();
        }
    }, [searchValue, hasFocus]);

    const handleFocus = () => {
        setHasFocus(true);
    };

    const handleBlur = () => {
        setHasFocus(false);
    };

    return (
        <div onClick={handleFocus} onBlur={handleBlur} onFocus={handleFocus} data-testid={`search__${name}`}>
            {searchValue && (
                <StyledSearch
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    assistiveText={error}
                    onCrossIconClicked={handleSearchClear}
                    minCharacter={minimumSearchCharacters}
                    id="populated-search"
                    options={options}
                    onSearchChange={handleSearchChange}
                    defaultValue={searchValue}
                    onOptionClick={handleSearchOptionClick}
                />
            )}
            {!searchValue && (
                <StyledSearch
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    assistiveText={error}
                    minCharacter={minimumSearchCharacters}
                    id="empty-search"
                    options={options}
                    onSearchChange={handleSearchChange}
                    onOptionClick={handleSearchOptionClick}
                />
            )}
        </div>
    );
};

export default Search;

const StyledSearch = styled(TelstraSearch)`
    .tl-inline-alert-container .alert-label {
        color: ${errorMessage};
    }
`;
