/* eslint-disable react/react-in-jsx-scope */
import { TagManager } from "../services/WebService/TagManagerService";

export const TagInfoModalContent: Array<{
    key: string;
    tagFieldMatcherKeys: {
        internalId: string;
        type: TagManager.Web.TagType;
        category: TagManager.Web.TagCategory;
    };
    name: string;
    assetName: string;
    title: string;
    description: string;
    descriptionLimit: string;
    helperHint: string;
    helperDescription: JSX.Element;
    helperExample: string;
    helperVisualSrc: string;
}> = [
    {
        key: "ADD_LARGE_AREA_TAGS",
        tagFieldMatcherKeys: {
            internalId: "ANALYTICS_LARGE_AREA",
            type: "trip line",
            category: "large area",
        },
        name: "Large area tags",
        assetName: "Large areas",
        title: "Enter large area tags",
        description:
            "Large area tags are used to aggregate data from trip lines to define a common area of interest across sites.  They can be used as filters in the ‘Data Analysis’ section of this app. Once created, tags are saved for future use with other sites in your organisation.",
        descriptionLimit: "An organisation may have up to 20 ‘Large area’ tags.",
        helperHint: "E.g. Shop floor, Carpark, Foodcourt, Section A lvl 3",
        helperDescription: (
            <>
                Large Area tags are created when Trip Lines are grouped together under one tag name to estimate the{" "}
                <span style={{ fontWeight: "bold" }}>occupancy of an enclosed area.</span>
            </>
        ),
        helperExample:
            "E.g. To find out the occupancy of the shop floor, the tag ‘Shop floor’ has been added to the two trip lines where people enter and exit.",
        helperVisualSrc: "/images/info-modal-analytics-tags-large-area-tags.svg",
    },
    {
        key: "ADD_CROSSING_TAGS",
        tagFieldMatcherKeys: {
            internalId: "ANALYTICS_CROSSING",
            type: "trip line",
            category: "crossing",
        },
        name: "Crossing tags",
        assetName: "Crossings",
        title: "Enter crossing tags",
        description:
            "Crossing tags are used to aggregate data from trip lines to define a common crossing of interest across sites.  They can be used as filters in the ‘Data Analysis’ section of this app. Once created, tags are saved for future use with other sites in your organisation.",
        descriptionLimit: "An organisation may have up to 20 ‘Crossing’ tags.",
        helperHint: "E.g. Main crossing, secondary crossing, emergency crossing",
        helperDescription: (
            <>
                Crossings are created when Triplines are grouped together under one tag name to{" "}
                <span style={{ fontWeight: "bold" }}>count the number of objects passing.</span>
            </>
        ),
        helperExample: "E.g. The tag 'Main entry' can be applied to all sites with a main entry.",
        helperVisualSrc: "/images/info-modal-analytics-tags-crossing-tags.svg",
    },
    {
        key: "ADD_DWELL_AREA_TAGS",
        tagFieldMatcherKeys: {
            internalId: "ANALYTICS_DWELL_AREA",
            type: "polygon",
            category: "dwell area",
        },
        name: "Dwell area tags",
        assetName: "Dwell areas",
        title: "Enter dwell area tags",
        description:
            "Dwell area tags are used to aggregate data from polygons to define a common area of interest across sites.  They can be used as filters in the ‘Data Analysis’ section of this app. Once created, tags are saved for future use with other sites in your organisation.",
        descriptionLimit: "An organisation may have up to 20 ‘Dwell area’ tags.",
        helperHint: "E.g. Cash register queue, New product display",
        helperDescription: (
            <>
                Dwell Area Tags are drawn as Polygons within one camera view. They are used to calculate the{" "}
                <span style={{ fontWeight: "bold" }}>Dwell Time</span> and{" "}
                <span style={{ fontWeight: "bold" }}>Occupancy</span> of objects. By tagging Dwell Areas, Polygons can
                be aggregated.
            </>
        ),
        helperExample:
            "E.g. The tag ‘Register queue’ can be applied to register queues in many different shops (sites) to represent the dwell time of people.",
        helperVisualSrc: "/images/info-modal-analytics-tags-dwell-area-tags.svg",
    },
    {
        key: "ADD_SITE_TYPE_TAGS",
        tagFieldMatcherKeys: {
            internalId: "ANALYTICS_SITE_TYPE",
            type: "site",
            category: "type",
        },
        name: "Site type tags",
        assetName: "Site type",
        title: "Create site type tags",
        description:
            "Site type tags are used group same sites together. They are used as filters in the ‘Data Analysis’ section of this app. Once created, tags are saved for future use with other sites in your organisation.",
        descriptionLimit: "An organisation may have up to 20 ‘Site type’ tags.",
        helperHint: "E.g. Park, beach, new store, flagship store",
        helperDescription: <>Site type tags are used to group sites under one category.</>,
        helperExample:
            "E.g. Shop A and Shop B might both be flagship stores. Adding the tag ‘Flagship stores’ would allow for easy comparison of data in the Data Analysis section of this app.",
        helperVisualSrc: "/images/info-modal-analytics-tags-site-type-tags.svg",
    },
    {
        key: "ADD_SITE_REGION_TAGS",
        tagFieldMatcherKeys: {
            internalId: "ANALYTICS_SITE_REGION",
            type: "site",
            category: "region",
        },
        name: "Site region tags",
        assetName: "Site region",
        title: "Create site region tags",
        description:
            "Site region tags are used to group sites which belong to the same region. They are used as filters in the ‘Data Analysis’ section of this app. Once created, tags are saved for future use with other sites in your organisation.",
        descriptionLimit: "An organisation may have up to 20 ‘Site region’ tags.",
        helperHint: "E.g. Sydney, NSW, North-West suburbs, Highlands",
        helperDescription: <>Site region tags are used to group sites under one geographic region.</>,
        helperExample:
            "E.g. Shop A and Shop B might both be in the South-Western region. Adding the tag ‘South-Western’ would allow for easy comparison of data in the Data Analysis section of this app.",
        helperVisualSrc: "/images/info-modal-analytics-tags-site-region-tags.svg",
    },
];
