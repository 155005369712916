import useOrganisation from "../../hooks/Organisation/useOrganisation";
import React, { FC, useEffect, useState } from "react";
import { DropdownOption, OrganisationType } from "@sv/types";
import {
    Body18,
    Box,
    FormikProvider,
    Heading20,
    Modal,
    OutlinedTextField,
    SecondaryButton,
    useFormik,
    Row,
    Col,
    DropDown,
} from "telstra-components";
import { validationSchemas } from "../../helpers/formValidation";
import strings from "../../strings/strings.json";

const defaultValues = {
    organisationName: "",
    parentId: "",
};

const {
    orgEditOrganisationHeading,
    orgAddOrganisationHeading,
    orgFormParentOrganisationLabel,
    orgFormOrganisationDescriptionLabel,
    orgFormCancelButtonLabel,
    orgFormSaveButtonLabel,
} = strings.components.Modals.OrganisationModal;

interface OrganisationModalProps {
    isOpen: boolean;
    isEditMode: boolean;
    parentId?: string;
    organisationName?: string;
    onReset?: () => void;
    onSubmit?: (organisationName: string, parentId: string) => void;
}

export const mapOrgsToDropdownOptions = (orgs: Array<OrganisationType>): Array<DropdownOption> =>
    orgs?.map((o) => ({ id: o.id, label: o.name, value: o.id } as DropdownOption));

const OrganisationModal: FC<OrganisationModalProps> = ({
    isOpen,
    isEditMode,
    parentId,
    organisationName,
    onReset,
    onSubmit,
}: OrganisationModalProps) => {
    const { organisations, getOrganisations } = useOrganisation();
    const [orgOptions, setOrgOptions] = useState<Array<DropdownOption>>([]);

    const formik = useFormik({
        initialValues: defaultValues,
        validationSchema: () => validationSchemas.organisation,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSubmit: async ({ organisationName, parentId }: any, { resetForm }: any) => {
            onSubmit && onSubmit(organisationName, parentId);
            resetForm();
        },
        onReset: () => {
            onReset && onReset();
        },
    });

    useEffect(() => {
        if (!parentId) return;
        getOrganisations(parentId, !isEditMode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, parentId]);

    useEffect(() => {
        setOrgOptions(mapOrgsToDropdownOptions(organisations));
    }, [organisations]);

    useEffect(() => {
        formik.setFieldValue("parentId", parentId, false);
        formik.setFieldValue("organisationName", organisationName, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, parentId, organisationName]);

    return (
        <Modal isOpen={isOpen} showCloseButton={false}>
            <Box margin="s">
                <Heading20>{isEditMode ? orgEditOrganisationHeading : orgAddOrganisationHeading}</Heading20>
                <FormikProvider value={formik}>
                    <Box marginTop="s" marginBottom="l">
                        <Row>
                            <Col md={2.5} justify="center">
                                <Body18>{orgFormParentOrganisationLabel}</Body18>
                            </Col>
                            <Col md={5.5} justify="center">
                                <DropDown name="parentId" options={orgOptions} disabled={isEditMode} />
                            </Col>
                        </Row>
                        <Box marginTop="xxs">
                            <Row>
                                <Col md={2.5}>
                                    <Box display="flex" marginTop="xxxs">
                                        <Box flex="1">
                                            <Body18>{orgFormOrganisationDescriptionLabel}</Body18>
                                        </Box>
                                    </Box>
                                </Col>
                                <Col md={5.5} justify="center">
                                    <OutlinedTextField name="organisationName" fullWidth />
                                </Col>
                            </Row>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <SecondaryButton size="large" label={orgFormCancelButtonLabel} onClick={formik.resetForm} />
                        <SecondaryButton
                            size="large"
                            label={orgFormSaveButtonLabel}
                            onClick={formik.submitForm}
                            testid="modal-organisation_submit_button"
                            disabled={formik.isSubmitting}
                        />
                    </Box>
                </FormikProvider>
            </Box>
        </Modal>
    );
};

export default OrganisationModal;
