import { DetectObjectsOptions } from "./DetectObjects"

// Points are normalised on a scale of 0.0 to 1.0, and will be converted to the pixel positions based on the resize_to value.
export type Coordinate = {
    x: number;
    y: number;
}

type Tripline = {
    points: [
        [x1: number, y1: number],
        [x2:number, y2: number],
    ]
    name: string;
    direction: 0 | 1;
    classes: Array<string>
}

// note contour array.length must be > 3
type Polygon = {
    contour: Array<Coordinate>;
    name: string;
    classes: Array<string>;
}

export type LineModule = {
    enabled: boolean;
    lines: Array<Tripline>;
    telemetry_configuration: {
        enabled: boolean;
    }
};

export type PolygonModule = {
    enabled: boolean;
    polygons: Array<Polygon>;
    telemetry_configuration: {
        enabled: boolean;
    }
}

export type Object = {
    id: string;
    displayName: string;
    defaultAlgorithmId: string;
    currentAlgorithmId: string;
    availableAlgorithms: Array<string>;
};

export type Algorithm = {
    id: string;
    name: string;
    displayName: string;
};

export const AlgorithmOSNet025: Algorithm = {
    id: "OSNet025",
    name: "OSNet025",
    displayName: "Algorithm OSNet025",
};

export const AlgorithmVehicleID: Algorithm = {
    id: "VehicleID",
    name: "VehicleID",
    displayName: "Algorithm VehicleID",
};

// When creating a new algorithm need to add its entry to algorithmsMap
export const algorithmsMap = {
    [AlgorithmOSNet025.id]: AlgorithmOSNet025,
    [AlgorithmVehicleID.id]: AlgorithmVehicleID,
};

export const machineLearningModels: Array<DetectObjectsOptions> = [
    { displayText: "Object Detection Model", itemId: "object-detection-model-option" },
    { displayText: "Top Down People Detection Model", itemId: "top-down-people-detection-model-option" },
];

export const multiDetectionObjects: Array<Object> = [
    {
        id: "odm-person-option",
        displayName: "Person",
        defaultAlgorithmId: AlgorithmOSNet025.id,
        currentAlgorithmId: AlgorithmOSNet025.id,
        availableAlgorithms: [],
    },
    {
        id: "odm-bicycle-option",
        displayName: "Bicycle",
        defaultAlgorithmId: AlgorithmOSNet025.id,
        currentAlgorithmId: AlgorithmOSNet025.id,
        availableAlgorithms: [],
    },
    {
        id: "odm-car-option",
        displayName: "Car",
        defaultAlgorithmId: AlgorithmVehicleID.id,
        currentAlgorithmId: AlgorithmVehicleID.id,
        availableAlgorithms: [],
    },
    {
        id: "odm-motorbike-option",
        displayName: "Motor Bike",
        defaultAlgorithmId: AlgorithmVehicleID.id,
        currentAlgorithmId: AlgorithmVehicleID.id,
        availableAlgorithms: [],
    },
    {
        id: "odm-dog-option",
        displayName: "Dog",
        defaultAlgorithmId: AlgorithmOSNet025.id,
        currentAlgorithmId: AlgorithmOSNet025.id,
        availableAlgorithms: [],
    },
    {
        id: "odm-bus-option",
        displayName: "Bus",
        defaultAlgorithmId: AlgorithmVehicleID.id,
        currentAlgorithmId: AlgorithmVehicleID.id,
        availableAlgorithms: [],
    },
    {
        id: "odm-train-option",
        displayName: "Train",
        defaultAlgorithmId: AlgorithmVehicleID.id,
        currentAlgorithmId: AlgorithmVehicleID.id,
        availableAlgorithms: [],
    },
    {
        id: "odm-truck-option",
        displayName: "Truck",
        defaultAlgorithmId: AlgorithmVehicleID.id,
        currentAlgorithmId: AlgorithmVehicleID.id,
        availableAlgorithms: [],
    },
];

export const topDownObjects: Array<Object> = [
    {
        id: "tdpdm-person-option",
        displayName: "Person",
        defaultAlgorithmId: AlgorithmOSNet025.id,
        currentAlgorithmId: AlgorithmOSNet025.id,
        availableAlgorithms: [],
    },
    {
        id: "tdpdm-head-option",
        displayName: "Head",
        defaultAlgorithmId: AlgorithmOSNet025.id,
        currentAlgorithmId: AlgorithmOSNet025.id,
        availableAlgorithms: [],
    },
];
