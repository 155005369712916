import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles, Tooltip } from "@material-ui/core";
import { CaasOrgOverviewObject } from "../../../services/WebService/wca-api/Site";
import styled, { keyframes } from "styled-components";

export interface Metrics {
    times: Array<string>;
    sites: Array<CaasOrgOverviewObject>;
    dirs: Array<string>;
    objects: Array<string>;
}
export interface Props {
    data?: Metrics;
    report: Array<{
        periodStartTime: string; // ISO8601
        sites: {
            [siteKey: string]: number;
        };
    }>;
}

type ReportRow = {
    site: CaasOrgOverviewObject;
    min: number;
    max: number;
    avg: number;
    sum: number;
};

export const calculateReportRowsFromDataset = (dataset: Props["report"], sites: Array<CaasOrgOverviewObject>) => {
    // Create O(n) efficient lookup.
    const lookup = dataset.reduce(
        (a, e) => ({
            ...a,
            ...Object.keys(e.sites).reduce(
                (a2, e2) => ({
                    ...a2,
                    [e2]: (a[e2] || []).concat({
                        periodStartTime: e.periodStartTime,
                        count: e.sites[e2],
                    }),
                }),
                {} as { [siteKey: string]: Array<{ periodStartTime: string; count: number }> },
            ),
        }),
        {} as { [siteKey: string]: Array<{ periodStartTime: string; count: number }> },
    );

    return sites.map((site) => {
        const sorted = (lookup[`${site.id}`] ?? []).sort((a, b) => a.count - b.count);
        const sum = sorted.length === 0 ? 0 : Math.round(sorted.reduce((a, b) => a + b.count, 0));
        const min = sorted.length === 0 ? 0 : Math.round(sorted[0].count);
        const max = sorted.length === 0 ? 0 : Math.round(sorted[sorted.length - 1].count);
        const avg = sorted.length === 0 ? 0 : Math.round(sum / sorted.length);

        const row: ReportRow = { site, sum, min, max, avg };
        return row;
    });
};

const TableView: React.FC<Props> = ({ data, report }) => {
    const [prevReport, setPrevReport] = useState<Array<ReportRow>>([]);
    const [currentReport, setCurrentReport] = useState<Array<ReportRow>>(
        calculateReportRowsFromDataset(report, data?.sites || []),
    );
    const [deltaReport, setDeltaReport] = useState<{ [siteId: string]: ReportRow }>({});

    useEffect(() => {
        const updateChange = () => {
            const newPrevReport = currentReport;
            const newCurrentReport = calculateReportRowsFromDataset(report, data?.sites || []);

            // For efficient O(n) processing rather than O(n^2).
            const prevDataLookup = newPrevReport.reduce(
                (a, e) => ({ ...a, [e.site.id]: e }),
                {} as { [siteId: string]: ReportRow },
            );

            const newDeltaReport = newCurrentReport
                .map((e) => ({
                    ...e,
                    sum: e.sum - (prevDataLookup[e.site.id]?.sum || 0),
                    min: e.min - (prevDataLookup[e.site.id]?.min || 0),
                    max: e.max - (prevDataLookup[e.site.id]?.max || 0),
                    avg: e.avg - (prevDataLookup[e.site.id]?.avg || 0),
                }))
                .reduce((a, e) => ({ ...a, [e.site.id]: e }), {} as { [siteId: string]: ReportRow });

            setPrevReport(newPrevReport);
            setCurrentReport(newCurrentReport);
            setDeltaReport(newDeltaReport);
        };

        // 84% of 3s (see `fadeInOut` keyframes).
        // (0.84 * 3000ms) + (0.1 * 3000ms) = 2520 + 300 = 2820ms
        const updateIntervalHandle = window.setInterval(updateChange, 2820);

        return () => {
            if (updateIntervalHandle !== null) {
                window.clearInterval(updateIntervalHandle);
            }
        };
    }, [report, data?.sites, prevReport, currentReport]);

    const classes = useStyles();

    const renderHeader = (label: string, tooltip: string) => (
        <Tooltip title={tooltip} arrow={true} classes={{tooltip: classes.tooltip}}>
            <TableCell key={label} colSpan={label === "Site" ? 4 : 1} align="left">
                {label}
            </TableCell>
        </Tooltip>
    );

    return (
        <Container>
            <TableContainer className={classes.wrapper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>{COLUMN_HEADERS.map(header => renderHeader(header.label, header.tooltip))}</TableRow>
                    </TableHead>
                    <TableBody>
                        {currentReport.map((row) => {
                            const delta = deltaReport[row.site.id] || {};

                            return (
                                <TableRow style={{ position: "relative" }} key={row.site.id}>
                                    <TableCell
                                        colSpan={4}
                                        align="left"
                                        style={{
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            maxWidth: 300,
                                        }}
                                    >{`${row.site.id}`}</TableCell>
                                    <TableCell colSpan={1} align="left">
                                        {`${row.min}ㅤ`}
                                        {!!delta.min && delta.min !== 0 && (
                                            <TableRowTotalChangeIndicator
                                                numValueDigits={row.min.toString().length}
                                                numDeltaDigits={delta.min.toString().length}
                                                isPositiveChange={delta.min > 0}
                                            >{`${delta.min > 0 ? "+" : ""}${delta.min}`}</TableRowTotalChangeIndicator>
                                        )}
                                    </TableCell>
                                    <TableCell colSpan={1} align="left">
                                        {`${row.max}ㅤ`}
                                        {!!delta.max && delta.max !== 0 && (
                                            <TableRowTotalChangeIndicator
                                                numValueDigits={row.max.toString().length}
                                                numDeltaDigits={delta.max.toString().length}
                                                isPositiveChange={delta.max > 0}
                                            >{`${delta.max > 0 ? "+" : ""}${delta.max}`}</TableRowTotalChangeIndicator>
                                        )}
                                    </TableCell>
                                    <TableCell colSpan={1} align="left">
                                        {`${row.avg}ㅤ`}
                                        {!!delta.avg && delta.avg !== 0 && (
                                            <TableRowTotalChangeIndicator
                                                numValueDigits={row.avg.toString().length}
                                                numDeltaDigits={delta.avg.toString().length}
                                                isPositiveChange={delta.avg > 0}
                                            >{`${delta.avg > 0 ? "+" : ""}${delta.avg}`}</TableRowTotalChangeIndicator>
                                        )}
                                    </TableCell>
                                    <TableCell colSpan={1} align="left">
                                        {`${row.sum}ㅤ`}
                                        {!!delta.sum && delta.sum !== 0 && (
                                            <TableRowTotalChangeIndicator
                                                numValueDigits={row.sum.toString().length}
                                                numDeltaDigits={delta.sum.toString().length}
                                                isPositiveChange={delta.sum > 0}
                                            >{`${delta.sum > 0 ? "+" : ""}${delta.sum}`}</TableRowTotalChangeIndicator>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default TableView;
const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "50px",
    },
    table: {
        width: "60%",
        minWidth: 700,
    },
    tooltip: {
      fontSize: "0.8em",
    },
});

const Container = styled.div`
    td,
    th {
        border: 1px solid #ccc;
        padding: 7px;
        text-indent: 20px;
        padding-right: 27px;
    }

    th {
        background: black;
        color: white;
    }

    table {
        justify-self: center;
        align-self: center;
        margin-top: 20px;
        border-collapse: collapse;
    }
`;

// Create the fade in and out keyframes
const fadeInOut = keyframes`
    0% {
        opacity: 0;
    }
    16% {
        opacity: 1;
    }
    84% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

const TableRowTotalChangeIndicator = styled.div<{
    isPositiveChange: boolean;
    numValueDigits: number;
    numDeltaDigits: number;
}>`
    z-index: 1000;
    // Note: the following formula was defined experimentally from observing the target UX.
    margin-left: ${(props): string => `${Math.pow(props.numValueDigits + 0.01 / props.numDeltaDigits, 0.9) * 11}px`};
    margin-top: -20px;
    font-weight: bold;
    font-size: 0.875rem;

    color: ${(props): string => (props.isPositiveChange ? "#14B851" : "#CF4E68")};
    -webkit-animation: ${fadeInOut} 3s;
    animation: ${fadeInOut} 3s;
    position: absolute;
`;

// eslint-disable-next-line @typescript-eslint/naming-convention
const COLUMN_HEADERS = [
    {
        label: "Site",
        tooltip: "The ID of the site for where the observations took place.",
    },
    {
        label: "Min",
        tooltip: "The minimum number of objects observed for the site in any 15 minute period.",
    },
    {
        label: "Max",
        tooltip: "The maximum number of objects observed for the site in any 15 minute period.",
    },
    {
        label: "Avg",
        tooltip: "The average number of people observed in a 15 minute period for the day.",
    },
    {
        label: "Total",
        tooltip: "The minimum number of people observed for the site.",
    },
];
