import moment from "moment";
import React, { ReactNode, FC, useState } from "react";
import styled from "styled-components";
import { media } from "../../config/Config";
import { FloorListItem } from "./FloorList";
import Toggle from "../Tab/Toggle";
import Timer from "../Icon/Timer";
import { OccupancyMode } from "./../../services/Store/UIStore";

/**
 * Tab types
 */
type InsightTab = "Floors" | "Desks" | "Rooms";

/**
 * FloorOccupancy
 */
interface FloorOccupancy {
    floorOccupancyCount?: number;
    floorOccupancyCapacity?: number;
    deskOccupancyCount?: number;
    deskRecentOccupancyCount?: number;
    deskOccupancyCapacity?: number;
    roomOccupancyCount?: number;
    roomOccupancyCapacity?: number;
}

/**
 * Definition of the data required for each list item.
 *
 * @interface floorData
 */
export interface FloorData {
    id: number;
    name: string;
    occupancy: FloorOccupancy;
    shouldShowFloorOccupancyInsights: boolean;
}

/**
 * Props
 */
export interface Props {
    floorsData: Array<FloorData>;
    buildingId: number;
    wrldId: string;
    availableInsights: { spaceInsightPlan: boolean; spaceInsightDesk: boolean; spaceInsightMeetingRoom: boolean };
    shouldShowDeskRecentOccupancyInsights: boolean;
    occupancyMode: OccupancyMode;
    setOccupancyMode: (occupancyMode: OccupancyMode) => void;
}

const BuildingLevelList: FC<Props> = (props: Props) => {
    const [selectedTab, setSelectedTab] = useState("Floors");
    const [selectedTabIndex, setSelectedTabIndex] = useState("0");
    const { floorsData, shouldShowDeskRecentOccupancyInsights, buildingId, wrldId, occupancyMode } = props;

    const onUpdateOccupancy = (occupancy: string): void => {
        props.setOccupancyMode(occupancy as OccupancyMode);
    };

    const onSelectInsightTab = (selected: string, tabIndex: string): void => {
        const Selected: InsightTab = selected as InsightTab;
        setSelectedTab(Selected);
        setSelectedTabIndex(tabIndex);
    };

    const generateListItem = (floor: FloorData): ReactNode => {
        return (
            <FloorListItem
                key={floor.id}
                floorName={floor.name}
                tabType={selectedTab as InsightTab}
                occupancyMode={occupancyMode}
                occupancy={floor.occupancy}
                shouldShowDeskRecentOccupancyInsights={shouldShowDeskRecentOccupancyInsights}
                shouldShowFloorOccupancyInsights={floor.shouldShowFloorOccupancyInsights}
                floorId={floor.id}
                buildingId={buildingId}
                wrldId={wrldId}
            />
        );
    };

    /**
     * Generates a list from floorsData.
     */
    const listItems = floorsData.map(generateListItem);
    const generateList = (): ReactNode => <Content>{listItems}</Content>;

    /**
     * Check what the available insights are and return as a string array.
     * Used for setting up what tabs are available.
     */
    const checkAvailableInsights = (): Array<string> => {
        // We are hard setting the Floors tab as always available as there should always be insights on this tab.
        const availableButtons: [string] = ["Floors"];

        // Check what optional insights we have.
        if (props.availableInsights.spaceInsightDesk) {
            availableButtons.push("Desks");
        }
        if (props.availableInsights.spaceInsightMeetingRoom) {
            availableButtons.push("Rooms");
        }

        return availableButtons;
    };

    const toggleElements = ["Occupied", "Vacant"];

    return (
        <Container>
            <Header>
                <HeaderTimestamp>
                    <HeaderTimeIcon>
                        <TimerIcon />
                    </HeaderTimeIcon>
                    <HeaderTimestampTitle>Last Updated:&nbsp;&nbsp;</HeaderTimestampTitle>{" "}
                    {moment(new Date()).format("D MMM h:mm a")}
                </HeaderTimestamp>
                <HeaderLeft>
                    <HeaderTitle>{selectedTab}</HeaderTitle>
                    <Toggle
                        elements={toggleElements}
                        ToggleStyle={"Blue"}
                        onCallback={onUpdateOccupancy}
                        selected={toggleElements.indexOf(occupancyMode as string).toString()}
                    />
                </HeaderLeft>
                <HeaderRight>
                    <Toggle
                        elements={checkAvailableInsights()}
                        ToggleStyle={"White"}
                        selected={selectedTabIndex}
                        onCallback={onSelectInsightTab}
                    />
                </HeaderRight>
            </Header>
            {generateList()}
        </Container>
    );
};

export default BuildingLevelList;

const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #f4f4f4;
`;

const Header = styled.div`
    overflow: hidden;
    position: relative;
    padding: 0px 24px 18px 24px;
`;

const HeaderTimestamp = styled.div`
    height: 34.23px;
    display: flex;
    align-items: center;

    color: #000000;
    font-size: 13px;
    ${media.mobile} {
        display: none;
    }
`;

const TimerIcon = styled(Timer).attrs((props) => ({ height: "200%", width: "200%" }))``;

const HeaderTimeIcon = styled.div`
    width: 16px;
    height: 16px;
    margin-right: 10px;
    user-select: none;
    justify-content: center;
`;

const HeaderTimestampTitle = styled.span`
    color: #9e9e9e;
`;

const HeaderLeft = styled.div`
    height: auto;
    float: left;
    display: flex;
    align-items: center;
    ${media.mobile} {
        justify-content: space-between;
        float: none;
    }
`;

const HeaderTitle = styled.h3`
    float: left;
    color: #000000;
    font-size: 24px;
    font-weight: normal;
    line-height: 29px;
    margin: 0px;
    margin-right: 15px;
`;

const HeaderRight = styled.div`
    float: right;
    ${media.mobile} {
        display: none;
    }
`;

const Content = styled.div`
    overflow: hidden;
    position: relative;
    padding: 0px 24px 18px 24px;

    & > div {
        margin-bottom: 12px;
    }
`;
