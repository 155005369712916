import React, { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Box, Body14, greyL2 } from "telstra-components";
import { Button } from "telstra-ui/components/button/Button";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore 1
// import { TextInput } from "telstra-ui/components/text-input/TextInput";

export interface CustomTextFieldProps {
    // name: string;
    helperText?: string;
    placeholder?: string;
    disabled?: boolean;
    password?: boolean;
    confirmButtonText?: string;
    errorText?: string;
    maxLength?: number;
    prefixLabel?: string;
    value?: string;
    defaultValue?: string;
    name?: string;
    id?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onClickConfirmButton?: (textInput: HTMLInputElement) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractByString = (o: any, s: string): any => {
    if (!s.includes(".")) {
        return o[s];
    }
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    const a = s.split(".");
    for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }

    return o;
};

const CustomTextField = (props: CustomTextFieldProps): ReactElement => {
    const { helperText, placeholder, disabled, password, confirmButtonText, errorText, maxLength, prefixLabel, value, defaultValue, name, id, onChange, onKeyDown, onClickConfirmButton } = props;
    const [showPassword, setShowPassword] = useState(password);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // const { values, handleChange, handleBlur, touched, errors } = useFormikContext<any>();
    const passwordHelperText = showPassword ? "Show password" : "Hide password";

    const textInputRef = useRef<HTMLInputElement>();

    const [shouldShowConfirmButton, setShouldShowConfirmButton] = useState(false);

    const handlerShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // handleChange(e);
        if (!textInputRef.current) {
            textInputRef.current = e.target;
        }
        // console.log("e.target.value.length", e.target.value.length)
        setShouldShowConfirmButton((e.target.value.length ?? 0) > 0);
        onChange && onChange(e);
    };

    useEffect(() => {
        const callback = (event: Event) => {
            setShouldShowConfirmButton(((event.target as HTMLInputElement).value.length ?? 0) > 0);
        };
        textInputRef.current?.addEventListener('change', callback);

        return () => {
            textInputRef.current?.removeEventListener("change", callback);
        }
    }, [textInputRef.current])

    const [inputFocus, setInputFocus] = useState(false);

    return (
        <Root data-testid={`text-field__${name}`}>
            <TextRowContainer>
                <TextInputContainer>
                    {prefixLabel && (<PrefixContainer $hasFocus={inputFocus}>
                        <Label $hasFocus={inputFocus}>{prefixLabel}</Label>
                    </PrefixContainer>)}
                    {/* <TextInput
                        name={name}
                        value={extractByString(values, name) || initialValue || ""}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        errors={
                            errorText ? [errorText] : extractByString(touched, name) && extractByString(errors, name) && [extractByString(errors, name)]
                        }
                        variant="basic"
                        type={(password && showPassword && "password") || "text"}
                        placeholder={placeholder}
                        assistiveText={helperText}
                        disabled={disabled}
                        maxLength={maxLength}
                        onKeyDown={onKeyDown}
                    /> */}
                    <TextInput
                        // value={"Gateway 1 - "}
                        // name={name}
                        // value={extractByString(values, name)}
                        value={value}
                        onChange={handleInputChange}
                        onFocus={() => setInputFocus(true)}
                        onBlur={() => setInputFocus(false)}
                        // errors={
                        //     errorText ? [errorText] : extractByString(touched, name) && extractByString(errors, name) && [extractByString(errors, name)]
                        // }
                        // variant="basic"
                        // type={(password && showPassword && "password") || "text"}
                        placeholder={placeholder}
                        // assistiveText={helperText}
                        name={name}
                        disabled={disabled}
                        maxLength={maxLength}
                        onKeyDown={onKeyDown}
                        defaultValue={defaultValue}
                        id={id}
                    />
                    {confirmButtonText && shouldShowConfirmButton && 
                        <ButtonContainer>
                            <Button
                                variant="secondary"
                                size="medium"
                                label={confirmButtonText}
                                onClick={() => {
                                    if (onClickConfirmButton) {
                                        onClickConfirmButton(textInputRef.current!);
                                    }
                                }}
                            />
                        </ButtonContainer>
                    }
                </TextInputContainer>
            </TextRowContainer>
            <Box display="flex" justifyContent="space-between">
            <Box display="flex" justifyContent="space-between">
                {helperText && (
                    <HelperText>
                        {/* <Body14 aria-label={passwordHelperText} onClick={handlerShowPassword}> */}
                            {helperText}
                        {/* </Body14> */}
                    </HelperText>
                )}
            </Box>
                {password && (
                    <StyledBody14 aria-label={passwordHelperText} onClick={handlerShowPassword}>
                        {passwordHelperText}
                    </StyledBody14>
                )}
            </Box>
        </Root>
    );
};

export default CustomTextField;

const PrefixContainer = styled.div<{ $hasFocus?: boolean }>`
    position: relative;
    left: 5px;
    display: flex;
    flex-direction: column;
    border-bottom: ${props => props.$hasFocus ? '2px solid' : '1px solid'};
    margin-bottom: -1px;
    border-color: ${props => props.$hasFocus ? "#0064d2" : `${greyL2}`};
`;

const Root = styled.div`
    letter-spacing: -0.13px;
    line-height: 22px;

    .tl-input {
        margin-top: 0;
        width: 100%;
    }
`;

const StyledBody14 = styled(Body14)`
    font-style: italic;
    cursor: pointer;
    color: #414141;
`;

const TextRowContainer = styled.div`
    position: relative;
    // margin-left: 100px;
    // border-bottom: 1px solid #757575;
    // background: red;
    // border-color: red;
    height: 42px;
`;

const TextInputContainer = styled.div`
    // position: relative;
    // margin-left: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    // align-items: flex-start;
    height: 42px;
`;

const ButtonContainer = styled.div`
    position: absolute;
    top: -6px;
    right: 12px;
`;

const Label = styled.div<{ $hasFocus?: boolean }>`
    width: max-content;
    height: max-content;
    // height: 25px;
    // overflow: hidden;
    white-space: nowrap;
    word-wrap: break-word;
    // line-height: 25px;
    display: block;

    // line-height: 25px;
    letter-spacing: -0.15px;
    font-weight: normal;
    font-size: 16px;
    font-family: TelstraAkkurat-Light;
    color: #414141;

    margin-top: 3px;

    padding-bottom: ${props => props.$hasFocus ? '4px' : '5px'};
    padding-right: 5px;

    // border-bottom: 1px solid transparent;
`;

const TextInput = styled.input`
    margin-left: 5px;
    width: 100%;
    height: 32px;

    outline: none;
    box-shadow: none;
    border-radius: 0;
    border: none;
    line-height: 25px;
    letter-spacing: -0.15px;
    font-weight: normal;
    font-size: 16px;
    font-family: TelstraAkkurat-Light;
    color: #414141;
    padding: 8px 0px 8px 0px;
    border-bottom: 1px solid #757575;

    :focus {
        border-bottom: 2px solid #0064d2;
    }

    // error state.
    // border-bottom: 2px solid #d0021b
`;

const HelperText = styled.div`
    color: #757575;
    font-size: 14px;
    font-family: TelstraAkkurat-Regular;
    letter-spacing: -0.2px;
    line-height: 1.4;
    font-weight: 400;
    vertical-align: middle;
    margin-top: 8px;
    margin-left: 4px;
`;
