import React, { ReactElement } from "react";
import styled from "styled-components";
import { EntryType } from "./types";
import { IconCoverageMobile, IconMapLocation, IconOrganisation, IconSecurityView, IconSiteMonitor } from "../../../Icons";
import { greyL2 } from "../../../themes/Colors";

export interface RenderCellProps {
  disabled?: boolean;
  type: EntryType;
}

export const renderCell = ({ disabled, type }: RenderCellProps): ReactElement | null => {
  if (disabled) return null;

  const color = {
    organisation: greyL2,
    site: greyL2,
    gateway: greyL2,
    camera: greyL2,
    blueprint: greyL2,
  }[type];

  return (
    <IconWrapper>
      {
        {
          organisation: <IconOrganisation fill={color} />,
          site: <IconMapLocation fill={color} />,
          gateway: <IconCoverageMobile fill={color} />,
          camera: <IconSiteMonitor fill={color} />,
          blueprint: <IconSecurityView fill={color} />,
        }[type]
      }
    </IconWrapper>
  );
};

const IconWrapper = styled.div`
  display: block;
  height: 24px;
  width: 24px;
  margin: 8px 12px 8px 36px;
`;
