import axios from "axios";
import { getBaseUrl } from "./../../../services";
import { GranularityMinutes } from "../../../views/Report/Reporting/Reporting";

export namespace Reports {
    export namespace Database {
        export type Site = {
            "siteId"        : string;                           // e.g. "manual-test-site01"
            "name"          : string;                           // e.g. "manual test site01"
            "active"        : boolean;                          // e.g. true
        }

        export type SiteTelemetryProperties = {
            "objects"       : Array<Object>;                    // e.g. ["tripwire-1", "entrance-wire"]
            "locations"     : Array<Location>;                  // e.g. ["person", "bicycle"]
        }

        export type Location = {
            id              : string;                           // e.g. "entrance-polygon"
            name            : string;                           // e.g. "entrance-polygon"
            type            : "polygon" | "trip line";          // e.g. "polygon"
        };

        export type Object = string;                            // e.g. "bicycle"

        export type TelemetryPacketBase = {
            "edgeDeviceId"  : string;                           // e.g. "test-mock-d879428-sv-dev"
            "leafDeviceId"  : string;                           // e.g. "device-id"
            "timestamp"     : string;                           // e.g. "2023-06-02 10:45:00.000 +1000"
            "className"     : string;                           // e.g. "bicycle"
            "location"      : string;                           // e.g. "entrance-polygon"
            "objectType"    : "polygon" | "trip line";          // e.g. "polygon"
        };

        export type TelemetryPacketTripline = TelemetryPacketBase & {
            "plusCount"     : number;                           // e.g. 1
            "minusCount"    : number;                           // e.g. -1
        };

        export type TelemetryPacketPolygon = TelemetryPacketBase & {
            "dwellTime"         : number;                       // e.g. TBC
            "occupancy"         : number;                       // e.g. TBC
            "totalOccupancy"    : number;                       // e.g. TBC
            "entries"           : number;                       // e.g. TBC
            "exits"             : number;                       // e.g. TBC
        };
    }

    export namespace Web {
        export type Site = {
            "siteId"        : string;                           // e.g. "manual-test-site01"
            "name"          : string;                           // e.g. "manual test site01"
            "active"        : boolean;                          // e.g. true
        }
        
        export type Location = {
            "locationId"    : string;                           // e.g. "entrance-polygon"
            "name"          : string;                           // e.g. "entrance-polygon"
            "type"          : "polygon" | "trip line";          // e.g. "polygon"
            "active"        : boolean;                          // e.g. true
        }
        
        export type Object = {
            "objectId"      : string;                           // e.g. "bicycle"
            "name"          : string;                           // e.g. "bicycle"
            "active"        : boolean;                          // e.g. true
        }

        export type SiteTelemetryProperties = {
            "objects"       : Array<Object>;
            "locations"     : Array<Location>;
        }

        export type TelemetryPacketBase = {
            "edgeDeviceId"  : string;                           // e.g. "test-mock-d879428-sv-dev"
            "leafDeviceId"  : string;                           // e.g. "device-id"
            "timestamp"     : string;                           // e.g. "2023-06-02 10:45:00.000 +1000"
            "className"     : string;                           // e.g. "bicycle"
            "location"      : string;                           // e.g. "entrance-polygon"
            "objectType"    : "polygon" | "trip line";          // e.g. "polygon"
        };

        export type TelemetryPacketTripline = TelemetryPacketBase & {
            "plusCount"     : number;                           // e.g. 1
            "minusCount"    : number;                           // e.g. -1
        };

        export type TelemetryPacketPolygon = TelemetryPacketBase & {
            "dwellTime"         : number;                       // e.g. TBC
            "occupancy"         : number;                       // e.g. TBC
            "totalOccupancy"    : number;                       // e.g. TBC
            "entries"           : number;                       // e.g. TBC
            "exits"             : number;                       // e.g. TBC
        };
    }
}

export const ReportingService = () => {
    const baseUrl = `${getBaseUrl()}`;

    const getSites = async (): Promise<Array<Reports.Web.Site>> => {
        try {
            const res = await axios.get(`${baseUrl}/caas/sites`);
            const responseData: Array<Reports.Database.Site> = res.data;
            return responseData;
        } catch (error: unknown) {
            console.error(error);
            return [];
        }
    };

    const getSiteTelemetryProperties = async (siteId: string): Promise<Reports.Web.SiteTelemetryProperties> => {
        try {
            const res = await axios.get(`${baseUrl}/caas/sites/${siteId}`, {
                params: {
                    telemetryProperties: true,
                },
            });
            const responseData: Reports.Database.SiteTelemetryProperties = res.data;
            return {
                objects: responseData.objects.map(e => ({
                    objectId: e,
                    name: e,
                    active: true,
                })),
                locations: responseData.locations.map(e => ({
                    locationId: e.id,
                    name: e.name,
                    type: e.type,
                    active: true,
                })),
                // locations: responseData.locations.map(e => ({
                //     locationId: e,
                //     name: e,
                //     type: "polygon",
                //     active: true,
                // })),
            };
        } catch (error: unknown) {
            console.error(error);
            return {
                objects: [],
                locations: [],
            };
        }
    };

    const getTelemetry = async (
        siteId: string,
        startTime: Date,
        endTime: Date,
        metric: "trafficin" | "trafficout" | "totaltraffic" | "totaloccupancy" | "dwelltime",
        objects: Array<string>,
        locations: Array<string>,
        interval: GranularityMinutes,
    ): Promise<Array<Reports.Web.TelemetryPacketTripline> | Array<Reports.Web.TelemetryPacketPolygon>> => {
        try {
            const res = await axios.get(`${baseUrl}/reporting/telemetry-agg`, {
                params: {
                    isVantage: false,
                    siteId: siteId,
                    startTime: startTime.valueOf(),
                    endTime: endTime.valueOf(),
                    metric: metric,
                    object: objects.join(","),
                    location: locations.join(","),
                    interval,
                },
            });
            const responseData = res.data;
            if (["trafficin", "trafficout", "totaltraffic"].includes(metric)) {
                return responseData as Array<Reports.Database.TelemetryPacketTripline>;
            } else {
                return responseData as Array<Reports.Database.TelemetryPacketPolygon>;
            }
        } catch (error: unknown) {
            console.error(error);
            return [];
        }
    };

    return {
        getSites,
        getSiteTelemetryProperties,
        getTelemetry,
    };
}
