import React, { ReactElement } from "react";
import styled from "styled-components";

interface Props {
  fill?: string;
  title?: string;
}

const SVGIconInformationActive = ({ fill, title }: Props): ReactElement => {
  return (
    <SVGWrapper
      id="informationActiveIcon"
      fillColor={fill || ""}
      width="20"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <path
              d="M10 1.167c4.875.009 8.824 3.958 8.833 8.833 0 4.879-3.954 8.833-8.833 8.833S1.167 14.88 1.167 10 5.12 1.167 10 1.167zm.742 6.991H9.275v6.009h1.467V8.158zm.033-2.65h-1.55V7h1.55V5.508z"
              transform="translate(-502.000000, -472.000000) translate(502.000000, 472.000000)"
            />
          </g>
        </g>
      </g>
    </SVGWrapper>
  );
};

export default SVGIconInformationActive;

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
