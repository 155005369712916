import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
  fill?: string;
}

export default class RightArrow extends React.Component<Props> {
  public render(): ReactNode {
    return (
      <SVGWrapper width="24" height="24" viewBox="0 0 24 24" fillColor={this.props.fill}>
        <title>RightArrow</title>
        <g>
          <g>
            <g>
              <path
                d="M12 5.94l6.01 6.01-.025.024.026.026L12 18.01l-1.06-1.06 4.198-4.2H7v-1.5h8.188L10.939 7 12 5.94z"
                transform="translate(-50.000000, -269.000000) translate(50.000000, 269.000000)"
              />
            </g>
          </g>
        </g>
      </SVGWrapper>
    );
  }
}

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
