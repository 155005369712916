import { DropdownOption } from "./types";

export const maxTagNumber = 10;

// Max number of instruments allowed per camera config
export const maxInstruments = 10;

export const siteTagVariableOptions = [
    { id: "1", label: "Site Name", value: "Site Name" },
    { id: "2", label: "Site Type", value: "Site Type" },
    { id: "3", label: "State", value: "State" },
    { id: "4", label: "Region", value: "Region" },
    { id: "5", label: "Suburb", value: "Suburb" },
] as Array<DropdownOption>;

export const cameraTagVariableOptions = [
    { id: "1", label: "Area", value: "area" },
    { id: "2", label: "Crossing", value: "crossing" },
] as Array<DropdownOption>;

export const cameraClassVariableOptions = [
    { id: "1", label: "People", value: "People" },
    { id: "2", label: "Bicycles", value: "Bicycles" },
    { id: "3", label: "Cars", value: "Cars" },
    { id: "4", label: "Motorcycles", value: "Motorcycles" },
    { id: "5", label: "Light Trucks", value: "Light Trucks" },
    { id: "6", label: "Heavy Trucks", value: "Heavy Trucks" },
] as Array<DropdownOption>;
