import React, { ReactElement } from "react";
import styled from "styled-components";

interface Props {
  fill?: string;
}

const SVGIconSearch = ({ fill = "#000000" }: Props): ReactElement => {
  return (
    <SVGWrapper
      id="searchIcon"
      height="100%"
      fillColor={fill || ""}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.6 17.61"
    >
      <title>Search Icon</title>
      <path
        d="M17.59,16.75,12.34,11.5A7,7,0,1,0,2,11.94H2a7,7,0,0,0,9.45.41l5.25,5.25ZM2.88,11.1h0A5.82,5.82,0,1,1,7,12.79,5.82,5.82,0,0,1,2.88,11.1Z"
        transform="translate(0.01 0.01)"
      />
    </SVGWrapper>
  );
};

export default SVGIconSearch;

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
