import React, { FC } from "react";
import { Dashboard } from "../../../layouts";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Organisations as SVOrganisations } from "../../../smart-video/views/Organisations/Organisations";

const Organisations: FC = observer(() => {
    return (
        <Dashboard>
            <Container>
                <SVOrganisations />
            </Container>
        </Dashboard>
    );
});

export default Organisations;

const Container = styled.div`
    padding: 0 24px 18px 24px;
`;
