import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import styled from "styled-components";
import { Dashboard } from "../../../layouts";
import { Body18, Heading28 } from "telstra-components";
import { useStore } from "../../../services";
import moment from "moment";
import { colorArray, ReportsType, Telemetry } from "./config";
import Throbber from "../../../components/Common/Throbber/Throbber";
import MultiSelectDropdown from "../../../components/List/MultiSelectDropdown/MultiSelectDropdown";
import TableView, { calculateReportRowsFromDataset } from "../TableView/TableView";
import { CaasOrgOverviewObject } from "../../../services/WebService/wca-api/Site";
import { SmartSpaces } from "../../../services/WebService/SmartSpaces";
import { ExportCsvButton } from "../../../components";

const timePeriodDuration = 15; // in minutes

const groupBy = (list: Array<Telemetry>, keyGetter: (obj: Telemetry) => string) => list
    .reduce((a, e) => ({
        ...a,
        [keyGetter(e)]: (a[keyGetter(e)] || []).concat(e),
    }), {} as { [groupByKey: string]: Array<Telemetry> });

const pDate = (raw: string) => round(
    moment(raw, "YYYY-MM-DD HH:mm:ssZ").local(),
    moment.duration(timePeriodDuration, "minutes"),
    "floor",
).format("HH:mm");

function round(date: moment.Moment, duration: moment.Duration, method: "floor" | "ceil") {
    return moment(Math[method]((+date) / (+duration)) * (+duration)); 
}

const filteredData = (
    items: Array<Telemetry>,
    orgSites: Array<CaasOrgOverviewObject>,
    _sites: Array<CaasOrgOverviewObject>,
    _dirs: Array<string>,
    _objects: Array<string>,
) => {
    // Turn this into a lookup table based on periodStartTime, to reduce complexity from O(n^2) to O(n).
    const filteredData = items
        .filter((item, index) => {
            const siteCheck = _sites.some(site => site.id === item.siteId);
            const dirsCheck = _dirs.includes(item.direction);
            const objCheck = _objects.includes(item.className);

            return siteCheck && dirsCheck && objCheck;
        })
        .reduce((a, e) => {
            const bucketKey = pDate(e.periodStartTime);
            return {
                ...a,
                [bucketKey]: (a[bucketKey] || []).concat(e),
            };
        }, {} as { [periodStartTime: string]: Array<SmartSpaces.WebApp.TelemetryV2> })

    const sitesLookupById = orgSites.reduce((a, e) => ({
        ...a,
        [e.id]: e,
    }), {} as { [siteId: string]: CaasOrgOverviewObject });

    const timePeriodCount = Math.ceil(((moment().hours() * 60) + moment().minutes()) / timePeriodDuration);

    const filters = {
        times: new Array(timePeriodCount).fill(0)
            .map((_, i) => moment().startOf("day").add(timePeriodDuration * i, "minutes").format("HH:mm")),
        sites: items
            .filter((v, i, a) => a.findIndex(v2=>(v2.siteId === v.siteId)) === i)   // Remove duplicates based on `siteId`
            .map(e => sitesLookupById[e.siteId])                                    // Map to corresponding `CaasOrgOverviewObject` using `siteId`
            .filter(e => !!e)                                                       // Remove unfound objects
            .sort((a, b) => b.text.localeCompare(a.text)),                          // Sort by `siteName` in ascending order
        dirs: Array.from(new Set(items.map((e) => e.direction))).sort(),
        objects: Array.from(new Set(items.map((e) => e.className))).sort(),
        orgSites: Array<string>(),
    };

    const timeAgg: Array<{
        periodStartTime: string; // ISO8601
        sites: {
            [siteKey: string]: number;
        };
    }> = filters.times
        .map((periodStartTimeString) => {
            const timePeriodData: Array<Telemetry> = filteredData[periodStartTimeString] || [];
            const timePeriodTelemetryBySiteId: { [siteId: string]: Array<Telemetry> } = groupBy(timePeriodData, (telemetry) => `${telemetry.siteId}`);
            const timePeriodEventCountBySiteId: { [siteId: string]: number } = _sites
                .reduce((a, site) => ({
                    ...a,
                    [site.id]: (a[site.id] || 0) + (timePeriodTelemetryBySiteId[site.id] || []).reduce((sum, telemetry) => sum + telemetry.eventCount, 0),
                }), {} as { [siteId: string]: number });

            return {
                periodStartTime: moment(periodStartTimeString, "HH:mm").local().format("hh:mm a"),
                sites: timePeriodEventCountBySiteId,
            };
        });

    return {
        timeAgg,
        filters,
    };
};

const Reports: React.FC = () => {
    const { pathname } = useLocation();

    const [rawData, setRawData] = useState<Array<Telemetry>>([]);
    const [report, setReport] = useState<Array<{
        periodStartTime: string; // ISO8601
        sites: {
            [siteKey: string]: number;
        };
    }>>([]);
    const [orgSites, setSites] = useState<Array<CaasOrgOverviewObject>>([]);
    const [metrics, setMetrics] = useState<{
        times: Array<string>;
        sites: Array<CaasOrgOverviewObject>;
        dirs: Array<string>;
        objects: Array<string>;
    }>();
    const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [selections, setSelections] = useState<{
        sites: Array<CaasOrgOverviewObject>;
        dirs: Array<string>;
        objects: Array<string>;
    }>({
        sites: [],
        dirs: [],
        objects: [],
    });

    const [dataLastUpdatedAt, setDataLastUpdatedAt] = useState<moment.Moment>(moment())

    const handleOnSelect = (values: Array<{key: string}>, key: string) => {
        const updatedSelections = (key === "site")
            ? { ...selections, sites: values as unknown as Array<CaasOrgOverviewObject> }
            : (key === "dir")
            ? { ...selections, dirs: values.map(e => e.key) }
            : (key === "class")
            ? { ...selections, objects: values.map(e => e.key) }
            : selections;

        const filteredReport: ReportsType = filteredData(
            rawData,
            orgSites,
            updatedSelections.sites,
            updatedSelections.dirs,
            updatedSelections.objects,
        );
        setSelections(updatedSelections);
        setStates(filteredReport);
    };

    const store = useStore();

    const setStates = (filteredReport: ReportsType) => {
        setReport(filteredReport.timeAgg);
        setMetrics(filteredReport.filters);
        setSites(filteredReport.filters.sites);
    };

    const processResponse = (response: Array<Telemetry>, sites: Array<CaasOrgOverviewObject>) => {
        setRawData(response);
        setDataLastUpdatedAt(moment());

        const telemetryData = response;
        const sitesLookupById = sites.reduce((a, e) => ({
            ...a,
            [e.id]: e,
        }), {} as { [siteId: string]: CaasOrgOverviewObject });

        const updatedSelections = {
            sites: telemetryData
                .filter((v, i, a) => a.findIndex(v2=>(v2.siteId === v.siteId)) === i)   // Remove duplicates based on `siteId`
                .map(e => sitesLookupById[e.siteId])                                    // Map to corresponding `CaasOrgOverviewObject` using `siteId`
                .filter(e => !!e)                                                       // Remove unfound objects
                .sort((a, b) => b.text.localeCompare(a.text)),                          // Sort by `siteName` in ascending order
            dirs: Array.from(new Set(telemetryData.map((e) => e.direction))).sort(),
            objects: Array.from(new Set(telemetryData.map((e) => e.className))).sort(),
        };
        setSelections(updatedSelections);

        const filteredReport: ReportsType = filteredData(
            response,
            sites,
            updatedSelections.sites,
            updatedSelections.dirs,
            updatedSelections.objects,
        );
        setStates(filteredReport);
    };

    const today = new Date().toLocaleDateString("en-US", { timeZone: "Australia/Melbourne" });
    const start = moment(today).startOf("day").utcOffset("+1000").valueOf();
    const end = moment(today).endOf("day").utcOffset("+1000").valueOf();

    useEffect(() => {
        if (report.length === 0 && orgSites.length === 0 && isInitialLoading === true) {
            setIsLoading(true);
            // store.data.spaces2.sites
            store.subscription.spaces.getCustomerSiteDevices().then(async ({sites, siteDevices}) => {
                const edgeDeviceIds = Object.values(siteDevices).reduce((a, e) => a.concat(e), []).map(e => e.id);
                const deviceIdsLookupBySiteId = Object.keys(siteDevices).reduce((a, e) => ({
                    ...a,
                    ...siteDevices[e].reduce((a, siteDevice) => ({
                        ...a,
                        [siteDevice.id]: e,
                    }), {}),
                }), {} as { [deviceId: string]: string });
                store.subscription.reports
                    .getTelemetryV2(start, end, edgeDeviceIds.join(","), deviceIdsLookupBySiteId)
                    .then((result) => {
                        if (result && result.length > 0) processResponse(result, sites);
                    })
                    .catch((err) => {
                        console.error(err);
                    })
                    .finally(() => {
                        setIsLoading(false);
                        setIsInitialLoading(false);
                    });
            });
        }
    }, []);

    useEffect(() => {
        if (isInitialLoading) {
            return;
        }

        const interval = setInterval(async () => {
            const {sites, siteDevices} = await store.subscription.spaces.getCustomerSiteDevices();
            const edgeDeviceIds = Object.values(siteDevices).reduce((a, e) => a.concat(e), []).map(e => e.id);
            const deviceIdsLookupBySiteId = Object.keys(siteDevices).reduce((a, e) => ({
                ...a,
                ...siteDevices[e].reduce((a, siteDevice) => ({
                    ...a,
                    [siteDevice.id]: e,
                }), {}),
            }), {} as { [deviceId: string]: string });
            const telemetry = await store.subscription.reports.getTelemetryV2(start, end, edgeDeviceIds.join(","), deviceIdsLookupBySiteId);
            if (telemetry && telemetry.length > 0) {
                setRawData(telemetry);
                setDataLastUpdatedAt(moment());

                // Update report but don't touch existing filter selections.
                const filteredReport: ReportsType = filteredData(
                    telemetry,
                    sites,
                    selections.sites,
                    selections.dirs,
                    selections.objects,
                );
                setStates(filteredReport);
            }
        }, 8000)
        return () => clearInterval(interval);
    }, [isInitialLoading, selections])

    const getReportSummaryCsvExportRows = () => {
        const headers = ["Site", "Min", "Max", "Avg", "Total"];
        const rows = [
            headers,
            ...calculateReportRowsFromDataset(report, metrics?.sites || [])
                .map(e => [
                    e.site.id,
                    e.min,
                    e.max,
                    e.avg,
                    e.sum,
                ]),
        ];
        
        return rows;
    }

    const getReportHourlyCsvExportRows = () => {
        const siteIds = (metrics?.sites || []).map(e => e.id);
        const headers = ["Hour", ...siteIds];
        const rows = [
            headers,
            ...report
                .map(e => [
                    e.periodStartTime,
                    ...siteIds.map(siteId => e.sites[siteId])
                ]),
        ];
        
        return rows;
    }

    const exportCsvWithRows = (exportType: "SUMMARY" | "HOURLY", csvRows: Array<Array<string | number>>) => {
        const csvContent = `data:text/csv;charset=utf-8,${csvRows.map(e => e.join(",")).join("\n")}`;

        // See: https://stackoverflow.com/a/14966131/699963
        const filename = `EXPORT-${exportType}-${moment().format("YYYY_MM_DD-HH_mm_ss")}.csv`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", filename);
        document.body.appendChild(link); // Required for Firefox.
        link.click(); // This will download the data file named as `filename`.
        document.body.removeChild(link); // Cleanup.
    }

    const onClickExportCsvButton = (): void => {
        if ((metrics?.sites || []).length === 0) {
            window.alert("There is no data to export.");
        } else {
            const csvSummaryRows = getReportSummaryCsvExportRows();
            exportCsvWithRows("SUMMARY", csvSummaryRows);
            const csvHourlyRows = getReportHourlyCsvExportRows();
            exportCsvWithRows("HOURLY", csvHourlyRows);
        }
    };

    console.log("sites", orgSites.map(e => ({ ...e, key: e.id })))

    // console.log("metrics", JSON.stringify(metrics))
    // console.log("report", JSON.stringify(report))

    return (
        <Dashboard shouldHide={pathname !== "/overview"}>
            {isLoading ? (
                <Container>
                    <Throbber />
                </Container>
            ) : (
                <ReportContainer>
                    <SideWrapper>
                        <span>Sites</span>
                        <MultiSelectDropdown<CaasOrgOverviewObject & {key: string}>
                            options={orgSites.map(e => ({ ...e, key: e.id }))}
                            displayTextExtractor={e => `${e.id}`}
                            handleOnSelect={e => handleOnSelect(e, "site")}
                        />
                        <span>Metric</span>

                        <MultiSelectDropdown
                            options={(metrics?.dirs ?? []).map(e => ({ key: e }))}
                            displayTextExtractor={e => e.key}
                            handleOnSelect={e => handleOnSelect(e, "dir")}
                        />

                        <span>Objects</span>
                        <MultiSelectDropdown
                            options={(metrics?.objects ?? []).map(e => ({ key: e }))}
                            displayTextExtractor={e => e.key}
                            handleOnSelect={e => handleOnSelect(e, "class")}
                        />
                    </SideWrapper>
                    <ReportContent>
                        <ChartWrapper>
                            <ResponsiveContainer width="100%" aspect={4.0 / 1.5}>
                                <ChartWrapper>
                                    <TitleRow>
                                        <Heading28>Overview</Heading28>
                                        <ExportCsvButton
                                            label="Export CSV"
                                            primaryColour="#001e82"
                                            hoverColour="#0064D2"
                                            onClick={onClickExportCsvButton}
                                        />
                                    </TitleRow>
                                    <Body18 regular>
                                        Telstra Spatial Insights reporting tool allows you to query and visualise any
                                        data you have collected today. You can graph one or multiple items and view summary
                                        information. Data is aggregated into {timePeriodDuration} minute increments and is updated every 30 seconds.
                                    </Body18>
                                    <Body18 regular style={{ textAlign: "right", marginRight: 20, fontSize: 15 }}>
                                        Last updated: {dataLastUpdatedAt.format("ddd D MMM YYYY [at] h:mm:ssa")}
                                    </Body18>
                                </ChartWrapper>
                            </ResponsiveContainer>
                            <ResponsiveContainer width="97%" aspect={Math.round(4.0 / 1.3)}>
                                <LineChart
                                    data={report.map((e) => ({ periodStartTime: e.periodStartTime, ...e.sites }))}
                                    margin={{
                                        top: 50,
                                        right: 20,
                                        bottom: 20,
                                        left: 20,
                                    }}
                                >
                                    <CartesianGrid stroke="#f5f5f5" />
                                    <XAxis dataKey="periodStartTime">
                                        <Label offset={0} position="insideBottom" />
                                    </XAxis>
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    {(metrics?.sites ?? [])
                                        .filter((e) =>
                                            Array.from(
                                                new Set(
                                                    report
                                                        .map((e) => Object.keys(e.sites))
                                                        .reduce((a, e) => a.concat(e), []),
                                                ),
                                            ).some((e2) => e2 === `${e.id}`),
                                        )
                                        .map((item, index) => {
                                            const colorIndex =
                                                index > colorArray.length ? index % colorArray.length : index;
                                            return (
                                                <Line
                                                    key={index}
                                                    strokeWidth={2}
                                                    type="monotone"
                                                    dataKey={`${item.id}`}
                                                    stroke={colorArray[colorIndex]}
                                                />
                                            );
                                        })}
                                </LineChart>
                            </ResponsiveContainer>
                        </ChartWrapper>
                        <ReportMetadata>
                            <TableView data={metrics} report={report} />
                        </ReportMetadata>
                    </ReportContent>
                </ReportContainer>
            )}
        </Dashboard>
    );
};

export default Reports;

const ReportContainer = styled.div`
    display: flex;
    width: 100%;
    flex-grow: 1;
    border: solid 0;
    border-top: 1px solid #ececf1;
    background: transparent;
    padding: 50px;
    overflow: hidden;
`;
const SideWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-right: 1px solid #cccc;
`;
const ChartWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 30px;
`;

const ReportMetadata = styled.div`
    display: flex;
    flex-direction: column;
`;

const ReportContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const Container = styled.div`
    text-align: center;
    margin-top: 25vh;
`;

const TitleRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
