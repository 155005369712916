import React, { ReactElement } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
  fill?: string;
  title?: string;
}

const SVGIconSortingDescending = ({ fill, title }: Props): ReactElement => {
  return (
    <SVGWrapper viewBox="0 0 24 24" fillColor={fill || ""}>
      <title>{title}</title>
      <g id="Style-guide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-579.000000, -1026.000000)" fill={fill}>
          <g id="Icons/System/Sorting-Descending" transform="translate(579.000000, 1026.000000)">
            <polygon
              id="Icon"
              transform="translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000) "
              points="12 10 16 14 8 14"
            ></polygon>
          </g>
        </g>
      </g>
    </SVGWrapper>
  );
};

export default SVGIconSortingDescending;

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
