import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
  fill?: string;
}

export default class PlaceHolder extends React.Component<Props> {
  private fillColor = (): string => {
    // If we have a valid colour pass down.
    return this.props.fill;
  };

  /**
   * Render
   */
  public render(): ReactNode {
    return (
      <SVGWrapper viewBox="0 0 32 32" fillColor={this.fillColor()}>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          d="M0 0L32 0 32 32 0 32z"
                          transform="translate(-210.000000, -1446.000000) translate(189.000000, 1422.000000) translate(21.000000, 24.000000)"
                        />
                        <g opacity=".1">
                          <path
                            fill="#000"
                            d="M11.65 1.012V8.923c0 .357.003.715 0 1.074 0 .022-.001.045-.004.067l.02-.146c-.007.043-.018.084-.035.124l.055-.132c-.015.033-.033.063-.055.094l.086-.112c-.026.033-.054.062-.087.088l.11-.087c-.028.022-.06.04-.092.055l.131-.055c-.04.017-.08.028-.123.035l.145-.02c-.11.011-.223.005-.333.005H1.013c-.023 0-.047-.002-.071-.005l.145.02c-.043-.007-.084-.018-.123-.035l.131.055c-.033-.015-.063-.033-.093-.055l.11.087c-.032-.026-.06-.055-.087-.088l.087.112c-.022-.03-.04-.061-.055-.094l.055.132c-.017-.04-.028-.081-.035-.124l.02.146c-.01-.093-.005-.19-.005-.285v-.65-2.153-2.614-2.256c0-.36-.002-.721 0-1.083 0-.023.002-.045.004-.069l-.019.146c.007-.042.018-.084.035-.124l-.055.133c.015-.033.033-.064.055-.094l-.087.112c.026-.033.055-.062.088-.089l-.111.087c.029-.022.06-.04.093-.055l-.131.055c.04-.016.08-.027.123-.034l-.145.02c.11-.012.223-.005.333-.005H11.73c.023 0 .046.001.071.004l-.145-.02c.042.008.083.019.123.035l-.131-.055c.032.015.063.033.093.055l-.111-.087c.033.027.061.056.087.089l-.086-.112c.022.029.04.06.055.094L11.63.976c.017.04.028.082.034.124l-.019-.146c.003.02.004.039.004.058.003.144.06.289.16.39.095.096.251.17.389.162.291-.013.555-.243.548-.552-.011-.555-.451-1-1.004-1.008H1.007C.865.006.74.034.61.09.48.145.356.224.265.333.158.46.065.606.03.772.008.875.001.975.001 1.078v8.854c0 .039-.001.076.002.114.024.343.193.647.486.828.154.095.337.139.516.142h10.738c.172-.003.332-.044.484-.125.247-.13.453-.4.496-.679.027-.167.024-.327.024-.496v-.821-1.201-1.428-1.504-1.429-1.207-.821-.292c0-.288-.252-.566-.548-.552-.298.013-.549.242-.549.551z"
                            transform="translate(-210.000000, -1446.000000) translate(189.000000, 1422.000000) translate(21.000000, 24.000000) translate(10.000000, 10.000000)"
                          />
                          <g>
                            <path
                              fill="#FFF"
                              d="M11.938 3.16v3.284c0 .251-.203.46-.452.46H.74c-.248 0-.452-.209-.452-.46V4.398l2.352-2.19 2.33 2.19L8.632.284l3.306 2.875z"
                              transform="translate(-210.000000, -1446.000000) translate(189.000000, 1422.000000) translate(21.000000, 24.000000) translate(10.000000, 10.000000) translate(0.260418, 3.562378)"
                            />
                            <path
                              fill="#000"
                              d="M11.664 3.16v1.635c0 .55.01 1.1 0 1.65l-.001.04c-.007 0 .013-.062.001-.019l-.015.054c-.015.058.031-.05-.001 0-.006.01-.013.02-.018.032-.028.052.04-.04 0 .001l-.039.039c-.04.04.048-.026-.001 0-.003.001-.045.029-.047.026.011-.004.022-.008.033-.014l-.034.013c-.012.004-.023.005-.036.01-.06.014.062 0-.001 0H.72c-.063 0 .057.017-.002 0l-.044-.013c-.056-.014.01 0 .007.004-.001.001-.063-.033-.061-.036 0-.005.043.04.013.01l-.038-.039c-.043-.041.027.051 0-.001L.577 6.52c-.033-.05.014.058-.001 0L.56 6.466c-.015-.054.005.018.001.018l-.001-.039c-.011-.615 0-1.231 0-1.847v-.199l-.081.195c.264-.247.53-.495.795-.74l1.266-1.18.29-.27h-.387l.785.739 1.254 1.179c.098.09.194.182.29.273.105.098.287.115.389 0 .122-.137.244-.273.364-.41l.872-.98 1.06-1.19L8.37.99c.148-.167.306-.329.446-.501.001-.003.004-.004.007-.007h-.388l1.122.976 1.775 1.544.409.355c.108.094.282.116.388 0 .094-.104.116-.29 0-.391l-1.123-.976L9.231.446 8.823.091c-.107-.094-.283-.117-.388 0l-.365.41-.871.98c-.354.397-.706.795-1.06 1.19l-.913 1.025c-.148.167-.305.329-.445.502l-.007.006h.388l-.786-.738-1.254-1.18-.29-.273c-.111-.105-.276-.105-.388 0l-.526.49-1.04.968-.74.69c-.016.013-.032.027-.046.042-.071.073-.081.159-.081.251v1.817c0 .181 0 .351.086.52.125.243.385.385.651.386.118.002.236 0 .354 0h10.365c.253-.001.48-.121.623-.333.102-.149.12-.321.12-.495v-.426-1.382-1.193-.189c0-.145-.127-.283-.275-.276-.145.007-.271.122-.271.276z"
                              transform="translate(-210.000000, -1446.000000) translate(189.000000, 1422.000000) translate(21.000000, 24.000000) translate(10.000000, 10.000000) translate(0.260418, 3.562378)"
                            />
                          </g>
                          <g
                            fill="#000"
                            transform="translate(-210.000000, -1446.000000) translate(189.000000, 1422.000000) translate(21.000000, 24.000000) translate(10.000000, 10.000000) translate(3.933686, 2.209227)"
                          >
                            <circle cx="1.05" cy="1.069" r="1" />
                            <path d="M1.549 1.069c0 .019 0 .038-.003.056l-.001.02.002-.018c.005.001-.023.098-.026.107-.001.006-.013.051-.017.051.004-.01.01-.022.013-.033l-.015.033c-.016.032-.035.063-.055.093-.033.052.041-.044 0 0-.012.012-.023.026-.035.04l-.039.037c-.004.004-.008.009-.013.011-.024.02-.018.016.013-.01.002.008-.07.048-.074.05-.015.01-.031.017-.048.025-.027.013-.022.011.017-.005l-.034.012c-.035.013-.07.02-.106.03-.053.013.02-.004.018-.002-.003.005-.05.004-.056.004-.039.001-.076 0-.114-.003-.065-.004.062.015 0-.001-.023-.006-.048-.011-.071-.018l-.043-.014c-.055-.017.008.003.007.004-.004.006-.086-.047-.095-.05C.77 1.483.73 1.46.731 1.456c.01.007.017.014.027.02-.01-.006-.018-.015-.027-.023-.027-.024-.05-.05-.074-.076-.043-.046.032.051 0 0-.01-.015-.021-.029-.03-.046l-.025-.047-.009-.016c-.012-.028-.01-.022.006.016-.011 0-.027-.084-.032-.095l-.01-.045c-.005-.03-.003-.025.002.018l-.004-.038c-.003-.038-.003-.076 0-.114L.556.99C.561.961.56.967.554 1.01c-.002 0 .008-.041.01-.045C.571.93.58.899.591.867c.02-.06-.029.054.001 0C.604.847.615.824.628.804L.657.758c.032-.052-.042.044 0 0L.718.693C.721.69.755.656.758.66l-.027.02.028-.02C.77.652.863.59.868.598.869.6.81.618.853.605.87.599.887.592.905.587.922.58.94.577.958.573L.976.569C1.006.563 1 .565.958.57c.01-.014.097-.005.114-.005l.056.002c.055.003-.058-.013 0 .002.041.01.082.02.122.034.059.02-.053-.029 0 .002l.063.034.03.02.029.02C1.34.654 1.335.65 1.358.67c.03.03.06.059.088.089.042.046-.033-.051 0 0l.038.06.017.033.015.033C1.5.845 1.498.84 1.509.869c.014.04.025.081.034.122.017.063-.002-.065.002 0 .001.027.004.051.004.078.001.145.125.283.274.276.147-.007.275-.122.274-.276C2.092.616 1.812.23 1.394.073 1.004-.075.52.058.256.383c-.282.346-.338.839-.104 1.228.233.388.683.575 1.12.488.48-.094.82-.548.825-1.03.001-.145-.127-.283-.274-.276-.15.006-.273.121-.274.276z" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </SVGWrapper>
    );
  }
}

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
