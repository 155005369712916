// Extended colors

export const blueL1 = "#001E82";
export const blueL2 = "#0064d2";
export const blueL4 = "#001D81";
export const blueL5 = "#b3e0fd";

// Neutral colors
export const greyL0 = "#282828";
export const greyL1 = "#414141";
export const greyL2 = "#757575";
export const greyL3 = "#aaaaaa";
export const greyL4 = "#d8d8d8";
export const greyL5 = "#e6e6e6";
export const greyL6 = "#ededed";
export const greyL7 = "#f4f4f4";

export const white = "#ffffff";

// Traffic light colors

export const red = "#d0021b";
export const yellow = "#fad949";
export const green = "#008a00";

// Missing from design guide
// For help with naming use: https://www.colorhexa.com

export const cadmiumOrange = "#f18f2b";
export const lightGreyishBlue = "#ececf1";
export const rossoCorsa = "#da0000";
export const pureBlue = "#0099f8";
export const red2 = "#ff4a00";
export const red3 = "#a0148c";
export const turquoiseL2 = "#008381";
export const purplel1 = "#5e50bf";
export const errorMessage = "#c91800";

export const gradientColor1 = "#0064d2";
export const gradientColor2 = "#5cd6e0";
export const gradientColor3 = "#5e50bf";

export const classTagBackground = "#ad349c";
