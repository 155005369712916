import React, { FC, useEffect, useState } from "react";
import { Box, Row, Col, Body18, TreeItem } from "telstra-components";
import CustomTextFieldRow from "../CustomTextFieldRow";
import strings from "../../../strings/strings.json";
import GatewayStatus from "./GatewayStatus";
import axios from "axios";
import { getBaseUrl } from "./../../../../services";

import { Dropdown } from 'telstra-ui/components/drop-down/Dropdown';
import styled from "styled-components";
import useGatewayForm from "./useGatewayForm";

const {
    gtwGatewayNameTextFieldLabel,
} = strings.components.Forms.GatewayForm;

interface GatewayFormProps {
    gatewayFormNavigationState: ReturnType<typeof useGatewayForm>;
}

type SerialNumber = {
    serialNumberId: number;
    value: string;
}

const GatewayForm: FC<GatewayFormProps> = ({
    gatewayFormNavigationState,
}: GatewayFormProps) => {
    const [availableSerialNumbers, setAvailableSerialNumbers] = useState<SerialNumber[]>([])

    const gatewayNamePrefix = 
        gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_1"
        ? "Gateway 1 - "
        : gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_2"
        ? "Gateway 2 - "
        : gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_3"
        ? "Gateway 3 - "
        : "Gateway 4 - "

    useEffect(() => {
        axios.get<SerialNumber[]>(`${getBaseUrl()}/caas/serialNumbers`)
            .then((availableSerialNumbersResponse) => {
                console.log("availableSerialNumbersResponse length", availableSerialNumbersResponse.data.length);
                axios.get<TreeItem[]>(`${getBaseUrl()}/caas/organisations/overview`)
                    .then((treeItems) => {
                        const gatewayIdLookup = treeItems.data
                            .filter(e => e.type === "gateway")
                            .reduce((a, e) => ({ ...a, [e.id]: true }), {} as { [gatewayId: string]: boolean })

                        const currentSerialNumber = gatewayFormNavigationState.gatewayConfiguration[gatewayFormNavigationState.gatewayFormFlowPage].serialNumber;

                        setAvailableSerialNumbers(
                            availableSerialNumbersResponse.data.filter(e => !gatewayIdLookup[e.value] || e.value === currentSerialNumber)
                        );
                        console.log("reduced availableSerialNumbersResponse length", availableSerialNumbersResponse.data.filter(e => !gatewayIdLookup[e.value]).length);
                    });
            });
    }, [gatewayFormNavigationState.gatewayFormFlowPage]);

    return (
        <Container>
            { ( gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_COUNT" ) &&
                <Row>
                    <Col md={3} justify="center" noPadding>
                        <Body18 regular>
                            {"Number of Gateway devices"}
                        </Body18>
                    </Col>
                    <Col md={5} justify="center" noPadding>
                        <DropdownContainer>
                            <Dropdown
                                dropdownId="gatewayDeviceCount"
                                placeholder={"Select..."}
                                value={gatewayFormNavigationState.numberOfRequiredGatewayDevices?.toString()}
                                options={[
                                    { displayText: "1", itemId: "option-1", optionId: "id_option-1", disabled: false, subheader: false },
                                    { displayText: "2", itemId: "option-2", optionId: "id_option-2", disabled: false, subheader: false },
                                    { displayText: "3", itemId: "option-3", optionId: "id_option-3", disabled: false, subheader: false },
                                    { displayText: "4", itemId: "option-4", optionId: "id_option-4", disabled: false, subheader: false },
                                ]}
                                onOptionClick={(event: Event) => {
                                    gatewayFormNavigationState.actions.setNumberOfRequiredGatewayDevices(Number((event.target as any).textContent));
                                }}>
                            </Dropdown>
                        </DropdownContainer>
                    </Col>
                </Row>
            }
            { ( gatewayFormNavigationState.gatewayFormFlowPage !== "DEVICE_COUNT" ) &&
                <>
                    <Box marginTop="xxs">
                        <CustomTextFieldRow
                            prefixLabel={gatewayNamePrefix}
                            label={gtwGatewayNameTextFieldLabel}
                            placeholder={"Enter..."}
                            // disabled={!!connectionString && !isEditModal}
                            helperText="E.g Gateway 1 - Parking, lvl 2, Section A, North"
                            value={gatewayFormNavigationState.gatewayConfiguration[gatewayFormNavigationState.gatewayFormFlowPage]?.name || ""}
                            onInputChange={(event) => {
                                gatewayFormNavigationState.actions.setGatewayConfiguration({
                                    ...gatewayFormNavigationState.gatewayConfiguration,
                                    [gatewayFormNavigationState.gatewayFormFlowPage]: {
                                        ...(gatewayFormNavigationState.gatewayConfiguration[gatewayFormNavigationState.gatewayFormFlowPage] || {}),
                                        name: (event.target as any).value,
                                    },
                                });
                            }}
                        />
                    </Box>
                    <Box marginTop="l">
                        <Row>
                            <Col md={3} sm={3} justify="flex-start" noPadding>
                                <Body18 regular>
                                    {"Serial number:"}
                                </Body18>
                            </Col>
                            <Col md={5} sm={5} justify="flex-start" noPadding>
                                <SerialNumberDropdownContainer>
                                    <Dropdown
                                        dropdownId="serialNumberDropdown"
                                        placeholder={"Select..."}
                                        value={gatewayFormNavigationState.gatewayConfiguration[gatewayFormNavigationState.gatewayFormFlowPage]?.serialNumber || ""}
                                        options={availableSerialNumbers.map(e => ({
                                            displayText: e.value,
                                            itemId: `option-${e.serialNumberId}`,
                                            optionId: `id_option-${e.serialNumberId}`,
                                            disabled: false,
                                            subheader: false,
                                        }))}
                                        onOptionClick={(event: Event) => {
                                            gatewayFormNavigationState.actions.setGatewayConfiguration({
                                                ...gatewayFormNavigationState.gatewayConfiguration,
                                                [gatewayFormNavigationState.gatewayFormFlowPage]: {
                                                    ...(gatewayFormNavigationState.gatewayConfiguration[gatewayFormNavigationState.gatewayFormFlowPage] || {}),
                                                    serialNumber: (event.target as any).textContent,
                                                },
                                            });
                                        }}
                                        assistiveText={"Select the serial number of the Edge Compute device"}
                                        showAssistiveTextAlways={true}
                                    />
                                </SerialNumberDropdownContainer>
                            </Col>
                        </Row>
                    </Box>
                    <Box style={{marginTop: -10}}>
                        <Row>
                            <Col md={3} sm={3} justify="center" noPadding>
                                <Body18 regular>
                                    {"Cameras attached:"}
                                </Body18>
                            </Col>
                            <Col md={5} sm={5} justify="center" noPadding>
                                <CameraCountDropdownContainer>
                                    <Dropdown
                                        dropdownId="cameraCountDropdown"
                                        placeholder={"Select..."}
                                        value={gatewayFormNavigationState.gatewayConfiguration[gatewayFormNavigationState.gatewayFormFlowPage]?.numberOfCameras?.toString() || ""}
                                        options={[
                                            { displayText: "1", itemId: "option-1", optionId: "id_option-1", disabled: false, subheader: false },
                                            { displayText: "2", itemId: "option-2", optionId: "id_option-2", disabled: false, subheader: false },
                                            { displayText: "3", itemId: "option-3", optionId: "id_option-3", disabled: false, subheader: false },
                                            { displayText: "4", itemId: "option-4", optionId: "id_option-4", disabled: false, subheader: false },
                                        ]}
                                        onOptionClick={(event: Event) => {
                                            gatewayFormNavigationState.actions.setGatewayConfiguration({
                                                ...gatewayFormNavigationState.gatewayConfiguration,
                                                [gatewayFormNavigationState.gatewayFormFlowPage]: {
                                                    ...(gatewayFormNavigationState.gatewayConfiguration[gatewayFormNavigationState.gatewayFormFlowPage] || {}),
                                                    numberOfCameras: Number((event.target as any).textContent),
                                                },
                                            });
                                        }}>
                                    </Dropdown>
                                </CameraCountDropdownContainer>
                            </Col>
                        </Row>
                    </Box>
                    <Box marginTop="xxs">
                        {/* <GatewayStatus deviceId={"TODO_LINK_ACTIVE_DEVICE_ID"} testStatusOnline={gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_2"} /> */}
                        <GatewayStatus
                            // deviceId={gatewayFormNavigationState.gatewayConfiguration[gatewayFormNavigationState.gatewayFormFlowPage].id}
                            deviceId={gatewayFormNavigationState.gatewayConfiguration[gatewayFormNavigationState.gatewayFormFlowPage]?.serialNumber}
                        />
                    </Box>
                </>
            }
        </Container>
    );
};

const Container = styled.div`
    margin-left: 0.75rem;
    margin-right: 0.75rem;
`;

const DropdownContainer = styled.div`
    width: 100px;
    margin-top: 20px;

    .tl-dropdown-assistive-text {
        // margin-bottom: -20px;
        display: none;
    }

    .tl-dropdown-icon {
        margin-top: 20px;
    }
`;

const CameraCountDropdownContainer = styled.div`
    width: 100px;
    margin-top: 20px;
`;

const SerialNumberDropdownContainer = styled.div`
    .tl-dropdown-assistive-text {
        padding-top: 8px;
    }

    .tl-dropdown .tl-dropdown-header {
        margin-top: 0px;
    }
`;

export default GatewayForm;
