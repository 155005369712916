// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC } from "react";
import Modal from "telstra-ui/components/modal/Modal";
import { Button } from "telstra-ui/components/button/Button";
// TODO: Below imports are replaced with divs for now, should be replaced with components
// import { Body16, Heading20 } from "telstra-components/src/components/Typography/Typography";
import { Box } from "telstra-components";

interface ConfirmationModalProps {
    isOpen: boolean;
    title: string;
    bodyParagraph1: string;
    bodyParagraph2?: string;
    noLabel?: string;
    yesLabel: string;
    onNoClick?: () => void;
    onYesClick: () => void;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
    isOpen,
    title,
    bodyParagraph1,
    bodyParagraph2,
    onNoClick,
    noLabel,
    onYesClick,
    yesLabel,
}: ConfirmationModalProps) => {
    return isOpen ? (
        <Modal
            isOpen={isOpen}
            isCloseOnOverlayClick={true}
            size="large"
            modalType="alert-modal-default"
            onShowModal={(modalType, isOpen) => {
                isOpen || onNoClick();
            }}
        >
            <Modal.Header isClosable={false}>
                <div>{title}</div>
            </Modal.Header>
            <Modal.Body>
                <Box testId="modal-confirmation_body">
                    <Box paddingBottom="xxxs">
                        <div>{bodyParagraph1}</div>
                    </Box>
                    <Box>
                        <div>{bodyParagraph2}</div>
                    </Box>
                </Box>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    size="medium"
                    variant="secondary"
                    label={noLabel}
                    onClick={onNoClick}
                    testid="modal-confirmation_no"
                />
                <Button
                    size="medium"
                    variant="secondary"
                    label={yesLabel}
                    onClick={onYesClick}
                    testid="modal-confirmation_yes"
                />
            </Modal.Footer>
        </Modal>
    ) : null;
};

export default ConfirmationModal;
