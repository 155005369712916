import React, { FC, useEffect, useState } from "react";
import LocationMap from "../../../components/LocationMap/LocationMap";
import Search from "../../../components/Forms/SiteForm/Search";
import { Tag } from "@sv/types";
import { Box, Row, Col, Body18, AddTag, WhiteSpace, useFormikContext } from "telstra-components";
import TextFieldRow from "../TextFieldRow";
import strings from "../../../strings/strings.json";
import { ToolTip } from "telstra-ui/components/tool-tip/Tooltip";
import { getDisplayText, LocationSearchResult } from "../../../hooks/useLocationSearch";
import { ISearchItem } from "telstra-ui/components/search/Search";
import { maxTagNumber, siteTagVariableOptions } from "../../../config";
import { FeatureCollection, GeoJsonProperties, Point } from "geojson";
import axios from "axios";
import styled from "styled-components";
import { getBaseUrl } from "./../../../../services";

const {
    siteSiteNameTextFieldLabel,
    siteSiteLocationSearchHeading,
    tagsLabel,
    addTag,
    siteNameTooltip,
    siteTagTooltip,
    locationSearchError,
} = strings.components.Forms.SiteForm;

const { requiredField } = strings.components.Forms.Validation;

interface SiteFormProps {
    onTagModalOpen: () => void;
    onNameChange?: () => void;
    selectedTags: Array<Tag>;
    onTagDeselect: (tag: Tag) => void;
    searchValue: string;

    searchLocationError: boolean;

    searchActions: {
        onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSearchOptionClick: (event: any) => void;
        resetState: () => void;
    };
    chosenLocation: FeatureCollection<Point, GeoJsonProperties> | undefined;
    searchResults: Array<LocationSearchResult>;
}

const SiteForm: FC<SiteFormProps> = ({
    onTagModalOpen: handleSiteModalOpen,
    onNameChange: handleInputChange,
    selectedTags,
    onTagDeselect: handleTagDeselect,
    searchValue,
    searchLocationError,
    searchActions,
    chosenLocation,
    searchResults,
}: SiteFormProps) => {
    const { errors, touched, setFieldTouched, setFieldValue } = useFormikContext();
    const [mapCredentials, setMapCredentials] = useState<{access_token:string, ms_client_id: string}>();
    const {
        onSearchChange: handleSearchChange,
        resetState,
        onSearchOptionClick: handleSearchOptionClick,
    } = searchActions;

    const handleSearchClear = () => {
        resetState();
    };

    const handleAddTagClick = () => {
        handleSiteModalOpen();
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const renderError = (errors: any, touched: any) => {
        if (errors && errors.site?.location?.address && touched?.site?.location?.address) {
            return requiredField;
        }
    };

    const locationError = searchValue && searchLocationError ? locationSearchError : undefined;

    const getMapCredentials = async () => {
        const url = `${getBaseUrl()}/azure-maps/oauth2/token`;
        try {
            const res = await axios.get(url);
            setMapCredentials({
                access_token: res.data.access_token,
                ms_client_id: res.data.ms_client_id
            });
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                console.log(error.cause);
            }
        }
    }

    const handleLocationTouch = () => {
        setFieldTouched("site.location.geoLocation.latitude");
        setFieldTouched("site.location.geoLocation.longitude");
        setFieldTouched("site.location.id");
        setFieldTouched("site.location.address");
    };

    // reset location value when search is cleared
    useEffect(() => {
        if (searchValue) return;
        setFieldValue("site.location.geoLocation.latitude", 0);
        setFieldValue("site.location.geoLocation.longitude", 0);
        setFieldValue("site.location.id", "");
        setFieldValue("site.location.address", "");
    }, [setFieldValue, searchValue]);

    useEffect(() => {
        getMapCredentials();
    }, []);

    // return null;

    return (
        <Container>
            <TextFieldRow
                placeholder="Enter..."
                label={siteSiteNameTextFieldLabel}
                // tooltipText={siteNameTooltip}
                fieldName="site.name"
                onInputChange={handleInputChange}
            />
            {/* <Box marginTop="xs">
                <Row>
                    <Col noPadding md={3}>
                        <Box display="flex">
                            <Box flex="1">
                                <Body18 regular>{tagsLabel}</Body18>
                            </Box>
                            <ToolTip
                                iconName="icon-system-info"
                                description={<WhiteSpace>{siteTagTooltip}</WhiteSpace>}
                            />
                        </Box>
                    </Col>
                    <Col noPadding md={5} justify="center">
                        <Box display="flex" flexDirection="column">
                            <TagList tags={selectedTags} onTagClick={handleTagDeselect} />
                            {hasAddTag && <AddTag onClick={handleAddTagClick} label={addTag} />}
                        </Box>
                    </Col>
                </Row>
            </Box> */}
            <Box>
                <Row>
                    <Col noPadding md={3} sm={3} justify="center">
                        <Body18 regular>{siteSiteLocationSearchHeading}</Body18>
                    </Col>
                    <Col noPadding md={5} sm={5} justify="center">
                        <div onBlur={handleLocationTouch}>
                            <Search
                                name="siteSearch"
                                error={locationError ?? renderError(errors, touched)}
                                searchValue={searchValue}
                                options={mapResultsToOption(searchResults?.filter(Boolean) ?? [])}
                                onSearchChange={handleSearchChange}
                                onSearchOptionClick={handleSearchOptionClick}
                                onSearchClear={handleSearchClear}
                            />
                        </div>
                    </Col>
                </Row>
            </Box>
            <Box marginTop="xxxs">
                <Row>
                    <Col noPadding md={5} sm={3} offset={{ sm: 3, md: 3 }} justify="center">
                        <LocationMap chosenLocation={chosenLocation} searchValue={searchValue} mapCredentials={mapCredentials} />
                    </Col>
                </Row>
            </Box>
        </Container>
    );
};

export default SiteForm;

const mapResultsToOption = (results: Array<LocationSearchResult>) => {
    return results?.map((result) => {
        return {
            displayText: getDisplayText(result),
            itemId: result.id,
        } as ISearchItem;
    });
};

const Container = styled.div`
    margin-left: 0.75rem;
    margin-right: 0.75rem;
`;
