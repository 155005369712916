import React, { FC } from "react";
import { CameraConfigView as CameraConfigViewBase } from "telstra-components";
import { CameraObjectItem } from "telstra-components/build/components/CameraConfig/CameraConfigView";

//we need this component for tests

interface CameraConfigViewProps {
    leafDeviceId: string;
    edgeDeviceId: string;
    // TODO: remove this once we confirm we don't need anymore.
    // imageSrc: string;
    cameraObjects: Array<CameraObjectItem>;
    onCameraObjectChange: (cameraObject: CameraObjectItem) => void;
    stage: "loading" | "normal" | "error";
    onErrorRetryClick: () => void;
    onErrorCancelClick: () => void;
    onEmptySpaceClick?: () => void;
    onCameraObjectClick?: (id: string) => void;
}

const CameraConfigView: FC<CameraConfigViewProps> = ({
    leafDeviceId,
    edgeDeviceId,
    // TODO: remove this once we confirm we don't need anymore.
    // imageSrc,
    cameraObjects,
    onCameraObjectChange,
    stage,
    onErrorRetryClick,
    onErrorCancelClick,
    onEmptySpaceClick,
    onCameraObjectClick,
}: CameraConfigViewProps) => {
    return (
        <CameraConfigViewBase
            leafDeviceId={leafDeviceId}
            edgeDeviceId={edgeDeviceId}
            // TODO: remove this once we confirm we don't need anymore.
            // imageSrc={imageSrc}
            cameraObjects={cameraObjects}
            onCameraObjectChange={onCameraObjectChange}
            stage={stage}
            onErrorCancelClick={onErrorCancelClick}
            onErrorRetryClick={onErrorRetryClick}
            onEmptySpaceClick={onEmptySpaceClick}
            onCameraObjectClick={onCameraObjectClick}
        />
    );
};

export default CameraConfigView;
