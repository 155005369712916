import { TreeItem } from "telstra-components";

const addParents = (item: TreeItem, arr: Array<TreeItem>): Array<TreeItem> => {
    let perents = arr.filter((entry) => entry.id === item.parentId);

    if (perents.length > 0) {
        perents.forEach((entry) => {
            perents = [entry, ...perents, ...addParents(entry, arr)];
        });
    }

    return perents;
};

const setAddPermission = (type: string, permissions: { [key: string]: boolean }): boolean => {
    switch (type) {
        case "organisation":
            return !permissions.CreateSites ? true : false;
        case "site":
            return !permissions.CreateGateways ? true : false;
        case "gateway":
            return !permissions.CreateDevices ? true : false;
        case "camera":
            return !permissions.CreateDevices ? true : false;
        default:
            return false;
    }
};

const setManagePermission = (type: string, permissions: { [key: string]: boolean }): boolean => {
    switch (type) {
        case "organisation":
            return !permissions.CreateOrgs ? true : false;
        case "site":
            return !permissions.CreateSites ? true : false;
        case "gateway":
            return !permissions.CreateGateways ? true : false;
        case "camera":
            return !permissions.CreateDevices ? true : false;
        default:
            return false;
    }
};

export const filterData = (
    value: string,
    arr: Array<TreeItem>,
    permissions: { [key: string]: boolean },
): Array<TreeItem> => {
    if (arr === undefined || arr.length === 0) return [];

    if (value === undefined || value === "")
        return arr.map((entry) => {
            return {
                ...entry,
                hideAddAction: setAddPermission(entry.type, permissions),
                hideManageActions: setManagePermission(entry.type, permissions),
            };
        });

    const normalisedValue = value.toLowerCase();
    let data: Array<TreeItem> = [];

    arr.filter((entry) => {
        return (
            [entry]
                .map(({ text }) => text)
                .join(",")
                .toLowerCase()
                .indexOf(normalisedValue) > -1
        );
    }).forEach((entry) => {
        data = [entry, ...data, ...addParents(entry, arr)];
    });

    return data.map((entry) => {
        const selected = entry.text.toLowerCase().indexOf(normalisedValue) > -1;
        return {
            ...entry,
            selected,
            disabled: !selected,
            hideAddAction: setAddPermission(entry.type, permissions),
            hideManageActions: setManagePermission(entry.type, permissions),
        };
    });
};
