import React from "react";
import styled from "styled-components";

export type ContextMenuProps = {
    x: number;
    y: number;
    optionsText?: Array<string>;
    onClose: () => void;
    onMenuItemClick: (index: number) => void;
};

const ContextMenu: React.FC<ContextMenuProps> = ({ x, y, optionsText, onClose, onMenuItemClick }) => {
    const handleClick = (index: number) => {
        onMenuItemClick(index);
        onClose();
    };

    return (
        <MenuContainer
            id="menu"
            style={{
                top: `${y}px`,
                left: `${x}px`,
            }}
        >
            {optionsText &&
                optionsText.length > 0 &&
                optionsText.map((text, index) => (
                    <MenuButton key={index} onClick={() => handleClick(index)}>
                        <ButtonText>{text}</ButtonText>
                    </MenuButton>
                ))}
        </MenuContainer>
    );
};

export default ContextMenu;

const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    background: white;
    box-shadow: 0 0 5px grey;
    border-radius: 3px;
`;

const ButtonText = styled.div`
    display: flex;
    justify-content: flex-start;
    padding-left: 30px;
    color: #000;
    font-family: Telstra Akkurat;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px; /* 137.5% */
    letter-spacing: -0.5px;
`;

const MenuButton = styled.button`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    margin: 0
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: 230px;
    height: 50px;
    border-radius: 3px;

    :hover {
        background: #ecf7fe;
    }
`;
