import { useState, useCallback, useEffect } from "react";
import { DropdownOption, Tag } from "@sv/types";

interface ReturnType {
    selectedTags: Array<Tag>;
    tagModalVisible: boolean;
    handleTagDeselect: (tag: Tag) => void;
    handleTagModalToggle: (toggle: boolean) => () => void;
    handleTagSelect: (tagToSelect: Tag) => void;
    resetSelectedTags: () => void;
}

const useSiteTags = (loadedTags?: Array<Tag>): ReturnType => {
    const [selectedTags, setSelectedTags] = useState<Array<Tag>>([]);
    const [tagModalVisible, setTagModalVisible] = useState<boolean>(false);

    const handleTagDeselect = (tagToDeselect: Tag) => {
        setSelectedTags((currentTags) =>
            currentTags.filter((tag) => tag.id !== tagToDeselect.id),
        );
    };

    useEffect(() => {
        if (!loadedTags) return;
        setSelectedTags(loadedTags);
    }, [loadedTags]);

    const handleTagSelect = (tagToSelect: Tag) => {
        setSelectedTags((currentTags) => [...currentTags, tagToSelect]);
    };

    const handleTagModalToggle = (toggle: boolean) => () => {
        setTagModalVisible(toggle);
    };

    const resetSelectedTags = useCallback(() => setSelectedTags([]), []);

    return {
        selectedTags,
        tagModalVisible,
        handleTagDeselect,
        handleTagModalToggle,
        handleTagSelect,
        resetSelectedTags,
    };
};

export default useSiteTags;

export const getRemainingVariableOptions = (
    variableOptions: Array<DropdownOption>,
    selectedTags: Array<Tag>,
): Array<DropdownOption> => {
    const selectedTagVariables = [...new Set(selectedTags.map((t) => `${t.type} | ${t.category}`))];
    return variableOptions.filter((t) => !selectedTagVariables.includes(t.value));
};
