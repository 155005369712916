import React, { ReactElement } from "react";
import styled from "styled-components";

interface Props {
  fill?: string;
  title?: string;
}

const SVGIconDelete = ({ fill, title }: Props): ReactElement => {
  return (
    <SVGWrapper
      id="deleteIcon"
      height="100%"
      fillColor={fill || ""}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.5 19.77"
    >
      <title>{title}</title>
      <path d="M21.5,6.74H2.5a.75.75,0,0,1,0-1.5h19a.75.75,0,0,1,0,1.5Z" transform="translate(-1.75 -2.31)" />
      <path
        d="M16.71,5.24h-1.5V4A.18.18,0,0,0,15,3.81H9.19A.18.18,0,0,0,9,4V5.24H7.51V4A1.68,1.68,0,0,1,9.19,2.31H15A1.68,1.68,0,0,1,16.71,4Z"
        transform="translate(-1.75 -2.31)"
      />
      <path
        d="M17.13,22.08H6.74a1.68,1.68,0,0,1-1.69-1.55L3.84,6.7l1.5-.13L6.54,20.4a.2.2,0,0,0,.2.18H17.13a.18.18,0,0,0,.18-.16L18.56,6.56l1.5.14L18.8,20.54A1.68,1.68,0,0,1,17.13,22.08Z"
        transform="translate(-1.75 -2.31)"
      />
      <path
        d="M9.74,18.24A.76.76,0,0,1,9,17.49V9.6a.75.75,0,0,1,1.5,0v7.89A.76.76,0,0,1,9.74,18.24Z"
        transform="translate(-1.75 -2.31)"
      />
      <path
        d="M14.26,18.24a.75.75,0,0,1-.75-.75V9.6a.75.75,0,0,1,1.5,0v7.89A.76.76,0,0,1,14.26,18.24Z"
        transform="translate(-1.75 -2.31)"
      />
    </SVGWrapper>
  );
};

export default SVGIconDelete;

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
