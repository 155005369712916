import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
  fill?: string;
}

export default class LeftArrow extends React.Component<Props> {
  public render(): ReactNode {
    return (
      <SVGWrapper width="24" height="24" viewBox="0 0 24 24" fillColor={this.props.fill || ""}>
        <title>LeftArrow</title>
        <g>
          <g>
            <g>
              <path
                d="M10.99 5.94L17 11.95l-.026.024L17 12l-6.01 6.01-1.061-1.06 4.198-4.2H5.99v-1.5h8.188L9.93 7l1.06-1.06z"
                transform="translate(-116.000000, -252.000000) translate(116.000000, 236.000000) translate(0.000000, 16.000000) translate(11.494670, 11.975000) scale(-1, 1) translate(-11.494670, -11.975000)"
              />
            </g>
          </g>
        </g>
      </SVGWrapper>
    );
  }
}

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
