import React, { FC, useState } from "react";
import styled from "styled-components";

import { Body16, Box, Heading24, Modal as InternalModal } from "telstra-components";
import { Button } from "telstra-ui/components/button/Button";
import { greyL1 } from "../../../themes/Colors";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Spinner } from "telstra-ui/components/spinner/spinner";
import { ToolTip } from "telstra-ui/components/tool-tip/Tooltip";

import { DropdownMultiSelect } from "./DropdownMultiSelect";

import Modal from "telstra-ui/components/modal/Modal";

const CONFIGURATION = {
    "TRIP_LINES": {
        title: "Aggregate data from other trip-lines",
        description: "To find out how many objects have entered and/or exited an area, you can aggregate data from trip-lines which surround that area.",
        actionPrompt: "Select the trip-lines to aggregate data",
        actionHint: {
            title: "Aggregate data from other trip-lines",
            description: "To find out how many objects have entered and/or exited an area, you can aggregate data from trip-lines which surround that area.",
            example_lines: [
                "E.g.",
                "Metric = Traffic in",
                "Object = People",
                "Trip-line 1 + Trip-line 2 = total number of people entering the shop",
            ],
            visual: "/images/info-modal-aggregate-data-triplines.svg",
        },
        confirmButton: "Aggregate trip-lines",
    },
    "POLYGONS": {
        title: "Aggregate data from other polygons",
        description: "To find out how many objects have entered multiple areas and the average length of time they've spent in those areas, you can aggregate object data from polygons.",
        actionPrompt: "Select polygons to aggregate data",
        actionHint: {
            title: "Aggregate data from other polygons",
            description: "To find out how many objects have entered multiple areas and the average length of time they’ve spent in those areas, you can aggregate object data from polygons.",
            example_lines: [
                "E.g.",
                "Metric = Occupancy",
                "Object = People",
                "Polygon 1 + Polygon 2 = total number of people occupying polygons",
            ],
            visual: null,
        },
        confirmButton: "Aggregate polygons",
    },
    "TRIP_LINE-OBJECTS": {
        title: "Aggregate object types",
        description: "To find out the total number of objects which have crossed trip-line(s)",
        actionPrompt: "Select the objects to aggregate",
        actionHint: null,
        confirmButton: "Aggregate objects",
    },
    "POLYGON-OBJECTS": {
        title: "Aggregate object types",
        description: "To find out the total number of each object type in an area.",
        actionPrompt: "Select the objects to aggregate",
        actionHint: null,
        confirmButton: "Aggregate objects",
    },
}

interface AggregateDataModalProps {
    isOpen: boolean;
    type: "TRIP_LINES" | "POLYGONS" | "TRIP_LINE-OBJECTS" | "POLYGON-OBJECTS";
    options: Array<{
        displayText: string;
        itemId: string;
        active: boolean;
    }>;
    onCancel?: () => void;
    onSubmit?: (selectedOptions: Array<string>) => void;
}

export enum SiteAnalyticsTagsFormFlowPage {
    CONFIGURE_SITE_REGION_TAGS = 0,
    CONFIGURE_SITE_TYPE_TAGS,
}

export const AggregateDataModal: FC<AggregateDataModalProps> = ({
    isOpen,
    type,
    options,
    onCancel,
    onSubmit,
}: AggregateDataModalProps) => {
    const handleConfirmationYesClick = () => {
        if (onCancel) {
            onCancel();
        }
    };

    const handleSaveAndNextClick = () => {
        if (onSubmit) {
            onSubmit(selectedItemIds);
        }
    };

    // TODO: consider for additional load time.
    const saveNextLoading = false;

    const [showInfoModal, setShowInfoModal] = useState(false);

    const config = CONFIGURATION[type];

    const [selectedItemIds, setSelectedItemIds] = useState<Array<string>>(options.filter(e => e.active).map(e => e.itemId));

    return (
        <>
            <InternalModal isOpen={isOpen} showCloseButton={false} size="medium">
                <Container infoModalHeight={config.actionHint?.visual ? 600 : 320}>
                    <CloseButtonContainer>
                        <Button
                            iconPosition="left"
                            icon={"icon-system-close"}
                            variant="primary"
                            size="medium"
                            onClick={() => {
                                handleConfirmationYesClick();
                            }}
                        />
                    </CloseButtonContainer>
                    { config.actionHint &&
                        <Modal
                            isOpen={showInfoModal}
                            size="l"
                            onShowModal={(_: any, shouldShow: boolean) => setShowInfoModal(shouldShow)}
                        >
                            <Modal.Header>
                                {config.actionHint.title}
                            </Modal.Header>
                            <Modal.Body>
                                {config.actionHint.description}

                                <InfoModalExampleContainer>
                                    {config.actionHint.example_lines.map(e => 
                                        <InfoModalExample key={e}>{e}</InfoModalExample>
                                    )}
                                </InfoModalExampleContainer>

                                { config.actionHint.visual &&
                                    <InfoModalVisualContainer>
                                        <InfoModalVisual src={config.actionHint.visual} alt=""></InfoModalVisual>
                                    </InfoModalVisualContainer>
                                }
                            </Modal.Body>
                        </Modal>
                    }
                    <Box marginTop="xl" marginLeft="l" marginRight="l" marginBottom="s">
                        <Box marginLeft="xxxs" marginRight="xxxs">
                            <Heading24>{config.title}</Heading24>
                            <Body16>
                                {config.description}
                            </Body16>
                            <div style={{marginTop: 20, marginBottom: 20, display: "flex", flexDirection: "row"}}>
                                <Body16 style={{fontWeight: "bold"}}>
                                    {config.actionPrompt}
                                </Body16>
                                <Box style={{marginLeft: 12, marginTop: -4}}>
                                    { config.actionHint &&
                                        <ToolTip
                                            iconName="icon-system-info"
                                            onTooltipToggle={() => setShowInfoModal(true)}
                                        />
                                    }
                                </Box>
                            </div>

                            <DropdownMultiSelect
                                id="aggregate-selector"
                                options={options}
                                onUpdateOptions={(updatedOptions) => {
                                    setSelectedItemIds(updatedOptions.filter(e => e.active).map(e => e.itemId.toString()))
                                }}
                            />
                        </Box>
                        <Box style={{position: "absolute", bottom: 32, left: 48, right: 48}}>
                            <Box display="flex" marginTop="xs" justifyContent="space-between">
                                <Box display="inline-flex">
                                    <Box marginRight="xxs">
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    {!saveNextLoading ? (
                                        <Button
                                            variant="primary"
                                            size="medium"
                                            label={config.confirmButton}
                                            onClick={handleSaveAndNextClick}
                                            disabled={false}
                                        />
                                    ) : (
                                        <Box marginRight="m">
                                            <Spinner size="tl-spinner-medium" />
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </InternalModal>
        </>
    );
};

const Container = styled.div<{infoModalHeight: number}>`
    height: 607px;

    .tl-modalbox-container .tl-large {
        height: ${props => props.infoModalHeight}px;
    }
`;

const CloseButtonContainer = styled.div`
    position: absolute;
    top: 18px;
    right: 18px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        fill: ${greyL1};
        margin: 0px !important;
        left: 0px !important;
    }
    button {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    span {
        width: 24px;
        height: 24px;
    }
`;

const InfoModalExampleContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    font-style: italic;
`;

const InfoModalExample = styled.div`
`;

const InfoModalVisualContainer = styled.div`
    padding-top: 25px;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const InfoModalVisual = styled.img`
    width: 100%;
    height: 100%;
`;
