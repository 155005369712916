/* eslint-disable @typescript-eslint/no-explicit-any */
import BlueprintForm from "../../components/Forms/BlueprintForm";
import DeviceForm from "../../components/Forms/DeviceForm/DeviceForm";
import GatewayForm from "../../components/Forms/GatewayForm/GatewayForm";
import SiteForm from "../../components/Forms/SiteForm";
import SiteAnalyticsTagsForm from "../../components/Forms/SiteAnalyticsTagsForm";
import useDevice from "../../hooks/Device/useDevice";
import useSite from "../../hooks/Site/useSite";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SiteType, DropdownOption, Device, Tag } from "@sv/types";
import { Body16, Box, Col, FormikProvider, Heading20, Heading34, Modal, Row, Timeline, useFormik } from "telstra-components";
import { Button } from "telstra-ui/components/button/Button";
import strings from "../../strings/strings.json";
import { greyL1 } from "../../../themes/Colors";
import * as yup from "yup";
import { validationSchemas } from "../../helpers/formValidation";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Spinner } from "telstra-ui/components/spinner/spinner";
import { Stepper } from "telstra-ui/components/path-stepper/PathStepper";
import useSiteForm from "../../components/Forms/SiteForm/useSiteForm";
import { transformMappedTags } from "../../helpers/TagHelper";
import styled from "styled-components";
import useGatewayForm from "../../components/Forms/GatewayForm/useGatewayForm";
import { MessageInline } from "@able/react";

const { siteNameDuplicated } = strings.components.Forms.Validation;
const { siteAddSiteTitle, siteIntro } = strings.components.Wizard.WizardModal.Site;
const { siteAnalyticsTagsAddTagTitle, siteAnalyticsTagsAddTagDescription } = strings.components.Wizard.WizardModal.SiteAnalyticsTags;
const { gtwAddGatewayTitle, gtwAddGatewayDescription } = strings.components.Wizard.WizardModal.Gateway;
const { confirmationModalTitle, confirmationModalDescription, yesButtonLabel, noButtonLabel } =
    strings.components.Wizard.WizardModal.ConfirmationModal;
const { errorModalTitle, errorModalDescription, okButtonLabel } = strings.components.Wizard.WizardModal.ErrorModal;
const { btnSaveAndNextLabel } = strings.components.Wizard.WizardModal.Buttons;

const insertInTemplate = (template: string, value: string) => {
    return template.includes("{}") ? template.replace("{}", value) : template;
};

const deviceTypeList = [{ id: "device-type-camera", label: "Camera", value: "camera" } as DropdownOption];

const wizardData = [
    {
        title: siteAddSiteTitle,
        description: siteIntro,
    },
    {
        title: siteAnalyticsTagsAddTagTitle,
        description: siteAnalyticsTagsAddTagDescription,
    },
    {
        title: gtwAddGatewayTitle,
        description: gtwAddGatewayDescription,
    },
    {
        title: "Next step",
        description: "",
    },
];

const defaultValues = {
    site: {
        name: "",
        location: {
            id: "",
            address: "",
            geoLocation: {
                latitude: 0,
                longitude: 0,
            },
        },
    },

    gatewaySelectedSiteId: "",
    gatewayName: "",
    isGatewayCreateButtonClicked: false,
    gatewayConnectionString: undefined,

    deviceId: "",
    deviceName: "",
    deviceType: "",
    deviceUsername: "",
    devicePassword: "",
    deviceUrl: "",

    blueprintType: "",
    deviceBlueprint: "",
};

interface WizardModalProps {
    isOpen: boolean;
    onCancel?: () => void;
    onSubmit?: () => void;
    siteId?: string;
    gatewayId?: string;
    deviceId?: string;
    initialStep: number;
    selectedTags: Array<Tag>;
    onTagSelect: (tag: Tag) => void;
    onTagDeselect: (tagToSelect: Tag) => void;
    onSiteModalOpen: () => void;
}

export const mapSitesToDropdownOptions = (sites: Array<SiteType>): Array<DropdownOption> =>
    sites?.map((s) => ({ id: s.siteId, label: s.name, value: s.siteId } as DropdownOption));

export const mapDevicesToDropdownOptions = (devices: Array<Device>): Array<DropdownOption> =>
    devices?.map((s) => ({ id: s.id, label: s.name, value: s.id } as DropdownOption));

enum CurrentStep {
    ADD_SITE = 0,
    ADD_SITE_ANALYTICS_TAGS,
    ADD_GATEWAY,
    // ADD_DEVICE,
    // ADD_FEATURE,
    NEXT_STEP,
}

export enum SiteAnalyticsTagsFormFlowPage {
    CONFIGURE_SITE_REGION_TAGS = 0,
    CONFIGURE_SITE_TYPE_TAGS,
}

const WizardModal: FC<WizardModalProps> = ({
    isOpen,
    onCancel,
    onSubmit,
    siteId,
    gatewayId,
    deviceId,
    initialStep,
    selectedTags,
    onTagSelect: handleTagSelect,
    onTagDeselect: handleTagDeselect,
    onSiteModalOpen: handleTagModalToggle,
}: WizardModalProps) => {
    const [currentStep, setCurrentStep] = useState<CurrentStep>(initialStep || CurrentStep.ADD_SITE);
    // const [currentStep, setCurrentStep] = useState<CurrentStep>(CurrentStep.NEXT_STEP);

    const [siteOptions, setSiteOptions] = useState<Array<DropdownOption>>([]);
    const [gatewayOptions, setGatewayOptions] = useState<Array<DropdownOption>>([]);
    const [deviceOptions, setDeviceOptions] = useState<Array<DropdownOption>>([]);

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const {
        site,
        sites,
        error: siteError,
        addSite,
        editSite,
        getSites,
        loading: siteLoadingStates,
        resetState: resetSiteState,
    } = useSite();
    const modalSite = site;

    const { error: deviceError, device, devices, addDevice, getDevices, loading: deviceLoading } = useDevice();

    const gatewayFormNavigationState = useGatewayForm({});

    const [siteAnalyticsTagsFormNavigationState, setSiteAnalyticsTagsFormNavigationState] = useState<SiteAnalyticsTagsFormFlowPage>(0);

    const formik = useFormik({
        initialValues: defaultValues,
        validationSchema: () => {
            switch (currentStep) {
                case CurrentStep.ADD_SITE:
                    return validationSchemas.site.concat(
                        yup.object().shape({
                            site: yup.object().shape({
                                name: yup
                                    .string()
                                    .test("uniqueSiteName", siteNameDuplicated, () => !(siteError?.status === 409)),
                            }),
                        }),
                    );
                case CurrentStep.ADD_SITE_ANALYTICS_TAGS:
                    return null;
                case CurrentStep.ADD_GATEWAY:
                    return validationSchemas.gateway;
                default:
                    return;
            }
        },
        onSubmit: async (
            {
                site,
                gatewayName,
                gatewaySelectedSiteId,
                deviceName,
                deviceSelectedGateways,
                deviceType,
                deviceUrl,
                deviceUsername,
                devicePassword,
            }: any,
            { setSubmitting }: any,
        ) => {
            const TEMPORARY_DEFAULT_MODULE_NAME = "svaEdge1"; // TODO: this is temporary for LGAQ conference, should be from user input.
            switch (currentStep) {
                case CurrentStep.ADD_SITE: {
                    if (!modalSite?.siteId) {
                        await addSite(site.name, selectedTags, site.location);   
                    } else {
                        await editSite(site.name, modalSite?.siteId, selectedTags, site.location);
                    }
                    onSubmit && onSubmit();
                    getSites();
                    break;
                }
                case CurrentStep.ADD_SITE_ANALYTICS_TAGS: {
                    break;
                }
                case CurrentStep.ADD_GATEWAY:
                    try {
                        await addDevice(gatewayName, gatewaySelectedSiteId, "gateway", gatewayName, TEMPORARY_DEFAULT_MODULE_NAME);
                    } catch (error) {
                        return;
                    }
                    onSubmit && onSubmit();
                    getDevices(gatewaySelectedSiteId, "", "gateway");
                    break;
                default:
                    break;
            }
            setSubmitting(false);
        },
    });

    const { searchValue, actions, searchLocationError, chosenLocation, searchResults } = useSiteForm({
        initialLocation: undefined,
        siteId,
        formik,
        isSiteStep: currentStep === CurrentStep.ADD_SITE,
    });

    const navigate = useNavigate();

    useEffect(() => {
        setSiteOptions(mapSitesToDropdownOptions(sites));
    }, [sites]);

    useEffect(() => {
        if (formik.values.site.name) formik.validateForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.site.name]);

    useEffect(() => {
        if (devices) {
            setGatewayOptions(mapDevicesToDropdownOptions(devices?.filter((item) => item.type === "gateway")));
            setDeviceOptions(mapDevicesToDropdownOptions(devices?.filter((item) => item.type === "camera")));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devices]);

    const handleSaveAndNextClick = async () => {
        try {
            if (currentStep === CurrentStep.ADD_SITE_ANALYTICS_TAGS) {
                if (siteAnalyticsTagsFormNavigationState === SiteAnalyticsTagsFormFlowPage.CONFIGURE_SITE_REGION_TAGS) {
                    setSiteAnalyticsTagsFormNavigationState(siteAnalyticsTagsFormNavigationState + 1);
                } else if (siteAnalyticsTagsFormNavigationState === SiteAnalyticsTagsFormFlowPage.CONFIGURE_SITE_TYPE_TAGS) {
                    await editSite(site!.name, site!.siteId, selectedTags, site!.location);
                    onSubmit && onSubmit();

                    setCurrentStep((current) => current + 1);
                }
            } else if ( currentStep === CurrentStep.ADD_GATEWAY) {
                if (gatewayFormNavigationState.gatewayFormFlowPage !== "DEVICE_COUNT") {
                    await gatewayFormNavigationState.actions.saveGateways(site!.siteId);
                    await getDevices(site!.siteId, "", "gateway");
                    onSubmit && onSubmit();
                }
                
                if (
                    (gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_1" && gatewayFormNavigationState.numberOfRequiredGatewayDevices === 1) ||
                    (gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_2" && gatewayFormNavigationState.numberOfRequiredGatewayDevices === 2) ||
                    (gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_3" && gatewayFormNavigationState.numberOfRequiredGatewayDevices === 3) ||
                    (gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_4")
                ) {
                    setCurrentStep((current) => current + 1);
                } else {
                    gatewayFormNavigationState.actions.setGatewayFormFlowPage(
                        gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_COUNT"
                        ? "DEVICE_1"
                        : gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_1"
                        ? "DEVICE_2"
                        : gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_2"
                        ? "DEVICE_3"
                        : "DEVICE_4"
                    );
                }
            } else {
                // if (currentStep !== CurrentStep.ADD_GATEWAY || !formik.values.gatewayConnectionString) {
                //     await formik.submitForm();
                // }
   
                await formik.submitForm();
                const err = await formik.validateForm();
                if (Object.keys(err).length === 0) {
                    setCurrentStep((current) => current + 1);
                }
                // setCurrentStep((current) => current + 1);
            }
        } catch (error: any) {
            if (error.response?.status === 409) {
                await formik.validateForm();
            } else {
                setShowErrorModal(true);
            }
        }
    };

    const handleConfirmationNoClick = () => setShowConfirmModal(false);

    const handleConfirmationYesClick = async () => {
        setCurrentStep(initialStep);
        formik.resetForm();
        setShowConfirmModal(false);
        onCancel && onCancel();
    };

    const handleErrorOkClick = () => {
        onCancel && onCancel();
        setShowErrorModal(false);
    };

    const handleSiteNameChange = () => {};

    const saveNextLoading = deviceLoading?.getDevices || siteLoadingStates.addSite || siteLoadingStates.editSite;

    return (
        <>
            <ConfirmationModal
                isOpen={showConfirmModal}
                title={insertInTemplate(confirmationModalTitle, wizardData[currentStep].title)}
                bodyParagraph1={confirmationModalDescription}
                noLabel={noButtonLabel}
                onNoClick={handleConfirmationNoClick}
                yesLabel={yesButtonLabel}
                onYesClick={handleConfirmationYesClick}
            />
            <ConfirmationModal
                isOpen={showErrorModal}
                title={errorModalTitle}
                bodyParagraph1={errorModalDescription}
                yesLabel={okButtonLabel}
                onYesClick={handleErrorOkClick}
            />
            { ( currentStep < CurrentStep.NEXT_STEP ) &&
                <Modal isOpen={isOpen && !showConfirmModal && !showErrorModal} showCloseButton={false} size="large">
                    <Container>
                        <CloseButtonContainer>
                            <Button
                                iconPosition="left"
                                icon={"icon-system-close"}
                                variant="primary"
                                size="medium"
                                onClick={() => {
                                    handleConfirmationYesClick();
                                }}
                            />
                        </CloseButtonContainer>
                        <Box marginTop="s" marginLeft="l" marginRight="l" marginBottom="s">
                            <Heading34>Add a site</Heading34>
                            <StepperContainer>
                                <Stepper
                                    activeStep={currentStep + 1}
                                    step={[
                                        { label: "Site details" },
                                        { label: "Site analytics tags" },
                                        { label: "Gateway devices" },
                                    ]}
                                    showTextOnCompletion={true}
                                />
                            </StepperContainer>
                            <Box marginLeft="xxxl" marginRight="xxxl" marginTop="m">
                                <Box marginLeft="xxxl" marginRight="xxxl">
                                    {/* <Heading20>{wizardData[currentStep].title}</Heading20> */}
                                    {/* <Body16>{wizardData[currentStep].description}</Body16> */}
                                    <Heading20>
                                        {
                                            currentStep !== CurrentStep.ADD_GATEWAY
                                                ? wizardData[currentStep].title
                                                : gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_COUNT"
                                                ? "Number of Gateway devices"
                                                : gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_1"
                                                ? "Link - Gateway device #1"
                                                : gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_2"
                                                ? "Link - Gateway device #2"
                                                : gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_3"
                                                ? "Link - Gateway device #3"
                                                : "Link - Gateway device #4"
                                        }
                                    </Heading20>
                                    <Body16>
                                        {
                                            currentStep !== CurrentStep.ADD_GATEWAY
                                                ? wizardData[currentStep].description
                                                : gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_COUNT"
                                                ? wizardData[currentStep].description
                                                : "Gateway devices connect cameras to the Telstra Spatial Insights platform. They can have up to 4 cameras attached to capture data. "
                                        }
                                    </Body16>
                                    <FormikProvider value={formik}>
                                        <Box marginTop="xxs">
                                            {
                                                {
                                                    0: (
                                                        <SiteForm
                                                            onTagModalOpen={handleTagModalToggle}
                                                            onNameChange={handleSiteNameChange}
                                                            selectedTags={selectedTags}
                                                            onTagDeselect={handleTagDeselect}
                                                            chosenLocation={chosenLocation}
                                                            searchValue={searchValue}
                                                            searchLocationError={searchLocationError}
                                                            searchResults={searchResults}
                                                            searchActions={actions}
                                                        />
                                                    ),
                                                    1: (
                                                        <SiteAnalyticsTagsForm
                                                            navigationState={siteAnalyticsTagsFormNavigationState}
                                                            selectedTags={selectedTags}
                                                            onTagSelect={handleTagSelect}
                                                            onTagDeselect={handleTagDeselect}
                                                        />
                                                    ),
                                                    2: (
                                                        <GatewayForm
                                                            gatewayFormNavigationState={gatewayFormNavigationState}
                                                        />
                                                    ),
                                                    3: (
                                                        <DeviceForm
                                                            gatewaysOptions={gatewayOptions}
                                                            initialGatewayValue={gatewayId || device?.id}
                                                            deviceTypeOptions={deviceTypeList}
                                                            initialDeviceTypeValue="camera"
                                                        />
                                                    ),
                                                    4: <BlueprintForm devicesOptions={deviceOptions} />,
                                                }[currentStep]
                                            }
                                        </Box>
                                    </FormikProvider>
                                </Box>
                            </Box>
                            <Box style={{position: "absolute", bottom: 32 + 10, left: 48, right: 48}}>
                                <Box display="flex" marginTop="xs" justifyContent="space-between">
                                    <Box display="inline-flex">
                                        <Box marginRight="xxs">
                                            { currentStep > 0 &&
                                                <BackButtonContainer>
                                                    <Button
                                                        iconPosition="left"
                                                        icon={"icon-system-arrow"}
                                                        variant="primary"
                                                        size="medium"
                                                        label={"Back"}
                                                        onClick={() => {
                                                            if (currentStep === CurrentStep.ADD_SITE_ANALYTICS_TAGS && siteAnalyticsTagsFormNavigationState > 0) {
                                                                setSiteAnalyticsTagsFormNavigationState(siteAnalyticsTagsFormNavigationState - 1);
                                                            } else if (currentStep === CurrentStep.ADD_GATEWAY && gatewayFormNavigationState.gatewayFormFlowPage !== "DEVICE_COUNT") {
                                                                gatewayFormNavigationState.actions.setGatewayFormFlowPage(
                                                                    gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_1"
                                                                    ? "DEVICE_COUNT"
                                                                    : gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_2"
                                                                    ? "DEVICE_1"
                                                                    : gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_3"
                                                                    ? "DEVICE_2"
                                                                    : "DEVICE_3"
                                                                );
                                                            } else {
                                                                setCurrentStep((current) => current - 1);
                                                            }
                                                        }}
                                                    />
                                                </BackButtonContainer>
                                            }
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        {!saveNextLoading ? (
                                            <Button
                                                variant="primary"
                                                size="medium"
                                                label={
                                                    (
                                                        currentStep === CurrentStep.ADD_GATEWAY && (gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_1" && gatewayFormNavigationState.numberOfRequiredGatewayDevices === 1) ||
                                                        currentStep === CurrentStep.ADD_GATEWAY && (gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_2" && gatewayFormNavigationState.numberOfRequiredGatewayDevices === 2) ||
                                                        currentStep === CurrentStep.ADD_GATEWAY && (gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_3" && gatewayFormNavigationState.numberOfRequiredGatewayDevices === 3) ||
                                                        currentStep === CurrentStep.ADD_GATEWAY && (gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_4")
                                                    )
                                                    ? "Add site"
                                                    : btnSaveAndNextLabel
                                                }
                                                onClick={handleSaveAndNextClick}
                                                disabled={
                                                    false &&
                                                    (
                                                        currentStep === CurrentStep.ADD_SITE_ANALYTICS_TAGS
                                                        && (
                                                            (
                                                                siteAnalyticsTagsFormNavigationState === SiteAnalyticsTagsFormFlowPage.CONFIGURE_SITE_REGION_TAGS
                                                                && selectedTags.filter(e => e.type === "site" && e.category === "region").length < 1
                                                            ) ||
                                                            (
                                                                siteAnalyticsTagsFormNavigationState === SiteAnalyticsTagsFormFlowPage.CONFIGURE_SITE_TYPE_TAGS
                                                                && selectedTags.filter(e => e.type === "site" && e.category === "type").length < 1
                                                            )
                                                        )
                                                    ) ||
                                                    (
                                                        currentStep === CurrentStep.ADD_GATEWAY &&
                                                        (
                                                            (
                                                                gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_COUNT"
                                                                && gatewayFormNavigationState.numberOfRequiredGatewayDevices === undefined
                                                            ) ||
                                                            (
                                                                gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_1" &&
                                                                (
                                                                    !gatewayFormNavigationState.gatewayConfiguration["DEVICE_1"]?.name ||
                                                                    !gatewayFormNavigationState.gatewayConfiguration["DEVICE_1"]?.serialNumber ||
                                                                    !gatewayFormNavigationState.gatewayConfiguration["DEVICE_1"]?.numberOfCameras
                                                                )
                                                            ) ||
                                                            (
                                                                gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_2" &&
                                                                (
                                                                    !gatewayFormNavigationState.gatewayConfiguration["DEVICE_2"]?.name ||
                                                                    !gatewayFormNavigationState.gatewayConfiguration["DEVICE_2"]?.serialNumber ||
                                                                    !gatewayFormNavigationState.gatewayConfiguration["DEVICE_2"]?.numberOfCameras
                                                                )
                                                            ) ||
                                                            (
                                                                gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_3" &&
                                                                (
                                                                    !gatewayFormNavigationState.gatewayConfiguration["DEVICE_3"]?.name ||
                                                                    !gatewayFormNavigationState.gatewayConfiguration["DEVICE_3"]?.serialNumber ||
                                                                    !gatewayFormNavigationState.gatewayConfiguration["DEVICE_3"]?.numberOfCameras
                                                                )
                                                            ) ||
                                                            (
                                                                gatewayFormNavigationState.gatewayFormFlowPage === "DEVICE_4" &&
                                                                (
                                                                    !gatewayFormNavigationState.gatewayConfiguration["DEVICE_4"]?.name ||
                                                                    !gatewayFormNavigationState.gatewayConfiguration["DEVICE_4"]?.serialNumber ||
                                                                    !gatewayFormNavigationState.gatewayConfiguration["DEVICE_4"]?.numberOfCameras
                                                                )
                                                            )
                                                        )
                                                    )
                                                }
                                            />
                                        ) : (
                                            <Box marginRight="m">
                                                <Spinner size="tl-spinner-medium" />
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Modal>
            }
            { ( currentStep === CurrentStep.NEXT_STEP ) &&
                <Modal isOpen={isOpen && !showConfirmModal && !showErrorModal} showCloseButton={false} size="medium">
                    <NextStepContainer>
                        <CloseButtonContainer>
                            <Button
                                iconPosition="left"
                                icon={"icon-system-close"}
                                variant="primary"
                                size="medium"
                                onClick={() => {
                                    handleConfirmationYesClick();
                                }}
                            />
                        </CloseButtonContainer>
                        <Box marginTop="s" marginLeft="l" marginRight="l" marginBottom="s">
                            <Heading34>Next step</Heading34>
                            <Box marginTop="m">
                                <Heading20>
                                    {
                                        "Configure camera(s)"
                                    }
                                </Heading20>
                                <Body16 style={{fontFamily: "Telstra Akkurat", fontWeight: 300, lineHeight: "25px"}}>
                                    To configure camera(s) you will need to <b style={{fontWeight: 700}}>log into the Camera Portal</b> and get the:
                                    <ul>
                                        <li>RTPS stream</li>
                                        <li>Camera username and password</li>
                                    </ul>
                                    When you have this information, continue configuring the camera by selecting it from the site structure in the Web App as shown below:
                                </Body16>
                                <NextStepScreenshot src={"/images/configure-camera-screenshot.png"}/>
                            </Box>
                            <Box style={{position: "absolute", bottom: 32, left: 48, right: 48}}>
                                <Box display="flex" marginTop="xs" justifyContent="flex-end">
                                    <Box display="flex" alignItems="center">
                                        {!saveNextLoading ? (
                                            <Button
                                                variant="primary"
                                                size="medium"
                                                icon={"none"}
                                                label={"Close"}
                                                onClick={handleConfirmationYesClick}
                                            />
                                        ) : (
                                            <Box marginRight="m">
                                                <Spinner size="tl-spinner-medium" />
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </NextStepContainer>
                </Modal>
            }
        </>
    );
};

const Container = styled.div`
    width: 1024px;
    height: 795px;
`;

const NextStepContainer = styled.div`
    width: 720px;
    height: 654px;
`;

const NextStepScreenshot = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: 16px;
`;

const StepperContainer = styled.div`
    .tl-path-container-block .tl-path-stepper {
        width: unset;
        height: calc(64px - 2px);
    }

    .tl-path-parent {
        width: 100%;
        height: 100%;
    }

    .tl-path-container-block {
        height: 64px;
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }
`;

const CloseButtonContainer = styled.div`
    position: absolute;
    top: 18px;
    right: 18px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        fill: ${greyL1};
        margin: 0px !important;
        left: 0px !important;
    }
    button {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    span {
        width: 24px;
        height: 24px;
    }
`;

const BackButtonContainer = styled.div`
    svg {
        transform: rotate(180deg);
    }
`;

export default WizardModal;
