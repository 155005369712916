import React, { ReactElement } from "react";
import styled from "styled-components";

interface Props {
  fill?: string;
  title?: string;
}

const SVGIconAlert = ({ fill, title }: Props): ReactElement => {
  return (
    <SVGWrapper
      id="alertIcon"
      height="100%"
      fillColor={fill || ""}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31.01 29.5"
    >
      <title>{title}</title>
      <clipPath id="alert-icon-clip-path" transform="translate(3.25 2.89)">
        <path
          fill="none"
          clipRule="evenodd"
          d="M13.38,13.74a1.13,1.13,0,0,1-2.25,0V9.24a1.13,1.13,0,1,1,2.25,0Zm-1.13,4.87a1.12,1.12,0,0,1-.79-1.92,1.11,1.11,0,0,1,1.22-.24,1.12,1.12,0,0,1-.43,2.16Zm10.31.19L14,3.21a1.88,1.88,0,0,0-3.42,0L1.94,18.79a2,2,0,0,0,1.72,2.82H20.85a2,2,0,0,0,1.71-2.81Z"
        />
      </clipPath>
      <g clipPath="url(#alert-icon-clip-path)">
        <rect width="31.01" height="29.5" />
      </g>
    </SVGWrapper>
  );
};

export default SVGIconAlert;

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
