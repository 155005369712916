import React, { FC, ReactElement, useState } from "react";
import { Tag } from "@sv/types";
import { Box } from "telstra-components";
import TagList from "../Tag/TagList";
import styled from "styled-components";

import Modal from "telstra-ui/components/modal/Modal";
import { ConfirmationDestructiveDialog, CriticalDialog, useToggle } from '@able/react';

import CustomTextFieldRow from "../Forms/CustomTextFieldRow";
import { TagManagerService, TagManager } from "../../../services/WebService/TagManagerService";

const MAX_TAG_COUNT: number = 20;
const MAX_TAG_LENGTH: number = 40;
const MAX_TAG_DISPLAY_LENGTH: number = MAX_TAG_LENGTH;

interface SiteAnalyticsTagsFormProps {
    tagFieldMatcherKeys: {
        internalId: string;
        category: TagManager.Web.TagCategory;
        type: TagManager.Web.TagType;
    };
    tagCategoryName: string;
    assetName: string;
    helperHint: string;
    description: ReactElement;
    example: string;
    visualSrc: string;
    selectedTags: Array<Tag>;
    onTagSelect: (tagToSelect: Tag) => void;
    onTagDeselect: (tag: Tag) => void;
}

declare global {
    interface String {
        toTitleCase(): string;
    }
}

String.prototype.toTitleCase = function () {
    return this.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

const TagsForm: FC<SiteAnalyticsTagsFormProps> = ({
    tagFieldMatcherKeys,
    tagCategoryName,
    assetName,
    helperHint,
    description,
    example,
    visualSrc,
    selectedTags,
    onTagSelect: handleTagSelect,
    onTagDeselect: handleTagDeselect,
}: SiteAnalyticsTagsFormProps) => {
    const tags = selectedTags
        .filter(e => e.type === tagFieldMatcherKeys.type && e.category === tagFieldMatcherKeys.category)
        .map(e => ({
            ...e,
            displayValue: e.name.length > MAX_TAG_DISPLAY_LENGTH - 3
                ? e.name.split("").slice(0, MAX_TAG_DISPLAY_LENGTH - 3).join("").trim() + "..."
                : e.name,
        }));

    const [maxTagsError, setMaxTagsError] = useState("");

    const [showInfoModal, setShowInfoModal] = useState(false);

    const [activeDeletionTag, setActiveDeletionTag] = useState(null as Tag | null);
    const [confirmDeleteTagModalIsOpen, toggleConfirmDeleteTagModal] = useToggle();

    const [maxTagCountModalIsOpen, toggleMaxTagCountModal] = useToggle();

    const buttonEvents = {
      onClick: () => {
        if (activeDeletionTag) {
            handleRemoveTag(activeDeletionTag);
        }
      },
    };

    const handleRemoveTag = (tag: Tag) => {
        handleTagDeselect(tag);
        if (tags.length < MAX_TAG_COUNT && maxTagsError) {
            setMaxTagsError("");
        }
    }

    const handleOnClickConfirmTagField = async (textInput: HTMLInputElement) => {
        const tagValue = textInput.value.toUpperCase();
        if (tagValue === "") {
            return;
        }
        if (tags.length >= MAX_TAG_COUNT) {
            toggleMaxTagCountModal();
            return;
        }
        if (tags.find(e => e.name.toUpperCase() === tagValue)) {
            setMaxTagsError("This tag already exists.");
            return;
        }
        setMaxTagsError("");
        const tag = await TagManagerService().createTag({
            name: tagValue,
            type: tagFieldMatcherKeys.type,
            category: tagFieldMatcherKeys.category,
        });
        if (tag) {
            handleTagSelect(tag);
        }
        setTimeout(() => {
            textInput.value = "";
            textInput.dispatchEvent(new Event("change", { bubbles: true }));
        }, 0);
    }

    const handleKeyDownForTagField = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleOnClickConfirmTagField(event.target as HTMLInputElement);
        }
    }

    return (
        <Container>
            <ConfirmationDestructiveDialog
                isShowing={confirmDeleteTagModalIsOpen}
                setHideDialog={toggleConfirmDeleteTagModal}
                title="Are you sure you want to delete this tag?"
                description="Once a tag is deleted, all trip lines and polygons will be unlinked from the tag, impacting data analysis and reporting."
                stackButtonOnVXS={false}
                confirmButtonLabel='Delete'
                confirmButtonEvents={buttonEvents}
                cancelButtonLabel='Cancel'
                cancelButtonEvents={{}}
                developmentUrl={`http://${window.location.host}/able-sprites.svg`}
            />
            <CriticalDialog
                isShowing={maxTagCountModalIsOpen}
                setHideDialog={toggleMaxTagCountModal}
                title={`20/20 ${tagCategoryName} used`}
                description="Tag limited exceeded, this tag could not be added."
                OKButtonEvents={{}}
                developmentUrl={`http://${window.location.host}/able-sprites.svg`}
            />
            <CriticalDialog
                isShowing={maxTagsError !== ""}
                setHideDialog={() => setMaxTagsError("")}
                title={`This tag already exists`}
                description="The tag you have entered already exists within your organisation. Please review the list of tags available."
                OKButtonEvents={{}}
                developmentUrl={`http://${window.location.host}/able-sprites.svg`}
            />
            <Modal
                isOpen={showInfoModal}
                onShowModal={(_: any, shouldShow: boolean) => setShowInfoModal(shouldShow)}
            >
                <Modal.Header>{tagCategoryName.toTitleCase()}</Modal.Header>
                <Modal.Body>
                    {description}
                    <InfoModalExample>
                        {example}
                    </InfoModalExample>

                    <InfoModalVisualContainer>
                        <InfoModalVisual src={visualSrc} alt=""></InfoModalVisual>
                    </InfoModalVisualContainer>
                </Modal.Body>
            </Modal>
            <Box marginTop="l" marginLeft="xxxl" marginRight="xxxl">
                <CustomTextFieldRow
                    placeholder="Enter tag name"
                    label={`${assetName}:`}
                    // fieldName="" // TODO: add once accessibility is revisited.
                    helperText={helperHint}
                    confirmButtonText="Create tag"
                    // errorText={maxTagsError} // TODO: implement this functionality for textfield once needed.
                    maxLength={MAX_TAG_LENGTH}
                    onKeyDown={handleKeyDownForTagField}
                    onClickConfirmButton={handleOnClickConfirmTagField}
                    onClickLabelTooltip={() => setShowInfoModal(true)}
                />
                <TagContainer tagLineWidth={400}>
                    <TagList tags={tags} tagLineWidth={400} onTagClick={(tag: Tag) => {
                        setActiveDeletionTag(tag);
                        toggleConfirmDeleteTagModal();
                    }} />
                </TagContainer>
            </Box>
        </Container>
    );
};

export default TagsForm;

const Container = styled.div`
    margin-left: 0.75rem;
    margin-right: 0.75rem;

    .tl-modal {
        height: 555px;
    }
`;

const InfoModalExample = styled.div`
    font-style: italic;
    margin-top: 25px;
`;

const InfoModalVisualContainer = styled.div`
    padding-top: 25px;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const InfoModalVisual = styled.img`
    width: 100%;
    height: 100%;
`;

const TagContainer = styled.div<{tagLineWidth?: number}>`
    margin-top: 24px;
    margin-left: 228px;

    .tl-ui {
        width: ${props => props.tagLineWidth ?? 602}px;
        display: flex;
        justify-content: space-between;
        margin-right: 0px;

        // TODO: re-enable once APIs / product feature is ready in FYE 2024 Q2.
        .tl-tag-remove {
            display: none;
        }
    }
`;
