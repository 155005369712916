import Konva from "konva";
import React, { FC, useRef, useState, useEffect } from "react";
import { Layer, Stage } from "react-konva";
import styled from "styled-components";
import Zone from "./Zone";
import { Coordinate } from "../config";
import IconButton from "telstra-ui/components/button/IconButton";
import { handleCursorDefault, handleCursorGrab } from "./utils";
import Tripwire from "./Tripwire";
import ContextMenu from "./ContextMenu";
import { KonvaEventObject } from "konva/lib/Node";
import { Tag } from "@sv/types";
import ImageStream from "./ImageStream";

export interface CameraObjectPoint {
    x: number;
    y: number;
}

export interface CameraObjectScale {
    x: number;
    y: number;
}

export interface CameraObjectBase {
    id: string;
    name: string;
    points: Array<CameraObjectPoint>;
    editMode?: boolean;
}

export type Tripwire = "tripwire";
export type Zone = "zone";
export type CameraObjectType = Tripwire | Zone;

export interface CameraObjectZone extends CameraObjectBase {
    type: Zone;
    objectClasses: Array<string>;
    dwellAreaTags: Array<Tag>;
}

export interface CameraObjectTripwire extends CameraObjectBase {
    type: Tripwire;
    direction: number;
    objectClasses: Array<string>;
    largeAreaTags: Array<Tag>;
    crossingTags: Array<Tag>;
}

export type CameraObjectItem = CameraObjectZone | CameraObjectTripwire;

export interface CameraConfigViewProps {
    leafDeviceId: string;
    edgeDeviceId: string;
    cameraObjects: Array<CameraObjectItem>;
    isLoaded: boolean;
    onCameraObjectChange: (cameraObject: CameraObjectItem, isStageFocused: boolean) => void;
    onCameraObjectClick?: (id: string) => void;
    onContextMenuClick: (cameraObject: CameraObjectItem, type: "tripline" | "polygon") => void;
}

const CameraConfigView: FC<CameraConfigViewProps> = ({
    leafDeviceId,
    edgeDeviceId,
    cameraObjects,
    isLoaded,
    onCameraObjectChange,
    onContextMenuClick,
}: CameraConfigViewProps) => {
    const getCameraObjectIds = () => {
        const cameraIds = cameraObjects?.map((camObject) => {
            return camObject.id;
        });
        return cameraIds ?? "";
    };

    const [stageWidth, setStageWidth] = useState(0);
    const [stageHeight, setStageHeight] = useState(0);
    const [stageX, setStageX] = useState(0);
    const [stageY, setStageY] = useState(0);
    const [stageScale, setStageScale] = useState(1);
    const rootRef = useRef<HTMLHeadingElement>(null);
    const stageRef = React.useRef<Konva.Stage>(null);
    const [zoomInLimit] = useState(3);
    const [zoomOutLimit] = useState(1);
    const [zoomInActive, setZoomInActive] = useState(false);
    const [zoomOutActive, setZoomOutActive] = useState(false);
    const [scaleAmount] = useState(0.1);
    const [focusedPointIndex, setFocusedPointIndex] = useState<number>(-1);
    const [selectedObjectIndex, setSelectedObjectIndex] = useState<number>(-1);
    const [selectedObject, setSelectedObject] = useState(
        getCameraObjectIds()[selectedObjectIndex ? selectedObjectIndex : 0],
    );
    const [contextMenuOffsetX] = useState(225);
    const [contextMenuOffsetY] = useState(235);
    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
    const [contextType, setContextType] = useState<"tripline" | "polygon">();
    const [contextMenuOptions, setContextMenuOptions] = useState<Array<string>>([""]);

    const checkSize = () => {
        if (rootRef && rootRef.current) {
            const width = rootRef.current.offsetWidth;
            const height = (width / 16) * 9;
            setStageWidth(width);
            setStageHeight(height);
        }
    };

    const handleClickOutsideStage = (event: MouseEvent) => {
        if (
            rootRef.current &&
            stageRef.current &&
            !rootRef.current.contains(event.target as Node) &&
            !stageRef.current
                .getStage()
                .container()
                .contains(event.target as Node)
        ) {
            setFocusedPointIndex(-1);
            setSelectedObjectIndex(-1);
            setSelectedObject(getCameraObjectIds()[-1]);
            setContextMenuVisible(false);
        }
    };

    useEffect(() => {
        if (cameraObjects.length === 0 || selectedObject === null) return;
        const selectedIndex = cameraObjects.findIndex((object) => object.id === selectedObject);

        if (selectedIndex !== -1 && selectedObjectIndex !== null) {
            onCameraObjectChange(cameraObjects[selectedIndex === -1 ? 0 : selectedIndex], true);
        } else if (selectedObjectIndex === null) {
            onCameraObjectChange(cameraObjects[0], false);
        }
    }, [selectedObject]);

    const handleStageClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
        const pointTypes = [Konva.Circle, Konva.Text, Konva.Rect, Konva.Line, Konva.RegularPolygon];
        const clickedOnPoint = pointTypes.some((pointType) => e.target instanceof pointType);

        if (cameraObjects.length === 0) return;

        // -- clear focus when clicking on stage but not on a point
        if (!clickedOnPoint) {
            onCameraObjectChange(cameraObjects[selectedObjectIndex === -1 ? 0 : selectedObjectIndex], false);
        }

        // Only hide the context menu if it's visible and you didn't right-click on a tripline/polygon
        if (!clickedOnPoint && contextMenuVisible) {
            setContextMenuVisible(false);
        }
    };

    useEffect(() => {
        checkSize();
        window.addEventListener("resize", checkSize);
        document.addEventListener("mousedown", handleClickOutsideStage);

        return function cleanup() {
            window.removeEventListener("resize", checkSize);
            document.removeEventListener("mousedown", handleClickOutsideStage);
        };
    }, []);

    const getNumberOfPointsInObject = (id: string) => {
        const cameraObject = cameraObjects?.find((obj) => {
            return obj.id === id;
        });
        return cameraObject?.points.length ?? 1;
    };

    const updateAriaLiveLabel = (selectedObject: string, selectedPointIndex: number) => {
        const label = `Selected camera object: ${selectedObject}, Selected Point: ${selectedPointIndex + 1}`;
        const liveRegion = document.getElementById("aria-live-region");
        if (liveRegion) {
            liveRegion.textContent = label;
        }
    };

    const isElementFocused = (elementId: string): boolean => {
        const focusedElement = document.activeElement;
        if (focusedElement instanceof HTMLElement && focusedElement.id === elementId) {
            return true;
        }
        return false;
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (cameraObjects.length === 0) return;

        if (event.key === "Tab" && isElementFocused("stage")) {
            event.preventDefault();
            const cameraObjectIds = getCameraObjectIds();
            const objectPointsLength = getNumberOfPointsInObject(selectedObject);

            if (event.shiftKey) {
                // cycle focus backwards on shift+tab
                if (focusedPointIndex > 0) {
                    setFocusedPointIndex(focusedPointIndex - 1);
                    updateAriaLiveLabel(cameraObjects[selectedObjectIndex]?.name || "", focusedPointIndex - 1);
                    onCameraObjectChange(cameraObjects[selectedObjectIndex === -1 ? 0 : selectedObjectIndex], true);
                } else {
                    const prevObjectIndex = (selectedObjectIndex - 1 + cameraObjectIds.length) % cameraObjectIds.length;
                    const prevObjectPointsLength = getNumberOfPointsInObject(cameraObjectIds[prevObjectIndex]);
                    setFocusedPointIndex(prevObjectPointsLength - 1);
                    setSelectedObject(cameraObjectIds[prevObjectIndex]);
                    setSelectedObjectIndex(prevObjectIndex);
                    updateAriaLiveLabel(cameraObjects[prevObjectIndex]?.name || "", prevObjectPointsLength - 1);

                    // remove focus from stage and pass to previous element
                    if (selectedObjectIndex === 0) {
                        setFocusedPointIndex(-1);
                        setSelectedObjectIndex(-1);
                        setSelectedObject(getCameraObjectIds()[-1]);

                        // Note this id needs to be kept updated to the previous element in the html structure before the canvas
                        const previousInputElement = document.getElementById("addPolygonButton");
                        if (previousInputElement) {
                            previousInputElement.focus();
                        }
                    }
                }
            } else {
                // cycle focus forwards on tab
                onCameraObjectChange(cameraObjects[selectedObjectIndex === -1 ? 0 : selectedObjectIndex], true);

                if (focusedPointIndex < objectPointsLength - 1) {
                    setFocusedPointIndex(focusedPointIndex + 1);
                    updateAriaLiveLabel(cameraObjects[selectedObjectIndex]?.name || "", focusedPointIndex + 1);
                } else {
                    // go to next cameraObject
                    const nextObjectIndex = (selectedObjectIndex + 1) % cameraObjectIds.length;
                    setFocusedPointIndex(0);
                    setSelectedObject(cameraObjectIds[nextObjectIndex]);
                    setSelectedObjectIndex(nextObjectIndex);
                    updateAriaLiveLabel(cameraObjects[nextObjectIndex]?.name || "", 0);

                    // remove focus from stage and pass to next element (zoom in button)
                    if (selectedObjectIndex + 1 === cameraObjectIds.length) {
                        setFocusedPointIndex(-1);
                        setSelectedObjectIndex(null);
                        // Note this id needs to be kept updated to the next element in the html structure after the canvas
                        const nextInputElement = document.getElementById("zoominButton");
                        if (nextInputElement) {
                            nextInputElement.focus();
                        }
                    }
                }
            }
        }
    };

    const onZoomIn = () => {
        setContextMenuVisible(false);
        const stage = stageRef.current;
        const newScale = stageScale + scaleAmount;

        if (newScale > zoomInLimit + scaleAmount || !stage) {
            return;
        }

        const pos = boundFunc({ x: stage.x(), y: stage.y() }, newScale);
        setStageScale(newScale);
        setStageX(pos.x);
        setStageY(pos.y);
        setZoomInActive(true);
        setZoomOutActive(false);
    };

    const onZoomOut = () => {
        setContextMenuVisible(false);
        const stage = stageRef.current;
        const newScale = stageScale - scaleAmount;

        if (newScale < zoomOutLimit || !stage) {
            return;
        }

        const pos = boundFunc({ x: stage.x(), y: stage.y() }, newScale);
        setStageScale(newScale);
        setStageX(pos.x);
        setStageY(pos.y);
        setZoomOutActive(true);
        setZoomInActive(false);
    };

    const boundFunc = (pos: Coordinate, scale: number) => {
        const x = Math.min(0, Math.max(pos.x, stageWidth * (1 - scale)));
        const y = Math.min(0, Math.max(pos.y, stageHeight * (1 - scale)));

        return {
            x,
            y,
        };
    };

    const stageDragBoundFunc = (pos: Coordinate) => {
        return boundFunc(pos, stageScale);
    };

    const findSelectedObjectIndex = (selectedObjectId: string, clickedIndex: number) => {
        if (!cameraObjects) return -1;
        const selectedObject = cameraObjects.find((object) => object.id === selectedObjectId);

        if (!selectedObject) return -1;

        const pointIndexInSelectedObject = selectedObject.points.findIndex((_point, index) => index === clickedIndex);

        if (pointIndexInSelectedObject === -1) return -1;

        return cameraObjects?.findIndex((object) => object.id === selectedObjectId);
    };

    const handlePointClick = (selectedObject: CameraObjectZone | CameraObjectTripwire, clickedIndex: number) => {
        if (cameraObjects.length === 0) return;
        const clickedZoneIndex = findSelectedObjectIndex(selectedObject.id, clickedIndex);

        updateAriaLiveLabel(selectedObject.name, clickedIndex);
        onCameraObjectChange(cameraObjects[clickedZoneIndex], true);

        if (clickedZoneIndex >= 0 && cameraObjects && clickedZoneIndex < cameraObjects.length) {
            const selectedObj = getCameraObjectIds()[clickedZoneIndex];
            setFocusedPointIndex(clickedIndex);
            setSelectedObject(selectedObj);
            setSelectedObjectIndex(clickedZoneIndex);
        }
    };

    const handleDragMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
        const pointTypes = [Konva.Circle, Konva.Text, Konva.Rect, Konva.Line, Konva.RegularPolygon];
        const clickedOnPoint = pointTypes.some((pointType) => e.target instanceof pointType);
        if (!clickedOnPoint) {
            setStageX(e.target.x());
            setStageY(e.target.y());
        }
    };

    const handleStageDragEnd = (e: Konva.KonvaEventObject<MouseEvent>) => {
        const pointTypes = [Konva.Circle, Konva.Text, Konva.Rect, Konva.Line, Konva.RegularPolygon];
        const clickedOnPoint = pointTypes.some((pointType) => e.target instanceof pointType);
        const container = e?.target?.getStage()?.container();

        if (!clickedOnPoint && container) {
            if (stageScale > 1) {
                container.style.cursor = "grab";
            } else if (stageScale === 1) {
                container.style.cursor = "default";
            } else {
                container.style.cursor = "grabbing";
            }
        }
    };

    const handleStageDragStart = (e: Konva.KonvaEventObject<MouseEvent>) => {
        const pointTypes = [Konva.Circle, Konva.Text, Konva.Rect, Konva.Line, Konva.RegularPolygon];
        const clickedOnPoint = pointTypes.some((pointType) => e.target instanceof pointType);
        const container = e?.target?.getStage()?.container();

        if (!clickedOnPoint && container) {
            if (stageScale > 1) {
                container.style.cursor = "grabbing";
            } else {
                container.style.cursor = "default";
            }
        }
    };

    const handleContextMenu = (e: KonvaEventObject<PointerEvent>, type: "tripline" | "polygon") => {
        if (stageRef.current === null) return;
        e.evt.preventDefault(); // Prevent the browser's context menu from appearing
        setContextMenuPosition({
            x:
                stageRef.current.getPointerPosition() !== null
                    ? (stageRef.current.getPointerPosition()?.x as number)
                    : 0,
            y:
                stageRef.current.getPointerPosition() !== null
                    ? (stageRef.current.getPointerPosition()?.y as number)
                    : 0,
        });

        if (type === "tripline") {
            setContextMenuOptions((prevOptionsText) => {
                const newArray = [...prevOptionsText];
                newArray[0] = "Edit tripline";
                return newArray;
            });
        } else if (type === "polygon") {
            setContextMenuOptions((prevOptionsText) => {
                const newArray = [...prevOptionsText];
                newArray[0] = "Edit polygon";
                return newArray;
            });
        }

        setContextMenuVisible(true);
        setContextType(type);
    };

    const handleMenuItemClick = (index: number) => {
        if (index === 0) {
            if (contextType === "tripline") {
                onContextMenuClick(cameraObjects[selectedObjectIndex], "tripline");
            }

            if (contextType === "polygon") {
                onContextMenuClick(cameraObjects[selectedObjectIndex], "polygon");
            }
        }
    };

    const handleCloseContextMenu = () => {
        setContextMenuVisible(false);
    };

    return (
        <Root
            ref={rootRef}
            tabIndex={0}
            onKeyDown={handleKeyDown}
            role="canvas"
            aria-label="video stream canvas"
            id="stage"
            onContextMenu={(e) => e.preventDefault()}
        >
            <AriaLiveRegion
                id="aria-live-region"
                role="status"
                aria-live="polite"
                style={{ position: "absolute", top: "-9999px", left: "-9999px" }}
                tabIndex={-1}
            />
            <StageContainer focused={isElementFocused("stage")}>
                <Stage
                    x={stageX}
                    y={stageY}
                    width={stageWidth}
                    height={stageHeight}
                    onClick={handleStageClick}
                    ref={stageRef}
                    scale={{ x: stageScale, y: stageScale }}
                    draggable={stageScale > 1}
                    dragBoundFunc={stageDragBoundFunc}
                    onMouseEnter={stageScale > 1 ? handleCursorGrab : handleCursorDefault}
                    onMouseUp={handleStageDragEnd}
                    onDragEnd={handleStageDragEnd}
                    onDragStart={handleStageDragStart}
                    onMouseDown={handleStageDragStart}
                    onDragMove={handleDragMove}
                >
                    <Layer>
                        <ImageStream
                            leafDeviceId={leafDeviceId}
                            edgeDeviceId={edgeDeviceId}
                            width={stageWidth}
                            height={stageHeight}
                        />
                    </Layer>

                    <Layer>
                        {cameraObjects &&
                            cameraObjects.length > 0 &&
                            cameraObjects.map((cameraObject, index) => {
                                if (cameraObject.type === "tripwire") {
                                    return (
                                        <Tripwire
                                            key={index}
                                            cameraObject={cameraObject}
                                            onLineMove={(cameraObject) => onCameraObjectChange(cameraObject, true)}
                                            onDirectionChange={(cameraObject) =>
                                                onCameraObjectChange(cameraObject, true)
                                            }
                                            stageScale={stageScale}
                                            maxPositionX={stageWidth}
                                            maxPositionY={stageHeight}
                                            onClick={(line) => {
                                                handlePointClick(line, 0);
                                            }}
                                            onPointClick={handlePointClick}
                                            focusedPointIndex={focusedPointIndex}
                                            selectedCameraObjectId={selectedObject}
                                            onContextMenu={(e) => handleContextMenu(e, "tripline")}
                                        />
                                    );
                                }
                                return (
                                    <Zone
                                        focusedPointIndex={focusedPointIndex}
                                        selectedCameraObjectId={selectedObject}
                                        key={index}
                                        cameraObject={cameraObject}
                                        maxPositionX={stageWidth}
                                        maxPositionY={stageHeight}
                                        onZoneMove={(cameraObject) => onCameraObjectChange(cameraObject, true)}
                                        onClick={(zone) => handlePointClick(zone, 0)}
                                        onPointClick={handlePointClick}
                                        stageScale={stageScale}
                                        onContextMenu={(e) => handleContextMenu(e, "polygon")}
                                    />
                                );
                            })}
                    </Layer>
                </Stage>
                {contextMenuVisible && (
                    <ContextMenu
                        x={contextMenuPosition.x + contextMenuOffsetX}
                        y={contextMenuPosition.y + contextMenuOffsetY}
                        optionsText={contextMenuOptions}
                        onClose={handleCloseContextMenu}
                        onMenuItemClick={handleMenuItemClick}
                    />
                )}
            </StageContainer>
            <ZoomContainer>
                {isLoaded && (
                    <>
                        <ZoomButtonContainer
                            disabled={stageScale >= zoomInLimit}
                            active={zoomInActive}
                            aria-label="Zoom in"
                            title="Zoom in"
                        >
                            <IconButton icon="icon-system-zoom-in" onClick={onZoomIn} alt="zoom in" id="zoominButton" />
                        </ZoomButtonContainer>
                        <ZoomButtonContainer
                            disabled={stageScale <= zoomOutLimit}
                            active={zoomOutActive}
                            aria-label="Zoom out"
                            title="Zoom out"
                        >
                            <IconButton icon="icon-system-zoom-out" onClick={onZoomOut} alt="zoom out" />
                        </ZoomButtonContainer>
                    </>
                )}
            </ZoomContainer>
        </Root>
    );
};

export default CameraConfigView;

const Root = styled.div`
    display: flex;
    flex-direction: column;
    outline: none;
`;

const AriaLiveRegion = styled.div``;

const StageContainer = styled.div<{ focused: boolean }>`
    outline: ${(props) => (props.focused ? "2px blue solid" : "none")};
`;

const ZoomButtonContainer = styled.div<{ disabled: boolean; active: boolean }>`
    margin-right: 12px;

    .tl-icon-button.tl-icon-button-Default:focus {
        border: 2px solid blue;
    }

    .tl-icon-button.tl-icon-button-Default .able-icon use {
        fill: ${(props) => (props.active ? "" : "#414141")};
    }

    .tl-icon-button.tl-icon-button-Default .able-icon use {
        fill: ${(props) => (props.disabled ? "#AAAAAA" : "")};
    }

    .tl-icon-button.tl-icon-button-Default {
        cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    }

    .tl-icon-button.tl-icon-button-Default:hover::before {
        background-color: ${(props) => (props.disabled ? "transparent" : "rgba(0, 100, 210, 0.06);")};
    }

    .tl-icon-button.tl-icon-button-Default:focus:not(:hover)::before {
        background-color: transparent;
        box-shadow: 0 0 0 0.1875rem transparent;
    }

    .tl-icon-button.tl-icon-button-Default::after {
        background-color: ${(props) => (props.disabled ? "transparent" : "rgba(0, 100, 210, 0.16)")};
    }
`;

const ZoomContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 48px;
`;
