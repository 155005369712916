import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
    fill?: string;
}

export default class SVGIconHome extends React.Component<Props> {
    /**
     * Sets colour depending on user input, if no colour given then set to a default of black.
     *
     * @private
     * @memberof SVGIconHome
     */
    private fillColor = (): string => {
        // If no colour provided then default to black.
        if (!this.props.fill) {
            return "#000000";
        }
        // If we have a valid colour pass down.
        return this.props.fill;
    };

    /**
     * Render
     */
    public render(): ReactNode {
        return (
            <SVGWrapper
                id="homeIcon"
                width="100%"
                height="100%"
                fillColor={this.fillColor()}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16.96 18.96"
            >
                <title>Home Icon</title>
                <path
                    d="M20.48,20.83h-7V15.16a1.58,1.58,0,0,0-3.16,0v5.67H3.52V9.34L12,1.86l8.48,7.48ZM15,19.33h4V10L12,3.86,5,10v9.31H8.84V15.16a3.08,3.08,0,0,1,6.16,0Z"
                    transform="translate(-3.52 -1.86)"
                />
            </SVGWrapper>
        );
    }
}

const SVGWrapper = styled.svg<{ fillColor: string }>`
    fill: ${(props): string => props.fillColor};
`;
