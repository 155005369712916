import useLocationSearch, { getLocationDetails, LocationSearchResult } from "../../../hooks/useLocationSearch";
import React, { useEffect, useState } from "react";
import { Location } from "@sv/types";
import { FeatureCollection, GeoJsonProperties, Point } from "geojson";
import { FormikProps } from "formik";

interface Props {
    initialLocation: Location | undefined;
    onMapSearch?: (latitude: number | undefined, longitude: number | undefined) => void;
    siteId?: string;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: FormikProps<any>;
    isSiteStep?: boolean;
}

interface ReturnType {
    searchValue: string;
    chosenLocation: FeatureCollection<Point, GeoJsonProperties> | undefined;
    searchLocationError: boolean;
    searchResults: Array<LocationSearchResult>;
    actions: {
        onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSearchOptionClick: (event: any) => void;
        resetState: () => void;
    };
}

const useSiteForm = ({ initialLocation, formik, siteId, isSiteStep = true }: Props): ReturnType => {
    const [searchValue, setSearchValue] = useState("");
    const {
        searchResults,
        chosenLocation,
        error: searchLocationError,
        setSelectedLocation,
        clearSelectedLocation,
    } = useLocationSearch({
        searchValue,
        initialLocation,
    });

    const onMapSearch = async (location: Location | undefined) => {
        const { id, address, geoLocation } = location ?? {};
        const { latitude, longitude } = geoLocation ?? {};
        if (!formik) return;
        await Promise.all([
            formik.setFieldValue("site.location.geoLocation.latitude", latitude ?? 0),
            formik.setFieldValue("site.location.geoLocation.longitude", longitude ?? 0),
            formik.setFieldValue("site.location.id", id ?? ""),
            formik.setFieldValue("site.location.address", address ?? ""),
        ]);

        if (!location) return;
        formik.validateField("site.location.geoLocation.latitude");
        formik.validateField("site.location.geoLocation.longitude");
        formik.validateField("site.location.id");
        formik.validateField("site.location.address");
    };

    useEffect(() => {
        if (!siteId) {
            setSearchValue("");
            clearSelectedLocation();
        }
    }, [siteId, clearSelectedLocation]);

    useEffect(() => {
        if (chosenLocation) {
            const { address = "" } = getLocationDetails(chosenLocation) ?? {};
            setSearchValue(address);
        }
    }, [chosenLocation]);

    useEffect(() => {
        if (!isSiteStep || !onMapSearch) return;
        if (!chosenLocation) {
            onMapSearch(undefined);
            return;
        }

        const location = getLocationDetails(chosenLocation);
        if (!location) return;

        onMapSearch(location);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSiteStep, chosenLocation]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const handleSearchOptionClick = (event: React.ChangeEvent) => {
        const val = event.target.getAttribute("data-item");
        if (!val) return;
        setSearchValue(val);
        setSelectedLocation(val);
        formik.setFieldTouched("site.location.geoLocation.latitude");
        formik.setFieldTouched("site.location.geoLocation.longitude");
        formik.setFieldTouched("site.location.id");
        formik.setFieldTouched("site.location.address");
    };

    const resetState = () => {
        setSearchValue("");
        clearSelectedLocation();
    };

    return {
        searchValue,
        chosenLocation,
        searchLocationError,
        searchResults,
        actions: {
            onSearchChange: handleSearchChange,
            onSearchOptionClick: handleSearchOptionClick,
            resetState,
        },
    };
};

export default useSiteForm;
