import Konva from 'konva';
import React, { FC, useEffect, useRef, useState } from "react";
import { Circle, Line, Rect, RegularPolygon, Text, Group } from "react-konva";
import { CameraObjectTripwire, CameraObjectZone } from "./CameraConfigView";
import {
    getCircleFill,
    handleArrowPress,
    handleCursorDefault,
    handleCursorGrab,
    handleCursorGrabbing,
    handleCursorPointer,
} from "./utils";
import { KonvaEventObject } from "konva/lib/Node";

interface TripwireProps {
    cameraObject: CameraObjectTripwire;
    maxPositionX: number;
    maxPositionY: number;
    selectedCameraObjectId: string;
    focusedPointIndex: number;
    stageScale: number;
    onPointClick: (cameraObject: CameraObjectTripwire, clickedIndex: number) => void;
    onLineMove: (cameraObject: CameraObjectTripwire | CameraObjectZone) => void;
    onDirectionChange?: (cameraObject: CameraObjectTripwire) => void;
    onClick: (cameraObject: CameraObjectTripwire | CameraObjectZone) => void;
    onContextMenu: (item: KonvaEventObject<PointerEvent>) => void;
}

const charWidth = 10;
const maxTextWidth = 110;

const Tripwire: FC<TripwireProps> = ({
    cameraObject,
    maxPositionX,
    maxPositionY,
    selectedCameraObjectId,
    focusedPointIndex,
    stageScale,
    onPointClick,
    onLineMove,
    onDirectionChange,
    onClick,
    onContextMenu,
}: TripwireProps) => {
    const triplineNormal = "#046904";
    const triplineHighlight = "#78F878";
    const triplineCircleHighlight = "#1BB91B";
    const triplineCircleNormal = "#008A00";
    const defaultStrokeWidth = 2;
    const highlightStrokeWidth = defaultStrokeWidth + 2;
    const textWidth = Math.min(maxTextWidth, charWidth * cameraObject.name.length);
    const titleBoxWidth = textWidth > 60 ? textWidth : 60;
    const groupRef = useRef<Konva.Group | null>(null);

    const xRate = 1 / maxPositionX;
    const yRate = 1 / maxPositionY;

    const x1 = cameraObject.points[0].x / xRate;
    const y1 = cameraObject.points[0].y / yRate;

    const x2 = cameraObject.points[1].x / xRate;
    const y2 = cameraObject.points[1].y / yRate;

    let theta = 0;

    if (x1 - x2 !== 0 && !isNaN(y1 - y2)) {
        theta = Math.atan((y1 - y2) / (x1 - x2));
    }

    const xMiddle = (x2 - x1) / 2;
    const yMiddle = (y2 - y1) / 2;

    const xCenter = x1 + xMiddle + Math.cos(theta);
    const yCenter = y1 + yMiddle + Math.sin(theta);

    const [triplineStrokeWidth, setTriplineStrokeWidth] = useState(defaultStrokeWidth);
    const [hoveredCircleIndex, setHoveredCircleIndex] = useState<null | number>();
    const [triplineColour, setTriplineColour] = useState(triplineNormal);
    const [triplineTagColour, setTriplineTagColour] = useState(triplineNormal);
    const [triplineTriangleColour, setTriplineTriangleColour] = useState(triplineNormal);

    const onMouseEnter = (e: Konva.KonvaEventObject<MouseEvent>) => {
        const container = e?.target?.getStage()?.container();
        if (container) {
            container.style.cursor = "pointer";
        }
    };

    const onMouseLeave = (e: Konva.KonvaEventObject<MouseEvent>) => {
        setHoveredCircleIndex(null);
        const container = e?.target?.getStage()?.container();
        if (container) {
            container.style.cursor = "default";
        }
    };

    const onTitleDragMove = (e: Konva.KonvaEventObject<DragEvent>) => {
        const newPosition = e.target.position();

        e.target.position({ x: xCenter, y: yCenter });

        CalculateNewPosition(newPosition.x - xMiddle, newPosition.y - yMiddle);
    };

    const onLineDragMove = (e: Konva.KonvaEventObject<DragEvent>) => {
        const newPosition = e.target.position();

        e.target.position({ x: x1, y: y1 });

        CalculateNewPosition(newPosition.x, newPosition.y);
    };

    const CalculateNewPosition = (newX: number, newY: number) => {
        const deltaX = x2 - x1;
        const deltaY = y2 - y1;

        let newX1 = Math.min(maxPositionX, Math.max(0, newX));
        let newY1 = Math.min(maxPositionY, Math.max(0, newY));
        let newX2 = newX1 + deltaX;
        let newY2 = newY1 + deltaY;

        if (newX2 > maxPositionX || newX2 < 0) {
            newX2 = Math.min(maxPositionX, Math.max(0, newX2));
            newX1 = newX2 - deltaX;
        }

        if (newY2 > maxPositionY || newY2 < 0) {
            newY2 = Math.min(maxPositionY, Math.max(0, newY2));
            newY1 = newY2 - deltaY;
        }

        const newPoints = [...cameraObject.points];
        newPoints[0].x = newX1 * xRate;
        newPoints[0].y = newY1 * yRate;

        newPoints[1].x = newX2 * xRate;
        newPoints[1].y = newY2 * yRate;

        onLineMove && onLineMove({ ...cameraObject, ...{ points: newPoints } });
    };

    const onLeftDotDragMove = (e: Konva.KonvaEventObject<DragEvent>) => {
        const newPosition = e.target.position();

        e.target.position({ x: x1, y: y1 });

        const newX1 = Math.min(maxPositionX, Math.max(0, newPosition.x));
        const newY1 = Math.min(maxPositionY, Math.max(0, newPosition.y));

        const newPoints = [...cameraObject.points];
        newPoints[0].x = newX1 * xRate;
        newPoints[0].y = newY1 * yRate;

        onPointClick(cameraObject, 0);

        onLineMove && onLineMove({ ...cameraObject, ...{ points: newPoints } });
    };

    const onRightDotDragMove = (e: Konva.KonvaEventObject<DragEvent>) => {
        const newPosition = e.target.position();

        e.target.position({ x: x2, y: y2 });

        const newX2 = Math.min(maxPositionX, Math.max(0, newPosition.x));
        const newY2 = Math.min(maxPositionY, Math.max(0, newPosition.y));

        const newPoints = [...cameraObject.points];
        newPoints[1].x = newX2 * xRate;
        newPoints[1].y = newY2 * yRate;

        onPointClick(cameraObject, 1);

        onLineMove && onLineMove({ ...cameraObject, ...{ points: newPoints } });
    };

    const rotation = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI;

    const handleClick = () => {
        handleRenderObjectOnTop();
        onClick && onClick(cameraObject);
    };

    const handleDirectionToggle = (e: Konva.KonvaEventObject<MouseEvent>) => {
        if (cameraObject.editMode) {
            if (e?.target?.name() === "direction-triangle" || e?.target?.name() === "direction-text") {
                cameraObject.direction = cameraObject.direction === 0 ? 1 : 0;
                onDirectionChange && onDirectionChange(cameraObject);
            }
        } else {
            handleClick();
        }
    };

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            handleArrowPress(
                e,
                cameraObject,
                focusedPointIndex,
                maxPositionX,
                maxPositionY,
                xRate,
                yRate,
                selectedCameraObjectId,
                onLineMove,
            );
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [cameraObject, focusedPointIndex, maxPositionX, maxPositionY, selectedCameraObjectId, onLineMove]);

    const handleRenderObjectOnTop = () => {
        if (groupRef.current) {
            groupRef.current?.moveToTop();
        }
    };

    const onMouseOverTripline = (event: KonvaEventObject<MouseEvent>) => {
        if (cameraObject.editMode) {
            setTriplineColour(triplineNormal);
            setTriplineStrokeWidth(defaultStrokeWidth);
            setTriplineTriangleColour(triplineNormal);
        } else {
            setTriplineColour(triplineHighlight);
            setTriplineTagColour(triplineCircleHighlight);
            setTriplineStrokeWidth(highlightStrokeWidth);
            setTriplineTriangleColour(triplineCircleHighlight);
        }

        handleCursorPointer(event);
    };

    const onMouseOverTriplineTriangle = (event: KonvaEventObject<MouseEvent>) => {
        if (cameraObject.editMode) {
            setTriplineTriangleColour(triplineCircleHighlight);
        } else {
            setTriplineColour(triplineHighlight);
            setTriplineTagColour(triplineCircleHighlight);
            setTriplineStrokeWidth(highlightStrokeWidth);
            setTriplineTriangleColour(triplineCircleHighlight);
        }

        handleCursorPointer(event);
    };

    const onMouseLeaveTag = (event: KonvaEventObject<MouseEvent>) => {
        stageScale > 1 ? handleCursorGrab(event) : handleCursorDefault(event);
        setTriplineColour(triplineNormal);
        setTriplineStrokeWidth(defaultStrokeWidth);

        if (cameraObject.editMode) {
            setTriplineTriangleColour(triplineNormal);
            setTriplineTagColour(triplineCircleHighlight);
        } else {
            setTriplineTagColour(triplineNormal);
            setTriplineTriangleColour(triplineNormal);
        }
    };

    const onMouseLeaveTriangle = (event: KonvaEventObject<MouseEvent>) => {
        stageScale > 1 ? handleCursorGrab(event) : handleCursorDefault(event);

        if (cameraObject.editMode) {
            setTriplineTriangleColour(triplineNormal);
            setTriplineTagColour(triplineCircleHighlight);
        } else {
            setTriplineColour(triplineNormal);
            setTriplineTagColour(triplineNormal);
            setTriplineStrokeWidth(defaultStrokeWidth);
        }

        setTriplineTriangleColour(triplineNormal);
    };

    useEffect(() => {
        if (!cameraObject.editMode) {
            setTriplineTagColour(triplineNormal);
        }
        setTriplineTriangleColour(triplineNormal);
    }, [cameraObject.editMode]);

    return (
        <Group ref={groupRef}>
            <Line
                x={x1}
                y={y1}
                points={[0, 0, x2 - x1, y2 - y1]}
                draggable={cameraObject.editMode}
                stroke={triplineColour}
                strokeWidth={triplineStrokeWidth}
                onDragMove={onLineDragMove}
                onClick={handleClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            />
            <Rect
                x={xCenter}
                y={yCenter}
                width={titleBoxWidth && titleBoxWidth > 60 ? titleBoxWidth : 60}
                height={24}
                fill={triplineTagColour}
                offset={{ x: (titleBoxWidth && titleBoxWidth > 60 ? titleBoxWidth : 60) / 2, y: 12 }}
                rotation={rotation}
                onClick={handleClick}
                draggable
                onDragMove={onTitleDragMove}
                onMouseEnter={handleCursorPointer}
                onMouseDown={cameraObject.editMode ? handleCursorGrabbing : handleCursorPointer}
                onMouseUp={handleCursorPointer}
                onDragEnd={handleCursorPointer}
                cornerRadius={5}
                onContextMenu={onContextMenu}
                onMouseLeave={onMouseLeaveTag}
                onMouseOver={onMouseOverTripline}
            />
            <RegularPolygon
                name="direction-triangle"
                x={xCenter}
                y={yCenter}
                sides={3}
                radius={20}
                offset={{ x: 0, y: 22 }}
                fill={triplineTriangleColour}
                rotation={cameraObject.direction === 0 ? rotation : rotation + 180}
                onClick={(e) => {
                    handleDirectionToggle(e);
                }}
                onDragMove={onTitleDragMove}
                onMouseEnter={handleCursorPointer}
                onMouseLeave={onMouseLeaveTriangle}
                onDragEnd={handleCursorPointer}
                onMouseOver={onMouseOverTriplineTriangle}
                draggable={cameraObject.editMode}
            />

            <Text
                x={xCenter}
                y={yCenter}
                width={titleBoxWidth - 10}
                offset={{ x: titleBoxWidth / 2 - 5, y: 7 }}
                rotation={rotation}
                text={cameraObject.name}
                fill="white"
                fontSize={14}
                fontStyle="700"
                ellipsis={true}
                wrap="none"
                align="center"
                onDragMove={onTitleDragMove}
                draggable
                onMouseEnter={handleCursorPointer}
                onMouseDown={(e) => {
                    handleCursorGrabbing(e);
                    handleClick();
                }}
                onMouseUp={handleCursorPointer}
                onDragEnd={handleCursorPointer}
                onContextMenu={onContextMenu}
                onMouseLeave={onMouseLeaveTag}
                onMouseOver={onMouseOverTripline}
            />
            <Text
                name="direction-text"
                x={xCenter}
                y={yCenter}
                width={titleBoxWidth - 30}
                offset={{ x: titleBoxWidth / 2 - 16, y: cameraObject.direction === 0 ? 24 : -16 }}
                rotation={rotation}
                text={"IN"}
                fill="white"
                fontSize={10}
                fontStyle="700"
                ellipsis={true}
                wrap="none"
                onClick={(e) => {
                    handleDirectionToggle(e);
                }}
                align="center"
                draggable={cameraObject.editMode}
                onDragMove={onTitleDragMove}
                onMouseEnter={handleCursorPointer}
                onMouseLeave={onMouseLeaveTriangle}
                onMouseUp={handleCursorPointer}
                onDragEnd={handleCursorPointer}
                onMouseOver={onMouseOverTriplineTriangle}
            />
            {cameraObject.editMode && (
                <>
                    <Circle
                        x={x1}
                        y={y1}
                        radius={6}
                        stroke={hoveredCircleIndex === 0 ? triplineCircleHighlight : triplineCircleNormal}
                        fill={getCircleFill(
                            cameraObject.id,
                            0,
                            selectedCameraObjectId,
                            focusedPointIndex,
                            "#CFFFCF",
                            "white",
                        )}
                        strokeWidth={hoveredCircleIndex === 0 ? highlightStrokeWidth : defaultStrokeWidth}
                        draggable
                        onDragMove={onLeftDotDragMove}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        onDragStart={handleCursorGrabbing}
                        onDragEnd={handleCursorPointer}
                        onClick={() => onPointClick(cameraObject, 0)}
                        onMouseOver={() => setHoveredCircleIndex(0)}
                    />
                    <Circle
                        x={x2}
                        y={y2}
                        radius={6}
                        stroke={hoveredCircleIndex === 1 ? triplineCircleHighlight : triplineCircleNormal}
                        fill={getCircleFill(
                            cameraObject.id,
                            1,
                            selectedCameraObjectId,
                            focusedPointIndex,
                            "#CFFFCF",
                            "white",
                        )}
                        strokeWidth={hoveredCircleIndex === 1 ? highlightStrokeWidth : defaultStrokeWidth}
                        draggable
                        onDragMove={onRightDotDragMove}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        onDragStart={handleCursorGrabbing}
                        onDragEnd={handleCursorPointer}
                        onClick={() => onPointClick(cameraObject, 1)}
                        onMouseOver={() => setHoveredCircleIndex(1)}
                    />
                </>
            )}
        </Group>
    );
};

export default Tripwire;
