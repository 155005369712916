import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
  fill?: string;
}

export default class SVGIconDownArrow extends React.Component<Props> {
  /**
   * Sets colour depending on user input, if no colour given then set to a default of black.
   *
   * @private
   * @memberof SVGIconDownArrow
   */
  private fillColor = (): string => {
    // If no colour provided then default to black.
    if (!this.props.fill) {
      return "#000000";
    }
    // If we have a valid colour pass down.
    return this.props.fill;
  };

  /**
   * Render
   */
  public render(): ReactNode {
    return (
      <SVGWrapper
        id="downArrowIcon"
        width="100%"
        height="100%"
        fillColor={this.fillColor()}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14 8"
      >
        <title>Down Arrow Icon</title>
        {/* <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.0372 0.727625C12.8964 0.585026 12.7043 0.504741 12.5039 0.504683C12.3034 0.506289 12.112 0.588055 11.9722 0.731737L7.05639 5.70076L2.09481 0.769878C1.95396 0.627279 1.76188 0.546993 1.56145 0.546935C1.36363 0.550517 1.17525 0.632117 1.03732 0.773961C0.74766 1.06761 0.749484 1.54005 1.0414 1.83145L6.5375 7.28527C6.67888 7.42671 6.87088 7.50583 7.07086 7.50506C7.27083 7.50429 7.46222 7.42369 7.6025 7.28116L13.0563 1.78506C13.3419 1.48732 13.3333 1.01487 13.0372 0.727625Z"
            fill={this.fillColor()}
          />
        {/* </svg> */}
      </SVGWrapper>
    );
  }
}

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
