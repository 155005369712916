import Konva from "konva";
import React, { FC, useEffect, useRef, useState } from "react";
import { Circle, Line, Rect, Text, Group } from "react-konva";
import { CameraObjectPoint, CameraObjectTripwire, CameraObjectZone } from "./CameraConfigView";
import {
    handleCursorPointer,
    handleCursorDefault,
    handleCursorGrab,
    handleCursorGrabbing,
    getCircleFill,
    handleArrowPress,
} from "./utils";
import { KonvaEventObject } from "konva/lib/Node";
import CrossCircle from "./CrossCircle";

interface CustomMouseEvent extends MouseEvent {
    layerX: number;
    layerY: number;
}

export interface ZoneProps {
    cameraObject: CameraObjectZone;
    maxPositionX: number;
    maxPositionY: number;
    focusedPointIndex: number;
    selectedCameraObjectId: string;
    stageScale: number;
    onPointClick: (cameraObject: CameraObjectZone, clickedIndex: number) => void;
    onZoneMove: (cameraObject: CameraObjectZone | CameraObjectTripwire) => void;
    onClick: (cameraObject: CameraObjectZone | CameraObjectTripwire) => void;
    onContextMenu: (item: KonvaEventObject<PointerEvent>) => void;
}

interface CursorTooltip {
    text: string;
    x: number;
    y: number;
}

const charWidth = 10;
const maxTextWidth = 110;
let cursorTooltipTimeout: NodeJS.Timeout | null = null;

const Zone: FC<ZoneProps> = ({
    cameraObject,
    maxPositionX,
    maxPositionY,
    focusedPointIndex,
    selectedCameraObjectId,
    stageScale,
    onPointClick,
    onZoneMove,
    onClick,
    onContextMenu,
}: ZoneProps) => {
    const polygonLineNormal = "#0064D2";
    const polygonTagNormal = "#001D81";
    const polygonHighlight = "#68BFFA";
    const defaultStrokeWidth = 2;
    const highlightStrokeWidth = defaultStrokeWidth + 2;

    const [polygonLineColour, setPolygonLineColour] = useState(polygonLineNormal);
    const [polygonTagColour, setPolygonTagColour] = useState(polygonTagNormal);
    const [polygonStrokeWidth, setPolygonStrokeWidth] = useState(defaultStrokeWidth);
    const [hoveredCircleIndex, setHoveredCircleIndex] = useState<null | number>();
    const [crossCircleVisible, setCrossCircleVisible] = useState(false);
    const [crossCirclePos, setCrossCirclePos] = useState({ x: 0, y: 0 });
    const [colorLinePoints, setColorLinePoints] = useState<Array<number>>([]);
    const [showCursorTooltip, setShowCursorTooltip] = useState(false);
    const [cursorTooltip, setCursorTooltip] = useState<CursorTooltip>();
    const groupRef = useRef<Konva.Group | null>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const textWidth = Math.min(maxTextWidth, charWidth * cameraObject.name.length);
    const titleBoxWidth = textWidth + 10;

    const xRate = 1 / maxPositionX;
    const yRate = 1 / maxPositionY;

    const x = cameraObject.points[0].x / xRate;
    const y = cameraObject.points[0].y / yRate;

    const linePoints = cameraObject.points.flatMap((point) => {
        return [point.x / xRate - x, point.y / yRate - y];
    });

    const dotsPoints: Array<CameraObjectPoint> = cameraObject.points.map((point) => {
        return { x: point.x / xRate, y: point.y / yRate };
    });

    const handleCursorWithToolboxShow = (e: Konva.KonvaEventObject<MouseEvent>) => {
        handleCursorPointer(e);
        cursorTooltipTimeout = setTimeout(() => {
            setShowCursorTooltip(false);
        }, 1000);
    };

    const removeCursorTimer = () => {
        if (cursorTooltipTimeout) {
            clearTimeout(cursorTooltipTimeout);
        }
    };

    const handleLineDragMove = (e: Konva.KonvaEventObject<DragEvent>) => {
        const newPosition = e.target.position();

        e.target.position({ x: x, y: y });

        const maxY = Math.max(...linePoints.filter((_item, index) => index % 2));
        const minY = Math.min(...linePoints.filter((_item, index) => index % 2));

        const maxX = Math.max(...linePoints.filter((_item, index) => index % 2 === 0));
        const minX = Math.min(...linePoints.filter((_item, index) => index % 2 === 0));

        const newX = Math.min(maxPositionX - maxX, Math.max(-minX, newPosition.x));
        const newY = Math.min(maxPositionY - maxY, Math.max(-minY, newPosition.y));

        const deltaX = (newX - x) * xRate;
        const deltaY = (newY - y) * yRate;

        const newPoints = cameraObject.points.map((point) => ({
            x: point.x + deltaX,
            y: point.y + deltaY,
        }));

        onZoneMove && onZoneMove({ ...cameraObject, ...{ points: newPoints } });
    };

    const handleDotDragMove =
        (index: number, oldPosition: CameraObjectPoint, selectedZoneId: string) =>
        (e: Konva.KonvaEventObject<DragEvent>) => {
            const newPosition = e.target.position();

            e.target.position({ x: oldPosition.x, y: oldPosition.y });

            const newX = Math.min(maxPositionX, Math.max(0, newPosition.x));
            const newY = Math.min(maxPositionY, Math.max(0, newPosition.y));

            const deltaX = (newX - oldPosition.x) * xRate;
            const deltaY = (newY - oldPosition.y) * yRate;

            const newPoints = [...cameraObject.points];
            newPoints[index] = {
                x: newPoints[index].x + deltaX,
                y: newPoints[index].y + deltaY,
            };

            onPointClick(cameraObject, index);
            onZoneMove && onZoneMove({ ...cameraObject, ...{ points: newPoints } });
        };

    const handlePointDelete = (index: number) => {
        if (cameraObject.points.length > 3) {
            cameraObject.points.splice(index, 1);
            onZoneMove && onZoneMove(cameraObject);
        }
    };

    const titleBoxRotation = () => {
        const maxY = linePoints[1];
        const minY = linePoints[3];

        const maxX = linePoints[0];
        const minX = linePoints[2];

        return (Math.atan2(maxY - minY, maxX - minX) * 180) / Math.PI - 180;
    };

    const titleBoxOffset =
        Math.sqrt(
            Math.pow(cameraObject.points[1].x / xRate - cameraObject.points[0].x / xRate, 2) +
                Math.pow(cameraObject.points[1].y / yRate - cameraObject.points[0].y / yRate, 2),
        ) /
            2 -
        titleBoxWidth / 2;

    const handleBorderLineClick = (index: number) => (e: Konva.KonvaEventObject<CustomMouseEvent>) => {
        removeCursorTimer();
        setShowCursorTooltip(false);
        setCrossCircleVisible(false);
        setColorLinePoints([]);

        const stage = e.target.getStage();
        if (!stage) return;

        // Calculate the position in the scaled stage coordinates
        const scale = stage.scaleX();
        const offsetX = stage.x();
        const offsetY = stage.y();
        const mouseX = (e.evt.layerX - offsetX) / scale;
        const mouseY = (e.evt.layerY - offsetY) / scale;

        const newPoints = [...cameraObject.points];

        // Check if the index corresponds to the last point in the array
        if (index === newPoints.length - 1 || index === -1) {
            // If so, add the new point after the last point
            newPoints.push({
                x: mouseX * xRate,
                y: mouseY * yRate,
            });
        } else {
            // Otherwise, insert the new point after the specified index
            newPoints.splice(index + 1, 0, {
                x: mouseX * xRate,
                y: mouseY * yRate,
            });
        }

        setSelectedIndex(index + 1);
        onZoneMove && onZoneMove({ ...cameraObject, ...{ points: newPoints } });
    };

    useEffect(() => {
        if (cameraObject.points.length >= selectedIndex) {
            onPointClick(cameraObject, selectedIndex);
        }
        setSelectedIndex(0);
    }, [cameraObject.points.length]);

    const handleClick = (e: Konva.KonvaEventObject<CustomMouseEvent>) => {
        handleRenderObjectOnTop();
        onClick && onClick(cameraObject);
    };

    const mapPointsToBorderLine = (item: CameraObjectPoint, index: number) => {
        const nextIndex = index < cameraObject.points.length - 1 ? index + 1 : 0;

        const linePoints = [
            item.x / xRate - x,
            item.y / yRate - y,
            cameraObject.points[nextIndex].x / xRate - x,
            cameraObject.points[nextIndex].y / yRate - y,
        ];

        return (
            <Line
                key={index}
                x={x}
                y={y}
                points={linePoints}
                stroke="transparent"
                strokeWidth={5}
                onMouseLeave={onMouseLeaveLine}
                onMouseEnter={(e) => {
                    handleCursorWithToolboxShow(e);
                }}
                onMouseMove={(e) => {
                    handleCrossCircle(e);
                    checkMouseOverLine(
                        e.target.getStage()?.getPointerPosition()?.x as number,
                        e.target.getStage()?.getPointerPosition()?.y as number,
                        linePoints,
                    );
                }}
                onClick={handleBorderLineClick(index)}
                hitStrokeWidth={6}
            />
        );
    };

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            handleArrowPress(
                e,
                cameraObject,
                focusedPointIndex,
                maxPositionX,
                maxPositionY,
                xRate,
                yRate,
                selectedCameraObjectId,
                onZoneMove,
            );
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [cameraObject, focusedPointIndex, maxPositionX, maxPositionY, selectedCameraObjectId, onZoneMove]);

    const handleRenderObjectOnTop = () => {
        if (groupRef.current) {
            groupRef.current?.moveToTop();
        }
    };

    const handleCrossCircle = (event: KonvaEventObject<MouseEvent>) => {
        const stage = event.target.getStage();
        const mousePosition = stage?.getPointerPosition();
        if (!stage) return;

        // Calculate the position in the scaled stage coordinates
        const scale = stage.scaleX();
        const offsetX = stage.x();
        const offsetY = stage.y();

        if (mousePosition) {
            const mouseX = (mousePosition.x - offsetX) / scale;
            const mouseY = (mousePosition.y - offsetY) / scale;
            setCrossCirclePos({
                x: mouseX,
                y: mouseY,
            });
            setCrossCircleVisible(true);
        }
    };

    // https://paulbourke.net/geometry/pointlineplane/javascript.txt
    const intersectWithMouse = (
        mouseX: number,
        mouseY: number,
        x2: number,
        y2: number,
        x3: number,
        y3: number,
        x4: number,
        y4: number,
    ) => {
        // Check if none of the lines are of length 0
        if ((mouseX === x2 && mouseY === y2) || (x3 === x4 && y3 === y4)) {
            return false;
        }

        const denominator = (y4 - y3) * (x2 - mouseX) - (x4 - x3) * (y2 - mouseY);

        // Lines are parallel
        if (denominator === 0) {
            return false;
        }

        const ua = ((x4 - x3) * (mouseY - y3) - (y4 - y3) * (mouseX - x3)) / denominator;
        const ub = ((x2 - mouseX) * (mouseY - y3) - (y2 - mouseY) * (mouseX - x3)) / denominator;

        // Check if the intersection is along the line segments
        if (ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1) {
            // Return the x and y coordinates of the intersection
            const x = mouseX + ua * (x2 - mouseX);
            const y = mouseY + ua * (y2 - mouseY);
            return { x, y };
        }

        return false;
    };

    const checkMouseOverLine = (mouseX: number, mouseY: number, linePoints: Array<number>) => {
        // linePoints is a flat point structure e.g. [x1, y1, x2, y2] so need to iterate by 2
        for (let i = 0; i < linePoints.length - 2; i += 2) {
            const x1 = linePoints[i];
            const y1 = linePoints[i + 1];
            const x2 = linePoints[i + 2];
            const y2 = linePoints[i + 3];

            const intersection = intersectWithMouse(mouseX, mouseY, x1, y1, x2, y2, x1, y1);

            if (intersection) {
                setColorLinePoints([x1, y1, x2, y2]);
            }
        }
    };

    const onMouseLeaveLine = (event: KonvaEventObject<MouseEvent>) => {
        stageScale > 1 ? handleCursorGrab(event) : handleCursorDefault(event);
        setPolygonLineColour(polygonLineNormal);
        setPolygonStrokeWidth(defaultStrokeWidth);
        setColorLinePoints([]);
        setCrossCircleVisible(false);
        if (cameraObject.editMode) {
            setPolygonTagColour(polygonHighlight);
        } else {
            setPolygonTagColour(polygonTagNormal);
        }
    };

    const onMouseOverPolygon = (event: KonvaEventObject<MouseEvent>) => {
        if (cameraObject.editMode) {
            setPolygonLineColour(polygonLineNormal);
            setPolygonTagColour(polygonHighlight);
            setPolygonStrokeWidth(defaultStrokeWidth);
        } else {
            setPolygonLineColour(polygonHighlight);
            setPolygonTagColour(polygonHighlight);
            setPolygonStrokeWidth(highlightStrokeWidth);
        }

        handleCursorPointer(event);
    };

    const onMouseLeaveTag = (event: KonvaEventObject<MouseEvent>) => {
        stageScale > 1 ? handleCursorGrab(event) : handleCursorDefault(event);
        setPolygonLineColour(polygonLineNormal);
        setPolygonStrokeWidth(defaultStrokeWidth);

        if (cameraObject.editMode) {
            setPolygonTagColour(polygonHighlight);
        } else {
            setPolygonTagColour(polygonTagNormal);
        }
    };

    const onMouseOverVertex = (index: number) => {
        setHoveredCircleIndex(index);
    };

    const onMouseLeaveVertex = (event: KonvaEventObject<MouseEvent>) => {
        stageScale > 1 ? handleCursorPointer(event) : handleCursorDefault(event);
        setHoveredCircleIndex(null);
    };

    useEffect(() => {
        if (cameraObject.editMode) {
            setPolygonLineColour(polygonLineNormal);
            setPolygonTagColour(polygonHighlight);
            setPolygonStrokeWidth(defaultStrokeWidth);
        } else {
            setPolygonTagColour(polygonTagNormal);
        }
    }, [cameraObject.editMode]);

    return (
        <Group ref={groupRef}>
            <Line
                x={x}
                y={y}
                points={linePoints}
                closed
                stroke={polygonLineColour}
                strokeWidth={polygonStrokeWidth}
                onMouseLeave={onMouseLeaveLine}
                onMouseUp={handleCursorPointer}
                onDragEnd={handleCursorPointer}
                onMouseOver={onMouseOverPolygon}
                onClick={handleClick}
                onDragMove={handleLineDragMove}
                onDragStart={handleCursorGrabbing}
                onMouseDown={cameraObject.editMode ? handleCursorGrabbing : handleCursorPointer}
                draggable={cameraObject.editMode}
            />
            <Rect
                x={x}
                y={y}
                width={titleBoxWidth}
                height={24}
                fill={polygonTagColour}
                offset={{ x: -titleBoxOffset, y: 24 }}
                rotation={titleBoxRotation()}
                onMouseLeave={onMouseLeaveTag}
                onDragMove={handleLineDragMove}
                onMouseEnter={handleCursorPointer}
                onMouseDown={handleCursorGrabbing}
                onMouseUp={handleCursorPointer}
                onDragstart={handleCursorGrabbing}
                onDragEnd={handleCursorPointer}
                onMouseOver={onMouseOverPolygon}
                onClick={handleClick}
                cornerRadius={5}
                onContextMenu={onContextMenu}
            />
            <Text
                x={x}
                y={y}
                width={titleBoxWidth - 10}
                offset={{ x: -titleBoxOffset - 5, y: 18 }}
                rotation={titleBoxRotation()}
                text={cameraObject.name}
                fill="white"
                fontSize={14}
                fontStyle="700"
                ellipsis={true}
                wrap="none"
                align="center"
                draggable
                onMouseLeave={onMouseLeaveTag}
                onMouseOver={onMouseOverPolygon}
                onDragMove={handleLineDragMove}
                onMouseEnter={handleCursorPointer}
                onMouseDown={(e) => {
                    handleClick(e as KonvaEventObject<CustomMouseEvent>);
                    handleCursorGrabbing(e);
                    handleLineDragMove(e as Konva.KonvaEventObject<DragEvent>);
                }}
                onDragStart={handleLineDragMove}
                onMouseUp={handleCursorPointer}
                onDragEnd={handleCursorPointer}
                onClick={handleClick}
                onContextMenu={onContextMenu}
            />

            {colorLinePoints && (
                <Line x={x} y={y} points={colorLinePoints} closed stroke="#B2E0FD" strokeWidth={highlightStrokeWidth} />
            )}

            {cameraObject.editMode && cameraObject.points && cameraObject.points.map(mapPointsToBorderLine)}
            {cameraObject.editMode &&
                dotsPoints.map((item, index) => {
                    return (
                        <Circle
                            id={`point-${cameraObject.id}-${index}`}
                            name={`circle ${index}`}
                            key={index}
                            x={item.x}
                            y={item.y}
                            radius={6}
                            stroke={hoveredCircleIndex === index ? polygonLineNormal : polygonTagNormal}
                            fill={getCircleFill(
                                cameraObject.id,
                                index,
                                selectedCameraObjectId,
                                focusedPointIndex,
                                "#B2E0FD",
                                "white",
                            )}
                            strokeWidth={hoveredCircleIndex === index ? highlightStrokeWidth : defaultStrokeWidth}
                            draggable
                            onMouseEnter={handleCursorWithToolboxShow}
                            onMouseLeave={(e) => onMouseLeaveVertex(e)}
                            onDragMove={handleDotDragMove(index, item, cameraObject.id)}
                            onDragStart={handleCursorGrabbing}
                            onDragEnd={handleCursorPointer}
                            onClick={() => onPointClick(cameraObject, index)}
                            onContextMenu={() => handlePointDelete(index)}
                            onMouseOver={() => onMouseOverVertex(index)}
                        />
                    );
                })}

            {crossCircleVisible && <CrossCircle x={crossCirclePos.x} y={crossCirclePos.y} />}

            {showCursorTooltip && (
                <>
                    <Rect
                        x={cursorTooltip?.x}
                        y={cursorTooltip?.y}
                        width={cursorTooltip ? cursorTooltip.text.length * 5.5 : 100}
                        height={20}
                        fill="white"
                        offset={{ x: 0, y: -30 }}
                    />
                    <Text
                        x={cursorTooltip?.x}
                        y={cursorTooltip?.y}
                        height={20}
                        fill="grey"
                        offset={{ x: -3, y: -35 }}
                        text={cursorTooltip?.text}
                    />
                </>
            )}
        </Group>
    );
};

export default Zone;
