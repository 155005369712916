import React, { ReactElement } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
  fill?: string;
  title?: string;
}

const SVGIconConnect = ({ fill, title }: Props): ReactElement => {
  return (
    <SVGWrapper viewBox="0 0 20 20" fillColor={fill || ""}>
      <title>{title}</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="tripwire-instructional" transform="translate(-18.000000, -76.000000)">
          <g id="iconTelstraConnect24" transform="translate(16.000000, 74.000000)">
            <rect id="Viewbox" x="0" y="0" width="24" height="24"></rect>
            <path
              d="M19.86,16.65 L19.86,4.86 C19.86,4.44578644 19.5242136,4.11 19.11,4.11 L7.32,4.11 C6.91987311,3.01217999 5.81020444,2.34025314 4.65195805,2.49444657 C3.49371167,2.64864 2.59847798,3.58747273 2.4994948,4.75173758 C2.40051162,5.91600242 3.12440732,6.99248989 4.24,7.34 L4.24,19 C4.24538581,19.4119621 4.57803794,19.7446142 4.99,19.75 L16.6,19.75 C16.9531317,20.8490601 18.017898,21.5591217 19.1682622,21.4626991 C20.3186264,21.3662765 21.2503326,20.4888722 21.4155776,19.3463621 C21.5808227,18.2038521 20.9359015,17.0984149 19.86,16.68 L19.86,16.65 Z M5,4 C5.55228475,4 6,4.44771525 6,5 C6,5.55228475 5.55228475,6 5,6 C4.44771525,6 4,5.55228475 4,5 C4,4.44771525 4.44771525,4 5,4 Z M5.75,7.35 C6.5586348,7.08922373 7.17748622,6.43263746 7.39,5.61 L18.39,5.61 L18.39,16.61 C17.5549176,16.8202394 16.8886737,17.4486285 16.63,18.27 L5.74,18.27 L5.75,7.35 Z M19,20 C18.4477153,20 18,19.5522847 18,19 C18,18.4477153 18.4477153,18 19,18 C19.5522847,18 20,18.4477153 20,19 C20,19.5522847 19.5522847,20 19,20 Z"
              id="Icon-Colour"
              fill={fill}
            ></path>
          </g>
        </g>
      </g>
    </SVGWrapper>
  );
};

export default SVGIconConnect;

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
