import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
    fill?: string;
}

export default class SVGIconLogout extends React.Component<Props> {
    /**
     * Sets colour depending on user input, if no colour given then set to a default of black.
     *
     * @private
     * @memberof SVGIconLogout
     */
    private fillColor = (): string => {
        // If no colour provided then default to black.
        if (!this.props.fill) {
            return "#000000";
        }
        // If we have a valid colour pass down.
        return this.props.fill;
    };

    /**
     * Render
     */
    public render(): ReactNode {
        return (
            <SVGWrapper
                id="logoutIcon"
                width="100%"
                height="100%"
                fillColor={this.fillColor()}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20.19 20.29"
            >
                <title>Logout Icon</title>
                <path
                    d="M7.31,21.67h-4a1.44,1.44,0,0,1-1.42-1.45V2.83A1.44,1.44,0,0,1,3.33,1.38h4a.75.75,0,0,1,0,1.5h-4l.08,17.34,3.9,0a.75.75,0,1,1,0,1.5Z"
                    transform="translate(-1.91 -1.38)"
                />
                <path
                    d="M21.83,10.89,16.39,5.44A.75.75,0,1,0,15.33,6.5l4.27,4.28H8.11a.75.75,0,1,0,0,1.5H19.6l-4.27,4.27a.74.74,0,0,0,0,1.06.75.75,0,0,0,.53.22.79.79,0,0,0,.53-.22l5.45-5.45A.91.91,0,0,0,21.83,10.89Z"
                    transform="translate(-1.91 -1.38)"
                />
            </SVGWrapper>
        );
    }
}

const SVGWrapper = styled.svg<{ fillColor: string }>`
    fill: ${(props): string => props.fillColor};
`;
