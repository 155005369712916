import React, { ReactElement } from "react";
import styled from "styled-components";

interface Props {
    fill?: string;
}

const SVGIconBillsAndPayments = ({ fill }: Props): ReactElement => {
    return (
        <SVGWrapper
            id="billsAndPayments"
            height="100%"
            fillColor={fill || ""}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 31.2 31.2"
        >
            <title>Bills And Payments Icon</title>
            <clipPath id="bills-and-payments-icon-clip-path" transform="translate(3.6 3.54)">
                <path
                    fill="none"
                    clipRule="evenodd"
                    d="M21.4,21.51H6.6V12.66h6v-6h8.8Zm-18.8-4V8.66h6L5.4,11.81v5.65Zm4.8-14v4H3.45Zm4,4v4H7.45Zm6-2H11.75L8.6,8.61v-6h8.8Zm1.2,0v-4H7.75L1.4,7.81V18.66h4v4H22.6V5.46Z"
                />
            </clipPath>
            <g clipPath="url(#bills-and-payments-icon-clip-path)">
                <rect width="31.2" height="31.2" />
            </g>
        </SVGWrapper>
    );
};

export default SVGIconBillsAndPayments;

const SVGWrapper = styled.svg<{ fillColor: string }>`
    fill: ${(props): string => props.fillColor};
`;
