import React, { ReactElement } from "react";
import styled from "styled-components";

interface Props {
  fill?: string;
  title?: string;
}

const SVGIconAdd = ({ fill, title }: Props): ReactElement => {
  return (
    <SVGWrapper id="addIcon" height="100%" fillColor={fill || ""} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.8 12.8">
      <title>{title}</title>
      <polygon points="12.8 5.8 7 5.8 7 0 5.8 0 5.8 5.8 0 5.8 0 7 5.8 7 5.8 12.8 7 12.8 7 7 12.8 7 12.8 5.8" />
    </SVGWrapper>
  );
};

export default SVGIconAdd;

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
