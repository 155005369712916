/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState } from "react";
import { DeviceConfig, FetchError, Pipeline } from "@sv/types";
import axios, { AxiosRequestConfig } from "axios";
import { getBaseUrl } from "./../../../services";

const isAppTestMode = process.env.IS_APP_TEST_MODE || false;

export interface DeviceConfigsLoadingState {
    getDeviceConfig: boolean;
    addDeviceConfig: boolean;
    deleteDeviceConfig: boolean;
}

interface UseDeviceConfigReturn {
    error: FetchError;
    loading: DeviceConfigsLoadingState;
    deviceConfig?: DeviceConfig;
    getDeviceConfig: (module: string, gatewayId: string, deviceId: string) => Promise<void>;
    addDeviceConfig: (module: string, gatewayId: string, pipeline: Pipeline) => Promise<void>;
    deleteDeviceConfig: (module: string, gatewayId: string, deviceId: string) => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const prepareError = (error: any, defaultMessage: string) => {
    return {
        status: error.response?.status,
        statusText: error.response?.data?.statusText,
        message: error.data?.message || defaultMessage,
    };
};

const initialLoadingState: DeviceConfigsLoadingState = {
    getDeviceConfig: false,
    addDeviceConfig: false,
    deleteDeviceConfig: false,
};

const useDeviceConfigs = (): UseDeviceConfigReturn => {
    const [error, setError] = useState({} as FetchError);
    const [loading, setLoading] = useState(initialLoadingState);
    const [deviceConfig, setDeviceConfig] = useState<DeviceConfig>();

    const url = useMemo(() => `${getBaseUrl}/edge-devices`, []);

    const getDeviceConfig = async (module: string, gatewayId: string, deviceId: string) => {
        setLoading((current) => ({ ...current, getDeviceConfig: true }));
        setDeviceConfig(undefined);

        const requestConfig = {
            params: {
                module,
                edgeDevice: gatewayId,
                leafDevice: deviceId,
            },
        };

        try {
            const resp = await axios.get<DeviceConfig>(url + `/${gatewayId}/leaf-devices/${deviceId}/modules/${module}/config`, requestConfig);
            setError({});

            // TODO - this is only temporary for LGAQ conference
            resp.data.config.pipeline.pipeline_modules.line_module.lines = resp.data.config.pipeline.pipeline_modules.line_module.lines?.filter((e: any) => e.name !== "Tripwire-test-one") || [];
            
            setDeviceConfig(resp.data);
        } catch (error) {
            setDeviceConfig(undefined);
            setError(prepareError(error, "Get camera configs error"));
        } finally {
            setLoading((current) => ({ ...current, getDeviceConfig: false }));
        }
    };

    const addDeviceConfig = async (module: string, gatewayId: string, pipeline: Pipeline) => {
        setLoading((current) => ({ ...current, addDeviceConfig: true }));
        setDeviceConfig(undefined);

        const requestBody = {
            edgeDevice: gatewayId,
            module: module,
            leafDevice: pipeline.pipeline_device_id,
            config: { pipeline },
        };

        const requestOptions = {
            params: {
                ...(isAppTestMode && { test: true }),
            },
        };

        try {
            const resp = await axios.post(url + `/${gatewayId}/leaf-devices/${pipeline.pipeline_device_id}/modules/${module}/config`, requestBody, requestOptions);
            setDeviceConfig(resp.data);
            setError({});
        } catch (error) {
            setError(prepareError(error, "Add device config error"));
        } finally {
            setLoading((current) => ({ ...current, addDeviceConfig: false }));
        }
    };

    const deleteDeviceConfig = async (module: string, gatewayId: string, deviceId: string) => {
        setLoading((current) => ({ ...current, deleteDeviceConfig: true }));

        const options: AxiosRequestConfig = {
            params: {
                module,
                edgeDevice: gatewayId,
                leafDevice: deviceId,
            },
        };

        try {
            await axios.delete(url + `/${gatewayId}/leaf-devices/${deviceId}/modules/${module}/config`, options);
            setError({});
        } catch (error) {
            setError(prepareError(error, "Delete device config error"));
        } finally {
            setLoading((current) => ({ ...current, deleteDeviceConfig: false }));
        }
    };

    return { error, loading, deviceConfig, getDeviceConfig, addDeviceConfig, deleteDeviceConfig };
};

export default useDeviceConfigs;
