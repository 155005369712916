import React from "react";
import { Button } from "telstra-ui/components/button/Button";
import { Body18, Box, Col, Row, DropDown, FormikProvider, useFormik, Modal, WhiteSpace } from "telstra-components";
import { DropdownOption, ClassTag } from "@sv/types";
import strings from "../../../strings/strings.json";
import { FormikState } from "formik";
import { ToolTip } from "telstra-ui/components/tool-tip/Tooltip";
import { validationSchemas } from "../../../helpers/formValidation";
import TextField from "../../../components/Fields/TextField";

const { cancelButton, doneButton, valueLabel, variableLabel } = strings.components.Modals.TagModal;

const defaultValues = {
    variable: "",
    value: "",
} as ClassTag;

interface Props {
    heading: string;
    valueTooltip: string;
    description: string;
    variableTooltip: string;
    variableOptions: Array<DropdownOption>;
    onTagModalToggle: () => void;
    onSubmit: (tag: ClassTag) => void;
}

const TagModal = ({
    heading,
    valueTooltip,
    description,
    variableTooltip,
    variableOptions,
    onTagModalToggle: handleTagModalToggle,
    onSubmit: handleSubmit,
}: Props): JSX.Element => {
    const formik = useFormik({
        initialValues: defaultValues,
        validationSchema: validationSchemas.siteTag,
        onSubmit: async (
            tag: ClassTag,
            { resetForm }: { resetForm: (nextState?: Partial<FormikState<ClassTag>> | undefined) => void },
        ) => {
            handleSubmit(tag);
            resetForm();
            handleTagModalToggle();
        },
    });
    const handleOnSelect = () => formik.validateForm();

    return (
        <Modal showCloseButton={false} onClose={handleTagModalToggle} isOpen>
            <Box margin="s" display="flex" gap="xs" flexDirection="column">
                <Box className="heading20">{heading}</Box>

                <Box>
                    <span className="body16">{description}</span>
                    <FormikProvider value={formik}>
                        <Box display="flex" flexDirection="column" gap="xs">
                            <Row noMargin>
                                <Col noPadding md={1.5} sm={2} justify="center">
                                    <Box display="flex">
                                        <Box flex="1">
                                            <Body18 regular>{variableLabel}</Body18>
                                        </Box>
                                        <div role="button" data-testid="hello" aria-label="variable-tooltip">
                                            <ToolTip
                                                description={<WhiteSpace>{variableTooltip}</WhiteSpace>}
                                                iconName="icon-system-info"
                                            />
                                        </div>
                                    </Box>
                                </Col>
                                <Col noPadding md={6.5} sm={6} justify="center">
                                    {/* TODO: dropdown has no error message displayed */}
                                    <DropDown
                                        name="variable"
                                        options={variableOptions}
                                        disabled={false}
                                        onSelect={handleOnSelect}
                                    />
                                </Col>
                            </Row>

                            <Row noMargin>
                                <Col noPadding md={1.5} sm={2}>
                                    <Box display="flex">
                                        <Box flex="1">
                                            <Body18 regular>{valueLabel}</Body18>
                                        </Box>
                                        <div aria-label="value-tooltip">
                                            <ToolTip
                                                description={<WhiteSpace>{valueTooltip}</WhiteSpace>}
                                                iconName="icon-system-info"
                                            />
                                        </div>
                                    </Box>
                                </Col>
                                <Col noPadding md={6.5} sm={6} justify="center">
                                    <TextField name="value" />
                                </Col>
                            </Row>
                        </Box>
                    </FormikProvider>
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <Button variant="secondary" size="medium" onClick={handleTagModalToggle}>
                        {cancelButton}
                    </Button>
                    <Button variant="secondary" size="medium" onClick={formik.submitForm}>
                        {doneButton}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default TagModal;
