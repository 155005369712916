import * as React from "react";
import styled from "styled-components";
import { useOuterClickNotifier } from "./OuterClickNotifierHook";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { StylesProvider } from "@material-ui/core/styles";

/**
 * The available tabs that can be used for navigation
 */
export enum SelectedTab {
    Spaces,
    Reports,
}

/**
 * NavigationBar props
 */
export interface Props {
    firstName: string;
    lastName: string;
    primaryColor: string;
    onPerformLogout?: () => void;
    onClick?: () => void;
}

export const ProfileTab: React.FC<Props> = (props: Props) => {
    const [isShowingProfileDropDown, setIsShowingProfileDropDown] = React.useState(false);
    const innerRef = React.useRef<HTMLDivElement | null>(null);

    const onClickProfile = (): void => {
        setIsShowingProfileDropDown(!isShowingProfileDropDown);
    };

    const handleOuterClick = React.useCallback(
        // memoized callback for optimized performance
        (e: MouseEvent) => {
            if (isShowingProfileDropDown) {
                setIsShowingProfileDropDown(false);
            }
        },
        [isShowingProfileDropDown], // adjust deps to your needs
    );
    useOuterClickNotifier(handleOuterClick, innerRef);

    const getInitials = (): string => {
        const firstNameLetter = props.firstName?.length > 0 ? props.firstName.charAt(0) : "";
        const lastNameLetter = props.lastName?.length > 0 ? props.lastName.charAt(0) : "";
        return firstNameLetter + lastNameLetter;
    };

    return (
        <React.Fragment>
            <StylesProvider injectFirst>
                <Wrapper onClick={onClickProfile} ref={innerRef}>
                    <ProfileInfoContainer onClick={props.onClick}>
                        <ProfileImage>{getInitials()}</ProfileImage>
                        <ProfileInfo>
                            <ProfileName primaryColor={props.primaryColor}>
                                {props.firstName + " " + props.lastName}
                            </ProfileName>
                        </ProfileInfo>
                        <ProfileDropDown $isShowingProfileDropDown={isShowingProfileDropDown} />

                        <LogOutMenuItemCell
                            $isShowingProfileDropDown={isShowingProfileDropDown}
                            onClick={props.onPerformLogout}
                        >
                            <LogOutMenuItemCellTitle primaryColor={props.primaryColor}>Log Out</LogOutMenuItemCellTitle>
                        </LogOutMenuItemCell>
                    </ProfileInfoContainer>
                </Wrapper>
            </StylesProvider>
        </React.Fragment>
    );
};

export default ProfileTab;

const Wrapper = styled.div`
    position: relative;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px;
    :hover {
        cursor: pointer;
    }
`;

const ProfileInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ProfileName = styled.div<{ primaryColor: string }>`
    color: ${(props): string => props.primaryColor};
    text-align: left;
    line-height: 36px;
    letter-spacing: 0.22px;
    margin-left: 10px;
    margin-right: 5px;
`;

const ProfileImage = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #c0e4e3;
    color: #025450;

    text-align: center;
    vertical-align: middle;
    line-height: 36px;
    letter-spacing: 1px;
`;

const ProfileInfo = styled.div`
    float: right;
`;

const ProfileDropDown = styled(KeyboardArrowDownIcon)<{ $isShowingProfileDropDown: boolean }>`
    height: 36px;
    transform: scaleY(${(props): string => (props.$isShowingProfileDropDown ? "-1" : "1")});
`;

const LogOutMenuItemCell = styled.div<{ $isShowingProfileDropDown: boolean }>`
    transition: opacity 0.2s;
    position: absolute;
    box-sizing: border-box;
    height: 48px;
    width: 90%;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 10%);
    bottom: -45px;
    left: 6px;
    display: flex;
    align-items: center;
    position: absolute;
    pointer-events: ${(props): string => (props.$isShowingProfileDropDown ? "default" : "none")};
    opacity: ${(props): string => (props.$isShowingProfileDropDown ? "1" : "0")};

    :hover {
        cursor: pointer;
    }
`;

const LogOutMenuItemCellTitle = styled.div<{ primaryColor: string }>`
    color: ${(props): string => props.primaryColor};
    font-size: 14px;
    text-align: center;
    margin-left: 33px;
`;
