import React, { ReactElement } from "react";
import styled from "styled-components";

type Props = {
    title?: string;
    description?: string;
    iconSrc?: string;
    accentTopBarColor?: string;
    isVisible?: boolean;
};

const HoverToolTip = ({ title, description, iconSrc, accentTopBarColor, isVisible }: Props): ReactElement => {
    return (
        <Container isVisible={isVisible}>
            <HighlightTopBar accentTopBarColor={accentTopBarColor}/>
            <Row>
                <IconContainer>
                    <Icon src={iconSrc} />
                </IconContainer>
                {/* <Title>Devices are offline</Title> */}
                <Title>{title}</Title>
            </Row>
            <Row>
                {/* <Description>Expand site to review</Description> */}
                <Description>{description}</Description>
            </Row>
        </Container>
    );
};

const Container = styled.div<{isVisible?: boolean}>`
    width: 212px;
    height: 102px;
    background: red;
    position: absolute;
    display: ${props => props.isVisible ? "inline" : "none"};
    // width: 0px;

    // .tl-icon-wrapper {
    //     display: none;
    // }

    // .tl-tooltip-container {
    //     margin-left: calc(-12px - 150px);
    //     transform: translate(0px, calc(-100% - 24px));
    // }
    // overflow: visible;

    // margin-left: calc(-25% - 12px);
    // transform: translate(0px, calc(-50% - 24px));
    transform: translate(calc(-50% + 24px), calc(-50% - 24px));
    // transform: translate(max(calc(-50% + 24px), 0), calc(-50% - 24px));

    z-index: 999;

    background: #ffffff;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.1);

    border: solid;
    border-width: 1px;
    border-color: white;

    // Disable text selection
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */

    // Disable image selection
    img {
        pointer-events: none;
    }
`;

const HighlightTopBar = styled.div<{ accentTopBarColor?: string }>`
    width: 100%;
    height: 3px;
    background: ${(props) => props.accentTopBarColor};
    margin-bottom: 20px;
`;

const Row = styled.div`
    height: 25px;
    margin-left: 11px;
    margin-right: 11px;
    margin-top: 11px;
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: center;

    // background: orange;
`;

const IconContainer = styled.div`
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 20px;
    // margin-left: 11px;
    // background: green;
`;

const Icon = styled.img`
    // width: 100%;
    // height: 100%;
    // background: orange;
`;

const Title = styled.div`
    margin-left: 11px;

    font-family: 'Telstra Akkurat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    /* identical to box height, or 214% */

    letter-spacing: -0.5px;

    color: #414141;
`;

const Description = styled.div`
    font-family: 'Telstra Akkurat';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    /* identical to box height, or 156% */

    letter-spacing: -0.15px;

    color: #414141;
`;

export default HoverToolTip;
