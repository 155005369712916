import React, { FC } from "react";
import { DropdownOption } from "@sv/types";
import { Body18, Col, DropDown, Row, greyL1 } from "telstra-components";

interface DropDownRowProps {
    label: string;
    options: Array<DropdownOption>;
    fieldName: string;
    initialValue?: string;
    isEditModal?: boolean;
}

const DropDownRow: FC<DropDownRowProps> = ({
    label,
    options,
    fieldName,
    initialValue,
    isEditModal,
}: DropDownRowProps) => {
    return (
        <Row>
            <Col aria-label={label} md={2} justify="center" noPadding>
                <Body18 aria-label={label} regular color={greyL1}>
                    {label}
                </Body18>
            </Col>
            <Col md={6} justify="center" noPadding>
                <DropDown name={fieldName} options={options} initialValue={initialValue} disabled={isEditModal} />
            </Col>
        </Row>
    );
};

export default DropDownRow;
