import React, { FC, useEffect, useState } from "react";
import { Body18, Box, cadmiumOrange, Col, Heading16, rossoCorsa, Row, green } from "telstra-components";
import { Button } from "telstra-ui/components/button/Button";
import strings from "../../../strings/strings.json";
import useDevice from "../../../hooks/Device/useDevice";
import styled from "styled-components";

// 2 minutes
const connectionTimeOutDuration = 120000;
let getStatusTimeout: NodeJS.Timeout | null = null;

const {
    connectionStatusLabel,
    connectionStatusDisconnected,
    connectionStatusWaiting,
    connectionStatusConnected,
    connectionStatusTimedOut,
    connectionStatusTryAgain,
} = strings.components.Forms.GatewayForm;

interface GatewayStatusProps {
    deviceId?: string;
    testStatusOnline?: boolean;
}

const GatewayStatus: FC<GatewayStatusProps> = ({ deviceId, testStatusOnline }: GatewayStatusProps) => {
    const [connectionTimedOut, setConnectionTimedOut] = useState(false);

    const { deviceConnectionStatus, getDeviceConnectionStatus, controller, loading } = useDevice();

    useEffect(() => {
        if (deviceId) {
            getGatewayConnectionStatus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceId]);

    useEffect(() => {
        if (getStatusTimeout) {
            clearTimeout(getStatusTimeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceConnectionStatus]);

    const getGatewayConnectionStatus = () => {
        if (!deviceId) return;

        setConnectionTimedOut(false);
        getDeviceConnectionStatus(deviceId);
        getStatusTimeout = setTimeout(() => {
            setConnectionTimedOut(deviceConnectionStatus.current?.connected === undefined);
            if (controller) controller.abort();
        }, connectionTimeOutDuration);
    };

    const { label: connectionStatusText, color: connectionStatusColor } = renderConnectionStatus(
        !!deviceConnectionStatus?.current?.connected,
        !!connectionTimedOut,
        !!loading?.getDeviceConnectionStatus,
        deviceId,
    );

    return (
        <>
            <Row align="center">
                <Col md={3} sm={3} noPadding>
                    <Body18 regular>{connectionStatusLabel}</Body18>
                </Col>
                <Col md={5} sm={5} noPadding>
                    <Box display="flex" alignItems="center">
                        
                        <IconContainer
                            // onMouseEnter={() => {
                            //     setOpenedStatusTooltipId(`status-tooltip-${e.id}`);
                            // }}
                            // onMouseLeave={() => {
                            //     setOpenedStatusTooltipId(null);
                            // }}
                        >
                            <img
                                src={
                                    connectionStatusColor === rossoCorsa
                                    ? "/images/site-hierarchy-icons/red-warning.svg"
                                    : connectionStatusColor === green
                                    ? "/images/site-hierarchy-icons/green-tick.svg"
                                    : "/images/site-hierarchy-icons/yellow-warning.svg"
                                }
                                alt={""}
                            />
                        </IconContainer>
                        <Heading16 as="span" color={connectionStatusColor}>
                            {connectionStatusText}
                        </Heading16>
                        {connectionTimedOut && (
                            <Button
                                variant="primary"
                                size="small"
                                label={connectionStatusTryAgain}
                                icon="icon-mics-transfer-plan"
                                iconPosition="right"
                                onClick={getGatewayConnectionStatus}
                            ></Button>
                        )}
                    </Box>
                </Col>
            </Row>
        </>
    );
};

const IconContainer = styled.div`
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
`;

export default GatewayStatus;

const renderConnectionStatus = (connectionStatus: boolean, connectionTimedOut: boolean, isLoading: boolean, deviceId?: string) => {
    if (!deviceId) return { label: connectionStatusDisconnected, color: rossoCorsa };
    if (connectionTimedOut) return { label: connectionStatusTimedOut, color: rossoCorsa };
    if (isLoading) return { label: connectionStatusWaiting, color: cadmiumOrange };
    if (!connectionStatus) return { label: connectionStatusDisconnected, color: rossoCorsa };
    return { label: connectionStatusConnected, color: green };
};
