import { getGroups } from "../../WebService/Groups";
import { getSpaceInsightsWithRetry } from "../../WebService/SpaceInsights";
import { getTelemetry } from "../../WebService/Telemetry";
import RootStore from "../RootStore";
import { getCustomerOrgTreeObjects } from "../../WebService/CustomerInfo";
import { CaasOrgOverviewObject } from "../../WebService/wca-api/Site";

class Spaces {
    private _accessToken: string;
    private _rootStore: RootStore | undefined;
    private _isInsightsCallOver: boolean;
    private _isTelemetryCallOver: boolean;
    private _floorInsightsSubscriptionTimer: number;
    private _telemetrySubscriptionTimer: number;

    public _isInsightSubscribed: boolean;
    public _isTelemetrySubscribed: boolean;

    constructor(accessToken: string, rootStore: RootStore) {
        this._accessToken = accessToken;
        this._rootStore = rootStore ?? undefined;
        this._isInsightsCallOver = true;
        this._isTelemetryCallOver = true;
        this._floorInsightsSubscriptionTimer = 0;
        this._telemetrySubscriptionTimer = 0;
        this._isInsightSubscribed = false;
        this._isTelemetrySubscribed = false;
    }

    public subscribeToSpaceInsights(): void {
        this._floorInsightsSubscriptionTimer = window.setInterval(async () => {
            if (this._isInsightsCallOver) {
                this._isInsightsCallOver = false;
                const floorInsights = await getSpaceInsightsWithRetry(0, this._accessToken, {});
                this._rootStore?.data.spaces.setFloorInsightsData(floorInsights);
                this._isInsightsCallOver = true;
            }
        }, 30 * 1000);
        this._isInsightSubscribed = true;
    }

    public cancelSpaceInsightsSubscription(): void {
        clearInterval(this._floorInsightsSubscriptionTimer);
        this._isInsightSubscribed = false;
    }

    public subscribeToTelemetry(planId: number): void {
        this._telemetrySubscriptionTimer = window.setInterval(async () => {
            if (this._isTelemetryCallOver) {
                this._isTelemetryCallOver = false;
                const telemetry = await getTelemetry(this._accessToken, planId);
                this._rootStore?.data.spaces.setTelemetryData(telemetry);
                this._isTelemetryCallOver = true;
            }
        }, 30 * 1000);
        this._isTelemetrySubscribed = true;
    }

    public cancelTelemetrySubscription(): void {
        clearInterval(this._telemetrySubscriptionTimer);
        this._isTelemetrySubscribed = false;
    }

    public subscribeOnFloorSelect = async (buildingId: number, planId: number): Promise<void> => {
        this._isTelemetryCallOver = true;
        const areaGroups = await getGroups(this._accessToken, buildingId, planId);
        this._rootStore?.data.spaces.setAreaGroupsData(areaGroups);
        const telemetry = await getTelemetry(this._accessToken, planId);
        this._rootStore?.data.spaces.setTelemetryData(telemetry);

        this.cancelSpaceInsightsSubscription();
        this.subscribeToSpaceInsights();

        this.cancelTelemetrySubscription();
        this.subscribeToTelemetry(planId);
    };

    public getCustomerSites = async () => {
        const orgOverviewTreeObjects = await getCustomerOrgTreeObjects(this._accessToken);
        const sites = orgOverviewTreeObjects.filter(e => e.type === "site");
        return sites;
    };

    public getCustomerSiteDevices = async () => {
        const orgOverviewTreeObjects = await getCustomerOrgTreeObjects(this._accessToken);

        const sites: Array<CaasOrgOverviewObject> = orgOverviewTreeObjects.filter((m) => m.type === "site");
        const siteDevices: { [siteId: string]: Array<CaasOrgOverviewObject> } = orgOverviewTreeObjects
            .filter((m) => m.type === "gateway")
            .filter((m) => sites.some(site => site.id === m.parentId))
            .map((m) => m as CaasOrgOverviewObject & {
                id: string;
                type: "gateway";
                text: string;
                parentId: string;
                href: string;
            })
            .reduce((a, e) => ({
                ...a,
                [e.parentId]: (a[e.parentId] || []).concat(e),
            }), {} as { [siteId: string]: Array<CaasOrgOverviewObject> });

        return {
            sites,
            siteDevices,
        };
    };
}

export default Spaces;
