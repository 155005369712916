import React, { ReactElement } from "react";
import styled from "styled-components";

interface Props {
  fill?: string;
  title?: string;
}

const SVGIconEdit = ({ fill, title }: Props): ReactElement => {
  return (
    <SVGWrapper
      id="editIcon"
      height="100%"
      fillColor={fill || ""}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.19 20.19"
    >
      <title>{title}</title>
      <path
        d="M1.64,22.64V15.16l12-12a2.53,2.53,0,0,1,3.57,0l3.91,3.91a2.53,2.53,0,0,1,0,3.57l-12,12ZM3.75,16v4.48H8.23L19.59,9.16a.41.41,0,0,0,0-.58l-3.9-3.9a.41.41,0,0,0-.58,0Z"
        transform="translate(-1.64 -2.44)"
      />
      <rect x="13.59" y="6.45" width="2.11" height="6.34" transform="translate(-4.15 10.73) rotate(-45)" />
    </SVGWrapper>
  );
};

export default SVGIconEdit;

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
