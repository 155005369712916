import { useEffect, useState, useMemo, useCallback } from "react";
import * as atlasService from "azure-maps-rest";
import { Location } from "@sv/types";
import useDebounce from "../hooks/Utils/useDebounce";
import axios from "axios";
import { getBaseUrl } from "./../../services";

//The number of ms between key strokes to wait before performing a search.
const keyStrokeDelay = 150;

const locationSearchLimit = 10;

interface Props {
    searchValue?: string;
    initialLocation: Location | undefined;
}

interface ReturnType {
    searchResults: Array<LocationSearchResult>;
    chosenLocation: GeoJSON.FeatureCollection<GeoJSON.Point> | undefined;
    setSelectedLocation: (locationString: string | undefined) => void;
    error: boolean;
    clearSelectedLocation: () => void;
}

export type LocationSearchResult = atlasService.Models.SearchFuzzyResult;

const useLocationSearch = ({ searchValue, initialLocation }: Props): ReturnType => {
    // the list of autocomplete results
    const [results, setResults] = useState<GeoJSON.FeatureCollection<GeoJSON.Point>>();
    const [error, setError] = useState(false);

    //  the location which was clicked from searchbox
    const [chosenLocation, setChosenLocation] = useState<GeoJSON.FeatureCollection<GeoJSON.Point>>();

    const debouncedSearchValue = useDebounce(searchValue, keyStrokeDelay);

    const reverseAddressSearch = async (location: Location) => {
        const res = await searchForLocation(location.address);
        setChosenLocation(res.geojson.getFeatures());
    };

    const locationSearch = async (searchValue: string) => {
        setError(false);
        if (!searchValue) return;
        const res = await searchForLocation(searchValue);
        if (!res?.results?.length) setError(true);

        setResults(res.geojson.getFeatures());
    };

    // search for the location with debounce
    useEffect(() => {
        if (!debouncedSearchValue) {
            setResults(undefined);
            return;
        }

        locationSearch(debouncedSearchValue);
    }, [debouncedSearchValue]);

    // search for address given coordinates
    useEffect(() => {
        if (!initialLocation) return;
        reverseAddressSearch(initialLocation);
    }, [initialLocation]);

    const setSelectedLocation = (locationString: string | undefined) => {
        if (!locationString) {
            clearSelectedLocation();
            return;
        }
        // remove other bubbles from map
        const chosenFeature = results?.features.find(
            (feature) => getDisplayText(feature.properties as atlasService.Models.SearchFuzzyResult) === locationString,
        );
        const newFeatures = { ...results, features: [chosenFeature] } as GeoJSON.FeatureCollection<GeoJSON.Point>;
        setChosenLocation(newFeatures);
    };

    const clearSelectedLocation = useCallback(() => setChosenLocation(undefined), []);

    const searchResults = useMemo(() => getResultProperties(results), [results]);

    return {
        searchResults,
        chosenLocation,
        error,
        setSelectedLocation,
        clearSelectedLocation,
    };
};

export default useLocationSearch;

export const getLocationDetails = (location: GeoJSON.FeatureCollection<GeoJSON.Point> | undefined): Location | null => {
    const [longitude, latitude] = location?.features[0].geometry.coordinates ?? [];
    if (!latitude || !longitude) return null;
    const id = (location?.features[0].properties as LocationSearchResult).id as string;
    const address = getDisplayText(location?.features[0].properties as LocationSearchResult);
    return { id, address, geoLocation: { longitude, latitude } };
};

const getResultProperties = (
    results: GeoJSON.FeatureCollection<GeoJSON.Point> | undefined,
): Array<LocationSearchResult> => {
    if (!results) return [];
    return results?.features.map((feature) => feature.properties) as Array<LocationSearchResult>;
};

export const getDisplayText = (result: LocationSearchResult): string => {
    return result.poi ? `${result.poi.name}, ${result.address?.freeformAddress}` : `${result.address?.freeformAddress}`;
};

const searchForLocation = async (searchValue: string): Promise<atlasService.SearchFuzzyResponse> => {
    let results;
    const url = `${getBaseUrl()}//azure-maps/oauth2/token`;
        try {
            results = await axios.get(url);
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                console.log(error.cause);
            }
        }
        
        const mapCredentials = {
            accessToken: results?.data.access_token,
            msClientId: results?.data.ms_client_id
        };
        const pipeline = atlasService.MapsURL.newPipeline(
            new atlasService.TokenCredential(mapCredentials.msClientId, mapCredentials.accessToken),
        );
        const searchURL = new atlasService.SearchURL(pipeline);
        return searchURL.searchFuzzy(atlasService.Aborter.timeout(10000), searchValue, {
            limit: locationSearchLimit,
            view: "Auto",
            countrySet: ["AU"],
            typeahead: true,
        });
};
