import React, { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useStore } from "../../../services";
import { IncompatibleBrowser } from "../../Common";
import styled from "styled-components";
import { config, media } from "../../../config/Config";
import { observer } from "mobx-react";
import { browserName } from "react-device-detect";
import LoginForm from "../../../components/Common/Login/LoginForm/LoginForm";
import strings from "../../../smart-video/strings/strings.json";

const Login: FC = observer(() => {
    const store = useStore();

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();

    const queryError = query.get("error") ?? "";
    const queryLoginHint = query.get("login_hint") ?? "";

    const isUnauthorized = queryError === "unauthorized" && queryLoginHint !== "";
    const initialValue = isUnauthorized ? queryLoginHint : "";
    const initialState = isUnauthorized ? "unauthorised" : "default";
    const isAuthenticated = store.user.isAuthenticated();
    const { applayoutTopNavTitle } = strings.components.Layout.AppLayout;

    const isSupportedBrowser =
        config.supportedBrowsers.some((e) => e === browserName) || store.ui.isContinueIncompatibleBrowser;

    const generateDomainHint = (email: string) => {
        return email.split("@")[1];
    };

    const handleOnSubmit = (email: string) => {
        const domainHint = generateDomainHint(email);
        store.user.signin(domainHint, email);
    };

    const loginView = (
        <Container>
            <LeftContainer>
                <LeftContentContainer>
                    <LogoContainer>
                        <Logo data-testid="Logo" src={"/images/t-logo-blue.svg"} alt="Logo" />
                    </LogoContainer>
                    <WelcomeLine1 data-testid="WelcomeLine1">Welcome to</WelcomeLine1>
                    <WelcomeAlign>
                        <WelcomeLine2 data-testid="WelcomeLine2">{applayoutTopNavTitle}</WelcomeLine2>
                    </WelcomeAlign>
                    <Intro data-testid="Intro">
                        Telstra Spatial Insights allows you to fully understand how your space is being used.
                    </Intro>
                    <LoginForm initialValue={initialValue} initialState={initialState} onSubmit={handleOnSubmit} />
                </LeftContentContainer>
            </LeftContainer>
            <HeroContainer>
                <HeroImage data-testid="HeroImage" src={"/images/Landing_Page_Hero.jpg"} alt="Landing page image" />
            </HeroContainer>
        </Container>
    );

    const compatibleBrowser = !isAuthenticated ? loginView : <Navigate to={"/home"} replace />;

    return isSupportedBrowser ? compatibleBrowser : <IncompatibleBrowser />;
});

export default Login;

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    user-select: none;

    // Disable image selection
    img {
        pointer-events: none;
    }
    ${media.mobile} {
        flex-direction: column;
    }
`;

const LeftContainer = styled.div`
    min-width: calc(462px);
    width: calc(100% * (1 - 0.5515625));
    height: 100%;
    text-align: center;
    background: white;

    padding-top: 83px;
    padding-left: 61px;
    padding-right: 61px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${media.mobile} {
        padding: 48.5px 24.5px;
        min-width: auto;

        width: 80%;
        height: auto;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
    }
`;

const LeftContentContainer = styled.div`
    width: auto;
    height: 100%;
    ${media.mobile} {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const LogoContainer = styled.div`
    height: 51px;
    width: 44px;
    ${media.mobile} {
        text-align: center;
    }
`;

const Logo = styled.img`
    height: 51px;
    width: 44px;
`;

const WelcomeLine1 = styled.div`
    width: 100%;
    color: #3e3e3e;
    font-size: 36px;
    margin-top: 32px;
    text-align: left;
    ${media.mobile} {
        text-align: center;
        font-size: 30px;
        line-height: 36px;
    }
`;
const WelcomeAlign = styled.div`
    text-align: left;
    ${media.mobile} {
        text-align: center;
        font-size: 30px;
        font-weight: bold;
        line-height: 36px;
    }
`;
const WelcomeLine2 = styled.span`
    width: 100%;
    color: #0064d2;
    font-size: 48px;
    font-weight: bold;
    text-align: left;
    ${media.mobile} {
        text-align: center;
        font-size: 30px;
        font-weight: bold;
        line-height: 36px;
    }
`;

const Intro = styled.div`
    width: 100%;
    color: #3e3e3e;
    font-size: 22px;
    font-weight: 300;

    margin-top: 24px;
    margin-bottom: 32px;
    text-align: left;
    ${media.mobile} {
        text-align: center;
        line-height: 26px;
    }
`;

const HeroContainer = styled.div`
    width: 100%;
    height: 100%;
    text-align: center;
    object-fit: cover;
    ${media.mobile} {
        z-index: -999;
        filter: blur(5px);
    }
`;

const HeroImage = styled.img`
    width: 100%;
    height: 100%;
    text-align: center;
    object-fit: cover;
    ${media.mobile} {
        filter: blur(5px);
        transform: scale(1.1);
    }
`;
