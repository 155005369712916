import React from "react";
import { ClassTag } from "@sv/types";
import { Box, classTagBackground } from "telstra-components";
import { Tag as ClickableTag } from "telstra-ui/components/tag/Tag";
import strings from "../../strings/strings.json";
import styled from "styled-components";

const { classTagLabel } = strings.components.Modals.ClassModal;

export const getLabelText = ({ variable, value }: ClassTag): string => `${variable} = ${value}`;

interface ClassTagListProps {
    onTagClick: (tag: ClassTag) => void;
    tags: Array<ClassTag>;
    classes?: Array<string>;
    onClassClick?: (value: string) => void;
}

const ClassTagList = ({ tags, classes, onTagClick, onClassClick }: ClassTagListProps): JSX.Element => {
    const handleTagClick = (tag: ClassTag) => () => {
        onTagClick(tag);
    };

    const handleClassClick = (value: string) => () => {
        onClassClick && onClassClick(value);
    };

    return (
        <Box display="flex" flexWrap="wrap">
            {tags.map((tag) => {
                return (
                    <ClickableTag
                        key={tag.variable + tag.value}
                        label={getLabelText(tag)}
                        onCloseClick={handleTagClick(tag)}
                    />
                );
            })}
            {classes &&
                classes.map((item, index) => {
                    return (
                        <StyledClickableTag
                            key={index}
                            label={`${classTagLabel} = ${item}`}
                            onCloseClick={handleClassClick(item)}
                        />
                    );
                })}
        </Box>
    );
};

export default ClassTagList;

const StyledClickableTag = styled(ClickableTag)`
    background-color: ${classTagBackground};
`;
