import React from "react";
import { Tag } from "@sv/types";
import { Box, classTagBackground } from "telstra-components";
import { Tag as ClickableTag } from "telstra-ui/components/tag/Tag";
import strings from "../../strings/strings.json";
import styled from "styled-components";

const { classTagLabel } = strings.components.Modals.ClassModal;

export const getLabelText = (tag: Tag): string => `${(tag.displayValue || tag.name).toUpperCase()}`;

interface TagListProps {
    onTagClick: (tag: Tag) => void;
    tags: Array<Tag>;
    classes?: Array<string>;
    backgroundColor?: string;
    tagLineWidth?: number;
    onClassClick?: (value: string) => void;
}

const TagList = ({ tags, classes, backgroundColor, tagLineWidth, onTagClick, onClassClick }: TagListProps): JSX.Element => {
    const handleTagClick = (tag: Tag) => () => {
        onTagClick(tag);
    };

    const handleClassClick = (value: string) => () => {
        onClassClick && onClassClick(value);
    };

    return (
        <Container backgroundColor={backgroundColor} tagLineWidth={tagLineWidth}>
            <Box display="flex" flexWrap="wrap">
                {tags.map((tag) => {
                    return (
                        <div aria-label={`edit ${tag.displayValue} tag`} data-testid={tag.displayValue} key={tag.id}>
                            <ClickableTag
                                key={tag.id}
                                label={getLabelText(tag)}
                                onCloseClick={handleTagClick(tag)}
                                icon={tag.icon}
                            />
                        </div>
                    );
                })}
                {classes &&
                    classes.map((item, index) => {
                        return (
                            <StyledClickableTag
                                key={index}
                                label={`${classTagLabel} = ${item}`}
                                onCloseClick={handleClassClick(item)}
                            />
                        );
                    })}
            </Box>
        </Container>
    );
};

export default TagList;

const Container = styled.div<{backgroundColor?: string, tagLineWidth?: number}>`
    width: ${props => props.tagLineWidth ?? 592}px;
    .tl-tag-value {
        background-color: ${props => props.backgroundColor}
    }
`;

const StyledClickableTag = styled(ClickableTag)`
    background-color: ${classTagBackground};
`;
