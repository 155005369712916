import { Pipeline } from "@sv/types";

export const defaultPipeliene = {
    pipeline_name: "",
    pipeline_deviceId: "",
    pipeline_telemetry: {
        enabled: true,
        telemetry_configuration: {
            mqtt_broker_ip: "eclipseMosquitto",
            mqtt_broker_port: 1883,
        },
    },
    pipeline_modules: {
        input_module: {
            enabled: true,
            input_configuration: {
                input_uri: "",
                resolution: [1280, 720],
                resize_to: [960, 540],
                frame_rate: 30,
                buffer_size: 10,
            },
        },
        detector_module: {
            enabled: true,
            detector_configuration: {
                model: "YOLOv4Original",
                detector_frame_skip: 2,
                conf_thresh: 0.3,
                nms_thresh: 0.7,
                max_area: 800000,
                min_aspect_ratio: 1.2,
            },
            collector_configuration: {},
            telemetry_configuration: {
                enabled: false,
            },
        },
        tracker_module: {
            enabled: true,
            feature_extractor_configuration: [
                {
                    model: "OSNet025",
                    batch_size: 16,
                },
            ],
            tracking_configuration: {
                tracking_position: 1,
                max_age: 6,
                age_penalty: 2,
                motion_weight: 0.2,
                max_assoc_cost: 0.8,
                max_reid_cost: 0.6,
                iou_thresh: 0.4,
                duplicate_thresh: 0.8,
                occlusion_thresh: 0.7,
                conf_thresh: 0.5,
                confirm_hits: 1,
                history_size: 50,
                kalman_filter_cfg: {
                    std_factor_acc: 2.25,
                    std_offset_acc: 78.5,
                    std_factor_det: [0.08, 0.08],
                    std_factor_klt: [0.14, 0.14],
                    min_std_det: [4, 4],
                    min_std_klt: [5, 5],
                    init_pos_weight: 5,
                    init_vel_weight: 12,
                    vel_coupling: 0.6,
                    vel_half_life: 2,
                },
                flow_cfg: {
                    bg_feat_scale_factor: [0.1, 0.1],
                    opt_flow_scale_factor: [0.5, 0.5],
                    feat_density: 0.005,
                    feat_dist_factor: 0.06,
                    ransac_max_iter: 500,
                    ransac_conf: 0.99,
                    max_error: 100,
                    inlier_thresh: 4,
                    bg_feat_thresh: 10,
                    obj_feat_params: {
                        maxCorners: 1000,
                        qualityLevel: 0.06,
                        blockSize: 3,
                    },
                    opt_flow_params: {
                        winSize: [5, 5],
                        maxLevel: 5,
                        criteria: [3, 10, 0.03],
                    },
                },
            },
            telemetry_configuration: {
                enabled: true,
            },
        },
        line_module: {
            enabled: false,
            telemetry_configuration: {
                enabled: true,
            },
        },
        polygon_module: {
            enabled: false,
            telemetry_configuration: {
                enabled: true,
            },
        },
        visualiser_module: {
            enabled: true,
            visualiser_configuration: {
                draw_detections: true,
                draw_confidence: true,
                draw_covariance: false,
                draw_klt: false,
                draw_obj_flow: false,
                draw_bg_flow: false,
                draw_trajectory: true,
                draw_lines: true,
                draw_polygons: true,
                show_fps: true,
            },
        },
    },
} as Pipeline;
