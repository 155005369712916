import React, { FC } from "react";
import { AzureMapsProvider } from "react-azure-maps";
import { Box, greyL5, Heading16 } from "telstra-components";

import styled from "styled-components";
import MapController from "./MapController";
import strings from "../../strings/strings.json";

const { locationMapPlaceholder } = strings.components.LocationMap.LocationMap;

const locationMapHeight = "202px";

interface LocationMapProps {
    searchValue?: string;
    chosenLocation: GeoJSON.FeatureCollection<GeoJSON.Point> | undefined;
    mapCredentials: MapCredentials | undefined
}

interface LocationMapProps {
    searchValue?: string;
    chosenLocation: GeoJSON.FeatureCollection<GeoJSON.Point> | undefined;
}

interface MapCredentials {
    access_token: string,
    ms_client_id: string
}

const LocationMap: FC<LocationMapProps> = ({ searchValue, chosenLocation, mapCredentials }: LocationMapProps) => (
    <Box>
        {!searchValue || mapCredentials === undefined ? (
            <Box
                display="flex"
                height={locationMapHeight}
                backgroundColor={greyL5}
                justifyContent="center"
                alignItems="center"
            >
                <Heading16>{locationMapPlaceholder}</Heading16>
            </Box>
        ) : (
            <LocationMapBox>
                <AzureMapsProvider>
                    <MapController searchResult={chosenLocation} mapCredentials={mapCredentials as MapCredentials} />
                </AzureMapsProvider>
            </LocationMapBox>
        )}
    </Box>
);

export default LocationMap;

const LocationMapBox = styled(Box)`
    margin-top: 4px;
    height: ${locationMapHeight};
    .popup-close {
        display: none;
    }
    .popup-arrow {
        display: none;
    }
`;
