import React, { useState, ReactElement, useEffect, useRef } from "react";
import styled from "styled-components";
import {
    greyL1,
    greyL2,
    Heading24,
    Search,
    Box,
    PrimaryButton,
    TreeItem,
    EntryType,
    Row,
    Col,
} from "telstra-components";
import axios from "axios";

// import { observer } from "mobx-react-lite";

import { Menu } from "telstra-ui/components/menu-list/Menu";
import { Dropdown } from "telstra-ui/components/drop-down/Dropdown";

import { IconDownArrow } from "../../../Icons";

import { IconAdd, IconBillsAndPayments } from "./../../components/Icon";
// import withLayout from "./../../components/hocs/withLayout";
// import AppLayout from "./../../components/Layout/AppLayout";
import { filterData } from "./utils";
import useOrganisation from "./../../hooks/Organisation/useOrganisation";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import OrganisationModal from "../../components/Modals/OrganisationModal";
import WizardModal from "../../components/Wizard/WizardModal";
import useSite from "./../../hooks/Site/useSite";
// import SiteEditModal from "./../../components/Modals/SiteEditModal";
import useOrganisationTree from "./../../hooks/OrganisationTree/useOrganisationTree";
import DeviceEditModal from "../../components/Modals/DeviceEditModal/DeviceEditModal";
import GatewayEditModal from "./../../components/Modals/GatewayEditModal";
import { useStore } from "./../../store/useStore";

import useDevice from "./../../hooks/Device/useDevice";
import strings from "./../../strings/strings.json";
import useSiteTags, { getRemainingVariableOptions } from "./../../hooks/Site/useSiteTags";
// import TagModal from "./../../components/Modals/TagModal";
import { siteTagVariableOptions } from "./../../config";
import { useNavigate, useLocation } from "react-router-dom";
import useDeviceConfigs from "./../../hooks/DeviceConfigs/useDeviceConfigs";
import {TreeTable} from "../../../views/Administration/TreeTable/TreeTable";

import AnalyticsTagsWizardModal from "../../components/AnalyticsTagsWizard/AnalyticsTagsWizardModal";
import { getBaseUrl } from "../../../services";
import ConfigureCameraModal from "../../../components/Modal/ConfigureCamera/ConfigureCameraModal";

type ConfirmationMode =
    | "none"
    | "deleteSite"
    | "deleteOrganisation"
    | "deleteGateway"
    | "deleteDevice"
    | "deleteBlueprint";

interface EditItem {
    id: string;
    type: EntryType | string;
    name: string;
    siteId?: string;
    gatewayId?: string;
    deviceId?: string;
}

const populateEditItemParents = (editItem: EditItem, treeItem: TreeItem, data: Array<TreeItem>): EditItem => {
    // if (treeItem.type === "organisation") {
    //     editItem.organisationId = treeItem.id;
    //     editItem.parentOrganisationId = treeItem.parentId || treeItem.id;
    //     return editItem;
    // }
    if (treeItem.type === "site") {
        // editItem.organisationId = treeItem.parentId;
        // const parent = data.filter((entry) => entry.id === treeItem.parentId)[0];
        // return populateEditItemParents(editItem, parent, data);
        return editItem;
    }
    if (treeItem.type === "gateway") {
        editItem.siteId = treeItem.parentId;
        const parent = data.filter((entry) => entry.id === treeItem.parentId)[0];
        return populateEditItemParents(editItem, parent, data);
    }
    if (treeItem.type === "camera") {
        editItem.gatewayId = treeItem.parentId;
        const parent = data.filter((entry) => entry.id === treeItem.parentId)[0];
        return populateEditItemParents(editItem, parent, data);
    }
    if (treeItem.type === "blueprint") {
        editItem.deviceId = treeItem.parentId;
        const parent = data.filter((entry) => entry.id === treeItem.parentId)[0];
        return populateEditItemParents(editItem, parent, data);
    }

    return editItem;
};

const {
    orgsSearchFieldPlaceholder,
    orgsAddOrgButtonLabel,
    orgsAddSiteButtonLabel,
    orgsManageLicensesButtonLabel,
    deleteConfirmationTitle,
    deleteConfirmationBodyPara1,
    deleteConfirmationBodyPara2,
    noButtonLabel,
    yesButtonLabel,
    iconsTitles,
} = strings.views.Organisations;

const { description, heading, valueTooltip, variableTooltip } = strings.components.Modals.TagModal.SiteTag;

interface LocationState {
    expandId: string;
}

export const Organisations = (): ReactElement => {
    // const { permissionsStore } = useStore();
    // const { permissions } = permissionsStore;

    const permissions = {
        ViewOrgPage: true,
        ViewOrgs: true,
        CreateOrgs: false,
        ViewLicenses: true,
        ManageLicenses: false,
        ViewSites: true,
        CreateSites: true,
        ViewGateways: true,
        CreateGateways: true,
        ViewDevices: true,
        CreateDevices: true,
        ViewDashboard: true,
        ManageUsers: true,
    };

    const location = useLocation().state as LocationState;
    const navigate = useNavigate();

    const [searchValue, setSearchValue] = useState("");
    const [confirmationMode, setConfirmationMode] = useState("none" as ConfirmationMode);

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showWizardModal, setShowWizardModal] = useState(false);

    const [filteredData, setFilteredData] = useState<Array<TreeItem>>([]);

    const [showOrgModal, setShowOrgModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editItem, setEditItem] = useState<EditItem>();

    const { setNewOrganisation, editOrganisation, deleteOrganisation } = useOrganisation();
    const { deleteDeviceConfig } = useDeviceConfigs();

    const [showSiteEditModal, setShowSiteEditModal] = useState(false);
    const { deleteSite } = useSite();

    const { deleteDevice, editDevice } = useDevice();
    const { mainOrganisation, treeData, refreshTreeData } = useOrganisationTree();

    const [showGatewayEditModal, setShowGatewayEditModal] = useState(false);
    const [showDeviceEditModal, setShowDeviceEditModal] = useState(false);

    const [isAdminMenuShowing, setIsAdminMenuShowing] = useState(false);
    const adminMenu = useRef(undefined as HTMLDivElement | undefined);

    const [showAnalyticsTagsWizardModal, setShowAnalyticsTagsWizardModal] = useState(false);

    const {
        selectedTags,
        handleTagModalToggle,
        tagModalVisible,
        handleTagDeselect,
        resetSelectedTags: resetSiteTags,
        handleTagSelect,
    } = useSiteTags();

    const remainingVariableOptions = getRemainingVariableOptions(siteTagVariableOptions, selectedTags);

    useEffect(() => {
        setFilteredData(filterData(searchValue, treeData, permissions));
    }, [searchValue, treeData]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const handleAddOrganisation = () => {
        setShowOrgModal(true);
        setIsEditMode(false);
    };

    const handleAddSite = () => setShowWizardModal(true);

    const onResetWizard = () => {
        setEditItem(undefined);
        setShowWizardModal(false);
        resetSiteTags();
    };

    const onSubmitWizard = () => refreshTreeData();

    const onSubmitOrganisation = (organisationName: string, parentId: string) => {
        if (isEditMode && editItem) {
            editOrganisation(parentId, editItem.id, organisationName)?.then(() => {
                setEditItem(undefined);
                refreshTreeData();
            });
        } else {
            setNewOrganisation(parentId, organisationName)?.then(() => {
                refreshTreeData();
            });
        }
        setShowOrgModal(false);
    };

    const onResetOrganisation = () => {
        setEditItem(undefined);
        setShowOrgModal(false);
    };

    const onSubmitSite = () => {
        if (editItem) {
            setEditItem(undefined);
            refreshTreeData();
        }
        setShowSiteEditModal(false);
    };

    const onResetSiteEdit = () => {
        setEditItem(undefined);
        setShowSiteEditModal(false);
    };

    const onResetGatewayEdit = () => {
        setEditItem(undefined);
        setShowGatewayEditModal(false);
    };

    const onSubmitGatewayEdit = () => {
        if (editItem) {
            setEditItem(undefined);
            refreshTreeData();
        }
        setEditItem(undefined);
        setShowGatewayEditModal(false);
    };

    const onResetDeviceEdit = () => {
        setEditItem(undefined);
        setShowDeviceEditModal(false);
    };

    const onSubmitDeviceEdit = (
        deviceName: string,
        deviceModuleName: string,
        deviceUrl?: string,
        deviceUsername?: string,
        devicePassword?: string,
    ) => {
        if (editItem && editItem.siteId && editItem.gatewayId) {
            editDevice(
                deviceName,
                editItem.siteId,
                editItem.id,
                "camera",
                deviceModuleName,
                editItem.gatewayId,
                deviceUrl,
                deviceUsername,
                devicePassword,
            )?.then(() => {
                if (!updateEditItem) return;
                const { siteId, gatewayId, name: configName, id } = editItem;
                navigateToCameraConfig({
                    siteId: siteId ?? "",
                    gatewayId: gatewayId ?? "",
                    deviceId: id ?? "",
                    configName,
                });
                setEditItem(undefined);
                refreshTreeData();
            });
        }
        setEditItem(undefined);
        setShowDeviceEditModal(false);
    };

    const updateEditItem = (id: string) => {
        const item = treeData.find((entry) => entry.id === id);

        if (item) {
            const newEditItem = populateEditItemParents({ id: id, type: item.type, name: item.text }, item, treeData);
            setEditItem(newEditItem);
            return newEditItem;
        }
    };

    const handleTreeTableAdd = (id: string, type: EntryType) => {
        updateEditItem(id);
        setShowWizardModal(true);
    };

    const navigateToCameraConfig = (params: {
        siteId: string;
        gatewayId: string;
        deviceId: string;
        configName: string;
    }) => {
        navigate("/administration/cameraconfig", { state : params});
    };

    const handleTreeTableEdit = (id: string, type: EntryType) => {
        const updatedEditItem = updateEditItem(id);

        // TODO: re-enable once edit is reimplemented.
        // switch (type) {
        //     case "organisation":
        //         setIsEditMode(true);
        //         setShowOrgModal(true);
        //         break;
        //     case "site":
        //         setShowSiteEditModal(true);
        //         break;
        //     case "camera":
        //         setShowDeviceEditModal(true);
        //         break;
        //     case "gateway":
        //         setShowGatewayEditModal(true);
        //         break;
        //     case "blueprint": {
        //         if (!updatedEditItem) return;
        //         const { siteId, gatewayId, deviceId, name: configName } = updatedEditItem;
        //         navigateToCameraConfig({
        //             siteId: siteId ?? "",
        //             gatewayId: gatewayId ?? "",
        //             deviceId: deviceId ?? "",
        //             configName,
        //         });
        //         break;
        //     }
        // }
    };

    const handleTreeTableDelete = (id: string, type: EntryType) => {
        updateEditItem(id);

        switch (type) {
            case "organisation":
                setConfirmationMode("deleteOrganisation");
                break;
            case "site":
                setConfirmationMode("deleteSite");
                break;
            case "camera":
                setConfirmationMode("deleteDevice");
                break;
            case "gateway":
                setConfirmationMode("deleteGateway");
                break;
            case "blueprint":
                setConfirmationMode("deleteBlueprint");
                break;
        }
        setShowConfirmModal(true);
    };

    const handleConfirmationNoClick = () => setShowConfirmModal(false);

    const handleConfirmationYesClick = async () => {
        if (!editItem) return;
        if (confirmationMode === "deleteSite") {
            deleteSite(editItem.id)?.then(() => {
                refreshTreeData();
            });
            setEditItem(undefined);
        } else if (confirmationMode === "deleteOrganisation") {
            deleteOrganisation(editItem.id)?.then(() => {
                refreshTreeData();
            });
        } else if (confirmationMode === "deleteDevice") {
            deleteDevice(editItem.id)?.then(() => {
                refreshTreeData();
            });
        } else if (confirmationMode === "deleteGateway") {
            deleteDevice(editItem.id)?.then(() => {
                refreshTreeData();
            });
        } else if (confirmationMode === "deleteBlueprint") {
            const { name: module, gatewayId, deviceId } = editItem;
            if (!(module && gatewayId && deviceId)) return;

            await deleteDeviceConfig(module, gatewayId, deviceId);
            refreshTreeData();
        }
        setShowConfirmModal(false);
    };

    const getWizardInitialStep = () => {
        if (!editItem) return 0;

        switch (editItem.type) {
            // case "organisation":
            //     return 1;
            case "site":
                return 2;
            case "gateway":
                return 3;
            case "camera":
                return 4;
            // case "blueprint":
            //     return 5;
            default:
                return 0;
        }
    };

    const insertInTemplate = (template: string, value: string) => {
        return template.includes("{}") ? template.replace("{}", value) : template;
    };

    return (
        <Container>
            <OrganisationModal
                isOpen={showOrgModal}
                isEditMode={isEditMode}
                parentId={""}
                organisationName={editItem?.name || ""}
                onSubmit={onSubmitOrganisation}
                onReset={onResetOrganisation}
            />

            <ConfirmationModal
                isOpen={showConfirmModal}
                title={insertInTemplate(deleteConfirmationTitle, editItem?.name || "")}
                bodyParagraph1={insertInTemplate(deleteConfirmationBodyPara1, editItem?.name || "")}
                bodyParagraph2={insertInTemplate(deleteConfirmationBodyPara2, editItem?.name || "")}
                noLabel={noButtonLabel}
                onNoClick={handleConfirmationNoClick}
                yesLabel={yesButtonLabel}
                onYesClick={handleConfirmationYesClick}
            />

            {showWizardModal && (
                <WizardModal
                    isOpen={!tagModalVisible}
                    onCancel={onResetWizard}
                    onSubmit={onSubmitWizard}
                    siteId={editItem?.type === "site" ? editItem?.id : editItem?.siteId}
                    gatewayId={editItem?.type === "gateway" ? editItem?.id : editItem?.gatewayId}
                    deviceId={editItem?.type === "camera" ? editItem?.id : undefined}
                    initialStep={getWizardInitialStep()}
                    onSiteModalOpen={handleTagModalToggle(true)}
                    selectedTags={selectedTags}
                    onTagSelect={handleTagSelect}
                    onTagDeselect={handleTagDeselect}
                    // tagModalVisible={tagModalVisible}
                />
            )}

            {showAnalyticsTagsWizardModal && (
                <AnalyticsTagsWizardModal
                    isOpen={!tagModalVisible}
                    onCancel={() => {
                        setShowAnalyticsTagsWizardModal(false);
                    }}
                />
            )}

            {/* {tagModalVisible && (
                <TagModal
                    description={description}
                    heading={heading}
                    valueTooltip={valueTooltip}
                    variableTooltip={variableTooltip}
                    onTagModalToggle={handleTagModalToggle(false)}
                    onSubmit={handleTagSelect}
                    variableOptions={remainingVariableOptions}
                />
            )} */}

            {/* {showSiteEditModal && (<SiteEditModal
                isOpen={showSiteEditModal}
                siteId={editItem?.id}
                onSubmit={onSubmitSite}
                onReset={onResetSiteEdit}
            />)} */}

            {showGatewayEditModal && (
                <GatewayEditModal
                    isOpen={true}
                    deviceId={editItem?.id || ""}
                    onSubmit={onSubmitGatewayEdit}
                    onReset={onResetGatewayEdit}
                />
            )}

            {showDeviceEditModal && (
                <ConfigureCameraModal
                    isOpen={showDeviceEditModal}
                    gatewayId={editItem?.gatewayId || ""}
                    deviceId={editItem?.id || ""}
                    onCancel={() => setShowDeviceEditModal(false)}
                    onSubmit={onSubmitWizard}
                />
            )}

            <Heading24 data-cy="title-organisation-name" color={greyL1}>
                {mainOrganisation?.text}
            </Heading24>
            <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="m">
                <Row>
                    <Col md={1.5}>
                        <Search
                            placeholder={orgsSearchFieldPlaceholder}
                            value={searchValue}
                            onChange={handleSearchChange}
                            aria-label="search-org__input"
                            style={{
                                // fontWeight: 300,
                                // fontSize: 18,
                                // color: "#414141",
                                // letterSpacing: -0.15,
                                // lineHeight: "25px",
                            }}
                        />
                    </Col>
                    <Col md={6.5} align="flex-end">
                        <Box display="flex">
                            {permissions.CreateOrgs && (
                                <PrimaryButton
                                    size="large"
                                    label={orgsAddOrgButtonLabel}
                                    icon={
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            width="100%"
                                            height="8px"
                                        >
                                            <IconAdd />
                                        </Box>
                                    }
                                    iconPosition="right"
                                    onClick={handleAddOrganisation}
                                />
                            )}
                            {permissions.ManageLicenses && (
                                <PrimaryButton
                                    size="large"
                                    label={orgsManageLicensesButtonLabel}
                                    icon={<IconBillsAndPayments />}
                                    iconPosition="right"
                                />
                            )}
                            <MenuOptionsIconContainer
                                id={`admin-menu-button`}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setIsAdminMenuShowing(true);
                                    (
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        adminMenu.current as any
                                    )?.toggleMenuList();
                                }}
                            >
                                <MenuButtonTitle>
                                    Administrator menu
                                </MenuButtonTitle>
                                <IconContainer>
                                    <IconDownArrow fill={"#0064d2"} />
                                </IconContainer>
                                {isAdminMenuShowing && (
                                    <MenuContainer
                                        onMouseLeave={() => {
                                            setIsAdminMenuShowing(false);
                                        }}
                                    >
                                        <Menu
                                            ref={(el: HTMLDivElement) => {
                                                adminMenu.current = el;
                                            }}
                                            menuListId={`admin-menu-dropdown`}
                                            selectedDropdown={() => {
                                                setIsAdminMenuShowing(false);
                                            }}
                                            buttonId={`admin-menu-button`}
                                            menuItems={[
                                                { displayText: "Create analytics tags", itemId: "analytics-tags-add" },
                                                // { displayText: "Edit analytics tags", itemId: "analytics-tags-edit" },
                                                { displayText: "Add a new site", itemId: "site-add" },
                                            ]}
                                            onOptionClick={(event: Event) => {
                                                const selectedOptionName = (event.target as HTMLElement).innerText;

                                                if (selectedOptionName === "Create analytics tags") {
                                                    // window.alert("In development.");
                                                    setShowAnalyticsTagsWizardModal(true);
                                                } else if (selectedOptionName === "Edit analytics tags") {
                                                    window.alert("In development.");
                                                } else if (selectedOptionName === "Add a new site") {
                                                    handleAddSite();
                                                }
                                                // if (onPerformAction) {
                                                //     onPerformAction(e, (event.target as HTMLElement).innerText);
                                                // }
                                            }}
                                        />
                                    </MenuContainer>
                                )}
                            </MenuOptionsIconContainer>
                        </Box>
                    </Col>
                </Row>
            </Box>
            {/* <TreeTable */}
            <TreeTable
                items={filteredData}
                expandAll={searchValue.length > 0}
                expands={(!searchValue.length && [location?.expandId]) || undefined}
                onPerformAction={(item, actionName) => {
                    console.log(item, actionName);
                    if (item.type === "site" && actionName === "Edit site details") {
                        window.alert("In development.");
                        // console.log(item.type)
                        // handleTreeTableEdit(item.id, item.type);
                    } else if (item.type === "site" && actionName === "Edit site analytics tags") {
                        window.alert("In development.");
                        // handleTreeTableEdit(item.id, item.type);
                    } else if (item.type === "site" && actionName === "Add gateway") {
                        window.alert("In development.");
                        // handleTreeTableAdd(item.id, item.type);
                    } else if (item.type === "site" && actionName === "DEBUG: Delete site") {
                        // handleTreeTableDelete(item.id, item.type);

                        axios.get<TreeItem[]>(`${getBaseUrl()}/caas/organisations/overview`)
                            .then(async (treeItemsResponse) => {
                                // TODO: if backend doesn't handle automatically, delete all subitems manually.
                                // const treeItems = treeItemsResponse.data;
                                // const gateways = treeItems.filter(e => e.parentId === item.id);
                                // const cameras = treeItems.filter(e => gateways.some(gateway => gateway.id === e.parentId));

                                // console.log("gateways", gateways);
                                // console.log("cameras", cameras);

                                // await Promise.all(cameras.map(e => deleteDevice(e.id)));
                                // await Promise.all(gateways.map(e => deleteDevice(e.id)));

                                await deleteSite(item.id);
                                await refreshTreeData();

                                // const gatewayIdLookup = treeItems.data
                                //     .filter(e => e.type === "gateway")
                                //     .reduce((a, e) => ({ ...a, [e.id]: true }), {} as { [gatewayId: string]: boolean })
                                // setAvailableSerialNumbers(availableSerialNumbersResponse.data.filter(e => !gatewayIdLookup[e.value]));
                                // console.log("reduced availableSerialNumbersResponse length", availableSerialNumbersResponse.data.filter(e => !gatewayIdLookup[e.value]).length);
                            });


                        // deleteSite(item.id)?.then(() => {
                        //     refreshTreeData();
                        // });
                    } else if (item.type === "gateway" && actionName === "Edit gateway details") {
                        window.alert("In development.");
                        // handleTreeTableEdit(item.id, item.type);
                    } else if (item.type === "gateway" && actionName === "Add camera to gateway") {
                        window.alert("In development.");
                        // handleTreeTableAdd(item.id, item.type);
                    } else if (item.type === "camera" && actionName === "Configure camera") {
                        handleTreeTableEdit(item.id, item.type);
                    }
                }}
            />
        </Container>
    );
};

// export default withLayout(AppLayout)(observer(Organisations));

const Container = styled.div`
    margin-top: 56px;
    margin-left: 115px;
    margin-right: 115px;
`;

const MenuButtonTitle = styled.div`
    font-size: 22px;
    color: #0064d2;
    line-height: 31px;
    letter-spacing: -0.42px;
`;

const MenuOptionsIconContainer = styled.div.attrs({ className: "MenuOptionsIconContainer" })`
    height: 44px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    right: 60px;
    > div {
        margin-right: 0px;
    }
`;

const MenuContainer = styled.div`
    width: 210px;
    margin-left: -210px;
    position: relative;
    top: calc(10px + 18px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    > div {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-end;

        > div {
            width: 200% !important;
        }

        .tl-menu-list-options {
            width: 100% !important;
        }

        .tl-menu-list-option {
            width: 95% !important;
            height: 100% !important;
        }
    }

    #admin-menu-dropdown-menu-list-options {
        > :nth-child(1) {
            cursor: pointer;
        }

        > :nth-child(2) {
            pointer-events: none;
            cursor: default;
            color: ${greyL2};
        }

        cursor: default;
    }
`;

const IconContainer = styled.div`
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    margin-top: 4px;

    > div {
        margin: 0px;
    }
`;
