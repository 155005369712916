import React, { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Box, Body14, useFormikContext, Row } from "telstra-components";
import { Button } from "telstra-ui/components/button/Button";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore 1
import { TextInput } from "telstra-ui/components/text-input/TextInput";

export interface TextFieldProps {
    name: string;
    helperText?: string;
    placeholder?: string;
    disabled?: boolean;
    password?: boolean;
    confirmButtonText?: string;
    errorText?: string;
    maxLength?: number;
    initialValue?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: KeyboardEvent) => void;
    onClickConfirmButton?: (textInput: HTMLInputElement) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractByString = (o: any, s: string): any => {
    if (!s.includes(".")) {
        return o[s];
    }
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    const a = s.split(".");
    for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }

    return o;
};

const TextField = (props: TextFieldProps): ReactElement => {
    const { name, helperText, placeholder, disabled, password, confirmButtonText, errorText, maxLength, initialValue, onChange, onKeyDown, onClickConfirmButton } = props;
    const [showPassword, setShowPassword] = useState(password);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { values, handleChange, handleBlur, touched, errors } = useFormikContext<any>();
    const passwordHelperText = showPassword ? "Show password" : "Hide password";

    const textInputRef = useRef<HTMLInputElement>();

    const [shouldShowConfirmButton, setShouldShowConfirmButton] = useState(false);

    const handlerShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(e);
        if (!textInputRef.current) {
            textInputRef.current = e.target;
        }
        // console.log("e.target.value.length", e.target.value.length)
        setShouldShowConfirmButton((e.target.value.length ?? 0) > 0);
        onChange && onChange(e);
    };

    useEffect(() => {
        const callback = (event: Event) => {
            setShouldShowConfirmButton(((event.target as HTMLInputElement).value.length ?? 0) > 0);
        };
        textInputRef.current?.addEventListener('change', callback);

        return () => {
            textInputRef.current?.removeEventListener("change", callback);
        }
    }, [textInputRef.current])

    return (
        <Root data-testid={`text-field__${name}`}>
            <TextRowContainer>
                {/* <TextInputContainer> */}
                    {/* {"Gateway 1"} */}
                    <TextInput
                        name={name}
                        value={extractByString(values, name)}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        errors={
                            errorText ? [errorText] : extractByString(touched, name) && extractByString(errors, name) && [extractByString(errors, name)]
                        }
                        variant="basic"
                        type={(password && showPassword && "password") || "text"}
                        placeholder={placeholder}
                        assistiveText={helperText}
                        disabled={disabled}
                        maxLength={maxLength}
                        onKeyDown={onKeyDown}
                    />
                    {confirmButtonText && shouldShowConfirmButton && 
                        <ButtonContainer>
                            <Button
                                variant="secondary"
                                size="medium"
                                label={confirmButtonText}
                                onClick={() => {
                                    if (onClickConfirmButton) {
                                        onClickConfirmButton(textInputRef.current!);
                                    }
                                }}
                            />
                        </ButtonContainer>
                    }
                {/* </TextInputContainer> */}
            </TextRowContainer>
            <Box display="flex" justifyContent="space-between">
                {password && (
                    <StyledBody14 aria-label={passwordHelperText} onClick={handlerShowPassword}>
                        {passwordHelperText}
                    </StyledBody14>
                )}
            </Box>
        </Root>
    );
};

export default TextField;

const Root = styled.div`
    letter-spacing: -0.13px;
    line-height: 22px;

    .tl-input {
        margin-top: 0;
        width: 100%;
    }
`;

const StyledBody14 = styled(Body14)`
    font-style: italic;
    cursor: pointer;
    color: #414141;
`;

const TextRowContainer = styled.div`
    position: relative;
    // // margin-left: 100px;
    // border-bottom: 1px solid #757575;
    // background: red;
    // // border-color: red;
    // height: 42px;
`;

const TextInputContainer = styled.div`
    // position: relative;
    margin-left: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: flex-start;
    height: 42px;
`;

const ButtonContainer = styled.div`
    position: absolute;
    top: -6px;
    right: 12px;
`;
