import React, { FC, useEffect, useState } from "react";
import { Tag } from "@sv/types";
import { Body16, Box, Heading20, Heading34, Modal } from "telstra-components";
import { Button } from "telstra-ui/components/button/Button";
import { greyL1, blueL1 } from "../../../themes/Colors";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Spinner } from "telstra-ui/components/spinner/spinner";
import { Stepper } from "telstra-ui/components/path-stepper/PathStepper";
import styled from "styled-components";

import TagsForm from "./TagsForm";
import { TagManager, TagManagerService } from "../../../services/WebService/TagManagerService";
import { TagInfoModalContent } from "../../../content/TagInfoModalContent";

interface AnalyticsTagsWizardModalProps {
    isOpen: boolean;
    onCancel?: () => void;
}

enum CurrentStep {
    ADD_LARGE_AREA_TAGS = 0,
    ADD_CROSSING_TAGS,
    ADD_DWELL_AREA_TAGS,
}

export enum SiteAnalyticsTagsFormFlowPage {
    CONFIGURE_SITE_REGION_TAGS = 0,
    CONFIGURE_SITE_TYPE_TAGS,
}

const AnalyticsTagsWizardModal: FC<AnalyticsTagsWizardModalProps> = ({
    isOpen,
    onCancel,
}: AnalyticsTagsWizardModalProps) => {
    const initialStep = CurrentStep.ADD_LARGE_AREA_TAGS;
    const [currentStep, setCurrentStep] = useState<CurrentStep>(initialStep);

    const [selectedTags, setSelectedTags] = useState<Array<Tag>>([]);
    const handleTagSelect = async (tag: Tag) => {
        const newTags = await TagManagerService().getTags();
        setSelectedTags(newTags);
    };
    const handleTagDeselect = async (tag: Tag) => {
        await TagManagerService().deleteTag(tag);
        const newTags = await TagManagerService().getTags();
        setSelectedTags(newTags);
    };

    useEffect(() => {
        let isCancelled = false;

        TagManagerService().getTags().then(newTags => {
            if (!isCancelled) {
                setSelectedTags(newTags);
            }
        });

        return () => {
            isCancelled = true;
        };
    }, []);

    const handleConfirmationYesClick = () => {
        setCurrentStep(initialStep);
        onCancel && onCancel();
    };

    const handleSaveAndNextClick = () => {
        handleConfirmationYesClick();
    };

    // Use this if we want to have a batch save option in future.
    const saveNextLoading = false;

    return (
        <Modal isOpen={isOpen} showCloseButton={false} size="large">
            <Container>
                <CloseButtonContainer>
                    <Button
                        iconPosition="left"
                        icon={"icon-system-close"}
                        variant="primary"
                        size="medium"
                        onClick={() => {
                            handleConfirmationYesClick();
                        }}
                    />
                </CloseButtonContainer>
                <Box marginTop="s" marginLeft="l" marginRight="l" marginBottom="s">
                    <Heading34>Manage analytics tags</Heading34>
                    <StepperContainer>
                        <Stepper
                            activeStep={currentStep + 1}
                            step={TagInfoModalContent.map(e => ({ label: e.name }))}
                            showTextOnCompletion={true}
                            pathStepClicked={(stepIndex: number) => setCurrentStep(stepIndex - 1)}
                        />
                    </StepperContainer>
                    <ScrollContainer>
                        <Box style={{overflowX: "hidden", height: 493}} marginTop="m">
                            <Box marginLeft="xxxl" marginRight="xxxl">
                                <Box marginLeft="xxxl" marginRight="xxxl">
                                    <Heading20>
                                        {TagInfoModalContent[currentStep].title}
                                    </Heading20>
                                    <Body16>
                                        {TagInfoModalContent[currentStep].description}
                                    </Body16>
                                    <div>
                                        <Body16>
                                            {TagInfoModalContent[currentStep].descriptionLimit}
                                        </Body16>
                                    </div>
                                </Box>
                                <Box marginTop="xxs">
                                    <TagsForm
                                        tagFieldMatcherKeys={TagInfoModalContent[currentStep].tagFieldMatcherKeys}
                                        tagCategoryName={TagInfoModalContent[currentStep].name}
                                        assetName={TagInfoModalContent[currentStep].assetName}
                                        helperHint={TagInfoModalContent[currentStep].helperHint}
                                        description={TagInfoModalContent[currentStep].helperDescription}
                                        example={TagInfoModalContent[currentStep].helperExample}
                                        visualSrc={TagInfoModalContent[currentStep].helperVisualSrc}
                                        selectedTags={selectedTags}
                                        onTagSelect={handleTagSelect}
                                        onTagDeselect={handleTagDeselect}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </ScrollContainer>
                    <Box style={{position: "absolute", bottom: 32, left: 48, right: 48}}>
                        <Box display="flex" marginTop="xs" justifyContent="space-between">
                            <Box display="inline-flex">
                                <Box marginRight="xxs">
                                    { false &&
                                        <BackButtonContainer>
                                            <Button
                                                iconPosition="left"
                                                icon={"icon-system-arrow"}
                                                variant="primary"
                                                size="medium"
                                                label={"Back"}
                                                onClick={() => {
                                                    setCurrentStep((current) => current - 1)
                                                }}
                                            />
                                        </BackButtonContainer>
                                    }
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center">
                                {!saveNextLoading ? (
                                    <Button
                                        variant="primary"
                                        size="medium"
                                        label={"Save & Close"}
                                        onClick={handleSaveAndNextClick}
                                        disabled={false}
                                    />
                                ) : (
                                    <Box marginRight="m">
                                        <Spinner size="tl-spinner-medium" />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Modal>
    );
};

export default AnalyticsTagsWizardModal;

const Container = styled.div`
    width: 1024px;
    height: 795px;
`;

const StepperContainer = styled.div`
    svg {
        display: none;
    }

    .tl-path-container-block .tl-path-complete .tl-path-stepper-bottom {
        background: #d8d8d8;
    }

    .tl-path-container-block .tl-path-stepper {
        width: unset;
        height: calc(64px - 2px);
    }

    .tl-path-parent {
        width: 100%;
        height: 100%;
    }

    .tl-path-container-block {
        height: 64px;
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }

    // -- removes mobile styling to render state instead of label
    .tl-path-container-block .tl-path-stepper > .tl-path-mobileView {
        display: none;
    }

    // -- force render desktop content in mobile
    @media only screen and (max-width: 769px) {
        .tl-path-container-block .tl-path-stepper > .tl-path-desktopView {
            display: block;
            font-size: 16px;
        }
    }

    .tl-path-content {
        font-size: 16px;
    }

    .tl-path-container-block .tl-path-active .tl-path-content {
        color: ${blueL1};
        font-size: 16px;
    }

    .tl-path-container-block .tl-path-active .tl-path-bottom {
        background: ${blueL1};
    }

    .tl-path-container-block .tl-path-bottom {
        margin-left: 4px;
        margin-right: 4px;
    }
`;

const CloseButtonContainer = styled.div`
    position: absolute;
    top: 18px;
    right: 18px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        fill: ${greyL1};
        margin: 0px !important;
        left: 0px !important;
    }
    button {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    span {
        width: 24px;
        height: 24px;
    }
`;

const BackButtonContainer = styled.div`
    svg {
        transform: rotate(180deg);
    }
`;

const ScrollContainer = styled.div`
    > div {
        overflow-y: auto;
    }
`;
