import React, { FC } from "react";
import { Body18, Box, Col, Row, greyL1 } from "telstra-components";
import { ToolTip } from "telstra-ui/components/tool-tip/Tooltip";
import CustomTextField from "../Fields/CustomTextField";

interface CustomTextFieldRowProps {
    label: string;
    tooltipText?: string;
    placeholder?: string;
    fieldName?: string;
    password?: boolean;
    disabled?: boolean;
    helperText?: string;
    confirmButtonText?: string;
    errorText?: string;
    maxLength?: number;
    prefixLabel?: string;
    value?: string;
    defaultValue?: string;
    labelFocusInput?: boolean;
    onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onClickConfirmButton?: (textInput: HTMLInputElement) => void;
    onClickLabelTooltip?: () => void;
}

const CustomTextFieldRow: FC<CustomTextFieldRowProps> = ({
    label,
    tooltipText,
    fieldName,
    placeholder,
    password,
    disabled,
    helperText,
    confirmButtonText,
    errorText,
    maxLength,
    prefixLabel,
    value,
    defaultValue,
    labelFocusInput = false,
    onInputChange: handleChange,
    onKeyDown,
    onClickConfirmButton,
    onClickLabelTooltip,
}: CustomTextFieldRowProps) => {
    return (
        <Row>
            <Col md={3} noPadding>
                <Box display="flex" marginTop="xxxs">
                    <Box flex="1">
                        <Body18 aria-label={label} regular color={greyL1} style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            {label}
                            {onClickLabelTooltip && (
                                <Box style={{marginLeft: 12, marginTop: -4}}>
                                    <ToolTip
                                        description={tooltipText}
                                        id={`${fieldName}_tooltip`}
                                        iconName="icon-system-info"
                                        onTooltipToggle={onClickLabelTooltip}
                                    />
                                </Box>
                            )}
                        </Body18>
                    </Box>
                    {/* {tooltipText && (
                        <Box>
                            <ToolTip
                                description={tooltipText}
                                id={`${fieldName}_tooltip`}
                                iconName="icon-system-info"
                            />
                        </Box>
                    )} */}
                </Box>
            </Col>
            <Col md={5} sm={5} justify="center" noPadding>
                <CustomTextField
                    name={fieldName}
                    placeholder={placeholder}
                    password={password}
                    disabled={disabled}
                    onChange={handleChange}
                    helperText={helperText}
                    confirmButtonText={confirmButtonText}
                    errorText={errorText}
                    maxLength={maxLength}
                    prefixLabel={prefixLabel}
                    value={value}
                    defaultValue={defaultValue}
                    onKeyDown={onKeyDown}
                    onClickConfirmButton={onClickConfirmButton}
                    id={`customTextField_${label}`}
                />
            </Col>
        </Row>
    );
};

export default CustomTextFieldRow;
