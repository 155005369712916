import React, { FC } from "react";
import { Dashboard } from "../../../layouts";
import { observer } from "mobx-react";
import styled from "styled-components";
import { CameraConfig as SVCameraConfig } from "../../../smart-video/views/CameraConfig/CameraConfig";

const CameraConfig: FC = observer(() => {
    return (
        <Dashboard>
            <Container>
                <SVCameraConfig />
            </Container>
        </Dashboard>
    );
});

export default CameraConfig;

const Container = styled.div`
    padding: 0 24px 18px 24px;
`;
