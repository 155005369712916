/**
 * Recursively flattens an array
 * @param array An array of objects and/or nested arrays
 * @returns A flattened array
 */
export const flatten = <A, B extends ConcatArray<A>>(array: Array<B>): Array<A> =>
    array.reduce((flat, toFlatten) => {
        return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    }, [] as Array<A>);

/**
 * Merges two objects
 * @param a The first object
 * @param b The second object
 * @returns A new object with values from both objects
 */
export const merge = <A, B>(a: A, b: B): A & B => ({
    ...a,
    ...b,
});

/**
 * Executes a list of functions sequentially
 * @param fns A list of functions
 * @returns
 */
export const pipe =
    <T>(...fns: Array<(arg: T) => T>) =>
    (value: T): T =>
        fns.reduce((acc, fn) => fn(acc), value);

/**
 * @param data An array of objects with the shape `{ level: number }`
 * @returns The maximum depth/level of an array, 0 if data is empty
 */
export const computeMaxDepth = (data: Array<{ level?: number }>): number =>
    Math.max(0, ...data.map(({ level }) => level || 0));
