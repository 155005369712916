import { useState } from "react";
import { FetchError, Location, SiteType, Tag } from "@sv/types";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { getBaseUrl } from "./../../../services";

interface LoadingStates {
    getSites: boolean;
    addSite: boolean;
    getSite: boolean;
    editSite: boolean;
    deleteSite: boolean;
}

interface UseSiteReturn {
    site: SiteType | undefined;
    sites: Array<SiteType>;
    error: FetchError;
    loading: LoadingStates;
    getSites: () => Promise<void>;
    addSite: (name: string, tags: Array<Tag>, location: Location) => Promise<void>;
    getSite: (siteId: string) => Promise<void>;
    editSite: (name: string, siteId: string, tags: Array<Tag>, location: Location) => Promise<void>;
    deleteSite: (siteId: string) => Promise<void>;
    resetState: () => void;
    resetError: () => void;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapSiteType = (sites: any): Array<SiteType> => {
    return sites?.map(({ siteId, name, metadata, location }: SiteType) => ({
        siteId,
        name,
        metadata,
        location,
    }));
};

const initialLoadingState: LoadingStates = {
    getSites: false,
    addSite: false,
    getSite: false,
    editSite: false,
    deleteSite: false,
};
const url = `${getBaseUrl()}/caas/sites`;

const useSite = (): UseSiteReturn => {
    const [site, setSite] = useState<SiteType>();
    const [sites, setSites] = useState(Array<SiteType>());
    const [error, setError] = useState({} as FetchError);
    const [loading, setLoading] = useState<LoadingStates>(initialLoadingState);

    const getSites = async () => {
        setLoading((current) => ({ ...current, getSites: true }));
        setSites([]);

        const options = {
            params: {
            },
        };

        try {
            const res = await axios.get(url, options);
            setSites(mapSiteType(res.data));
            setError({});
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                setError({
                    status: error?.response?.status,
                    statusText: error.response?.statusText,
                    message: "Get sites error",
                });
            }
        } finally {
            setLoading((current) => ({ ...current, getSites: false }));
        }
    };

    const addSite = async (name: string, tags: Array<Tag>, location: Location) => {
        setLoading((current) => ({ ...current, addSite: true }));
        setSite(undefined);

        const body = {
            name: name,
            siteId: uuidv4(),
            metadata: { tags },
            // TODO (@Jason): update once integration contract has been defined.
            // metadata: {
            //     sites: {
            //         ["Region"]: ["AU", "IND"],
            //         ["Site Type"]: ["Beach", "Office"]
            //     },
            // },
            // metadata: {
            //     sites: [1, 2, 5],
            // },
            location,
        };

        try {
            let responseData: Array<SiteType> = [];
            const res = await axios.post(url, body);
            // response may be a single object or array of objects
            if (
                typeof res.data === 'object' &&
                !Array.isArray(res.data) &&
                res.data !== null
            ) {
                responseData.push(res.data);
            } else {
                responseData = res.data;
            }
            setSite(mapSiteType(responseData)[0]);
            setError({});
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                setError({
                    status: error?.response?.status,
                    message: error?.response?.data.message || "Add new site error",
                });
            }
            throw error;
        } finally {
            setLoading((current) => ({ ...current, addSite: false }));
        }
    };

    const getSite = async (siteId: string) => {
        setLoading((current) => ({ ...current, getSite: true }));
        setSite(undefined);

        const options = {
            params: {
            },
        };

        try {
            let responseData: Array<SiteType> = [];
            const res = await axios.get(`${url}/${siteId}`, options);
            // response may be a single object or array of objects
            if (
                typeof res.data === 'object' &&
                !Array.isArray(res.data) &&
                res.data !== null
            ) {
                responseData.push(res.data);
            } else {
                responseData = res.data;
            }
            setSite(mapSiteType(responseData)[0]);
            setError({});
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                setError({
                    status: error?.response?.status,
                    statusText: error?.response?.statusText,
                    message: "Get site error",
                });
            }
        } finally {
            setLoading((current) => ({ ...current, getSite: false }));
        }
    };

    const editSite = async (name: string, siteId: string, tags: Array<Tag>, location: Location) => {
        setLoading((current) => ({ ...current, editSite: true }));

        const body = {
            name: name,
            metadata: { ...site?.metadata, tags },
            location,
        };

        try {
            let responseData: Array<SiteType> = [];
            const res = await axios.put(`${url}/${siteId}`, body);
            // response may be a single object or array of objects
            if (
                typeof res.data === 'object' &&
                !Array.isArray(res.data) &&
                res.data !== null
            ) {
                responseData.push(res.data);
            } else {
                responseData = res.data;
            }
            setSite(mapSiteType(responseData)[0]);
            setError({});
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                setError({
                    status: error?.response?.status,
                    message: error?.response?.data.message || "Edit site error",
                });
            }
            throw error;
        } finally {
            setLoading((current) => ({ ...current, editSite: false }));
        }
    };

    const deleteSite = async (siteId: string) => {
        setLoading((current) => ({ ...current, deleteSite: true }));
        setSite(undefined);

        try {
            await axios.delete(`${url}/${siteId}`, {
                params: {
                },
            });
            setError({});
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                setError({
                    status: error?.response?.status,
                    statusText: error?.response?.statusText,
                    message: "Delete site error",
                });
            }
        } finally {
            setLoading((current) => ({ ...current, deleteSite: false }));
        }
    };

    const resetState = () => {
        setSite(undefined);
        setError({});
    };

    const resetError = () => {
        setError({});
    };

    return {
        site,
        sites,
        error,
        loading,
        getSites,
        addSite,
        getSite,
        editSite,
        deleteSite,
        resetState,
        resetError,
    };
};

export default useSite;
