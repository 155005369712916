import React, { useState } from "react";
import styled from "styled-components";
import { CameraObjectPoint, Spinner } from "telstra-components";
import { Button } from "telstra-ui/components/button/Button";
import { GenericAlert } from "telstra-ui/components/generic-alert/GenericAlert";
import { Picto, Switch, Switch104 } from "@able/react";
import CameraConfigView, {
    CameraObjectItem,
    CameraObjectTripwire,
    CameraObjectZone,
} from "./CameraConfig/CameraConfigView";
import EditTripwireModal, { TriplineConfig } from "./CameraConfig/EditTripwireModal";
import { FormData } from "./ConfigureCameraModal";
import EditPolygonModal, { PolygonConfig } from "./CameraConfig/EditPolygonModal";

export enum ErrorType {
    NO_ERROR,
    TEST_REQUIRED,
    ADD_TRIPLINE,
    CONNECT_GATEWAY,
    DEFAULT,
}

type ConfigureCameraType = {
    error: ErrorType;
    testConfiguration: boolean;
    isTestingConfig: boolean;
    formData: FormData;
    onTestConfig: () => void;
    onRevertConfig?: () => void;
    onUpdateConfig?: () => void;
    onNext?: () => void;
};

const ConfigureCamera: React.FC<ConfigureCameraType> = ({
    error,
    testConfiguration,
    isTestingConfig,
    formData,
    onTestConfig,
    onRevertConfig,
    onUpdateConfig,
    onNext,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [editTripline, setEditTripline] = useState(false);
    const [editPolygon, setEditPolygon] = useState(false);
    const [cameraObjects, setCameraObjects] = useState<Array<CameraObjectItem>>([]);
    const [selectedObject, setSelectedObject] = useState<CameraObjectItem | undefined>();
    const [isEditCameraObject, setIsEditCameraObject] = useState(false);

    const onToggleCamera = () => {
        setIsLoading(true);
        // todo integrate video stream here
        setTimeout(() => setIsLoading(false), 1000);
        setIsLoaded(!isLoaded);
    };

    const getErrorMessage = (errorType: ErrorType): string => {
        switch (errorType) {
            case ErrorType.TEST_REQUIRED:
                return "Camera could not be configured. Please test this configuration before saving.";
            case ErrorType.ADD_TRIPLINE:
                return "Camera could not be configured. Please add at least 1 trip-line or polygon.";
            case ErrorType.CONNECT_GATEWAY:
                return "Camera could not be configured at this time. Please connect this camera’s gateway to the IoT hub and try again.";
            case ErrorType.DEFAULT:
                return "Camera could not be configured at this time. Please try again.";
            default:
                return "Camera could not be configured at this time. Please try again.";
        }
    };

    const onCameraObjectChange = (id: string, points: Array<CameraObjectPoint>, isStageFocused: boolean) => {
        if (cameraObjects.length > 0) {
            setCameraObjects((prevObjects) => {
                let objectToEdit: CameraObjectItem | null = null;

                // Find the object with the specified ID and set its editMode based on stageFocused
                const updatedObjects = prevObjects.map((object) => {
                    if (object && object.id === id) {
                        objectToEdit = {
                            ...object,
                            points: points,
                            editMode: isStageFocused,
                        };
                        return objectToEdit;
                    }
                    return {
                        ...object,
                        editMode: false,
                    };
                });

                return updatedObjects;
            });
        }
    };

    const onDeleteTripline = () => {
        const updatedObjects = cameraObjects.filter((object) => object.id !== selectedObject?.id);
        setCameraObjects(updatedObjects);
        setIsEditCameraObject(false);
        setEditTripline(false);
        setSelectedObject(undefined);
    };

    const onCancelTripline = () => {
        setEditTripline(false);
        setIsEditCameraObject(false);
        setSelectedObject(undefined);
    };

    const onDeletePolygon = () => {
        const updatedObjects = cameraObjects.filter((object) => object.id !== selectedObject?.id);
        setCameraObjects(updatedObjects);
        setIsEditCameraObject(false);
        setEditPolygon(false);
        setSelectedObject(undefined);
    };

    const onCancelPolygon = () => {
        setEditPolygon(false);
        setIsEditCameraObject(false);
        setSelectedObject(undefined);
    };

    const onSubmitTripline = (tripline: TriplineConfig) => {
        const existingObject = cameraObjects.find((obj) => obj.id === tripline.id);
        const updatedCameraObjects = cameraObjects.map((cameraObject) => {
            return { ...cameraObject, editMode: false };
        });

        setCameraObjects(updatedCameraObjects);

        if (isEditCameraObject) {
            // edit selected tripline
            setCameraObjects((prevObjects) =>
                prevObjects.map((obj) =>
                    obj.id === existingObject?.id
                        ? {
                              ...(existingObject as CameraObjectTripwire),
                              id: `${tripline.name}-${new Date().getTime()}`,
                              name: tripline.name,
                              objectClasses: tripline.objectClasses,
                              largeAreaTags: tripline.largeAreaTags,
                              crossingTags: tripline.crossingTags,
                          }
                        : obj,
                ),
            );
        } else {
            // create new tripline
            setCameraObjects((prevItems) => [
                ...prevItems,
                {
                    id: `${tripline.name}-${new Date().getTime()}`,
                    name: tripline.name,
                    points: [
                        {
                            x: 0.4,
                            y: 0.5,
                        },
                        {
                            x: 0.6,
                            y: 0.5,
                        },
                    ],
                    type: "tripwire",
                    editMode: true,
                    direction: 0,
                    objectClasses: tripline.objectClasses,
                    largeAreaTags: tripline.largeAreaTags,
                    crossingTags: tripline.crossingTags,
                },
            ]);
        }

        setIsEditCameraObject(false);
        setSelectedObject(undefined);
    };

    const onSubmitPolygon = (polygon: PolygonConfig) => {
        const existingObject = cameraObjects.find((obj) => obj.id === polygon.id);
        const updatedCameraObjects = cameraObjects.map((cameraObject) => {
            return { ...cameraObject, editMode: false };
        });

        setCameraObjects(updatedCameraObjects);

        if (isEditCameraObject) {
            // edit selected polygon
            setCameraObjects((prevObjects) =>
                prevObjects.map((obj) =>
                    obj.id === existingObject?.id
                        ? {
                              ...(existingObject as CameraObjectZone),
                              id: `${polygon.name}-${new Date().getTime()}`,
                              name: polygon.name,
                              objectClasses: polygon.objectClasses,
                              dwellAreaTags: polygon.dwellAreaTags,
                          }
                        : obj,
                ),
            );
        } else {
            // create new polygon
            setCameraObjects((prevItems) => [
                ...prevItems,
                {
                    id: `${polygon.name}-${new Date().getTime()}`,
                    name: polygon.name,
                    points: [
                        {
                            x: 0.4,
                            y: 0.4,
                        },
                        {
                            x: 0.6,
                            y: 0.4,
                        },
                        {
                            x: 0.6,
                            y: 0.6,
                        },
                        {
                            x: 0.4,
                            y: 0.6,
                        },
                    ],
                    type: "zone",
                    editMode: true,
                    objectClasses: polygon.objectClasses,
                    dwellAreaTags: polygon.dwellAreaTags,
                },
            ]);
        }
        setIsEditCameraObject(false);
        setSelectedObject(undefined);
    };

    const onContextMenuClick = (selectedObject: CameraObjectItem, type: "tripline" | "polygon") => {
        setIsEditCameraObject(true);
        setSelectedObject(selectedObject);
        if (type === "tripline") {
            setEditTripline(true);
        } else if (type === "polygon") {
            setEditPolygon(true);
        }
    };

    return (
        <Container>
            <EditTripwireModal
                isOpen={editTripline}
                formData={formData}
                selectedObject={selectedObject}
                isEditing={isEditCameraObject}
                onCancel={onCancelTripline}
                onDelete={onDeleteTripline}
                onSubmit={onSubmitTripline}
            />
            <EditPolygonModal
                isOpen={editPolygon}
                formData={formData}
                selectedObject={selectedObject}
                isEditing={isEditCameraObject}
                onCancel={onCancelPolygon}
                onDelete={onDeletePolygon}
                onSubmit={onSubmitPolygon}
            />
            <MenuAndVideoContainer>
                <SideMenuContainer>
                    <Switch label="Camera" events={{ onChange: () => onToggleCamera() }} data-testid="camera-button" />
                    {isLoaded && (
                        <SideMenuButtonsContainer>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setEditTripline(true);
                                }}
                            >
                                Add tripline
                            </Button>
                            <Button
                                variant="secondary"
                                id="addPolygonButton"
                                onClick={() => {
                                    setEditPolygon(true);
                                }}
                            >
                                Add polygon
                            </Button>
                        </SideMenuButtonsContainer>
                    )}
                </SideMenuContainer>
                <VideoContainer>
                    {isTestingConfig && <TestBanner>Object detection test in progress</TestBanner>}
                    <Video>
                        {isLoading ? (
                            <LoadingContainer>
                                <Spinner isVisible size="medium" />
                                <LoadingText>It may take a while for your device to connect.</LoadingText>
                            </LoadingContainer>
                        ) : !isLoaded ? (
                            <TurnOnContainer>
                                <Picto theme="blue">
                                    <Switch104 />
                                </Picto>
                                <TurnOnText>
                                    Turn camera on to view video stream, draw and test trip-lines and polygons
                                </TurnOnText>
                            </TurnOnContainer>
                        ) : (
                            <StreamContainer data-testid="video-container">
                                <CameraConfigView
                                    isLoaded
                                    cameraObjects={cameraObjects}
                                    onCameraObjectChange={(cameraObj: CameraObjectItem, isStageFocused: boolean) => {
                                        onCameraObjectChange(cameraObj.id, cameraObj.points, isStageFocused);
                                    }}
                                    onContextMenuClick={onContextMenuClick}
                                />
                            </StreamContainer>
                        )}
                    </Video>
                    <TestAndSaveContainer>
                        <ConfigButtonContainer>
                            {testConfiguration ? (
                                <Button onClick={onTestConfig} width="200px" height="52px">
                                    Test configuration
                                </Button>
                            ) : (
                                isTestingConfig && (
                                    <RevertUpdateContainer>
                                        <RevertButtonContainer>
                                            <Button onClick={onRevertConfig} width="125px" height="52px">
                                                Revert config
                                            </Button>
                                        </RevertButtonContainer>
                                        <UpdateButtonContainer>
                                            <Button onClick={onUpdateConfig} width="125px" height="52px">
                                                Update config
                                            </Button>
                                        </UpdateButtonContainer>
                                    </RevertUpdateContainer>
                                )
                            )}
                        </ConfigButtonContainer>
                        <SaveButtonContainer>
                            <Button
                                iconPosition="right"
                                icon="icon-system-arrow"
                                variant="primary"
                                size="medium"
                                label={"Save Configuration"}
                                onClick={onNext}
                            />
                        </SaveButtonContainer>
                    </TestAndSaveContainer>
                </VideoContainer>
            </MenuAndVideoContainer>
            {error !== ErrorType.NO_ERROR && (
                <AlertContainer>
                    <GenericAlert
                        name="warning"
                        description={getErrorMessage(error)}
                        showBackground={true}
                        customStyle={{ height: "48px", fontSize: "14px", marginTop: "20px" }}
                        isMobileView={false}
                    />
                </AlertContainer>
            )}
        </Container>
    );
};

export default ConfigureCamera;

const Container = styled.div`
    margin-top: 46px;
`;

const StreamContainer = styled.div``;

const ConfigButtonContainer = styled.div``;

const RevertUpdateContainer = styled.div`
    display: flex;
`;

const RevertButtonContainer = styled.div`
    margin-right: 10px;

    .tl-button-super-disabled {
        border: 1px solid #aaaaaa;
        color: #aaaaaa;
    }
`;

const UpdateButtonContainer = styled.div``;

const TestBanner = styled.div`
    margin-top: -41px;
    margin-bottom: 6px;
    height: 35px;
    background: rgba(255, 221, 133, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LoadingText = styled.p`
    margin-bottom: 80px;
    margin-top: -12px;
`;

const TurnOnText = styled.p`
    margin-bottom: 100px;
    margin-top: -4px;
`;

const TurnOnContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
`;

const SideMenuButtonsContainer = styled.div`
    margin-top: 16px;
    margin-left: 18px;
`;

const AlertContainer = styled.div`
    margin-top: -14px;

    .tl-generic-alert-container .tl-alert-box .tl-svg-info-icon {
        margin-bottom: -6px;
    }

    .tl-generic-alert-container .tl-alert-box .tl-container-wrapper {
        padding: 12px 0px 0px 20px;
        width: 910px;
    }

    .tl-generic-alert-container .tl-alert-box .tl-generic-body-small {
        margin-top: 0px;
    }
`;

const TestAndSaveContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
`;

const SaveButtonContainer = styled.div``;

const MenuAndVideoContainer = styled.div`
    display: flex;
`;

const SideMenuContainer = styled.div`
    width: 164px;
    margin-right: 10px;
`;

const VideoContainer = styled.div``;

const Video = styled.div`
    width: 732px;
    height: 412px;
    border: 0px solid #979797;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 4, 10, 0.2), 0px 1px 10px 0px rgba(0, 4, 10, 0.04),
        0px 4px 5px 0px rgba(0, 4, 10, 0.14);
`;
