import axios from "axios";
import { getBaseUrl } from "../..";
import { DeviceManagement } from "./Types";

// See: https://confluence.tools.telstra.com/display/IOTSV/Enable+Camera+RTSP+Stream+Connectivity+-+Backend+Integration

export const DeviceManagementService = () => {
  const baseUrl = `${getBaseUrl()}`;

  type Base64Image = string;

  const getImageForLeafDevice = async (leafDeviceId: string, edgeDeviceId: string): Promise<Base64Image | undefined> => {
    try {
      const res = await axios.get(`${baseUrl}/caas/device-management/rtsp-feed?edgeDeviceId=${edgeDeviceId}&leafDeviceId=${leafDeviceId}`);
      const responseData: DeviceManagement.API.CommandActionResult = res.data;
      return responseData.result.payload.imageResponse.image;
    } catch (error: unknown) {
      console.error(error);
      return undefined;
    }
  };

  return {
    getImageForLeafDevice,
  };
};
