import React, { ReactElement } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
  fill?: string;
}

const SVGIconSiteMonitor = ({ fill }: Props): ReactElement => {
  return (
    <SVGWrapper
      width="100%"
      height="100%"
      fillColor={fill || ""}
      id="SiteMonitorIcon"
      data-name="Layer 5"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 25"
    >
      <title>Site Monitor Icon</title>
      {/* <path
        d="M20.55,6H18.41V6A1.45,1.45,0,0,0,17,4.51H4.91A1.45,1.45,0,0,0,3.46,6v5.36a1.45,1.45,0,0,0,1.45,1.44H17a1.45,1.45,0,0,0,1.44-1.44v-.07h2.14a.85.85,0,0,0,.84-.84V6.87A.85.85,0,0,0,20.55,6Zm-3.64,5.22h0L5,11.31l0-5.3,12-.06v5.29Zm3-1.5H18.41V7.52h1.48Z"
        transform="translate(-3.45 -4.51)"
      />
      <rect x="2.83" y="14.67" width="9.55" height="1.5" transform="translate(-12.1 5.33) rotate(-44.79)" />
      <path
        d="M4.2,21.78A.76.76,0,0,1,3.45,21V15A.75.75,0,0,1,5,15v6A.76.76,0,0,1,4.2,21.78Z"
        transform="translate(-3.45 -4.51)"
      /> */}
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 6.49991H18.73C18.7327 6.10127 18.5755 5.71818 18.2936 5.43629C18.0117 5.1544 17.6286 4.99723 17.23 4.99991H5.22998C4.40155 4.99991 3.72998 5.67148 3.72998 6.49991V11.7499C3.72998 12.5783 4.40155 13.2499 5.22998 13.2499H9.48998L5.23998 17.4999V15.4999C5.23998 15.0857 4.90419 14.7499 4.48998 14.7499C4.07577 14.7499 3.73998 15.0857 3.73998 15.4999V21.4999C3.73998 21.9141 4.07577 22.2499 4.48998 22.2499C4.90419 22.2499 5.23998 21.9141 5.23998 21.4999V19.6199L11.61 13.2499H17.23C17.6286 13.2526 18.0117 13.0954 18.2936 12.8135C18.5755 12.5316 18.7327 12.1486 18.73 11.7499H21C21.4119 11.7445 21.7446 11.4119 21.75 10.9999V7.24991C21.7446 6.83795 21.4119 6.5053 21 6.49991ZM5.22998 11.7499V6.49991H17.23V11.7499H5.22998ZM20.23 10.2499H18.73V7.99991H20.23V10.2499Z"
          fill={fill || ""}
          fillOpacity="0.4"
        />
      </svg>
    </SVGWrapper>
  );
};

export default SVGIconSiteMonitor;

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
