import React, { FC } from "react";
import { Button } from "telstra-ui/components/button/Button";
import { Body18, Box, Col, Row, DropDown, FormikProvider, useFormik, Modal, WhiteSpace } from "telstra-components";
import { DropdownOption } from "../../../types";
import strings from "../../../strings/strings.json";
import { ToolTip } from "telstra-ui/components/tool-tip/Tooltip";
import { validationSchemas } from "../../../helpers/formValidation";

const { heading, cancelButton, description, doneButton, variableLabel, variableTooltip } =
    strings.components.Modals.ClassModal;

const defaultValues = {
    variable: "",
};

interface ClassModalProps {
    variableOptions: Array<DropdownOption>;
    onToggle: () => void;
    onSubmit: (variable: string) => void;
}

const ClassModal: FC<ClassModalProps> = ({ variableOptions, onToggle: handleModalToggle, onSubmit: handleSubmit }) => {
    const formik = useFormik({
        initialValues: defaultValues,
        validationSchema: validationSchemas.cameraClass,
        onSubmit: async (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            { variable }: any,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            { resetForm }: any,
        ) => {
            handleSubmit(variable);
            resetForm();
            handleModalToggle();
        },
    });

    return (
        <Modal showCloseButton={false} onClose={handleModalToggle} isOpen>
            <Box margin="s" display="flex" gap="xs" flexDirection="column">
                <Box className="heading20">{heading}</Box>
                <Box>
                    <span className="body16">{description}</span>
                </Box>

                <Box>
                    <FormikProvider value={formik}>
                        <Box display="flex" flexDirection="column" gap="xs">
                            <Row noMargin>
                                <Col noPadding md={1.5} sm={2} justify="center">
                                    <Box display="flex">
                                        <Box flex="1">
                                            <Body18 regular>{variableLabel}</Body18>
                                        </Box>
                                        <div role="button" data-testid="hello" aria-label="variable-tooltip">
                                            <ToolTip
                                                description={<WhiteSpace>{variableTooltip}</WhiteSpace>}
                                                iconName="icon-system-info"
                                            />
                                        </div>
                                    </Box>
                                </Col>
                                <Col noPadding md={6.5} sm={6} justify="center">
                                    <DropDown name="variable" options={variableOptions} disabled={false} />
                                </Col>
                            </Row>
                        </Box>
                    </FormikProvider>
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <Button variant="secondary" size="medium" onClick={handleModalToggle}>
                        {cancelButton}
                    </Button>
                    <Button variant="secondary" size="medium" onClick={formik.submitForm}>
                        {doneButton}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ClassModal;
