import React, { ReactElement } from "react";
import styled from "styled-components";

interface Props {
  fill?: string;
}

const SVGIconOrderTrackerDone = ({ fill }: Props): ReactElement => {
  return (
    <SVGWrapper
      id="orderTrackerDone"
      height="100%"
      fillColor={fill || ""}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <title>Order Tracker Done</title>
      <g transform="translate(-202.000000, -251.000000) translate(170.000000, 91.000000) translate(32.000000, 160.000000)">
        <circle cx="12" cy="12" r="12" fill={fill} />
        <path stroke="#FFF" strokeWidth="1.5" d="M8 12.682L10.837 15 17 9" />
      </g>
    </SVGWrapper>
  );
};

export default SVGIconOrderTrackerDone;

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
