import React, { useEffect, useState } from "react";
import { Image as KonvaImage } from "react-konva";
import { DeviceManagementService } from "../../../../services/WebService/DeviceManagementService";

interface ImageStreamProps {
    leafDeviceId: string;
    edgeDeviceId: string;
    width: number;
    height: number;
}

const ImageStream: React.FC<ImageStreamProps> = ({
    // TODO (Jason): re-enable once Logical Device APIs are back online.
    // leafDeviceId,
    // edgeDeviceId,
    width,
    height,
}) => {
    // TODO (Jason): remove these hard-coded config details once Logical Device APIs are back online.
    const edgeDeviceId = "rtsp-integration-test01";
    const leafDeviceId = "svaEdge1";

    // TODO (Jason): remove this once Logical Device APIs are back online and we have verified.
    console.log("leafDeviceId, edgeDeviceId", leafDeviceId, edgeDeviceId)

    // Notes: Jason's testing demonstrated that
    //  - Average API response times range from 300 - 700ms.
    //  - 500ms - 1000ms polling interval should be reliable for performance.
    //  - 200ms - 500ms polling interval may be achievable, but need to consider client device bandwidth + CPU consumption.
    //  - 50ms polling interval is unreliable and degrades performance.
    const pollingIntervalTimeMilliseconds = 500;
 
    const [leafDeviceImage, setLeafDeviceImage] = useState<{
        element: HTMLImageElement | undefined,
        timestamp: Date,
    }>({
        element: undefined,
        timestamp: new Date(),
    });

    useEffect(() => {
        const interval = setInterval(async () => {
            const fetchTimestamp = new Date();
            const imageBase64 = await DeviceManagementService().getImageForLeafDevice(leafDeviceId, edgeDeviceId);

            const img = new window.Image();
            img.onload = () => {
                // Ensure displayed images are in chronological sequence.
                if (leafDeviceImage.timestamp.valueOf() < fetchTimestamp.valueOf()) {
                    setLeafDeviceImage({
                        element: img,
                        timestamp: fetchTimestamp,
                    });
                }
            };
            img.src = `data:image/png;base64,${imageBase64}` || "";
        }, pollingIntervalTimeMilliseconds);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <>
            {leafDeviceImage.element && (
                <KonvaImage
                    image={leafDeviceImage.element}
                    width={width}
                    height={height}
                />
            )}
        </>
    );
};

export default ImageStream;
