import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "telstra-ui/components/modal/Modal";
import { useToTitleCase } from "../../../hooks/Utils";

export type LoadingModalProps = {
    heading: string;
    text?: string;
    isOpen: boolean;
    progressTimeMs: number;
    onComplete: () => void;
};

const LoadingModal: React.FC<LoadingModalProps> = ({ heading, text, isOpen, progressTimeMs, onComplete }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress + 1 > 100 ? 100 : prevProgress + 1));
        }, progressTimeMs / 100);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (progress >= 100) {
            onComplete();
        }
    }, [progress]);

    return (
        <Container>
            <Modal isOpen={isOpen} isCloseOnOverlayClick={false}>
                <Modal.Header>{useToTitleCase(heading)}</Modal.Header>
                <Modal.Body>
                    <ProgreessBarOuter>
                        <ProgreessBarInner progress={progress} />
                    </ProgreessBarOuter>
                    <Text>{text}</Text>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default LoadingModal;

const Container = styled.div`
    .tl-modalbox-container .tl-large {
        width: 400px;
    }

    .tl-modalbox-container .tl-modal {
        height: 225px;
    }

    .tl-modalbox-container .tl-modal-icon-svg {
        display: none;
    }
`;

const Text = styled.div`
    color: var(--colours-solid-grey-l-1, #414141);
    font-family: Telstra Akkurat;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 125% */
    letter-spacing: -0.2px;
    margin-top: 44px;
    overflow: hidden;
`;

const ProgreessBarOuter = styled.div`
    background-color: #d2d2d2;
    height: 5px;
    width: 336px;
    margin-top: 20px;
`;

const ProgreessBarInner = styled.div<{ progress: number }>`
    background-color: #d0021b;
    height: 5px;
    width: ${(props) => (props.progress ? props.progress : 0)}%;
    z-index: 2;
`;
