import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button } from "telstra-ui/components/button/Button";
import { DetectObjectsOptions } from "./DetectObjects";
import { Tag } from "telstra-ui/components/tag/Tag";
import { DropDownV1 as DropdownV1 } from "telstra-ui/components/drop-down/DropDownV1";
import { Heading24, Modal, greyL1 } from "telstra-components";

export type EditTagModalProps = {
    isOpen: boolean;
    selectedObject: DetectObjectsOptions;
    onCancel: () => void;
    onDelete: (object: DetectObjectsOptions) => void;
    onSubmit: (object: DetectObjectsOptions) => void;
};

const EditTagModal: React.FC<EditTagModalProps> = ({ isOpen, onCancel, onDelete, onSubmit, selectedObject }) => {
    const [selectedAlgorithm, setSelectedAlgorithm] = useState<DetectObjectsOptions>();
    const modalRef = useRef<HTMLDivElement>(null);
    const focusableElements = useRef<Array<HTMLElement | null>>([]);

    const onSelectAlgorithm = (item: DetectObjectsOptions) => {
        setSelectedAlgorithm(item);
    };

    const onUpdateAlgorithm = () => {
        const updatedObject: DetectObjectsOptions = {
            ...selectedObject,
            currentAlgorithm: {
                itemId: selectedAlgorithm?.itemId,
                displayText: selectedAlgorithm?.displayText,
            },
        };
        setSelectedAlgorithm(undefined);
        onSubmit(updatedObject);
    };

    useEffect(() => {
        if (modalRef.current) {
            focusableElements.current = Array.from(
                modalRef.current.querySelectorAll(
                    'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select',
                ),
            );
        }

        if (isOpen && focusableElements.current.length > 0) {
            focusableElements.current[0]?.focus();
        }
    }, [isOpen]);

    return (
        <Container>
            <Modal isOpen={isOpen} onClose={() => onCancel()} showCloseButton={false} size="medium">
                <ModalContainer ref={modalRef}>
                    <ContentContainer>
                        <CloseButtonContainer aria-label="close edit algorithm modal">
                            <Button
                                iconPosition="left"
                                icon={"icon-system-close"}
                                variant="primary"
                                size="medium"
                                onClick={() => {
                                    onCancel();
                                }}
                            />
                        </CloseButtonContainer>
                        <Heading24>Edit object algorithm</Heading24>
                        <DescriptionContainer>
                            This option is only for advanced users. You can manually edit the algorithm applied to an
                            object.
                        </DescriptionContainer>
                        <AlgorithmHeader>Default algorithm applied to object</AlgorithmHeader>
                        <TagContainer>
                            <Tag
                                label={`${selectedObject.displayText} = ${selectedObject.defaultAlgorithm?.displayText}`}
                                className="font-size: 12px"
                                hideIcon={true}
                            />
                        </TagContainer>
                        <AlgorithmHeader>Select new algorithm to apply to object</AlgorithmHeader>
                        <V1DropdownContainer aria-label="Select new algorithm">
                            <DropdownV1
                                label=""
                                placeholder={"Select..."}
                                options={selectedObject.availableAlgorithms}
                                onChange={(item: DetectObjectsOptions) => onSelectAlgorithm(item)}
                                value={selectedAlgorithm?.displayText}
                            />
                        </V1DropdownContainer>
                        <ButtonsContainer>
                            <Button variant="super" state="warning" onClick={() => onDelete(selectedObject)}>
                                Delete this object
                            </Button>
                            <Button onClick={() => onUpdateAlgorithm()} disabled={selectedAlgorithm === undefined}>
                                Save
                            </Button>
                        </ButtonsContainer>
                    </ContentContainer>
                </ModalContainer>
            </Modal>
        </Container>
    );
};

export default EditTagModal;

const Container = styled.div``;

const TagContainer = styled.div`
    .tl-tag-value .tl-tag-label {
        font-size: 14px;
    }
`;

const ModalContainer = styled.div`
    max-width: 752px;
    height: 794px;
`;

const ContentContainer = styled.div`
    width: 620px;
    margin-top: 59px;
    margin-left: 48px;
`;

const CloseButtonContainer = styled.div`
    position: absolute;
    top: 18px;
    right: 18px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        fill: ${greyL1};
        margin: 0px !important;
        left: 0px !important;
    }
    button {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    span {
        width: 24px;
        height: 24px;
    }
`;

const ButtonsContainer = styled.div`
    margin-top: 309px;
    display: flex;
    justify-content: space-between;
`;

const V1DropdownContainer = styled.div`
    margin-top: 30px;
    max-width: 600px;

    .tl-dropdown-v1 .tl-dropdown-button {
        padding: 0.5em;
        padding-left: 24px;
    }

    .tl-menu-list .tl-menu-list-options .tl-option-selected {
        color: #414141;
        background-color: #fff;
    }

    .tl-menu-list-filter-dropdown-v1 .tl-menu-list-options {
        width: 100%;
    }

    .tl-menu-list-filter-dropdown-v1 .tl-menu-list-options .tl-menu-list-option {
        width: 100%;
        padding-left: 24px;
    }

    .tl-dropdown-v1 .tl-dropdown-button:hover {
        background-color: white;
    }

    .tl-menu-list .tl-menu-list-options .tl-menu-list-options-list {
        padding: 0px;
    }

    .tl-menu-list .tl-menu-list-options .tl-menu-list-option:focus {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border: 2px solid #0064d2;
        color: #414141;
        font-weight: 700;
        outline: none;
    }

    .tl-menu-list .tl-menu-list-options .tl-menu-list-option {
        margin: 0px 0px 0px 0px;
        height: 47px;
    }

    .tl-menu-list .tl-menu-list-options {
        padding: 0;
        margin-top: 0;
    }

    .tl-menu-list .tl-menu-list-options .tl-menu-list-option:hover:not(.-drag-start):not(.tl-dn-overlay) {
        background-color: white;
        border-left: 4px solid #0064d2;
        color: #414141;
        font-weight: 700;
    }
`;

const AlgorithmHeader = styled.div`
    color: #282828;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 20px;
    margin-top: 17px;
`;

const DescriptionContainer = styled.div`
    font-size: 16px;
    margin-bottom: 32px;
`;
