import React, { ReactNode } from "react";
import styled from "styled-components";
import strings from "../../../smart-video/strings/strings.json";

class NotFound extends React.Component {
    public render(): ReactNode {
        const { applayoutTopNavTitle } = strings.components.Layout.AppLayout;
        return (
            <AppView>
                <Container>
                    <h1>{applayoutTopNavTitle}</h1>
                    <p>{"The URL you are looking for is not available."}</p>
                    <p>
                        <b>HTTP Error 404</b>: Not Found
                    </p>
                    <p>
                        Go back to <a href="/">Home Page</a>
                    </p>
                </Container>
            </AppView>
        );
    }
}

const AppView = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #f4f4f4;
    & > * {
        margin: 0;
    }
`;

const Container = styled.div`
    padding: 0 2rem 2rem 2rem;
`;

export default NotFound;
