import React, { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Menu } from "telstra-ui/components/menu-list/Menu";

import { EntryType, TreeDataProps, TreeItem } from "./types";
import { greyL1, greyL2, blueL4 } from "../../../themes/Colors";
import sortTreeData from "./sortTreeData";
import { IconDownArrow as IconArrowBase } from "../../../Icons";
import { renderCell } from "./addIcons";
import HoverToolTip from "./HoverToolTip";

export interface TreeTableProps<Entry extends TreeDataProps> {
    items: Array<Entry>;
    expandAll?: boolean;
    expands?: Array<string>;
    onPerformAction?: (item: Entry, name: string) => void;
}

const getOptionsForItem = <Entry extends TreeDataProps>(item: Entry) =>
    ((
        {
            organisation: [],
            site: [
                // TODO: re-enable when feature is ready.
                // { displayText: "Edit site details", itemId: "site-edit" },
                // { displayText: "Edit site analytics tags", itemId: "site-tags-edit" },
                // { displayText: "Add gateway", itemId: "site-gateways-add" },
                // { displayText: "DEBUG: Delete site", itemId: "site-delete" },
            ],
            gateway: [
                // TODO: re-enable when feature is ready.
                // { displayText: "Edit gateway details", itemId: "gateway-edit" },
                // { displayText: "Add camera to gateway", itemId: "gateway-cameras-add" },
            ],
            camera: [
                // TODO: re-enable when feature is ready.
                // { displayText: "Configure camera", itemId: "camera-configure" },
            ],
            blueprint: [],
        } as { [type in EntryType]: Array<{ displayText: string; itemId: string }> }
    )[item.type]);

export const TreeTable = <Entry extends TreeDataProps>({
    items,
    expandAll,
    expands,
    onPerformAction,
}: TreeTableProps<Entry>): ReactElement | null => {
    const [data, setData] = useState<Array<Entry>>([]);

    const [expandedRows, setExpandedRows] = useState({} as { [rowId: string]: boolean });
    const [openedMoreOptionMenuId, setOpenedMoreOptionMenuId] = useState(null as string | null);
    const [openedStatusTooltipId, setOpenedStatusTooltipId] = useState(null as string | null);

    const menusRef = useRef({} as { [key: string]: HTMLDivElement });

    useEffect(() => {
        const newData = sortTreeData(items)
            .filter((e, i, arr) => arr.findIndex((e2) => e2.id === e.id) === i) // remove duplicates - TODO: investigate why duplicates are being generated in the first place; seems like a flaw in the original algorithm.
            .filter((e) => e.type !== "blueprint")
            .map((e) => {
                if (e.type === "camera") {
                    return {
                        ...e,
                        hasChildren: false,
                    };
                }
                return e;
            });
        setData(newData);

        let newExpandedRows: typeof expandedRows = {};
        if (expandAll) {
            newExpandedRows = newData.reduce((a, e) => ({ ...a, [e.id]: true }), {});
        }
        if (expands) {
            const traverseAncestorsAndApplyAction = (item: TreeItem, action: (item: TreeItem) => void) => {
                if (!item) {
                    return;
                }
                action(item);
                if (item.parentId) {
                    traverseAncestorsAndApplyAction(itemLookup[item.parentId], action);
                }
            };
            const itemLookup: { [id: string]: TreeItem } = newData.reduce((a, e) => ({ ...a, [e.id]: e }), {});
            expands.forEach((itemId) => {
                traverseAncestorsAndApplyAction(itemLookup[itemId], (item) => {
                    newExpandedRows[item.id] = true;
                });
            });
        }
        setExpandedRows(newExpandedRows);
    }, [items]);

    return (
        <Container>
            <HeaderRow>
                <span style={{ width: 48 }}>Status</span>
                <span style={{ marginLeft: 24 }}>Site structure</span>
            </HeaderRow>
            {data.map((e) => {
                const areAncestorsExpanded = (e.ancestors || []).every(
                    (ancestor) => expandedRows[ancestor.id] === true,
                );
                const isRoot = e.type === "site";
                if (!areAncestorsExpanded && !isRoot) {
                    return null;
                }

                const type = e.type;
                const isExpanded = expandedRows[e.id];

                const handleClick = () => {
                    setExpandedRows({
                        ...expandedRows,
                        [e.id]: (e.hasChildren || false) && !(expandedRows[e.id] || false),
                    });
                };

                return (
                    <Row
                        key={e.id}
                        hasChildren={e.hasChildren || false}
                        isTopLevel={type === "site"}
                        isExpanded={isExpanded}
                        color={e.type === "camera" && e.status?.code !== "GREEN" ? greyL2 : greyL1}
                        highlightColor={
                            e.type === "camera" && e.status?.code !== "GREEN"
                                ? `${blueL4}66` // 40% transparency - see: https://stackoverflow.com/questions/15852122/hex-transparency-in-colors
                                : blueL4
                        }
                        onClick={handleClick}
                    >
                        <RowContent level={e.level || 0}>
                            <IconContainer
                                onMouseEnter={() => {
                                    setOpenedStatusTooltipId(`status-tooltip-${e.id}`);
                                }}
                                onMouseLeave={() => {
                                    setOpenedStatusTooltipId(null);
                                }}
                            >
                                <img src={e.status?.iconSrc} alt={""} />
                            </IconContainer>
                            {!(
                                (
                                    e.status?.code === "GREEN" &&
                                    !e.status.reason &&
                                    !e.status.action &&
                                    !e.status.iconSrc
                                ) ||
                                (
                                    e.status?.code === "GREEN" &&
                                    e.type === "gateway"
                                )
                            ) && (
                                <HoverToolTip
                                    title={e.status?.reason}
                                    description={e.status?.action}
                                    iconSrc={
                                        e.status?.code === "GREEN"
                                            ? "/images/site-hierarchy-icons/green-tick.svg"
                                            : e.status?.code === "AMBER"
                                            ? "/images/site-hierarchy-icons/yellow-warning.svg"
                                            : e.status?.code === "RED"
                                            ? "/images/site-hierarchy-icons/red-warning.svg"
                                            : undefined
                                    }
                                    accentTopBarColor={
                                        e.status?.code === "GREEN"
                                            ? "#008a00"
                                            : e.status?.code === "AMBER"
                                            ? "#ffd600"
                                            : e.status?.code === "RED"
                                            ? "#d0021b"
                                            : "transparent"
                                    }
                                    isVisible={openedStatusTooltipId === `status-tooltip-${e.id}`}
                                />
                            )}

                            {type !== "camera" && (
                                <Expander isExpanded={isExpanded}>
                                    {e.hasChildren && <IconArrowBase fill={greyL1} />}
                                    {/* {e.hasChildren && <img src="/images/right-arrow.svg" fill={greyL1} alt="" />} */}
                                    {/* {e.hasChildren && <img src="/images/right-arrow.svg" fill={"red"} alt="" />} */}
                                </Expander>
                            )}

                            <IconContainer>{renderCell({ disabled: false, type })}</IconContainer>

                            <div
                                style={{
                                    marginLeft: 8,
                                    letterSpacing: -0.2,
                                    fontWeight: type === "gateway" ? 700 : 300,
                                }}
                            >
                                {e.text}
                            </div>

                            {getOptionsForItem(e)?.length > 0 && (
                                <MoreOptionsIconContainer
                                        id={`more-button-${e.id}`}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setOpenedMoreOptionMenuId(`more-button-${e.id}`);
                                            (
                                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                menusRef.current[`more-options-dropdown-${e.id}`] as any
                                            )?.toggleMenuList();
                                        }}
                                    >
                                        <IconContainer>
                                            <img src="/images/ellipsis-icon.svg" alt=""></img>
                                        </IconContainer>
                                        {openedMoreOptionMenuId === `more-button-${e.id}` && (
                                            <MenuContainer
                                                onMouseLeave={() => {
                                                    setOpenedMoreOptionMenuId(null);
                                                }}
                                            >
                                                <Menu
                                                    ref={(el: HTMLDivElement) => {
                                                        menusRef.current[`more-options-dropdown-${e.id}`] = el;
                                                    }}
                                                    menuListId={`more-options-dropdown-${e.id}`}
                                                    selectedDropdown={() => {
                                                        setOpenedMoreOptionMenuId(null);
                                                    }}
                                                    buttonId={`more-button-${e.id}`}
                                                    menuItems={getOptionsForItem(e)}
                                                    onOptionClick={(event: Event) => {
                                                        if (onPerformAction) {
                                                            onPerformAction(e, (event.target as HTMLElement).innerText);
                                                        }
                                                    }}
                                                />
                                            </MenuContainer>
                                        )}
                                </MoreOptionsIconContainer>
                            )}
                        </RowContent>
                    </Row>
                );
            })}
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    margin-top: 38px;
`;

const HeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 44px;
    padding-left: 28px;

    border-style: solid;
    border-color: #aaaaaa;
    border-width: 0px 0px 1px;

    .MoreOptionsIconContainer {
        display: none;
    }

    // Disable text selection
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */

    // Disable image selection
    img {
        pointer-events: none;
    }

    font-weight: 300;
    letter-spacing: -0.2px;

    color: ${greyL2};
`;

const Row = styled.div<{
    hasChildren: boolean;
    isTopLevel: boolean;
    isExpanded: boolean;
    color: string;
    highlightColor: string;
}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 44px;
    padding-left: 25px;

    cursor: ${(props) => (props.hasChildren ? "pointer" : "default")};

    border-style: solid;
    border-color: transparent transparent
        ${(props) => (props.isTopLevel && !props.isExpanded ? `#d8d8d8` : `transparent`)};
    border-width: 1px 0px 1px;

    /* Table active */
    :hover {
        background: rgba(0, 100, 210, 0.06);
        border-color: transparent;
        border-width: 1px 0px 1px;

        color: ${(props) => props.highlightColor};

        svg#gateway-icon {
            g, path {
                stroke: ${blueL4};
            }
        }

        svg#map-icon {
            g, path {
                fill: ${blueL4};
            }
        }

        svg#SiteMonitorIcon {
            fill: ${blueL4};
            g, path {
                fill: ${blueL4};
            }
        }

        .MoreOptionsIconContainer {
            display: flex;
        }
    }

    .MoreOptionsIconContainer {
        display: none;
    }

    // Disable text selection
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */

    // Disable image selection
    img {
        pointer-events: none;
    }

    font-weight: 300;
    letter-spacing: -0.2px;

    color: ${(props) => props.color};
`;

const RowContent = styled.div<{ level: number }>`
    margin-left: ${(props) => (props.level - 1) * 96}px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const MenuContainer = styled.div`
    width: 210px;
    position: absolute;
    top: calc(44px - 6px);
    right: -60px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    // -- override telstra-ui styling for menu to match designs
    .tl-menu-list .tl-menu-list-options {
        padding-top: 0;
    }       

    // -- override telstra-ui styling for menu to match designs
    .tl-menu-list .tl-menu-list-options .tl-menu-list-options-list {
        padding: 0px !important;
    }

    > div {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-end;

        > div {
            width: 200% !important;
        }

        .tl-menu-list-options {
            width: 100% !important;
        }

        // -- override telstra-ui styling for menu items to match designs
        .tl-menu-list-option {
            width: 100% !important;
            display: flex;
            align-items: center;
            height: 53px !important;
            margin: 0px !important;
            padding-left: 30px !important;
            color: ${greyL1} !important;
            :focus {
                outline: none !important;
                box-shadow:inset 0px 0px 0px 2px #68bffa;
            }
        }
    }
`;

const MoreOptionsIconContainer = styled.div.attrs({ className: "MoreOptionsIconContainer" })`
    height: 44px;
    width: 44px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 60px;
    > div {
        margin-right: 0px;
    }
`;

const IconContainer = styled.div`
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;

    > div {
        margin: 0px;
    }
`;

const Expander = styled.div<{ isExpanded?: boolean }>`
    height: 24px;
    width: 24px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;
    > svg {
        width: 16px;
        height: 16px;
        transform: ${(props) => (props.isExpanded ? "rotate(0)" : "rotate(270deg)")};
    }
`;

export default TreeTable;
