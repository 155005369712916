import React, { ReactNode, PureComponent } from "react";
import styled from "styled-components";
import { Breadcrumb } from "../../services/Store/UIStore";
import { BreadCrumbNavigationBar } from "../../components";
import { media } from "../../config/Config";
import DropDown from "../../components/List/DropDown/DropDown";

interface Props {
    breadcrumbs: Array<Breadcrumb>;
    summaries: ReactNode;
    list: ReactNode;
    selectListItems: Array<string>;
    onClickSelect: (selected: string) => void;
    disableDropdown?: boolean;
    displayBanner?: boolean;
    bannerComp?: ReactNode;
}

class Management extends PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): ReactNode {
        const {
            breadcrumbs,
            summaries,
            list,
            selectListItems,
            onClickSelect,
            disableDropdown,
            displayBanner,
            bannerComp,
        } = this.props;

        return (
            <PortalLayout>
                <BreadcrumbsContainer>
                    <BreadCrumbNavigationBar breadcrumbs={breadcrumbs} />
                </BreadcrumbsContainer>
                <PageContainer>
                    <LeftNavigation>{/* Left navigation placeholder. Hidden for now. */}</LeftNavigation>
                    <PageLayout>
                        {displayBanner && <BannerContainer>{bannerComp}</BannerContainer>}
                        <LocationBarContainer>
                            <DropDown listItems={selectListItems} onClick={onClickSelect} disable={disableDropdown} />
                        </LocationBarContainer>
                        <SummaryContainer>{summaries}</SummaryContainer>
                        <ListContainer>{list}</ListContainer>
                    </PageLayout>
                </PageContainer>
            </PortalLayout>
        );
    }
}

const PortalLayout = styled.div`
    height: auto;
    flex-direction: column;
    user-select: none;
    background: #f4f4f4;
`;

const BreadcrumbsContainer = styled.div`
    box-sizing: border-box;
    padding: 24px 20px;
`;

const PageContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const LeftNavigation = styled.div``;

const PageLayout = styled.div`
    width: 1060px;
    ${media.tablet} {
        width: 800px;
    }
`;

const LocationBarContainer = styled.div`
    height: 28px;
    position: relative;
    z-index: 3;
`;

const SummaryContainer = styled.div`
    margin-top: 30px;
    height: 200px;
`;

const ListContainer = styled.div`
    margin: 72px auto;
`;

const BannerContainer = styled.div`
    margin-top: 18px;
    margin-bottom: 64px;
`;

export default Management;
