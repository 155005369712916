import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import {
    AzureMap,
    AzureMapDataSourceProvider,
    AzureMapLayerProvider,
    AzureMapPopup,
    AzureMapsContext,
    IAzureMapOptions,
    IAzureMapsContextProps,
} from "react-azure-maps";
import { AuthenticationType } from "azure-maps-control";
import { Box, Heading16, white } from "telstra-components";
import styled from "styled-components";
import axios from "axios";
import { resolve } from "dns";

const clientId = process.env.REACT_APP_AAD_APP_CLIENT_ID;

interface MapControllerProps {
    searchResult?: GeoJSON.FeatureCollection<GeoJSON.Point>;
    mapCredentials: MapCredentials;
}

interface MapCredentials {
    ms_client_id: string,
    access_token: string
}

const MapController: FC<MapControllerProps> = ({ searchResult, mapCredentials }: MapControllerProps) => {
    const [isVisible, setIsVisible] = useState(false);

    const { mapRef } = useContext<IAzureMapsContextProps>(AzureMapsContext);

    useEffect(() => {
        if (searchResult && searchResult.features) {
            if (mapRef) {
                const coordinates = searchResult.features[0].geometry.coordinates;
                mapRef.setCamera({ center: [coordinates[0], coordinates[1]] });
            }
        }
    }, [searchResult, mapRef])

    const option: IAzureMapOptions = {
        authOptions: {
            authType: AuthenticationType.anonymous,
            clientId: mapCredentials.ms_client_id,
            getToken: async (resolve) => {
                resolve(mapCredentials.access_token);
            }
        },
        center: [151.2099, -33.865143], // Australia latlong coordinates
        zoom: 15,
        view: "Auto",
    }

    const memoizedMapPopup = useMemo(
        () => (
            <AzureMapPopup
                isVisible={isVisible}
                options={{ position: searchResult && searchResult?.features[0].geometry.coordinates }}
                popupContent={
                    searchResult ? (
                        <Box>
                            <PopupDialog>
                                <Box>
                                    <Heading16>
                                        {searchResult?.features[0].properties?.poi
                                            ? searchResult?.features[0].properties.poi.name
                                            : searchResult?.features[0].properties?.address.streetNameAndNumber}
                                    </Heading16>
                                    <PopupContent>
                                        {searchResult?.features[0].properties?.address.freeformAddress}
                                    </PopupContent>
                                </Box>
                            </PopupDialog>
                        </Box>
                    ) : (
                        <></>
                    )
                }
            />
        ),
        [isVisible, searchResult],
    );

    const displayMarkerPopup = () => setIsVisible(true);

    const hideMarkerPopup = () => setIsVisible(false);

    return (
        <AzureMap options={option}>
            <AzureMapDataSourceProvider collection={searchResult} id={"AzureMapDataSourceProvider"}>
                <AzureMapLayerProvider
                    id={"AzureMapLayerProvider"}
                    options={{
                        iconOptions: {
                            image: "pin-blue",
                            anchor: "center",
                            allowOverlap: true,
                        },
                    }}
                    events={{
                        mouseover: displayMarkerPopup,
                        mouseout: hideMarkerPopup,
                    }}
                    type={"SymbolLayer"}
                />
            </AzureMapDataSourceProvider>
            {memoizedMapPopup}
        </AzureMap>
    );
};

export default MapController;

const PopupDialog = styled.div`
    position: absolute;
    z-index: 50;
    transition: none;
    margin-left: 30px;
    display: flex;
    align-items: center;
    max-width: 336px;
    width: max-content;
    height: auto;
    white-space: pre-wrap;
    padding: 0 16px 0 16px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
    background-color: ${white};
    border-style: solid;
    border-image: linear-gradient(to right, #0266d2 0%, #5cd6e0 30.6%, #5e50bf 100%) 100% 0 100% 0/3px 0 0 0 stretch;
`;

const PopupContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 5px 0 10px 0;
    pointer-events: auto;
    background-color: ${white};
`;
