/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Dashboard } from "../../../layouts";

import { ActionButton, IconButton, Picto, Search104, TextStyle } from "@able/react";
import Breadcrumb from "telstra-ui/components/breadcrumb/Breadcrumb";
import LineChart from "telstra-ui/components/line-chart/LineChart";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DateRangePicker from "telstra-ui/components/date-range-picker/DateRangePicker";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { DropDownV1 as DropdownV1 } from 'telstra-ui/components/drop-down/DropDownV1';

import { Menu } from "telstra-ui/components/menu-list/Menu";
import { Button } from "telstra-ui/components/button/Button";
import { Card } from 'telstra-ui/components/card/Card';

import { Pill } from 'telstra-ui/components/pill/Pill';

import { greyL1, greyL3, greyL4 } from "../../../themes/Colors";

import { DropdownMultiSelect } from "./DropdownMultiSelect";

import moment from "moment";

import {AggregateDataModal} from "./AggregateDataModal";

import { ReportingService, Reports } from "../../../services/WebService/Reporting";
// import { ReportingServiceMock } from "../../../services/WebService/Reporting/ReportingServiceMock";
import { aggregateTriplineTelemetry, aggregatePolygonTelemetry, timestampForIndex } from "./Aggregate";

const renderDropdown = (
    label: string,
    options: Array<{displayText: string; itemId: string; selected: boolean}>,
    selectedItemId: string | null,
    onChange: (selectedOption: {displayText: string; itemId: string; selected: boolean}) => void,
) => (
    <DropdownV1
        label={label}
        placeholder={"Select..."}
        options={options}
        developmentUrl={`http://${window.location.host}/able-sprites.svg`}
        size="Long"
        value={options.find(e => e.itemId === selectedItemId)?.displayText}
        onChange={onChange}
    />
);

export type DropdownSingleSelectOption = {
    itemId: string;
    displayText: string;
    selected: boolean;
    description?: string;
}

export type DropdownMultiSelectOption = {
    itemId: string;
    displayText: string;
    active: boolean;
}

export type GranularityMinutes =
    | 15        // 15 min
    | 30        // 30 min
    | 60        // 1 hour
    | 1440      // 1 day
    | 10080     // 1 week
    | 44640     // 1 month (31 days)

const getTimeAxisTickInterval = (
    selectedDateRangeLength: number,
    datapointPrecisionMinutes: GranularityMinutes,
) => {
    if (selectedDateRangeLength === 1) {
        return Math.round((3 * 60) / datapointPrecisionMinutes);
    } else if (selectedDateRangeLength >= 2 && selectedDateRangeLength <= 7) {
        return Math.round((24 * 60) / datapointPrecisionMinutes);
    } else if (selectedDateRangeLength >= 8 && selectedDateRangeLength <= 31) {
        return Math.round((4 * 24 * 60) / datapointPrecisionMinutes);
    } else if (selectedDateRangeLength >= 32 && selectedDateRangeLength <= 60) {
        return Math.round((7 * 24 * 60) / datapointPrecisionMinutes);
    } else if (selectedDateRangeLength >= 61 && selectedDateRangeLength <= 180) {
        return Math.round((21 * 24 * 60) / datapointPrecisionMinutes);
    } else if (selectedDateRangeLength >= 181 && selectedDateRangeLength <= 365) {
        return Math.round((45 * 24 * 60) / datapointPrecisionMinutes);
    } else {
        return 1;
    }
}

const durationFormatFromSeconds = (seconds: number) => {
    const NUM_MONTHS_IN_YEAR = 12;
    const NUM_DAYS_IN_MONTH = 30;
    const NUM_DAYS_IN_WEEK = 7;
    const NUM_HOURS_IN_DAY = 24;
    const NUM_MINUTES_IN_HOUR = 60;
    
    const NUM_SECONDS_IN_MINUTE = 60;
    const NUM_SECONDS_IN_HOUR = NUM_SECONDS_IN_MINUTE * NUM_MINUTES_IN_HOUR;
    const NUM_SECONDS_IN_DAY = NUM_SECONDS_IN_HOUR * NUM_HOURS_IN_DAY;
    const NUM_SECONDS_IN_WEEK = NUM_SECONDS_IN_DAY * NUM_DAYS_IN_WEEK;
    const NUM_SECONDS_IN_MONTH = NUM_SECONDS_IN_DAY * NUM_DAYS_IN_MONTH;
    const NUM_SECONDS_IN_YEAR = NUM_SECONDS_IN_MONTH * NUM_MONTHS_IN_YEAR;

    const output: Array<string> = [];
    let remainder = seconds;
    if (remainder >= NUM_SECONDS_IN_YEAR) {
        output.push(`${Math.floor(remainder / NUM_SECONDS_IN_YEAR)} yr`);
        remainder = remainder % NUM_SECONDS_IN_YEAR;
    }
    if (remainder >= NUM_SECONDS_IN_MONTH) {
        output.push(`${Math.floor(remainder / NUM_SECONDS_IN_MONTH)} mth`);
        remainder = remainder % NUM_SECONDS_IN_MONTH;
    }
    if (remainder >= NUM_SECONDS_IN_WEEK) {
        output.push(`${Math.floor(remainder / NUM_SECONDS_IN_WEEK)} wk`);
        remainder = remainder % NUM_SECONDS_IN_WEEK;
    }
    if (remainder >= NUM_SECONDS_IN_DAY) {
        output.push(`${Math.floor(remainder / NUM_SECONDS_IN_DAY)} day`);
        remainder = remainder % NUM_SECONDS_IN_DAY;
    }
    if (remainder >= NUM_SECONDS_IN_HOUR) {
        output.push(`${Math.floor(remainder / NUM_SECONDS_IN_HOUR)} hr`);
        remainder = remainder % NUM_SECONDS_IN_HOUR;
    }
    if (remainder >= NUM_SECONDS_IN_MINUTE) {
        output.push(`${Math.floor(remainder / NUM_SECONDS_IN_MINUTE)} min`);
        remainder = remainder % NUM_SECONDS_IN_MINUTE;
    }
    if (remainder >= 0) {
        output.push(`${Math.floor(remainder)} sec`);
    }
    return output.slice(0, 2).join(" ");
}

const Reporting: React.FC = () => {
    const { pathname } = useLocation();

    const [shouldRenderChart, setShouldRenderChart] = useState(false);

    const [sitesRaw, setSitesRaw] = useState<Array<Reports.Web.Site>>([]);
    const [objectsRaw, setObjectsRaw] = useState<Array<Reports.Web.Object>>([]);
    const [locationsRaw, setLocationsRaw] = useState<Array<Reports.Web.Location>>([]);
    const [telemetryRaw, setTelemetryRaw] = useState<Array<Reports.Web.TelemetryPacketTripline> | Array<Reports.Web.TelemetryPacketPolygon>>([]);

    const [isAggregateMenuShowing, setIsAggregateMenuShowing] = useState(false);
    const aggregateMenu = useRef(undefined as HTMLDivElement | undefined);

    const [aggregateDataModalDisplayType, setAggregateDataModalDisplayType] = useState<null | "TRIP_LINES" | "POLYGONS" | "TRIP_LINE-OBJECTS" | "POLYGON-OBJECTS">(null);

    const [selectedDateRange, setSelectedDateRange] = useState<{startDate: moment.Moment; endDate: moment.Moment}>({
        startDate: moment().subtract(7, "days"),
        endDate: moment().subtract(1, "day"),
    });

    const [selectedSite, setSelectedSite] = useState<string | null>(null);
    const [selectedMetric, setSelectedMetric] = useState<string | null>(null);
    const [selectedObjects, setSelectedObjects] = useState<Array<string>>([]);
    const [selectedLocation, setSelectedLocation] = useState<string | null>(null);

    const [selectedAggregateLocations, setSelectedAggregateLocations] = useState<Array<string>>([]);
    const [selectedAggregateObjects, setSelectedAggregateObjects] = useState<Array<string>>([]);

    const [precisionOptions, setPrecisionOptions] = useState<[
        GranularityMinutes,
        GranularityMinutes,
        GranularityMinutes,
    ]>([15, 30, 60]);

    const siteOptions: Array<DropdownSingleSelectOption> = useMemo(() => {
        return sitesRaw
            .filter(e => e.active)
            .map(e => ({
                itemId: e.siteId,
                displayText: e.name,
                selected: false,
            }))
    }, [sitesRaw]);

    const metricOptions: Array<DropdownSingleSelectOption> = [
        {
            displayText: 'Traffic - in',
            itemId: 'trafficin',
            selected: false,
            description: `Number of objects crossing a trip-line in the ‘in’ direction`,
        },
        {
            displayText: 'Traffic - out',
            itemId: 'trafficout',
            selected: false,
            description: `Average number  of objects crossing a trip-line in the ‘out’ direction`,
        },
        {
            displayText: 'Total traffic',
            itemId: 'totaltraffic',
            selected: false,
            description: `Average number of objects crossing a trip-line in the ‘in’ and ‘out’ direction`,
        },
        {
            displayText: 'Total Occupancy',
            itemId: 'totaloccupancy',
            selected: false,
            description: `Total number of items which have occupied a location tag within the selected time frame`,
        },
        {
            displayText: 'Dwell time',
            itemId: 'dwelltime',
            selected: false,
            description: `Average length of time objects spend inside polygons`,
        },
    ];

    const selectedMetricType = useMemo(() => (
          [ "trafficin", "trafficout", "totaltraffic" ].includes(selectedMetric || "")
        ? "trip line"
        : [ "totaloccupancy", "dwelltime" ].includes(selectedMetric || "")
        ? "polygon"
        : null
    ), [selectedMetric]);

    const locationOptions: Array<DropdownSingleSelectOption> = useMemo(() => {
        return locationsRaw
            .filter(e => e.active)
            .filter(e => e.type === selectedMetricType)
            .map(e => ({
                itemId: e.locationId,
                displayText: e.name,
                selected: false,
            }))
    }, [locationsRaw, selectedMetric]);

    const objectOptions: Array<DropdownMultiSelectOption> = useMemo(() => {
        return objectsRaw
            .filter(e => e.active)
            .map(e => ({
                itemId: e.objectId,
                displayText: e.name,
                active: false,
            }))
    }, [objectsRaw]);

    const sites = useMemo(() => siteOptions.reduce((a, e) => ({ ...a, [e.itemId]: e }), {} as { [id: string]: DropdownSingleSelectOption }), [siteOptions]);
    const metrics = useMemo(() => metricOptions.reduce((a, e) => ({ ...a, [e.itemId]: e }), {} as { [id: string]: DropdownSingleSelectOption }), [metricOptions]);
    const locations = useMemo(() => locationOptions.reduce((a, e) => ({ ...a, [e.itemId]: e }), {} as { [id: string]: DropdownSingleSelectOption }), [locationOptions]);
    const objects = useMemo(() => objectOptions.reduce((a, e) => ({ ...a, [e.itemId]: e }), {} as { [id: string]: DropdownMultiSelectOption }), [objectOptions]);

    const canRenderChart = selectedSite && selectedMetric && selectedObjects.length > 0 && selectedLocation;

    const updateFilterSelections = (updatedSelections: {
        updatedSelectedSite?: string | null;
        updatedSelectedMetric?: string | null;
        updatedSelectedObjects?: Array<string>;
        updatedSelectedLocation?: string | null;
    }) => {
        if (updatedSelections.updatedSelectedSite === null) {
            setSelectedSite(null);
            setSelectedMetric(null);
            setSelectedObjects([]);
            setSelectedLocation(null);
            setSelectedAggregateObjects([]);
            setSelectedAggregateLocations([]);
            setShouldRenderChart(false);
        } else if (updatedSelections.updatedSelectedMetric === null) {
            setSelectedSite(updatedSelections.updatedSelectedSite || selectedSite);
            setSelectedMetric(null);
            setSelectedObjects([]);
            setSelectedLocation(null);
            setSelectedAggregateObjects([]);
            setSelectedAggregateLocations([]);
            setShouldRenderChart(false);
        } else if (updatedSelections.updatedSelectedObjects?.length === 0) {
            setSelectedSite(updatedSelections.updatedSelectedSite || selectedSite);
            setSelectedMetric(updatedSelections.updatedSelectedMetric || selectedMetric);
            setSelectedObjects([]);
            setSelectedLocation(null);
            setSelectedAggregateObjects([]);
            setSelectedAggregateLocations([]);
            setShouldRenderChart(false);
        } else if (updatedSelections.updatedSelectedLocation === null) {
            setSelectedSite(updatedSelections.updatedSelectedSite || selectedSite);
            setSelectedMetric(updatedSelections.updatedSelectedMetric || selectedMetric);
            setSelectedObjects(updatedSelections.updatedSelectedObjects || selectedObjects);
            setSelectedLocation(null);
            setSelectedAggregateObjects([]);
            setSelectedAggregateLocations([]);
            setShouldRenderChart(false);
        } else {
            setSelectedSite(updatedSelections.updatedSelectedSite || selectedSite);
            setSelectedMetric(updatedSelections.updatedSelectedMetric || selectedMetric);
            setSelectedObjects(updatedSelections.updatedSelectedObjects || selectedObjects);
            setSelectedLocation(updatedSelections.updatedSelectedLocation || selectedLocation);
            setSelectedAggregateObjects([]);
            setSelectedAggregateLocations([]);
            setShouldRenderChart(false);
        }
    };

    useEffect(() => {
        if (!locationOptions.find(e => e.itemId === selectedLocation)) {
            setSelectedLocation(null);
        }
    }, [selectedLocation, locationOptions])

    const selectedDateRangeLength = moment(selectedDateRange.endDate).diff(selectedDateRange.startDate, "days") + 1;
    const timePeriodGranularity: "HOURLY" | "DAILY" = selectedDateRangeLength <= 1
        ? "HOURLY"
        : "DAILY"
    const [datapointPrecisionMinutes, setDatapointPrecisionMinutes] = useState<GranularityMinutes>(60);
    const timePeriodBuckets = timePeriodGranularity === "HOURLY"
        ? new Array(Math.ceil(selectedDateRangeLength * 24 * 60 / datapointPrecisionMinutes)).fill(0).map((_, i) => moment(selectedDateRange.startDate).hours(0).minutes(0).seconds(0).milliseconds(0).add(i * datapointPrecisionMinutes, "minutes"))
        : new Array(Math.ceil(selectedDateRangeLength * 24 * 60 / datapointPrecisionMinutes)).fill(0).map((_, i) => moment(selectedDateRange.startDate).hours(0).minutes(0).seconds(0).milliseconds(0).add(i * datapointPrecisionMinutes, "minutes"))

    useEffect(() => {
        if (selectedDateRangeLength === 1) {
            setPrecisionOptions([15, 30, 60]);
            setDatapointPrecisionMinutes(30);
        } else if (selectedDateRangeLength >= 2 && selectedDateRangeLength <= 7) {
            setPrecisionOptions([30, 60, 1440]);
            setDatapointPrecisionMinutes(60);
        } else if (selectedDateRangeLength >= 8 && selectedDateRangeLength <= 31) {
            setPrecisionOptions([60, 1440, 10080]);
            setDatapointPrecisionMinutes(1440);
        } else if (selectedDateRangeLength >= 32 && selectedDateRangeLength <= 60) {
            setPrecisionOptions([1440, 10080, 44640]);
            setDatapointPrecisionMinutes(10080);
        } else if (selectedDateRangeLength >= 61 && selectedDateRangeLength <= 180) {
            setPrecisionOptions([1440, 10080, 44640]);
            setDatapointPrecisionMinutes(10080);
        } else if (selectedDateRangeLength >= 181 && selectedDateRangeLength <= 365) {
            setPrecisionOptions([1440, 10080, 44640]);
            setDatapointPrecisionMinutes(10080);
        }
    }, [selectedDateRangeLength])

    const targetTelemetryData: Array<{
        object: {
            id: string;
            displayName: string;
        };
        location: {
            id: string;
            displayName: string;
        };
        data: Array<{
            timestamp: string,
            value: number | null,
        }>;
    }> = useMemo(() => {
        return shouldRenderChart
            ? (selectedMetricType === "trip line"
                ? aggregateTriplineTelemetry(
                    selectedDateRange,
                    selectedDateRangeLength,
                    datapointPrecisionMinutes,
                    telemetryRaw as Array<Reports.Web.TelemetryPacketTripline>,
                    objects,
                    locations,
                    selectedMetric,
                    selectedObjects,
                    selectedLocation,
                    selectedAggregateObjects,
                    selectedAggregateLocations,
                )
                : aggregatePolygonTelemetry(
                    selectedDateRange,
                    selectedDateRangeLength,
                    datapointPrecisionMinutes,
                    telemetryRaw as Array<Reports.Web.TelemetryPacketPolygon>,
                    objects,
                    locations,
                    selectedMetric,
                    selectedObjects,
                    selectedLocation,
                    selectedAggregateObjects,
                    selectedAggregateLocations,
                )
            )
            : []
    }, [
        shouldRenderChart,
        selectedDateRange.startDate,
        selectedDateRange.endDate,
        datapointPrecisionMinutes,
        telemetryRaw,
        selectedMetric,
        selectedObjects,
        selectedLocation,
        selectedAggregateObjects,
        selectedAggregateLocations,
    ])
    // console.log(targetTelemetryData);

    useEffect(() => {
        ReportingService().getSites().then(sites => {
            setSitesRaw(sites);

            if (selectedSite && !sites.find(e => e.siteId === selectedSite)) {
                updateFilterSelections({
                    updatedSelectedSite: null,
                });
            }
        });
    }, []);

    useEffect(() => {
        if (selectedSite) {
            // Note: disable when debugging only.
            updateFilterSelections({
                updatedSelectedSite: selectedSite,
                updatedSelectedMetric: null,
                updatedSelectedObjects: [],
                updatedSelectedLocation: null,
            });
            ReportingService().getSiteTelemetryProperties(selectedSite).then(siteTelemetryProperties => {
                setObjectsRaw(siteTelemetryProperties.objects);
                setLocationsRaw(siteTelemetryProperties.locations);
            });
        }
    }, [selectedSite]);

    useEffect(() => {
        if (selectedSite && selectedMetric && selectedLocation) {
            ReportingService().getTelemetry(
                selectedSite,
                moment(selectedDateRange.startDate).hours(0).minutes(0).seconds(0).milliseconds(0).toDate(),
                moment(selectedDateRange.endDate).add(1, "day").hours(0).minutes(0).seconds(0).milliseconds(0).subtract(1, "millisecond").toDate(),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                selectedMetric as any,
                selectedAggregateObjects.length > 0
                    ? selectedAggregateObjects
                    : selectedObjects,
                selectedAggregateLocations.length > 0
                    ? selectedAggregateLocations
                    : [selectedLocation],
                datapointPrecisionMinutes,
            ).then(telemetry => {
                setTelemetryRaw(telemetry);
            });
        }
    }, [selectedLocation, shouldRenderChart, selectedAggregateObjects, selectedAggregateLocations, selectedObjects, selectedDateRange, datapointPrecisionMinutes]);

    return (
        <Dashboard shouldHide={pathname !== "/reporting"}>
            <ReportContainer>
                <Breadcrumb
                    separator="icon-system-right-arrow"
                    className="customClassName"
                    items={[
                        {'active': false, 'disabled': false, link:'/reporting', label: 'Analytics Dashboard' },
                        {'active': true, 'disabled': false, link:'/reporting', label: 'Site performance' },
                    ]}
                    collapse={true}
                />
                
                <HeaderRow>
                    <TextStyle
                        alias="HeadingC"
                        colour="Default"
                        alignment="Left"
                        className=""
                    >
                        {`Analyse a site's performance`}
                    </TextStyle>

                    <DateRangePicker
                        id="reportDateRange"
                        label="Date"
                        startDate={selectedDateRange.startDate.toDate()}
                        endDate={selectedDateRange.endDate.toDate()}
                        onChange={([dateRangeStart, dateRangeEnd]: [Date, Date | null]) => {
                            if (dateRangeEnd) {
                                setShouldRenderChart(false);
                                setSelectedDateRange({
                                    startDate: moment(dateRangeStart),
                                    endDate: moment(dateRangeEnd),
                                });
                            } else {
                                // TODO: investigate why text is not set and remains blank.
                                setShouldRenderChart(false);
                                setSelectedDateRange({
                                    startDate: moment(dateRangeStart),
                                    endDate: moment(dateRangeStart),
                                });
                            }
                        }}
                        maxDate={new Date()}
                        monthsShown={2}
                        selectsRange
                        inline
                    />
                </HeaderRow>

                <Row isViewButtonDisabled={!canRenderChart}>
                    <DropDownWrapper>
                        <DropdownV1
                            label="Site"
                            placeholder={"Select..."}
                            options={siteOptions}
                            developmentUrl={`http://${window.location.host}/able-sprites.svg`}
                            size="Long"
                            value={siteOptions.find(e => e.itemId === selectedSite)?.displayText}
                            onChange={(selectedOption: typeof siteOptions[number]) => {
                                updateFilterSelections({
                                    updatedSelectedSite: selectedOption.itemId,
                                });
                            }}
                        />
                    </DropDownWrapper>
                    <DisableDropdownWrapper disabled={selectedSite === null}>
                        <DropdownV1
                            label="Metric"
                            placeholder={"Select..."}
                            options={metricOptions}
                            developmentUrl={`http://${window.location.host}/able-sprites.svg`}
                            size="Short"
                            value={metricOptions.find(e => e.itemId === selectedMetric)?.displayText}
                            onChange={(selectedOption: typeof siteOptions[number]) => {
                                updateFilterSelections({
                                    updatedSelectedMetric: selectedOption.itemId,
                                });
                            }}
                        />
                    </DisableDropdownWrapper>
                    { selectedAggregateObjects.length === 0 && (
                        <DisableDropdownWrapper disabled={selectedMetric === null}>
                            <DropdownMultiSelect
                                id="objects-selector"
                                label="Objects"
                                options={objectOptions.map(e => ({
                                    ...e,
                                    active: selectedObjects.includes(e.itemId),
                                }))}
                                onUpdateOptions={(options) => {
                                    updateFilterSelections({
                                        updatedSelectedObjects: options.filter(e => e.active).map(e => e.itemId.toString()),
                                    });
                                }}
                            />
                        </DisableDropdownWrapper>
                    )}
                    { selectedAggregateObjects.length > 0 && (
                        <DisableDropdownWrapper disabled={true}>
                            {renderDropdown(
                                "Objects",
                                [{ itemId: "aggregate", displayText: "Aggregated", selected: true }],
                                "aggregate",
                                () => ({})
                            )}
                        </DisableDropdownWrapper>
                    )}
                    { selectedAggregateLocations.length === 0 && (
                        <DisableDropdownWrapper disabled={selectedObjects.length === 0}>
                            {renderDropdown(
                                `${"Location"}${selectedMetricType === "trip line" ? " (Trip-lines)" : selectedMetricType === "polygon" ? " (Polygons)" : ""}`,
                                locationOptions,
                                selectedLocation,
                                (selectedOption) => {
                                    updateFilterSelections({
                                        updatedSelectedLocation: selectedOption.itemId,
                                    });
                                }
                            )}
                        </DisableDropdownWrapper>
                    )}
                    { selectedAggregateLocations.length > 0 && (
                        <DisableDropdownWrapper disabled={true}>
                            {renderDropdown(
                                `${"Location"}${selectedMetricType === "trip line" ? " (Trip-lines)" : selectedMetricType === "polygon" ? " (Polygons)" : ""}`,
                                [{ itemId: "aggregate", displayText: "Aggregated", selected: true }],
                                "aggregate",
                                () => ({})
                            )}
                        </DisableDropdownWrapper>
                    )}
                    <ActionButton
                        variant="HighEmphasis"
                        label="View"
                        element="button"
                        to="/"
                        developmentUrl={`http://${window.location.host}/able-sprites.svg`}
                        deleteIcon={false}
                        icon={undefined}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        onClick={() => {
                            if (!shouldRenderChart) {
                                setTelemetryRaw([]);
                                setShouldRenderChart(true);
                            }
                        }}
                    />
                </Row>
                <Row2
                    isAggregateDisabled={!canRenderChart || !shouldRenderChart}
                >
                    <AggregateMenuContainer
                        onBlur={(event) => {
                            // See: https://stackoverflow.com/a/60094794
                            if (!event.currentTarget.contains(event.relatedTarget)) {
                                setIsAggregateMenuShowing(false);
                            }
                        }}
                    >
                        <ActionButton
                            variant="MediumEmphasis"
                            label="Aggregate +"
                            element="button"
                            to="/"
                            developmentUrl={`http://${window.location.host}/able-sprites.svg`}
                            deleteIcon={false}
                            icon={undefined}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            onClick={(event) => {
                                event.stopPropagation();
                                setIsAggregateMenuShowing(true);
                                (
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    aggregateMenu.current as any
                                )?.toggleMenuList();
                            }}
                        />
                        {isAggregateMenuShowing && (
                            <MenuContainer
                            >
                                <Menu
                                    ref={(el: HTMLDivElement) => {
                                        aggregateMenu.current = el;
                                    }}
                                    menuListId={`aggregate-menu-dropdown`}
                                    selectedDropdown={() => {
                                        setIsAggregateMenuShowing(false);
                                    }}
                                    buttonId={`aggregate-menu-button`}
                                    menuItems={[
                                        { displayText: `Aggregate data from ${selectedMetricType === "trip line" ? "trip-lines" : selectedMetricType === "polygon" ? "polygons" : ""}`, itemId: "aggregate-locations" },
                                        { displayText: "Aggregate object types", itemId: "aggregate-object-types" },
                                    ]}
                                    onOptionClick={(event: Event) => {
                                        console.log("test");
                                        setIsAggregateMenuShowing(false);
                                        const selectedOptionId = (event.target as HTMLElement).id
                                            .replace(/-text$/, "") // the text element has an ID with `-text` suffix, so get rid of that to get the real option ID.

                                        if (selectedOptionId === "aggregate-locations") {
                                            setAggregateDataModalDisplayType(selectedMetricType === "trip line" ? "TRIP_LINES" : "POLYGONS");
                                        } else if (selectedOptionId === "aggregate-object-types") {
                                            setAggregateDataModalDisplayType(selectedMetricType === "trip line" ? "TRIP_LINE-OBJECTS" : "POLYGON-OBJECTS");
                                        } else {
                                            console.error("unknown selection", selectedOptionId, event);
                                        }
                                    }}
                                />
                            </MenuContainer>
                        )}
                    </AggregateMenuContainer>
                <AggregatePropContainer>
                    { selectedAggregateLocations.length > 0 &&
                        <Pill
                            variant='input'
                            label={`Aggregate ${selectedMetricType === "trip line" ? "trip-lines" : "polygons"}`}
                            onClick={() => {
                                // Swallow the event.
                            }}
                            onCancel={() => {
                                setSelectedAggregateLocations([]);
                            }}
                        />
                    }
                    { selectedAggregateObjects.length > 0 &&
                        <Pill
                            variant='input'
                            label='Aggregate objects'
                            onClick={() => {
                                // Swallow the event.
                            }}
                            onCancel={() => {
                                setSelectedAggregateObjects([]);
                            }}
                        />
                    }
                </AggregatePropContainer>

                </Row2>

                {aggregateDataModalDisplayType && (
                    <AggregateDataModal
                        isOpen={true}
                        type={aggregateDataModalDisplayType}
                        options={
                            (aggregateDataModalDisplayType === "TRIP_LINES" || aggregateDataModalDisplayType === "POLYGONS")
                                ? locationOptions
                                .map(e => ({
                                    displayText: e.displayText,
                                    itemId: e.itemId,
                                    active: selectedAggregateLocations.includes(e.itemId)
                                }))
                                : objectOptions.map(e => ({
                                    displayText: e.displayText,
                                    itemId: e.itemId,
                                    active: selectedAggregateObjects.includes(e.itemId)
                                }))
                        }
                        onCancel={() => {
                            setAggregateDataModalDisplayType(null);
                        }}
                        onSubmit={(selectedItemIds) => {
                            if (aggregateDataModalDisplayType === "TRIP_LINES" || aggregateDataModalDisplayType === "POLYGONS") {
                                setSelectedAggregateLocations(selectedItemIds);
                            } else if (aggregateDataModalDisplayType === "TRIP_LINE-OBJECTS" || aggregateDataModalDisplayType === "POLYGON-OBJECTS") {
                                setSelectedAggregateObjects(selectedItemIds);
                            }
                            setAggregateDataModalDisplayType(null);
                        }}
                    />
                )}

                <ChartContainer>
                    <Card
                        className="custom-class"
                        showAnimate={false}
                        onMouseOver={() => {
                            // Swallow the event.
                        }}
                        onClick={() => {
                            // Swallow the event.
                        }}
                    >
                        { !shouldRenderChart && (
                            <EmptyChart>
                                <Picto theme="blue">
                                    <Search104 />
                                </Picto>
                                <EmptyChartPrompt>
                                    Select a site, metric, object and location to generate report
                                </EmptyChartPrompt>
                            </EmptyChart>
                        ) }
                        { shouldRenderChart && targetTelemetryData.length === 0 && (
                            <EmptyChart>
                                <Picto theme="blue">
                                    <Search104 />
                                </Picto>
                                <EmptyChartPrompt>
                                    No data available; please select another site, metric, object and location to generate report
                                </EmptyChartPrompt>
                            </EmptyChart>
                        )}
                        { shouldRenderChart && targetTelemetryData.length > 0 && (
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            <LineChart
                                options={{
                                    legend: {
                                        enabled: true,

                                        // See: https://stackoverflow.com/questions/42928000/how-can-we-change-legends-position-in-highcharts
                                        layout: "vertical",
                                        align: "right",
                                        verticalAlign: "middle",
                                        itemMarginTop: 10,
                                        itemMarginBottom: 10,
                                        style: {
                                            padding: 120,
                                        },
                                    },
                                    credits: {enabled: false},
                                    title: {
                                        text: `${sites[selectedSite || ""]?.displayText} | ${metrics[selectedMetric || ""]?.displayText}`,

                                        // See: https://stackoverflow.com/q/48546600
                                        align: "left",
                                        floating: false,
                                        margin: 50,
                                        style: {
                                            fontFamily: "TelstraAkkurat-Bold",
                                            fontSize: "28px",
                                        },
                                    },
                                    // See: https://api.highcharts.com/highcharts/subtitle
                                    subtitle: {
                                        text: selectedAggregateLocations.length > 0
                                            ? `${metrics[selectedMetric || ""]?.description}<br><br><b>Aggregated ${selectedMetricType === "trip line" ? "trip-lines" : "polygons"}:</b> ${selectedAggregateLocations.map(e => locations[e]?.displayText).join(", ")}`
                                            : `${metrics[selectedMetric || ""]?.description}<br><br><b>${selectedMetricType === "trip line" ? "Trip-line" : "Polygon"}:</b> ${locations[selectedLocation || ""]?.displayText}`,
                                        align: 'left',
                                        verticalAlign: 'top',
                                        floating: false,
                                        style: {
                                            fontFamily: "TelstraAkkurat-Light",
                                            fontSize: "16px",
                                            lineHeight: 25,
                                        },
                                    },
                                    xAxis: {
                                        categories: timePeriodBuckets,
                                        crosshair: true,
                                        labels: {
                                            formatter: function (this: {value: moment.Moment | {format?: () => void}}) {
                                                const targetValue = datapointPrecisionMinutes === 44640
                                                    ? (this.value.format
                                                        ? moment(this.value as moment.Moment).startOf("month")
                                                        : this.value
                                                    )
                                                    : this.value;

                                                if (!targetValue.format) {
                                                    return timePeriodGranularity === "HOURLY"
                                                        ? moment().add(1, "day").hours(0).minutes(0).seconds(0).milliseconds(0).format("hh:mma")
                                                        : (timePeriodGranularity === "DAILY" && datapointPrecisionMinutes < 1440)
                                                        ? moment(selectedDateRange.startDate).add(timePeriodBuckets.length * datapointPrecisionMinutes, "minutes").hours(0).minutes(0).seconds(0).milliseconds(0).format("DD/MM")
                                                        : moment(selectedDateRange.startDate).add(timePeriodBuckets.length * datapointPrecisionMinutes, "minutes").hours(0).minutes(0).seconds(0).milliseconds(0).format("DD/MM");
                                                }
                                                return timePeriodGranularity === "HOURLY"
                                                    ? targetValue.format("hh:mma")
                                                    : (timePeriodGranularity === "DAILY" && datapointPrecisionMinutes < 1440)
                                                    ? targetValue.format("DD/MM")
                                                    : targetValue.format("DD/MM")
                                            },
                                            align: "right",
                                        },
                                        startOnTick: true,
                                        tickInterval: getTimeAxisTickInterval(selectedDateRangeLength, datapointPrecisionMinutes),
                                        tickmarkPlacement: "between",
                                        tickWidth: 1,
                                        max: timePeriodBuckets.length - 1,
                                    },
                                    yAxis: {
                                        gridLineDashStyle: 'dash',
                                        title: {
                                            text: selectedMetric === "dwelltime" ? "Time" : 'Number of objects'
                                        },
                                        labels: {
                                            formatter: function (this: {value: number}) {
                                                if (selectedMetric === "dwelltime") {
                                                    const hours = Math.floor(this.value / 3600);
                                                    const minutes = Math.floor((this.value % 3600) / 60);
                                                    const seconds = this.value % 60;

                                                    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
                                                } else {
                                                    return this.value;
                                                }
                                            },
                                            align: "right",
                                        },
                                        // tickInterval: 15,
                                        tickAmount: 5,
                                    },
                                    series: targetTelemetryData.map(e => ({
                                        name: e.object.displayName.split(", ").join("<br>"),
                                        data: e.data.map(e => e.value),
                                    })),
                                    tooltip: {
                                        followPointer: false,
                                        shared: true,
                                        formatter: function (this: {
                                            x: moment.Moment,
                                            points: Array<{
                                                x: number;
                                                y: number;
                                                series: {
                                                    name: string;
                                                };
                                            }>,
                                        }) {
                                            const targetDateFormat = datapointPrecisionMinutes < 1440
                                                ? "D MMM YY | hh:mma"
                                                : "D MMM YY"

                                            const targetDateRangeStart = datapointPrecisionMinutes === 44640
                                                ? moment(this.x).startOf("month")
                                                : this.x;
                                            const targetDateRangeEndExc = datapointPrecisionMinutes === 44640
                                                ? moment(targetDateRangeStart).startOf("month").add(1, "month")
                                                : moment(targetDateRangeStart).add(datapointPrecisionMinutes, "minutes");
                                            const targetDateRangeString: string = datapointPrecisionMinutes < 1440
                                                ? `${targetDateRangeStart.format(targetDateFormat)} - ${targetDateRangeEndExc.format("hh:mma")}`
                                                : datapointPrecisionMinutes === 1440
                                                ? `${targetDateRangeStart.format(targetDateFormat)}`
                                                : `${targetDateRangeStart.format(targetDateFormat)} - ${targetDateRangeEndExc.subtract(1, "day").format(targetDateFormat)}`

                                            return this.points.reduce(function (s, point) {
                                                const yValue: string = selectedMetric === "dwelltime"
                                                    ? durationFormatFromSeconds(point.y)
                                                    : selectedMetric === "totaloccupancy"
                                                    ? `${point.y}`
                                                    : `${point.y}`

                                                const aggregatedPolygonsOnlyText = `${point.series.name.toUpperCase()} inside all polygons`;
                                                const aggregatedTriplinesOnlyText = `${point.series.name.toUpperCase()} across all trip-lines`;
                                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                const aggregatedObjectsOnlyText = `${locations[selectedLocation!].displayText.toUpperCase()} all objects`;
                                                const aggregatedPolygonsAndObjectsText = `Aggregated time for all objects inside all polygons`;
                                                const aggregatedTriplinesAndObjectsText = `Aggregated all objects across all trip-lines`;

                                                const targetPrefix = selectedMetricType === "trip line"
                                                    ? (
                                                        (selectedAggregateLocations.length > 0 && selectedAggregateObjects.length > 0)
                                                        ? aggregatedTriplinesAndObjectsText
                                                        : (selectedAggregateLocations.length > 0)
                                                        ? aggregatedTriplinesOnlyText
                                                        : (selectedAggregateObjects.length > 0)
                                                        ? aggregatedObjectsOnlyText
                                                        : point.series.name.toUpperCase()
                                                    )
                                                    : (
                                                        (selectedAggregateLocations.length > 0 && selectedAggregateObjects.length > 0)
                                                        ? aggregatedPolygonsAndObjectsText
                                                        : (selectedAggregateLocations.length > 0)
                                                        ? aggregatedPolygonsOnlyText
                                                        : (selectedAggregateObjects.length > 0)
                                                        ? aggregatedObjectsOnlyText
                                                        : point.series.name.toUpperCase()
                                                    )

                                                return s
                                                    + '<br/>'
                                                    + targetPrefix
                                                    + ': '
                                                    + yValue;
                                            }, `<b>${metricOptions.find(e => e.itemId === selectedMetric)?.displayText}</b><br><br>${targetDateRangeString}<br>`);
                                        },
                                    },
                                    plotOptions: {
                                        series: {
                                            pointPlacement: 0,
                                        },
                                    },
                                }}
                                containerProps={{style: {height: "505px"}}}
                            />
                        ) }
                        <Row3>
                            { (precisionOptions.includes(15)) && (
                                <Button variant="secondary" size="small" state={datapointPrecisionMinutes === 15 ? "app" : "warning"} className={datapointPrecisionMinutes === 15 ? "selected" : ""} onClick={() => setDatapointPrecisionMinutes(15)}>15 mins</Button>
                            )}
                            { (precisionOptions.includes(30)) && (
                                <Button variant="secondary" size="small" state={datapointPrecisionMinutes === 30 ? "app" : "warning"} className={datapointPrecisionMinutes === 30 ? "selected" : ""} onClick={() => setDatapointPrecisionMinutes(30)}>30 mins</Button>
                            )}
                            { (precisionOptions.includes(60)) && (
                                <Button variant="secondary" size="small" state={datapointPrecisionMinutes === 60 ? "app" : "warning"} className={datapointPrecisionMinutes === 60 ? "selected" : ""} onClick={() => setDatapointPrecisionMinutes(60)}>1 hour</Button>
                            )}
                            { (precisionOptions.includes(1440)) && (
                                <Button variant="secondary" size="small" state={datapointPrecisionMinutes === 1440 ? "app" : "warning"} className={datapointPrecisionMinutes === 1440 ? "selected" : ""} onClick={() => setDatapointPrecisionMinutes(1440)}>1 day</Button>
                            )}
                            { (precisionOptions.includes(10080)) && (
                                <Button variant="secondary" size="small" state={datapointPrecisionMinutes === 10080 ? "app" : "warning"} className={datapointPrecisionMinutes === 10080 ? "selected" : ""} onClick={() => setDatapointPrecisionMinutes(10080)}>1 week</Button>
                            )}
                            { (precisionOptions.includes(44640)) && (
                                <Button variant="secondary" size="small" state={datapointPrecisionMinutes === 44640 ? "app" : "warning"} className={datapointPrecisionMinutes === 44640 ? "selected" : ""} onClick={() => setDatapointPrecisionMinutes(44640)}>1 month</Button>
                            )}
                            <IconButton
                                icon="FileDownload"
                                size="Default"
                                developmentUrl={`http://${window.location.host}/able-sprites.svg`}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                onClick={() => {
                                    const getReportHourlyCsvExportRows = () => {
                                        const targetColumnNames = selectedAggregateObjects.length > 0
                                            ? ([`${selectedAggregateLocations.length > 0 ? selectedAggregateLocations.join(", ") : selectedLocation} | ${selectedAggregateObjects.join(", ")}`])
                                            : selectedObjects.map(e => `${selectedAggregateLocations.length > 0 ? selectedAggregateLocations.join(", ") : selectedLocation} | ${e}`)

                                        const headers = ["Date", ...targetColumnNames];

                                        const timeSlots = new Array(Math.ceil(selectedDateRangeLength * 24 * 60 / datapointPrecisionMinutes)).fill(0)
                                            .map((_, i) => timestampForIndex(i, selectedDateRange, datapointPrecisionMinutes))

                                        type Lookup = {
                                            [periodStartTime: string]: {
                                                [objectLocationName: string]: number | null;
                                            };
                                        };

                                        const targetDateFormat = datapointPrecisionMinutes < 1440
                                            ? "YYYY-MM-DD hh:mm a"
                                            : "YYYY-MM-DD"

                                        const dataLookup = targetTelemetryData
                                            .map(e => e.data.map(e2 => ({ ...e2, object: e.object, location: e.location })))
                                            .reduce((a, e) => a.concat(e), [] as Array<{
                                                timestamp: string,
                                                object: typeof targetTelemetryData[number]["object"],
                                                location: typeof targetTelemetryData[number]["location"],
                                                value: number,
                                            }>)
                                            .reduce((a, e) => {
                                                const timestampKey = moment(e.timestamp).format(targetDateFormat);
                                                const objectLocationName = `${e.location.id} | ${e.object.id}`;
                                                return {
                                                    ...a,
                                                    [timestampKey]: {
                                                        ...(a[timestampKey] || {}),
                                                        [objectLocationName]: e.value,
                                                    },
                                                };
                                            }, {} as Lookup)

                                        const rows = [
                                            headers.map(e => `"${e}"`),
                                            ...timeSlots
                                                .map<Array<string | number>>(e => [
                                                    moment(e).format(targetDateFormat),
                                                    ...targetColumnNames.map<string | number>(targetColumnName => {
                                                        const value = dataLookup[moment(e).format(targetDateFormat)][targetColumnName];
                                                        return (value === null || isNaN(value ?? NaN))
                                                            ? "--"
                                                            : selectedMetric === "dwelltime"
                                                            ? value.toFixed(2)
                                                            : value
                                                    })
                                                ]),
                                        ];
                                        
                                        return rows;
                                    }

                                    const exportCsvWithRows = (exportType: "SITE_PERFORMANCE", csvRows: Array<Array<string | number>>) => {
                                        const csvContent = `data:text/csv;charset=utf-8,${csvRows.map(e => e.join(",")).join("\n")}`;

                                        // See: https://stackoverflow.com/a/14966131/699963
                                        const filename = `EXPORT-${exportType}-${moment().format("YYYY_MM_DD-HH_mm_ss")}.csv`;
                                        const encodedUri = encodeURI(csvContent);
                                        const link = document.createElement("a");
                                        link.setAttribute("href", encodedUri);
                                        link.setAttribute("download", filename);
                                        document.body.appendChild(link); // Required for Firefox.
                                        link.click(); // This will download the data file named as `filename`.
                                        document.body.removeChild(link); // Cleanup.
                                    }

                                    if (targetTelemetryData.length === 0) {
                                        window.alert("There is no data to export.");
                                    } else {
                                        const csvHourlyRows = getReportHourlyCsvExportRows();
                                        exportCsvWithRows("SITE_PERFORMANCE", csvHourlyRows);
                                    }
                                }}
                            />
                        </Row3>
                    </Card>
                </ChartContainer>
            </ReportContainer>
        </Dashboard>
    );
};

export default Reporting;

const Container = styled.div`
    text-align: center;
    margin-top: 25vh;
`;

const ReportContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    border: solid 0;
    border-top: 1px solid #ececf1;
    background: transparent;
    padding: 30px 139px 30px;
    overflow: hidden;
`;

const DropDownWrapper = styled.div<{disabled?: boolean}>`

`;

const HeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: -30px;

    .able-text-style--HeadingC {
        color: #414141;
    }

    // Date range picker label
    .tl-text-input .tl-input .text-input-small {
        top: calc(-20px - 0.5em);
    }
    .tl-text-input .tl-input .text-input-small-focus {
        top: calc(-20px - 0.5em);
    }

    .tl-date-picker {
        width: 514px;

        .tl-text-input {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            
            input {
                width: 294px;
                height: 48px;

                border-bottom: unset;
                box-shadow: inset 0 0 0 0.0625rem #707070;
                transition: background-color 250ms linear, box-shadow 250ms linear, outline 250ms linear;
                padding: 0.5em;
                border-radius: 3px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;

                :hover:focus {
                    box-shadow: inset 0 0 0 0.125rem #0064d2, 0 0 0 0.125rem rgba(0,100,210,0.1607843137);
                }

                :focus {
                    box-shadow: inset 0 0 0 0.125rem #0064d2, 0 0 0 0.125rem rgba(0,100,210,0.1607843137);
                }

                :hover {
                    background-color: rgba(0,100,210,0.06);
                    box-shadow: inset 0 0 0 0.0625rem #0064d2;
                }

                ::selection {
                    background-color: transparent;
                }
                caret-color: transparent;
            }
        }
    }
`;
const Row = styled.div<{isViewButtonDisabled: boolean}>`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: -12px;

    .tl-dropdown-wrapper {
        width: 94% !important;
    }

    .tl-menu-list-filter-dropdown-v1 .tl-menu-list-options .tl-menu-list-option {
        width: 100%;
    }

    .tl-dropdown-v1, div:has(.tl-dropdown-v1) {
        width: 100% !important;

        div {
            width: 100%;
        }
    }

    .tl-dropdown-filter {
        width: 96%;
        height: 48px;

        box-shadow: inset 0 0 0 0.0625rem #707070;
        transition: background-color 250ms linear, box-shadow 250ms linear, outline 250ms linear;
        border-radius: 3px;
        cursor: pointer;

        :hover:focus {
            box-shadow: inset 0 0 0 0.125rem #0064d2, 0 0 0 0.125rem rgba(0,100,210,0.1607843137);
        }

        :focus {
            box-shadow: inset 0 0 0 0.125rem #0064d2, 0 0 0 0.125rem rgba(0,100,210,0.1607843137);
        }

        :hover {
            background-color: rgba(0,100,210,0.06);
            box-shadow: inset 0 0 0 0.0625rem #0064d2;
        }

        ::selection {
            background-color: transparent;
        }
        caret-color: transparent;
    }

    .tl-filter-options {
        width: 100%;

        .tl-filter-option-name {
            font-size: 16px;
            font-family: TelstraAkkurat-Regular;
            letter-spacing: -0.15px;
        }
    }

    .tl-dropdown-filter-header {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tl-selection-override {
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.5em;
        }

        .tl-dropdown-filter-label {
            font-family: TelstraAkkurat-Regular;

            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            color: #414141;
            fill: #414141;
        }

        svg {
            fill: #414141;
            transition: transform 0.3s ease-in;
        }
        svg.opened {
            transform: rotate(180deg);
        }
    }

    button {
        margin-top: 28px;
        min-width: 80px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .able-ActionButton {
        ${props => props.isViewButtonDisabled ? `
            background-color: ${greyL4};
            pointer-events: none;
        ` : ""}
    }
`;

const Row2 = styled.div<{isAggregateDisabled: boolean}>`
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;

    position: relative;

    .tl-icon-button {
        z-index: 0 !important;
    }

    .able-ActionButton--medium-emphasis {
        margin-top: 8px;
        // width: 140px;
        min-width: 140px;
        height: 48px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: 2px solid var(--interactiveForegroundNormal);



        ${props => props.isAggregateDisabled ? `
            color: ${greyL3};
            border: 2px solid ${greyL3};
            pointer-events: none;
        ` : ``}
    }
`;

const ChartContainer = styled.div`
    margin-top: 24px;
    height: 100%;

    .tl-card .tl-hide-animate {
        border: none;
        box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.12);
    }

    .highcharts-container {
        height: 100% !important;
    }

    .highcharts-root {
        height: 100%;
    }
`;

const DisableDropdownWrapper = styled.div<{disabled: boolean}>`
    ${props => props.disabled ? `
        .tl-dropdown-label, span {
            color: ${greyL3};
        }

        .tl-dropdown-button {
            background-color: white;
            box-shadow: inset 0 0 0 0.0625rem ${greyL3};
            pointer-events: none;

            :hover, :focus {
                background-color: white;
                box-shadow: inset 0 0 0 0.0625rem ${greyL3};
            }
        }

        svg {
            fill: ${greyL3};
        }

        .tl-dropdown-filter {
            box-shadow: inset 0 0 0 0.0625rem ${greyL3};
            background-color: white;
            pointer-events: none;

            svg {
                fill: ${greyL3};
            }
        }

        width: 100%;
    ` : `
        width: 100%;
    `}
`;

const Row3 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    > button {
        margin-left: 24px;
    }
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    padding-top: 10px;
    padding-right: 10px;

    .selected {
        color: black;
    }
`;

const MenuContainer = styled.div`
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    position: absolute;
    left: 0px;
    top: calc(100% + 8px);

    > div {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-end;

        > div {
            width: 200% !important;
        }

        .tl-menu-list-options {
            width: 100% !important;
        }

        .tl-menu-list-option {
            width: 95% !important;
            height: 100% !important;
        }
    }
`;

const AggregatePropContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 56px;
    margin-top: 8px;
    margin-left: 16px;
`;

const EmptyChart = styled.div`
    width: 100%;
    height: 505px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;;
`;

const EmptyChartPrompt = styled.span`
    color: ${greyL1};
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
`;

const AggregateMenuContainer = styled.div`
`;
