import axios from "axios";
import UserStore from "../../services/Store/UserStore";
import { errorCallback } from "../../services/WebService/ServiceHandlers";
// import { refreshToken } from "../../services";

// Add a request interceptor
axios.interceptors.request.use(
    async (request) => {
        const token = await UserStore.getAccessToken(); // await refreshToken();
        
        if (request.headers) {
            request.headers.Authorization = `Bearer ${token}`;
            request.headers["Content-Type"] = "application/json";
        }
        return request;
    },
    (err) => Promise.reject(err),
);

axios.interceptors.response.use(
    async (response) => {
        return response;
    },
    async (err) => {
        if (err?.response?.status) {
            // check for 401 and redirect back to login
            await errorCallback(err?.response?.status);
        }
    }
);