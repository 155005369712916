import withLayout from "../../components/hocs/withLayout";
import AppLayout from "../../components/Layout/AppLayout";
import useDevice from "../../hooks/Device/useDevice";
import useDeviceConfigs from "../../hooks/DeviceConfigs/useDeviceConfigs";
import useDirectMethod from "../../hooks/DirectMethod/useDirectMethod";
import useOrganisation from "../../hooks/Organisation/useOrganisation";
import useSite from "../../hooks/Site/useSite";
import React, { FC, useEffect, useState, ReactElement } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Box,
    CameraObjectItem,
    CameraObjectType,
    Container,
    FormikProvider,
    useFormik,
} from "telstra-components";
import strings from "../../strings/strings.json";
import CameraConfigView from "../../components/CameraConfig/CameraConfigView/CameraConfigView";
import ConfigPanel, { ConfigItem } from "../../components/CameraConfig/ConfigPanel/ConfigPanel";
import { Button } from "telstra-ui/components/button/Button";
import Breadcrumb from "telstra-ui/components/breadcrumb/Breadcrumb";
import { Device, DeviceConfig, MappedTags, Pipeline, ClassTag } from "../../types";
import { getLabelText } from "../../components/Tag/ClassTagList";
import { defaultPipeliene } from "../../helpers/DefaultDeviceConfig";
import { maxInstruments } from "../../config";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import { transformFromMappedTags, transformMappedTags } from "../../helpers/TagHelper";
import { validationSchemas } from "../../helpers/formValidation";
import { CameraObjectPoint, CameraObjectTripwire } from "telstra-components/build/components/CameraConfig/CameraConfigView";

const { errorModalTitle, errorModalDescription, okButtonLabel } = strings.components.Wizard.WizardModal.ErrorModal;

const {
    title,
    description,
    tripwireConfig,
    zoneConfig,
    saveButtonLabel,
    cancelButtonLabel,
    defaultZoneName,
    defaultTripwireName,
} = strings.views.CameraConfig;

interface LocationState {
    siteId: string;
    gatewayId: string;
    deviceId: string;
    configName: string;
}

type CameraObjectMetrics = {
    tags: Array<ClassTag>;
    classes: Array<string>;
}

type CameraObject = CameraObjectItem & CameraObjectMetrics;

export const CameraConfig = (): ReactElement => {
    const [cameraObjects, setCameraObjects] = useState<Array<CameraObject>>([]);
    const [displayError, setDisplayError] = useState(false);
    const [savingMode, setSavingMode] = useState(false);

    const location = useLocation().state as LocationState;
    const navigate = useNavigate();

    const { device, getDevice } = useDevice();
    const { device: edgeDevice, getDevice: getEdgeDevice } = useDevice();
    // TODO: remove this once we confirm we don't need anymore.
    // const { error, isLoading: isLoadingCamera, imageData, getCameraImage } = useDirectMethod();
    const {
        error: deviceConfigError,
        loading: deviceConfigLoading,
        deviceConfig,
        getDeviceConfig,
        addDeviceConfig,
    } = useDeviceConfigs();
    const { organisation, getOrganisation, isLoading: isLoadingOrg } = useOrganisation();
    const { site, getSite, loading: isLoadingSite } = useSite();

    const hasAddButton = cameraObjects.length < maxInstruments;

    const formik = useFormik({
        initialValues: defaultValues,
        validationSchema: () => {
            return validationSchemas.cameraConfig;
        },
        onSubmit: async () => {
            setSavingMode(true);

            const newDeviceConfig = deviceConfig?.config.pipeline || defaultPipeliene;

            newDeviceConfig.pipeline_device_id = location?.deviceId;
            newDeviceConfig.pipeline_name = location?.configName;

            newDeviceConfig.pipeline_modules.detector_module.detector_configuration.classes = allClasses;

            const lineModule = newDeviceConfig?.pipeline_modules?.line_module;
            lineModule.enabled = true;
            lineModule.lines = cameraObjects.filter(byCameraObject("type", "tripwire")).map(mapTripwireToLine);

            const polygonModule = newDeviceConfig.pipeline_modules.polygon_module;
            polygonModule.enabled = true;
            polygonModule.polygons = cameraObjects.filter(byCameraObject("type", "zone")).map(mapZoneToPolygon);

            addDeviceConfig(location?.configName, location?.gatewayId, newDeviceConfig);
        },
    });

    useEffect(() => {
        if (savingMode && deviceConfigError.message) {
            setDisplayError(true);
        } else if (savingMode && deviceConfig) {
            navigate("/administration/organisations", { state:{ expandId: deviceConfig?.id }});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceConfigError, deviceConfig]);

    useEffect(() => {
        if (!savingMode && deviceConfig) {
            setCameraObjects(mapConfigToCameraObject(deviceConfig?.config?.pipeline));
        }
    }, [deviceConfig, savingMode]);

    useEffect(() => {
        formik.validateForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cameraObjects]);

    useEffect(() => {
        if(location) {
            getDevice(location?.deviceId);
            getSite(location?.siteId);
        } else {
            navigate("/administration/organisations");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        if (device.id) {
            // console.log(device)
            getEdgeDevice(device.edgeDevice)
            refreshCameraData(device.edgeDevice);
        }
    }, [device.id]);

    const getImageFromCamera = (device: Device) => getCameraImage(device.edgeDevice, device.id, device.moduleName);

    const handleCancel = () => navigate("/administration/organisations");
    const handleAddCameraObjects = (type: CameraObjectType) => () => {
        if (type === "zone") {
            setCameraObjects((previous) => [
                ...previous, {
                    id: (Math.max(...previous.map((p) => Number(p.id)), 0) + 1).toString(),
                    name: `${defaultZoneName}-${previous.filter((i) => i.type === type).length}`,
                    points: getCameraObjectDefaultPoints(type),
                    type: type,
                    tags: [],
                    classes: [],
                },
            ]);
        } else {
            setCameraObjects((previous) => [
                ...previous, {
                    id: (Math.max(...previous.map((p) => Number(p.id)), 0) + 1).toString(),
                    name: `${defaultTripwireName}-${previous.filter((i) => i.type === type).length}`,
                    points: getCameraObjectDefaultPoints(type),
                    type: type,
                    direction: 0,
                    tags: [],
                    classes: [],
                },
            ]);
        }
    };

    const handleCameraObject = (id: string) => {
        setCameraObjects((previous) => previous.filter((item) => item.id !== id));
    };

    const handleCameraObjectChange = (cameraObject: CameraObjectItem) => {
        // setCameraObjects((previous) => {
        //     return previous.map(item => {
        //         if (item.id === cameraObject.id) {
        //             return {
        //                 ...item,
        //                 ...cameraObject,
        //             };
        //         }
        //         return item;
        //     })
        // });
        setCameraObjects((previous) => {
            const item = previous.find((item) => item.id === cameraObject.id);
            if (!item) return previous;
            Object.assign(item, cameraObject);
            return [...previous];
        });
    };

    const handleCameraObjectNameChange = (id: string, value: string) => {
        setCameraObjects((previous) => {
            const item = previous.find((item) => item.id === id);
            if (!item) return previous;
            item.name = value;
            return [...previous];
        });
    };

    const handleCameraObjectEdit = (id: string) => {
        setCameraObjects((previous) => {
            previous.forEach((item) => {
                item.editMode = false;
            });
            const item = previous.find((item) => item.id === id);
            if (!item) return previous;
            item.editMode = true;
            return [...previous.filter((i) => i.id !== item.id), item];
        });
    };

    const handleEmptySpaceClick = () => {
        setCameraObjects((previous) => previous.map((object) => ({ ...object, editMode: false })));
    };

    const handleErrorRetryClick = () => {
        if (device.id) {
            getImageFromCamera(device);
        }
    };

    const refreshCameraData = (gatewayId: string, deviceId: string) => {
        getImageFromCamera(device);
        getDeviceConfig(location?.configName, gatewayId, deviceId);
    };

    const isImageLoaded = !isLoadingCamera && error.status === undefined;

    const handleAddTag = (id: string, tag: ClassTag) => {
        setCameraObjects((previous) => {
            const cameraObject = previous.find((i) => i.id === id);
            if (!cameraObject) return previous;

            cameraObject.tags = [tag, ...cameraObject.tags];
            return [...previous];
        });
    };

    const handleRemoveTag = (id: string, tag: ClassTag) => {
        setCameraObjects((previous) => {
            const cameraObject = previous.find((i) => i.id === id);
            if (!cameraObject) return previous;
            cameraObject.tags = cameraObject.tags.filter((t) => getLabelText(t) !== getLabelText(tag));
            return [...previous];
        });
    };

    const handleAddClass = (id: string, value: string) => {
        setCameraObjects((previous) => {
            const cameraObject = previous.find((i) => i.id === id);
            if (!cameraObject) return previous;
            cameraObject.classes = [value, ...cameraObject.classes];
            return [...previous];
        });
    };

    const handleRemoveClass = (id: string, value: string) => {
        setCameraObjects((previous) => {
            const cameraObject = previous.find((i) => i.id === id);
            if (!cameraObject) return previous;
            cameraObject.classes = cameraObject.classes.filter((c) => c !== value);
            return [...previous];
        });
    };

    const allClasses = [...new Set(cameraObjects.flatMap((object) => object.classes))];

    const handleConfirmationClick = () => setDisplayError(false);

    const isSaveButtonDisable = () => !deviceConfigLoading.addDeviceConfig && !(cameraObjects.length > 0);

    return (
        <>
            <ConfirmationModal
                isOpen={displayError}
                title={errorModalTitle}
                bodyParagraph1={errorModalDescription}
                yesLabel={okButtonLabel}
                onYesClick={handleConfirmationClick}
            />
            <Box marginLeft="xxxl" marginTop="xs">
                {!isLoadingOrg && !isLoadingSite.getSite && (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <Breadcrumb
                        separator="icon-system-right-arrow"
                        items={[
                            { active: false, disabled: false, label: organisation?.name, onClick: () => {
                                navigate("/administration/organisations", { state: { expandId: site?.siteId } })
                            } },
                            { active: false, disabled: false, label: site?.name, onClick: () => {
                                navigate("/administration/organisations", { state: { expandId: device?.edgeDevice } })
                            } },
                            { active: false, disabled: false, label: edgeDevice.name, onClick: () => {
                                // console.log(device);
                                // console.log(edgeDevice);
                                navigate("/administration/organisations", { state: { expandId: device?.id } })
                            } },
                            { active: false, disabled: true, label: device?.name },
                        ]}
                    />
                )}
                <Container>
                    <Box marginTop="xxxl">
                        <CameraConfigView
                            leafDeviceId={device.id}
                            edgeDeviceId={device.edgeDevice}
                            // TODO: remove this once we confirm we don't need anymore.
                            // imageSrc={imageData?.imageResponse?.image ? "data:image/jpeg;base64," + imageData?.imageResponse?.image : ""}
                            cameraObjects={cameraObjects}
                            onCameraObjectChange={handleCameraObjectChange}
                            stage={isLoadingCamera ? "loading" : error.status ? "error" : "normal"}
                            onErrorRetryClick={handleErrorRetryClick}
                            onErrorCancelClick={handleCancel}
                            onEmptySpaceClick={handleEmptySpaceClick}
                            onCameraObjectClick={handleCameraObjectEdit}
                        />
                        {isImageLoaded && (
                            <>
                                <Box marginTop="xs">
                                    <span className="heading16">{title}</span>
                                    <p className="body16">{description}</p>
                                </Box>
                                <FormikProvider value={formik}>
                                <ConfigPanel
                                        title={tripwireConfig.title}
                                        description={tripwireConfig.description}
                                        items={cameraObjects
                                            .filter(byCameraObject("type", "tripwire"))
                                            .map(mapCameraObjectToConfigItem)
                                            .sort(sortCameraObjectToConfigItem)}
                                        onAddClick={handleAddCameraObjects("tripwire")}
                                        onDeleteClick={handleCameraObject}
                                        onNameChange={handleCameraObjectNameChange}
                                        onClickEdit={handleCameraObjectEdit}
                                        onAddTag={handleAddTag}
                                        onRemoveTag={handleRemoveTag}
                                        onAddClass={handleAddClass}
                                        onRemoveClass={handleRemoveClass}
                                        addButtonDisabled={!hasAddButton}
                                    />
                                    <ConfigPanel
                                        title={zoneConfig.title}
                                        description={zoneConfig.description}
                                        items={cameraObjects
                                            .filter(byCameraObject("type", "zone"))
                                            .map(mapCameraObjectToConfigItem)
                                            .sort(sortCameraObjectToConfigItem)}
                                        onAddClick={handleAddCameraObjects("zone")}
                                        onDeleteClick={handleCameraObject}
                                        onNameChange={handleCameraObjectNameChange}
                                        onClickEdit={handleCameraObjectEdit}
                                        onAddTag={handleAddTag}
                                        onRemoveTag={handleRemoveTag}
                                        onAddClass={handleAddClass}
                                        onRemoveClass={handleRemoveClass}
                                        addButtonDisabled={!hasAddButton}
                                    />
                                </FormikProvider>
                                <Box display="flex" justifyContent="end" marginTop="xxxl">
                                    <Box marginRight="xxxs">
                                        <Button
                                            type="button"
                                            variant="super"
                                            label={cancelButtonLabel}
                                            onClick={handleCancel}
                                            disabled={deviceConfigLoading.addDeviceConfig}
                                        />
                                    </Box>
                                    <Button
                                        type="submit"
                                        variant="emphasis"
                                        label={saveButtonLabel}
                                        size="medium"
                                        onClick={formik.submitForm}
                                        disabled={isSaveButtonDisable()}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                </Container>
            </Box>
        </>
    );
};

// export default withLayout(AppLayout)(CameraConfig);

const defaultValues = {
    tripwire: [
        {
            name: "",
        },
    ],
    zone: [
        {
            name: "",
        },
    ],
};

const getCameraObjectDefaultPoints = (type: CameraObjectType) => {
    if (type === "zone") {
        return [
            { x: 0.44, y: 0.44 },
            { x: 0.56, y: 0.44 },
            { x: 0.56, y: 0.64 },
            { x: 0.44, y: 0.64 },
        ];
    }

    return [
        { x: 0.44, y: 0.44 },
        { x: 0.56, y: 0.44 },
    ];
};

const mapCameraObjectToConfigItem = (
    cameraObject: CameraObject
): ConfigItem => ({
    id: cameraObject.id,
    name: cameraObject.name,
    type: cameraObject.type,
    active: cameraObject.editMode,
    tags: cameraObject.tags,
    classes: cameraObject.classes,
});

const sortCameraObjectToConfigItem = (a: ConfigItem, b: ConfigItem): number => {
    if (a.id > b.id) return 0;
    return -1;
};

function byCameraObject<K extends string, V extends string>(key: K, value: V) {
    return <T extends Record<K, any>>(
        obj: T & Record<K, V extends T[K] ? T[K] : V>
    ): obj is Extract<T, Record<K, V>> => obj[key] === value;
}

const mapConfigToCameraObject = (pipeline?: Pipeline): Array<CameraObject> => {
    const cameraObjects: Array<CameraObject> = [];
    let index = 0;

    if (!pipeline) return cameraObjects;

    const lineModule = pipeline?.pipeline_modules?.line_module;
    if (lineModule?.enabled && lineModule?.lines) {
        lineModule.lines.forEach(line => {
            cameraObjects.push({
                id: (index++).toString(),
                name: line.name,
                points: translatePoints(line.points),
                direction: line.direction,
                type: "tripwire",
                tags: transformFromMappedTags(line.tags),
                classes: line.classes || [],
            });
        });
    }

    const polygonModule = pipeline?.pipeline_modules?.polygon_module;
    if (polygonModule?.enabled && polygonModule?.polygons) {
        polygonModule.polygons.forEach(polygon => {
            cameraObjects.push({
                id: (index++).toString(),
                name: polygon.name,
                points: translatePoints(polygon.contour),
                type: "zone",
                tags: transformFromMappedTags(polygon.tags),
                classes: polygon.classes || [],
            });
        });
    }

    return cameraObjects;
};

const mapTripwireToLine = (
    item: CameraObjectTripwire & CameraObjectMetrics
) => {
    console.log(item);
    return {
        points: translatePoints(item.points),
        name: item.name,
        direction: item.direction,
        tags: transformMappedTags(item.tags),
        classes: item.classes,
    };
};

const mapZoneToPolygon = (item: CameraObject) => {
    return {
        contour: translatePoints(item.points),
        name: item.name,
        tags: transformMappedTags(item.tags),
        classes: item.classes,
    };
};

const translatePoints = (
    points: CameraObjectPoint[] = []
): CameraObjectPoint[] => {
    return points.map(point => ({ x: point.y, y: point.x }));
};
