import React, { ReactNode } from "react";
import { NavigationBar } from "./../../components";
import { Spring, config } from "react-spring/renderprops.cjs";
import styled from "styled-components";
import { observer } from "mobx-react";
import { useStore } from "../../services";
import { SmartSpaces } from "../../services/WebService/SmartSpaces";
import strings from "../../smart-video/strings/strings.json";

interface Props {
    children: ReactNode;
    toolbar?: ReactNode;
    shouldHide?: boolean;
}

const Dashboard: React.FC<Props> = observer((props: Props) => {
    const store = useStore();
    const { applayoutTopNavTitle } = strings.components.Layout.AppLayout;
    const { children } = props;
    const tabData: Array<SmartSpaces.WebApp.Features.Navigation> | [] = store.features.getFeature("Navigation") ?? [];

    const shouldHide = !!props.shouldHide;

    return (
        <Spring from={{ opacity: 0 }} to={{ opacity: 1 }} config={config.gentle}>
            {(props): ReactNode => (
                <AppLayout shouldHide={shouldHide}>
                    <NavigationBar labelBold={applayoutTopNavTitle} tabData={tabData} />
                    {/* ---- Start render all children ---- */}
                    <AppView style={props}>{children}</AppView>
                </AppLayout>
            )}
        </Spring>
    );
});

const AppLayout = styled.div<{ shouldHide: boolean }>`
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    overflow-y: auto;
    user-select: none;
    background: #ffffff;
    display: ${(props) => props.shouldHide ? "none" : "block"};
`;

const AppView = styled.div`
    display: flex;
    flex-direction: column;
    user-select: none;
`;

export default Dashboard;
