import React, { ReactElement } from "react";
import styled from "styled-components";

interface Props {
  fill?: string;
}

const SVGOrganisationIcon = ({ fill }: Props): ReactElement => {
  return (
    <SVGWrapper
      id="enterpriseIcon"
      width="100%"
      height="100%"
      fillColor={fill || ""}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.78 16.56"
    >
      <title>Organisation Icon</title>
      <path
        d="M13.65,9.7h-3.3A1.28,1.28,0,0,1,9.07,8.42V5.13a1.28,1.28,0,0,1,1.28-1.28h3.3a1.28,1.28,0,0,1,1.28,1.28V8.42A1.28,1.28,0,0,1,13.65,9.7Zm-3.3-4.65a.08.08,0,0,0-.08.08V8.42a.08.08,0,0,0,.08.08h3.3a.08.08,0,0,0,.08-.08V5.13a.08.08,0,0,0-.08-.08Z"
        transform="translate(-2.1 -3.85)"
      />
      <path
        d="M6.68,20.41H3.38A1.29,1.29,0,0,1,2.1,19.13v-3.3a1.28,1.28,0,0,1,1.28-1.28h3.3A1.28,1.28,0,0,1,8,15.83v3.3A1.28,1.28,0,0,1,6.68,20.41Zm-3.3-4.66a.07.07,0,0,0-.07.08v3.3a.08.08,0,0,0,.07.08h3.3a.08.08,0,0,0,.08-.08v-3.3a.07.07,0,0,0-.08-.08Z"
        transform="translate(-2.1 -3.85)"
      />
      <path
        d="M13.65,20.41h-3.3a1.29,1.29,0,0,1-1.28-1.28v-3.3a1.28,1.28,0,0,1,1.28-1.28h3.3a1.28,1.28,0,0,1,1.28,1.28v3.3A1.29,1.29,0,0,1,13.65,20.41Zm-3.3-4.66a.08.08,0,0,0-.08.08v3.3a.09.09,0,0,0,.08.08h3.3a.09.09,0,0,0,.08-.08v-3.3a.08.08,0,0,0-.08-.08Z"
        transform="translate(-2.1 -3.85)"
      />
      <path
        d="M20.61,20.41h-3.3A1.29,1.29,0,0,1,16,19.13v-3.3a1.28,1.28,0,0,1,1.28-1.28h3.3a1.28,1.28,0,0,1,1.28,1.28v3.3A1.28,1.28,0,0,1,20.61,20.41Zm-3.3-4.66a.08.08,0,0,0-.08.08v3.3a.09.09,0,0,0,.08.08h3.3a.09.09,0,0,0,.08-.08v-3.3a.08.08,0,0,0-.08-.08Z"
        transform="translate(-2.1 -3.85)"
      />
      <path
        d="M19.56,14.55h-1.2V13H5.63v1.59H4.43v-2a.82.82,0,0,1,.8-.83H18.76a.82.82,0,0,1,.8.83Z"
        transform="translate(-2.1 -3.85)"
      />
    </SVGWrapper>
  );
};

export default SVGOrganisationIcon;

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
