import React, { FC } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { logout } from "./../../services";

const UserMenu: FC = () => {
    const handleOnClick = (): void => {
        logout();
    };

    return (
        <UserMenuContainer>
            <span role="img" aria-label="Profile picture of John Doe">
                👨‍🚀
            </span>{" "}
            John Doe &nbsp;
            <Link to="#logout" onClick={handleOnClick}>
                Sign out
            </Link>
        </UserMenuContainer>
    );
};

const UserMenuContainer = styled.div`
    -webkit-box-align: center;
    -webkit-box-pack: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-left: 1px solid rgb(236, 236, 241);
    display: flex;
    justify-content: center;
    line-height: 1.15;
    padding-left: 24px;
    padding-right: 24px;
    user-select: none;
`;

export default UserMenu;
