import React, { ReactElement } from "react";
import styled from "styled-components";

interface Props {
  fill?: string;
  title?: string;
}

const SVGIconOnline = ({ fill, title }: Props): ReactElement => {
  return (
    <SVGWrapper
      id="onlineIcon"
      height="100%"
      fillColor={fill || ""}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29.5 29.5"
    >
      <title>{title}</title>
      <clipPath id="online-icon-clip-path" transform="translate(2.75 2.75)">
        <path
          fill="none"
          clipRule="evenodd"
          d="M16.4,8.27a1,1,0,0,0-.75.32L10.5,13.73,8.38,11.62a1.09,1.09,0,0,0-.8-.32,1.13,1.13,0,0,0-1,.69,1.15,1.15,0,0,0,.25,1.23l2.91,2.9a1,1,0,0,0,.38.25.84.84,0,0,0,.41.08h.13a1.1,1.1,0,0,0,.67-.33v0l5.9-5.9a1.07,1.07,0,0,0,.32-.77,1.06,1.06,0,0,0-.32-.77l0-.05A1.09,1.09,0,0,0,16.4,8.27Zm-4.4-6A9.75,9.75,0,1,1,2.25,12,9.72,9.72,0,0,1,12,2.25Z"
        />
      </clipPath>
      <g clipPath="url(#online-icon-clip-path)">
        <rect width="29.5" height="29.5" />
      </g>
    </SVGWrapper>
  );
};

export default SVGIconOnline;

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
