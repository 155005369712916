import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { animated, config, useChain, useSpring, useTransition } from "react-spring";
import styled from "styled-components";
import { media } from "../../../config/Config";
import { SpringRendererFunc, Transition } from "react-spring/renderprops.cjs";
import { useInterval } from "../../../services/Login/IntervalHook";
import strings from "../../../smart-video/strings/strings.json";

export interface Props {
    progress: number;
    callback?: () => void;
}

const loadingMessages = [
    "Fetching your device profiles",
    "Drawing up floor plans",
    "Preparing your space analytics",
    "Collecting people count data",
    "Calculating occupancy metrics",
    "Calculating vacancy metrics",
    "Analysing space utilisation",
];

// Animation delays, in seconds
const InitialLogoLoadTime = 0.7;
const LogoLoadTime = 1;
const MessageDisplayTime = 2;

export const LoginLoadingPage: FC<Props> = (props: Props) => {
    const [hasShownLogo, setHasShownLogo] = useState(false);
    const [showProgressMessages, setShowProgressMessages] = useState(false);
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const [elapsedLoadingTimeSeconds, setElapsedLoadingTimeSeconds] = useState(0);
    const [messageDelay, setMessageDelay] = useState(500);

    const { applayoutTopNavTitle } = strings.components.Layout.AppLayout;
    // Only show progress indicator when logo animation has completed, and when loading progress isn't complete when the first message is about to be or being shown.
    const shouldShowProgress = hasShownLogo && !(props.progress === 1 && currentMessageIndex === 0);

    useEffect(() => {
        let isMounted = true;

        if (!hasShownLogo) {
            const id = setTimeout(() => {
                if (isMounted) {
                    props.callback?.();
                    setHasShownLogo(true);
                    setShowProgressMessages(true);
                    setMessageDelay(0);
                }
            }, (InitialLogoLoadTime + LogoLoadTime) * 1000);

            return (): void => {
                isMounted = false;
                clearTimeout(id);
            };
        }
    }, [hasShownLogo, props.progress, props.callback]);

    useInterval(
        () => {
            setShowProgressMessages(false);
            setTimeout(() => {
                setShowProgressMessages(true);
                const nextMessageIndex = (currentMessageIndex + 1) % loadingMessages.length;
                setCurrentMessageIndex(nextMessageIndex);
            }, 300);
        },
        shouldShowProgress ? (messageDelay + MessageDisplayTime) * 1000 : null,
    );

    useInterval(() => {
        setElapsedLoadingTimeSeconds(elapsedLoadingTimeSeconds + 1);
    }, 1 * 1000);

    const currentMessage = loadingMessages[currentMessageIndex];
    const logoRevealSpringRef = useRef(null);

    const logoRevealSpring = useSpring({
        ref: logoRevealSpringRef,
        opacity: 1,
        from: { opacity: 0 },
    });

    const logoTitleRevealSpringRef = useRef(null);
    const logoTitleRevealSpring = useSpring({
        ref: logoTitleRevealSpringRef,
        opacity: 1,
        width: isMobileOnly ? 284 : 494,
        from: { opacity: 0, width: 0 },
        config: { tension: 120, friction: 40 },
    });

    useChain([logoRevealSpringRef, logoTitleRevealSpringRef], [0, InitialLogoLoadTime], 1000);

    const progressRevealSpring = useSpring({
        opacity: shouldShowProgress ? 1 : 0,
        from: { opacity: 0 },
        config: config.stiff,
    });

    const progressTextTransition = useTransition(
        { showProgressMessages, currentMessage },
        (item) => `${item.currentMessage}${item.showProgressMessages}`,
        {
            from: (item) => (item.showProgressMessages ? { opacity: 0, bottom: 0 } : { opacity: 0, bottom: 30 }),
            enter: { opacity: 1, bottom: 30 },
            leave: { opacity: -1, bottom: 30 + 30 },
        },
    );

    const progressSpring = useSpring({
        width: 413 * Math.min(props.progress, 1),
        from: { width: 0 },
        config: { mass: 1, tension: 210, friction: 20, clamp: true },
    });

    return (
        <Container>
            <Transition
                items={props.progress < 1}
                from={{ marginTop: 0, width: "100%" }}
                enter={{ marginTop: 0 }}
                leave={{ marginTop: 50 }}
                config={{ delay: 200 }}
            >
                {(show): SpringRendererFunc | boolean =>
                    show &&
                    ((transitionLoadingProps): ReactNode => (
                        <div style={transitionLoadingProps}>
                            <LogoContainer style={logoRevealSpring}>
                                <Logo alt="" src={"/images/t-logo-blue.svg"} />
                                <TitleContainer style={logoTitleRevealSpring}>
                                    <Title>
                                        <strong>{applayoutTopNavTitle}</strong>
                                    </Title>
                                </TitleContainer>
                            </LogoContainer>
                            <LoadingIndicatorContainer style={progressRevealSpring}>
                                {progressTextTransition.map(({ item, props: animationProps, key }) => {
                                    return (
                                        <LoadingIndicatorTextContainer key={key} style={animationProps}>
                                            <LoadingIndicatorText>
                                                {item.showProgressMessages ? `${item.currentMessage}...` : ""}
                                            </LoadingIndicatorText>
                                        </LoadingIndicatorTextContainer>
                                    );
                                })}
                                <LoadingIndicatorProgressBarContainer>
                                    <LoadingIndicatorProgressBarBackground />
                                    <LoadingIndicatorProgressBarFill
                                        style={progressSpring}
                                        percentage={props.progress * 100}
                                    />
                                </LoadingIndicatorProgressBarContainer>
                            </LoadingIndicatorContainer>
                        </div>
                    ))
                }
            </Transition>
        </Container>
    );
};

export default LoginLoadingPage;

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: white;
    width: 100vw;
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    // Disable image selection
    img {
        pointer-events: none;
    }
    ${media.mobile} {
        height: 100%;
    }
`;

const LogoContainer = styled(animated.div)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

const Logo = styled.img`
    height: 51px;
    width: 44px;
    margin-top: 20px;
    ${media.mobile} {
        width: 35px;
        height: auto;
        margin-top: 0;
    }
`;

const TitleContainer = styled(animated.div)`
    height: 50px;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    ${media.mobile} {
        justify-content: center;
    }
`;

const Title = styled.div`
    height: 50px;
    color: #001e82;
    font-size: 42px;
    line-height: 50px;

    text-align: end;
    white-space: nowrap;
    ${media.mobile} {
        font-size: 1.6em;
        line-height: 40px;
    }
`;

const LoadingIndicatorContainer = styled(animated.div)`
    display: flex;
    flex-direction: column;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: 70px;
`;

const LoadingIndicatorTextContainer = styled(animated.div)`
    height: 21px;
    opacity: 0;
    position: absolute;
    bottom: 30px;
`;

const LoadingIndicatorText = styled.div`
    height: 21px;
    color: #3e3e3e;
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;
    text-align: center;
`;

const LoadingIndicatorProgressBarContainer = styled.div`
    height: 8px;
    width: 413px;
    margin-top: 30px;
    ${media.mobile} {
        width: 90%;
    }
`;

const LoadingIndicatorProgressBarBackground = styled.div`
    height: 8px;
    width: 413px;
    border-radius: 10px;
    background-color: #ececf1;
    ${media.mobile} {
        width: 100%;
    }
`;

const LoadingIndicatorProgressBarFill = styled(animated.div)<{ percentage: number }>`
    height: 8px;
    width: calc(413px * ${(props): {} => Math.min(props.percentage / 100, 1)});
    border-radius: 10px;
    background-color: #0099f8;

    margin-top: -8px;
    ${media.mobile} {
        max-width: 100% !important;
    }
`;
