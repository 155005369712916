import React, { ReactElement } from "react";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { SvgIcon } from "telstra-ui/components/svg/Icon";

interface Props {
    icon?: string;
    iconColor?: string;
}

const SVGIcon = ({ icon, iconColor }: Props): ReactElement => {
    return <SVGWrapper name={icon} iconColor={iconColor} />;
};

export default SVGIcon;

const SVGWrapper = styled(SvgIcon)<{ iconColor?: string }>`
    && {
        height: 20px;
        width: 20px;
        fill: ${(props) => props.iconColor};
    }
`;
