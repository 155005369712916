import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Body16, Body18, Heading20, Spinner, greyL3, green } from "telstra-components";
import { Button } from 'telstra-ui/components/button/Button';
import { Dropdown } from 'telstra-ui/components/drop-down/Dropdown';
import TagList from '../../../smart-video/components/Tag/TagList';
import { Tag } from '@sv/types';
import { ConfirmationDestructiveDialog, useToggle } from '@able/react';
import EditTagModal from './EditTagModal';
import { machineLearningModels, algorithmsMap, topDownObjects, multiDetectionObjects } from "./config";
import { useTransformObjectsToOptions } from "../../../hooks/Utils";

type DropdownOption = {
    itemId?: string;
    displayText?: string;
};

export type DetectObjectsOptions = {
    displayText: string;
    itemId: string;
    defaultAlgorithm?: DropdownOption;
    currentAlgorithm?: DropdownOption;
    availableAlgorithms?: Array<DropdownOption>;
};

export const defaultOption: DetectObjectsOptions = {
    displayText: "",
    itemId: "",
    defaultAlgorithm: {
        itemId: "",
        displayText: "",
    },
    currentAlgorithm: {
        itemId: "",
        displayText: "",
    },
    availableAlgorithms: [],
};

type DetectObjectsType = {
    isLoading: boolean;
    onNext: (selectedModel: DetectObjectsOptions, selectedObjects: Array<DetectObjectsOptions>) => void;
};

const DetectObjects: React.FC<DetectObjectsType> = ({ isLoading, onNext }) => {
    const [selectedModel, setSelectedModel] = useState<DetectObjectsOptions>(defaultOption);
    const [isModalConfirmed, setIsModalConfirmed] = useState(false);
    const [selectedModelOption, setSelectedModelOption] = useState<DetectObjectsOptions>(defaultOption);
    const [selectedObjects, setSelectedObjects] = useState<Array<DetectObjectsOptions>>([defaultOption]);
    const [isNextDisabled, setIsNextDisabled] = useState(true);
    const [objectTags, setObjectTags] = useState<Array<Tag>>([]);
    const [selectNewModelIsOpen, toggleSelectNewModal] = useToggle();
    const [isAlgorithmModalOpen, setIsAlgorithmModalOpen] = useState(false);
    const [objectToEdit, setObjectToEdit] = useState<DetectObjectsOptions>(defaultOption);
    const [editedObjects, setEditedObjects] = useState<Array<DetectObjectsOptions>>([defaultOption]);
    const [editedObjectTags, setEditedObjectTags] = useState<Array<Tag>>([]);

    const generateObjectTags = (objects: Array<DetectObjectsOptions>, editedObject?: boolean) => {
        const tags = objects.map((object, i) => {
            return {
                id: i, // TODO (@Shawn): please wire this up based on the new schema
                category: object.displayText, // TODO (@Shawn): please wire this up based on the new schema
                type: object.displayText, // TODO (@Shawn): please wire this up based on the new schema
                displayValue: editedObject
                    ? `${object.displayText} = ${object.currentAlgorithm?.displayText}`
                    : object.displayText,
                icon: "icon-authentication-register",
            } as Tag;
        });
        return tags;
    };

    useEffect(() => {
        if (selectedObjects[0] === defaultOption) {
            selectedObjects.shift();
        }

        if (editedObjects[0] === defaultOption) {
            editedObjects.shift();
        }

        setIsNextDisabled(
            selectedObjects[0] === defaultOption ||
                (selectedObjects[0] === undefined && editedObjects[0] === undefined),
        );
        setObjectTags(generateObjectTags(selectedObjects));
        setEditedObjectTags(generateObjectTags(editedObjects, true));
    }, [selectedModel, selectedObjects, editedObjects]);

    const onSelectModel = (event: Event) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const option = (event.target as any).textContent;
        const selectedOption = machineLearningModels.find((machineModel) => {
            return machineModel?.displayText === option;
        }) as DetectObjectsOptions;

        if (
            selectedModel !== defaultOption &&
            selectedModel?.displayText !== selectedOption?.displayText &&
            (selectedObjects.length > 0 || editedObjects.length > 0)
        ) {
            setIsModalConfirmed(false);
            setSelectedModelOption(selectedOption);
            toggleSelectNewModal();
        } else {
            setSelectedModel(selectedOption);
        }
    };

    useEffect(() => {
        if (isModalConfirmed) {
            setSelectedModel(selectedModelOption);
        }
    }, [isModalConfirmed, selectedModelOption]);

    const onSelectObject = (event: Event) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const option = (event.target as any).textContent;
        const dropdownOptions = getObjectsOptions();
        const selectedOption = dropdownOptions.find((objects) => {
            return objects?.displayText === option;
        }) as DetectObjectsOptions;

        // Remove the selectedOption from the editedObjects array to reset its edited state
        setEditedObjects((prevItems) => prevItems.filter((obj) => obj.itemId !== selectedOption.itemId));

        setSelectedObjects((prevItems) => [...prevItems, selectedOption]);
    };

    const confirmNewModelSelect = {
        onClick: () => {
            setIsModalConfirmed(true);
            setSelectedObjects([]);
            setEditedObjects([]);
        },
    };

    const multiDetectionOptions = useTransformObjectsToOptions(multiDetectionObjects, algorithmsMap);
    const topDownOptions = useTransformObjectsToOptions(topDownObjects, algorithmsMap);

    const getObjectsOptions = (): Array<DetectObjectsOptions> => {
        if (selectedModel.itemId === "object-detection-model-option") {
            return multiDetectionOptions.filter(
                (modelObject: DetectObjectsOptions) =>
                    !selectedObjects.some((selectedObject) => selectedObject.itemId === modelObject.itemId) &&
                    !editedObjects.some((editedObject) => editedObject.itemId === modelObject.itemId),
            );
        } else if (selectedModel.itemId === "top-down-people-detection-model-option") {
            return topDownOptions.filter(
                (modelObject) =>
                    !selectedObjects.some((selectedObject) => selectedObject.itemId === modelObject.itemId) &&
                    !editedObjects.some((editedObject) => editedObject.itemId === modelObject.itemId),
            );
        } else {
            return [defaultOption];
        }
    };

    const onSelectEditTag = (tag: Tag) => {
        const objectToUpdate = selectedObjects.find((obj) => {
            return obj.displayText === tag.displayValue; // TODO (@Shawn): please wire this up based on the new schema
        });

        if (objectToUpdate) {
            setObjectToEdit(objectToUpdate);
        }
        setIsAlgorithmModalOpen(true);
    };

    const onSelectUpdateEditTag = (tag: Tag) => {
        const objectToUpdate = editedObjects.find((obj) => {
            return obj.displayText === tag.displayValue; // TODO (@Shawn): please wire this up based on the new schema
        });
        if (objectToUpdate) {
            setObjectToEdit(objectToUpdate);
        }
        setIsAlgorithmModalOpen(true);
    };

    const onRemoveEditTag = (editedObject: DetectObjectsOptions) => {
        const updatedSelectedObjects = selectedObjects.filter((object) => object.itemId !== editedObject.itemId);
        setSelectedObjects(updatedSelectedObjects);

        const updatedEditedObjects = editedObjects.filter((object) => object.itemId !== editedObject.itemId);
        setEditedObjects(updatedEditedObjects);

        setIsAlgorithmModalOpen(false);
    };

    const onUpdateObjectAlgorithm = (editedObject: DetectObjectsOptions) => {
        // Remove tag from selected objects (blue)
        const updatedObjects = selectedObjects.filter((item) => item !== objectToEdit);

        // Update editedObjects array
        if (editedObjects.some((obj) => obj.itemId === editedObject.itemId)) {
            // If edited object already exists, replace it with the new one
            setEditedObjects((prevItems) =>
                prevItems.map((obj) => (obj.itemId === editedObject.itemId ? editedObject : obj)),
            );
        } else {
            // Otherwise, add the new edited object to the array
            setEditedObjects((prevItems) => [...prevItems, editedObject]);
        }

        setSelectedObjects(updatedObjects);
        setIsAlgorithmModalOpen(false);
    };

    return (
        <Container>
            <ConfirmationDestructiveDialog
                isShowing={selectNewModelIsOpen}
                setHideDialog={toggleSelectNewModal}
                title="Are you sure you want to select a new machine learning model?"
                description="Selecting a new machine learning model will delete previously selected objects."
                stackButtonOnVXS={false}
                confirmButtonLabel="Delete"
                confirmButtonEvents={confirmNewModelSelect}
                cancelButtonLabel="Cancel"
                cancelButtonEvents={{}}
                developmentUrl={`http://${window.location.host}/able-sprites.svg`}
            />
            <EditTagModal
                isOpen={isAlgorithmModalOpen}
                onCancel={() => setIsAlgorithmModalOpen(false)}
                selectedObject={objectToEdit}
                onDelete={(editedObject) => onRemoveEditTag(editedObject)}
                onSubmit={(editedObject) => onUpdateObjectAlgorithm(editedObject)}
            />
            <FormContainer>
                <ScrollContainer>
                    <Heading20 style={{ marginBottom: 10 }}>
                        Select the machine learning model and objects to detect
                    </Heading20>
                    <DescriptionContainer>
                        <Body16>
                            A machine learning model is a program which has been trained to recognise certain categories
                            of objects such as vehicles or farm animals or people.
                        </Body16>
                    </DescriptionContainer>
                    <ModelObjectsContainer>
                        <ModelRowContainer>
                            <DropdownLabelContainer>
                                <Body18 regular>Machine learning model:</Body18>
                            </DropdownLabelContainer>
                            <DropdownContainer>
                                <Dropdown
                                    dropdownId="machineLearningModels"
                                    placeholder={"Select..."}
                                    value={selectedModel?.displayText}
                                    options={machineLearningModels}
                                    onOptionClick={(event: Event) => {
                                        onSelectModel(event);
                                    }}
                                ></Dropdown>
                            </DropdownContainer>
                        </ModelRowContainer>
                        <ObjectsRowContainer>
                            <ObjectsLabelContainer disabled={selectedModel === defaultOption}>
                                <Body18 regular>Objects to detect:</Body18>
                            </ObjectsLabelContainer>
                            <DropdownContainer>
                                <Dropdown
                                    dropdownId="objectsToDetect"
                                    placeholder="Select..."
                                    options={getObjectsOptions()}
                                    disabled={selectedModel === defaultOption}
                                    onOptionClick={(event: Event) => {
                                        onSelectObject(event);
                                    }}
                                />
                            </DropdownContainer>
                        </ObjectsRowContainer>
                    </ModelObjectsContainer>
                    <TagContainer>
                        <UneditedTagsContainer>
                            <TagList
                                tags={objectTags}
                                tagLineWidth={360}
                                onTagClick={(tag: Tag) => {
                                    onSelectEditTag(tag);
                                }}
                            />
                        </UneditedTagsContainer>
                        {editedObjects.length > 0 && (
                            <EditedTagsContainer>
                                <EditedAlgorithmsText>Edited algorithms</EditedAlgorithmsText>
                                <TagList
                                    tags={editedObjectTags}
                                    backgroundColor={green}
                                    tagLineWidth={360}
                                    onTagClick={(tag: Tag) => {
                                        onSelectUpdateEditTag(tag);
                                    }}
                                />
                            </EditedTagsContainer>
                        )}
                    </TagContainer>
                </ScrollContainer>
                <SaveContainer>
                    <SaveButtonContainer>
                        <Button
                            iconPosition="right"
                            icon={!isLoading ? "icon-system-arrow" : "none"}
                            variant="primary"
                            size="medium"
                            label={"Save & continue"}
                            onClick={() => onNext(selectedModel, selectedObjects.concat(editedObjects))}
                            disabled={isNextDisabled}
                        />
                    </SaveButtonContainer>
                    <SpinnerContainer>
                        <Spinner isVisible={isLoading} size="small" />
                    </SpinnerContainer>
                </SaveContainer>
            </FormContainer>
        </Container>
    );
};

export default DetectObjects;

const Container = styled.div`
  margin-top: -26px;
`;

const DropdownLabelContainer = styled.div`
  margin-top: 40px;
`;

const UneditedTagsContainer = styled.div`
  display: inline-flex;

  .tl-ui {
      display: flex;
      justify-content: flex-start;
      height: 24px;
      margin-right: 11px;
  }
`;

const EditedTagsContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;

  .tl-ui {
    display: flex;
    justify-content: flex-start; 
    height: 24px;
    margin-right: 11px;
  }
`;

const EditedAlgorithmsText = styled.div`
  margin-bottom: 17px;
  color: #414141;
  font-size: 14px;
  font-weight: 700;
`;

const ScrollContainer = styled.div`
  height: 465px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const TagContainer = styled.div`
  margin-left: 255px;

  .tl-tag-value .tl-tag-label {
    font-size: 14px;
  }

  .tl-tag-value .tl-svg-close-icon {
    height: 16px;
    width: 16px;
    margin-left: 10px;
  }
`;

const ModelRowContainer = styled.div`
  margin-top: -25px;
  display: flex;
  justify-content: space-between; 
`;

const ObjectsRowContainer = styled.div`
  margin-top: -25px;
  display: flex;
  justify-content: space-between; 
`;

const ModelObjectsContainer = styled.div`
  margin-top: 30px;
  max-width: 616px;
`;

const DropdownContainer = styled.div`
  width: 362px;
  margin-top: 10px;

  .tl-dropdown-assistive-text {
      display: none;
  }

  .tl-dropdown-icon {
      margin-top: 20px;
  }

  .tl-dropdown.tl-dropdown-disabled * {
    background: white;
    color: #AAA;
  }

  .tl-dropdown .tl-dropdown-options{
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .tl-dropdown .tl-dropdown-options .tl-dropdown-options-list {
    max-height: 195px;
  }

  .tl-dropdown .tl-dropdown-header .tl-dropdown-display {
    font-size: 16px;
  }

  .tl-dropdown .tl-dropdown-options .tl-dropdown-option {
    font-size: 16px;
    font-weight: 700px;
    color: #414141;
  }

  .tl-dropdown .tl-dropdown-header .tl-dropdown-display.dropdown-open, .tl-dropdown .tl-dropdown-header:focus>.tl-dropdown-display {
    color: #0064D2;
  }
`;

const ObjectsLabelContainer = styled.div<{ disabled: boolean }>`
  color: ${props => props.disabled ? `${greyL3}` : '#414141'};
  margin-top: 40px;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: -14px;
  right: -25px;
`;

const DescriptionContainer = styled.div`
  width: 544px;
`;

const SaveContainer = styled.div`
  position: relative;
  padding-left: 600px;
`;

const FormContainer = styled.div`
  margin-top: 54px;
  margin-left: 145px;
`;

const SaveButtonContainer = styled.div`
  .tl-button-primary-disabled, .tl-button-secondary-disabled {
    color: #AAA;
  }

  .tl-button-icon-color-Disabled {
    fill: #AAA;
    padding-bottom: 2px;
  }
`;
