import React, { FC } from "react";
import { DropdownOption } from "@sv/types";
import { Box } from "telstra-components";
//import DropDownRow from "@components/Forms/DropDownRow";
import DropDownRow from "../../../components/Forms/DropDownRow";
import TextFieldRow from "../../../components/Forms/TextFieldRow";
import strings from "../../../strings/strings.json";

const {
    deviceGatewayDropDownLabel,
    deviceDeviceTypeDropDownLabel,
    deviceDeviceNameTextFieldLabel,
    deviceDeviceNameTextFieldTooltip,
    deviceUrlTextFieldLabel,
    deviceUrlTextFieldTooltip,
    deviceUsernameTextFieldLabel,
    deviceUsernameTextFieldTooltip,
    devicePasswordTextFieldLabel,
    devicePasswordTextFieldTooltip,
} = strings.components.Forms.DeviceForm;

interface DeviceFormProps {
    gatewaysOptions: Array<DropdownOption>;
    deviceTypeOptions: Array<DropdownOption>;
    initialGatewayValue?: string;
    initialDeviceTypeValue?: string;
}

const DeviceForm: FC<DeviceFormProps> = ({
    gatewaysOptions,
    initialGatewayValue,
    deviceTypeOptions,
    initialDeviceTypeValue,
}: DeviceFormProps) => {
    return (
        <Box>
            <DropDownRow
                label={deviceGatewayDropDownLabel}
                fieldName="deviceSelectedGateways"
                options={gatewaysOptions}
                initialValue={initialGatewayValue}
            />
            <Box marginTop="xxs">
                <DropDownRow
                    label={deviceDeviceTypeDropDownLabel}
                    fieldName="deviceType"
                    options={deviceTypeOptions}
                    initialValue={initialDeviceTypeValue}
                    isEditModal={deviceTypeOptions.length <= 1}
                />
            </Box>
            <Box marginTop="xxs">
                <TextFieldRow
                    label={deviceDeviceNameTextFieldLabel}
                    tooltipText={deviceDeviceNameTextFieldTooltip}
                    fieldName="deviceName"
                />
            </Box>
            <Box marginTop="xxs">
                <TextFieldRow
                    label={deviceUrlTextFieldLabel}
                    tooltipText={deviceUrlTextFieldTooltip}
                    placeholder="https://"
                    fieldName="deviceUrl"
                />
            </Box>
            <Box marginTop="xxs">
                <TextFieldRow
                    label={deviceUsernameTextFieldLabel}
                    tooltipText={deviceUsernameTextFieldTooltip}
                    fieldName="deviceUsername"
                />
            </Box>
            <Box marginTop="xxs">
                <TextFieldRow
                    label={devicePasswordTextFieldLabel}
                    tooltipText={devicePasswordTextFieldTooltip}
                    fieldName="devicePassword"
                    password
                />
            </Box>
        </Box>
    );
};

export default DeviceForm;
