import { DetectObjectsOptions } from "../components/Modal/ConfigureCamera/DetectObjects";
import { Object, Algorithm } from "../components/Modal/ConfigureCamera/config";

export const useTransformObjectsToOptions = (
    objects: Array<Object>,
    algorithmsMap: { [key: string]: Algorithm },
): Array<DetectObjectsOptions> => {
    return objects.map((object: Object) => {
        const defaultAlgorithm = algorithmsMap[object.defaultAlgorithmId];
        const currentAlgorithm = algorithmsMap[object.currentAlgorithmId];
        const availableAlgorithms = object.availableAlgorithms.map((algorithmId) => {
            const algorithm = algorithmsMap[algorithmId];
            return {
                itemId: algorithm.id,
                displayText: algorithm.displayName,
            };
        });

        return {
            displayText: object.displayName,
            itemId: object.id,
            defaultAlgorithm: {
                itemId: defaultAlgorithm.id,
                displayText: defaultAlgorithm.displayName,
            },
            currentAlgorithm: {
                itemId: currentAlgorithm.id,
                displayText: currentAlgorithm.displayName,
            },
            availableAlgorithms: availableAlgorithms,
        };
    });
};

export const useToTitleCase = (titleString: string) => {
    return titleString.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase());
};

