import React, { ReactElement } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
  fill?: string;
}

const SVGIconCoverageMobile = ({ fill }: Props): ReactElement => {
  return (
    <SVGWrapper viewBox="0 0 24 24" fillColor={fill || ""}>
      <svg id="gateway-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.5244 9.81747V21.8444" stroke="#414141"/>
        <path d="M15.8963 5.40539C17.1606 5.8988 18.0446 9.14237 15.8963 10.5609M8.86507 5.40539C7.52863 5.8988 6.86065 9.14909 8.86507 10.5609M16.9421 3C20.1493 4.20269 20.5502 9.81526 16.9421 12.6215M7.71439 3C4.50666 4.20269 3.70582 10.2162 7.71442 12.6215M12.53 9.81603C11.7263 9.81603 10.5255 9.12128 10.5255 7.81154C10.5255 6.5018 11.7263 5.80552 12.53 5.80628C13.3457 5.80706 14.5357 6.60808 14.5345 7.81154C14.5333 9.015 13.3457 9.81603 12.53 9.81603Z" stroke={fill || ""} strokeWidth="0.9" strokeLinejoin="round"/>
      </svg>
    </SVGWrapper>
  );
};

export default SVGIconCoverageMobile;

const SVGWrapper = styled.svg<{ fillColor: string }>`
  fill: ${(props): string => props.fillColor};
`;
