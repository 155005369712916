import { useEffect, useState } from "react";
import { FetchError } from "../../types";
import { TreeItem } from "telstra-components";
import axios from "axios";
import { getBaseUrl } from "./../../../services";

interface UseOrganisationTreeProps {
    mainOrganisation?: TreeItem;
    treeData: Array<TreeItem>;
    error: FetchError;
    isLoading: boolean;
    refreshTreeData: () => Promise<void>;
}

const getSiteIconForStatusCode = (healthStatusCode: string) => healthStatusCode === "GREEN"
    ? "/images/site-hierarchy-icons/green-status-icon.svg"
    : healthStatusCode === "AMBER"
    ? "/images/site-hierarchy-icons/amber-warning-icon.svg"
    : healthStatusCode === "RED"
    ? "/images/site-hierarchy-icons/red-warning-icon.svg"
    : ""

const getGatewayIconForStatusCode = (healthStatusCode: string) => healthStatusCode === "GREEN"
    ? undefined
    : healthStatusCode === "AMBER"
    ? "/images/site-hierarchy-icons/amber-camera-icon.svg"
    : healthStatusCode === "RED"
    ? "/images/site-hierarchy-icons/red-gateway-icon.svg"
    : ""

const getCameraIconForStatusCode = (healthStatusCode: string) => healthStatusCode === "GREEN"
    ? undefined
    : healthStatusCode === "AMBER"
    ? "/images/site-hierarchy-icons/red-alert-icon.svg"
    : healthStatusCode === "RED"
    ? "/images/site-hierarchy-icons/red-alert-icon.svg"
    : ""

type OrganisationHealthStatus = {
    tenantName: string;
    description: string;
    sites: Array<{
        siteId: string;
        siteFriendlyName: string;
        healthStatus: {
            code: string | "RED";
            reason: string;
            action: string;
        };
        edgeDevices: Array<{
            edgeDeviceId: string;
            edgeDeviceFriendlyName: string;
            lastActiveTime: string;
            healthStatus: {
                code: string | "RED";
                reason: string;
                action: string;
            };
            leafDevices: Array<{
                leafId: string;
                leafFriendlyName: string;
                lastActiveTime: string;
                healthStatus: {
                    code: string | "RED";
                    reason: string;
                    action: string;
                };
            }>;
        }>;
    }>;
}

const useOrganisationTree = (): UseOrganisationTreeProps => {
    const [mainOrganisation, setMainOrganisation] = useState<TreeItem>();
    const [treeData, setTreeData] = useState(Array<TreeItem>());
    const [error, setError] = useState({} as FetchError);
    const [isLoading, setIsLoading] = useState(false);

    const refreshTreeData = async () => {
        setIsLoading(true);

        try {
            const baseUrl = getBaseUrl();
            const organisation = await axios.get<OrganisationHealthStatus>(`${baseUrl}/caas/organisations/overview?includeHealthStatus=true&isVantage=false`).then(e => e.data);

            const treeData: Array<TreeItem> = [
                ...organisation.sites.map(s => ({
                    type: "site",
                    id: s.siteId,
                    text: s.siteFriendlyName,
                    parentId: undefined,
                    status: {
                        ...s.healthStatus,
                        iconSrc: getSiteIconForStatusCode(s.healthStatus.code),
                    },
                }) as TreeItem),
                ...organisation.sites.reduce((a, s) => (
                    a.concat(
                        s.edgeDevices.map(e => ({
                            type: "gateway",
                            id: e.edgeDeviceId,
                            text: e.edgeDeviceFriendlyName,
                            parentId: s.siteId,
                            status: {
                                ...e.healthStatus,
                                iconSrc: getGatewayIconForStatusCode(e.healthStatus.code),
                            },
                        }) as TreeItem)
                    )
                ), [] as TreeItem[]),
                ...organisation.sites.reduce((a, s) => (
                    a.concat(
                        s.edgeDevices.reduce((a, e) => (
                            a.concat(e.leafDevices.map(l => ({
                                type: "camera",
                                id: l.leafId,
                                text: l.leafFriendlyName,
                                parentId: e.edgeDeviceId,
                                status: {
                                    ...l.healthStatus,
                                    iconSrc: getCameraIconForStatusCode(l.healthStatus.code),
                                },
                            }) as TreeItem))
                        ), [] as TreeItem[])
                    )
                ), [] as TreeItem[]),
            ]

            setTreeData(treeData);
            setMainOrganisation({
                type: "organisation",
                id: organisation.tenantName,
                text: organisation.tenantName,
                parentId: undefined,
                status: undefined,
            } as TreeItem);
            setError({});
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                setError({
                    status: error?.response?.status,
                    statusText: error?.response?.statusText,
                    message: "Fetch Organisation Tree Data Error",
                });
            }
            setTreeData([]);
            setMainOrganisation(undefined);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        refreshTreeData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { mainOrganisation, treeData, error, isLoading, refreshTreeData };
};

export default useOrganisationTree;
