import { UserManager, UserManagerSettings } from "oidc-client-ts";
import { CaimanOidcMetadata } from "./OidcExtensions";

const caimanSettings = {
    authority: process.env.REACT_APP_AUTHORITY_CAIMAN,
    client_id: process.env.REACT_APP_AAD_APP_CLIENT_ID_CAIMAN,
    redirect_uri: window.location.origin,
    scope: process.env.REACT_APP_API_SCOPE_SITES_READ_CAIMAN,
};

export const caimanInstance = new UserManager(caimanSettings as UserManagerSettings);

const configureCallbackEvents = async (): Promise<void> => {
    caimanInstance.events.addUserLoaded(function () {
        window.location.reload();
    });

    const urlSearchParams = new URLSearchParams(window.location.search);
    const codeParam = urlSearchParams.get("code");
    if (codeParam !== null) {
        caimanInstance
            .signinRedirectCallback()
            .then((user) => {
                sessionStorage?.setItem("oidc.access_token", user.access_token);
                sessionStorage?.setItem("oidc.id_token", user.id_token);
            })
            .catch(function (err) {
                console.error("Error:", err);
            });
    }
};

configureCallbackEvents();

export const caimanRefreshToken = async (): Promise<unknown> => {
    return null;
};

export const caimanLogin = async (domainHint: string, loginHint: string): Promise<void> => {
    try {
        await caimanInstance.signinRedirect({
            extraQueryParams: {
                login_hint: loginHint,
            },
        });
    } catch (err) {
        console.error("Error:", err);
    }
};

export const caimanLogout = async (): Promise<void> => {
    try {
        const metadata: Partial<CaimanOidcMetadata> = await caimanInstance.metadataService.getMetadata();

        if (metadata.end_session_endpoint) {
            await caimanInstance.signoutRedirect();
        } else {
            // CAIMAN does not provide standard end_session_endpoint in metadata
            const endSessionEndpoint = metadata.ping_end_session_endpoint;
            if (endSessionEndpoint) {
                const fullLogoutUrl = new URL(endSessionEndpoint);
                fullLogoutUrl.searchParams.append("TargetResource", window.location.href);
                console.log(`fullLogoutUrl.href: ${fullLogoutUrl.href}`);
                window.location.href = fullLogoutUrl.href;
            } else {
                console.error("Missing end session endpoint");
            }
        }
    } catch (err) {
        console.error("Error:", err);
    } finally {
        sessionStorage.clear();
    }
};

export const caimanIsAuthenticated = (): boolean => {
    if (sessionStorage?.getItem("oidc.access_token") !== null) {
        return true;
    }
    return false;
};
