import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios, { AxiosError } from "axios";
import { Device, DeviceConnectionStatus, DeviceType, FetchError } from "@sv/types";
import { getBaseUrl } from "./../../../../services";

interface Props {
}

type GatewayFormFlowPage = "DEVICE_COUNT" | "DEVICE_1" | "DEVICE_2" | "DEVICE_3" | "DEVICE_4";

type GatewayFormConfiguration = {
    [gatewayPageDeviceId: string]: {
        // id?: string;
        name?: string;
        serialNumber?: string;
        numberOfCameras?: number;
        createdGatewayId: string | null;
        createdCameraIds: string[];
    };
};

interface GatewayFormNavigationState {
    numberOfRequiredGatewayDevices?: number;
    gatewayFormFlowPage: GatewayFormFlowPage;
    gatewayConfiguration: GatewayFormConfiguration;
    actions: {
        setNumberOfRequiredGatewayDevices: (requiredCount: number) => void;
        setGatewayFormFlowPage: (page: GatewayFormFlowPage) => void;
        setGatewayConfiguration: (configuration: GatewayFormConfiguration) => void;
        saveGateways: (siteId: string) => Promise<void>;
    };
}

const useGatewayForm = ({}: Props): GatewayFormNavigationState => {
    const [numberOfRequiredGatewayDevices, setNumberOfRequiredGatewayDevices] = useState<number | undefined>(undefined);
    const [gatewayFormFlowPage, setGatewayFormFlowPage] = useState<GatewayFormFlowPage>("DEVICE_COUNT");
    const [gatewayConfiguration, setGatewayConfiguration] = useState<GatewayFormConfiguration>({
        "DEVICE_1": {
            // id: uuidv4(),
            createdGatewayId: null,
            createdCameraIds: [],
        },
        "DEVICE_2": {
            // id: uuidv4(),
            createdGatewayId: null,
            createdCameraIds: [],
        },
        "DEVICE_3": {
            // id: uuidv4(),
            createdGatewayId: null,
            createdCameraIds: [],
        },
        "DEVICE_4": {
            // id: uuidv4(),
            createdGatewayId: null,
            createdCameraIds: [],
        },
    });

    return {
        numberOfRequiredGatewayDevices,
        gatewayFormFlowPage,
        gatewayConfiguration,
        actions: {
            setNumberOfRequiredGatewayDevices,
            setGatewayFormFlowPage,
            setGatewayConfiguration,
            saveGateways: async (siteId) => {
                const gatewaysToSave: string[] = [];

                if ((numberOfRequiredGatewayDevices || 0) >= 1 && gatewayConfiguration["DEVICE_1"].serialNumber) {
                    gatewaysToSave.push("DEVICE_1");
                }
                if ((numberOfRequiredGatewayDevices || 0) >= 2 && gatewayConfiguration["DEVICE_2"].serialNumber) {
                    gatewaysToSave.push("DEVICE_2");
                }
                if ((numberOfRequiredGatewayDevices || 0) >= 3 && gatewayConfiguration["DEVICE_3"].serialNumber) {
                    gatewaysToSave.push("DEVICE_3");
                }
                if ((numberOfRequiredGatewayDevices || 0) >= 4 && gatewayConfiguration["DEVICE_4"].serialNumber) {
                    gatewaysToSave.push("DEVICE_4");
                }

                const TEMPORARY_DEFAULT_MODULE_NAME = "svaEdge1"; // TODO: this is temporary for LGAQ conference, should be from user input.

                const workingGatewayConfiguration = Object.keys(gatewayConfiguration)
                    .reduce((a, e) => ({
                        ...a,
                        [e]: {
                            ...gatewayConfiguration[e],
                            // hasCreated: true,
                        },
                    }), {} as GatewayFormConfiguration)

                await gatewaysToSave
                    .map((gatewayKey, i) => async () => {
                        const requestOptions = {
                            params: {
                            },
                        };
                        const requestBody = {
                            tags: { site: siteId },

                            deviceId: workingGatewayConfiguration[gatewayKey].serialNumber,
                            name: workingGatewayConfiguration[gatewayKey].name || `Gateway ${i + 1}`,
                            siteId,
                            type: "gateway",
                            edgeDevice: workingGatewayConfiguration[gatewayKey].serialNumber,
                            edgeCapable: true,
                            moduleName: TEMPORARY_DEFAULT_MODULE_NAME,
                            // TODO: replace with serial number API
                            metadata: {
                                url: undefined,
                                username: undefined,
                                password: undefined,
                            },
                        };

                        console.log(gatewayKey, requestBody);

                        const baseUrl = getBaseUrl();

                        // If the gateway serial number has changed, we need to delete it and recreate it with the new serial number.
                        if (workingGatewayConfiguration[gatewayKey].createdGatewayId && workingGatewayConfiguration[gatewayKey].createdGatewayId !== workingGatewayConfiguration[gatewayKey].serialNumber) {
                            await Promise.all(workingGatewayConfiguration[gatewayKey].createdCameraIds.map(cameraId => {
                                return axios.delete(
                                    `${baseUrl}/caas/devices/${cameraId}`,
                                    requestOptions,
                                );
                            }));
                            await axios.delete(
                                `${baseUrl}/caas/devices/${workingGatewayConfiguration[gatewayKey].createdGatewayId}`,
                                requestOptions,
                            );
                            workingGatewayConfiguration[gatewayKey].createdCameraIds = [];
                            workingGatewayConfiguration[gatewayKey].createdGatewayId = null;
                        }

                        try {
                            let responseData: Array<Device> = [];
                            const res = workingGatewayConfiguration[gatewayKey].createdGatewayId
                                ? await axios.put(
                                    `${baseUrl}/caas/devices/edge-device/${workingGatewayConfiguration[gatewayKey].serialNumber}`,
                                    requestBody,
                                    requestOptions,
                                )
                                : await axios.post(
                                    `${baseUrl}/caas/devices/edge-device`,
                                    requestBody,
                                    requestOptions,
                                )
                            // response may be a single object or array of objects
                            if (
                                typeof res.data === 'object' &&
                                !Array.isArray(res.data) &&
                                res.data !== null
                            ) {
                                responseData.push(res.data);
                            } else {
                                responseData = res.data;
                            }
                            const mappedDevice = mapDevice(responseData)[0];
                            // setDevice(mappedDevice);
                            // setError({});

                            workingGatewayConfiguration[gatewayKey].createdGatewayId = mappedDevice.id;

                            const additionalRequiredCameraCount = (workingGatewayConfiguration[gatewayKey].numberOfCameras || 0) - workingGatewayConfiguration[gatewayKey].createdCameraIds.length;

                            console.log("gatewayKey", gatewayKey);
                            console.log("workingGatewayConfiguration[gatewayKey].numberOfCameras", workingGatewayConfiguration[gatewayKey].numberOfCameras);
                            console.log("workingGatewayConfiguration[gatewayKey].createdCameraIds.length", workingGatewayConfiguration[gatewayKey].createdCameraIds.length);
                            console.log("additionalRequiredCameraCount", additionalRequiredCameraCount);

                            if (additionalRequiredCameraCount > 0) {
                                // Some cameras need to be created

                                const workingCreatedCameraIds = [...workingGatewayConfiguration[gatewayKey].createdCameraIds];

                                await new Array(additionalRequiredCameraCount).fill(0)
                                    .map((_, i) => async () => {
                                        const newCameraResponse = await axios.post<{
                                            active: boolean;
                                            createdAt: number;
                                            deviceId: string;
                                            edgeCapable: false;
                                            edgeDevice: string;
                                            metadata: {
                                                auth: {
                                                    symmetricKey: {};
                                                };
                                            };
                                            moduleName: string;
                                            name: string;
                                            siteId: string;
                                            type: "camera";
                                        }>(
                                            `${baseUrl}/caas/devices/camera`,
                                            {
                                                tags: { site: siteId },
                    
                                                deviceId: uuidv4(),
                                                // name: workingGatewayConfiguration[gatewayKey].name,
                                                name: `Camera ${workingGatewayConfiguration[gatewayKey].createdCameraIds.length + i + 1}`,
                                                siteId,
                                                type: "camera",
                                                // edgeDevice: workingGatewayConfiguration[gatewayKey].id,
                                                edgeDevice: mappedDevice.id,
                                                edgeCapable: false,
                                                moduleName: TEMPORARY_DEFAULT_MODULE_NAME,
                                                // TODO: replace with serial number API
                                                metadata: {
                                                    url: undefined,
                                                    username: undefined,
                                                    password: undefined,
                                                },
                                            },
                                            requestOptions,
                                        );

                                        console.log("newCameraResponse.data", newCameraResponse.data);

                                        workingCreatedCameraIds.push(newCameraResponse.data.deviceId);
                                    })
                                    .reduce(async (a, e) => {
                                        await a;
                                        return e();
                                    }, Promise.resolve());

                                // setGatewayConfiguration({
                                //     ...workingGatewayConfiguration,
                                //     [gatewayKey]: {
                                //         ...workingGatewayConfiguration[gatewayKey],
                                //         createdCameraIds: workingCreatedCameraIds,
                                //     },
                                // })
                                workingGatewayConfiguration[gatewayKey].createdCameraIds = workingCreatedCameraIds;
                            } else if (additionalRequiredCameraCount < 0) {
                                // Some cameras need to be deleted

                                const workingCreatedCameraIds = [...workingGatewayConfiguration[gatewayKey].createdCameraIds];

                                console.log("workingCreatedCameraIds", workingCreatedCameraIds);

                                await new Array(Math.abs(additionalRequiredCameraCount)).fill(0)
                                    .map(() => async () => {
        
                                        const cameraId = workingCreatedCameraIds.pop();

                                        console.log("deleting camera with id", cameraId);

                                        const deleteCameraResponse = await axios.delete<Device>(
                                            `${baseUrl}/caas/devices/${cameraId}`,
                                            requestOptions,
                                        );

                                        // // workingCreatedCameraIds.push(newCameraResponse.data.id);
                                        workingGatewayConfiguration[gatewayKey].createdCameraIds = workingCreatedCameraIds;
                                    })
                                    .reduce(async (a, e) => {
                                        await a;
                                        return e();
                                    }, Promise.resolve());
                                workingGatewayConfiguration[gatewayKey].createdCameraIds = workingCreatedCameraIds;
                            }

                        } catch (error: unknown) {
                            // if (axios.isAxiosError(error)) setError(prepareError(error, defaultErrorMsg));
                            throw error;
                        } finally {
                            // setLoading((current) => ({ ...current, addDevice: false }));
                        }
                    })
                    .reduce(async (a, e) => {
                        await a;
                        return e();
                    }, Promise.resolve())

                setGatewayConfiguration(workingGatewayConfiguration);
            },
        },
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDevice = (devices: any, type?: DeviceType): Array<Device> =>
    devices
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ?.filter((d: any) => (type ? d.type === type : true))
        .map(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (d: any) =>
                ({
                    id: d.deviceId,
                    name: d.name,
                    siteId: d.siteId,
                    type: d.type,
                    edgeDevice: d.edgeDevice,
                    edgeCapable: d.edgeCapable,
                    active: d.active,
                    moduleName: d.moduleName,
                    url: d.metadata?.url,
                    username: d.metadata?.username,
                    password: d.metadata?.password,
                    connectionString: d.metadata?.auth?.iotHubConnectionString,
                } as Device),
        );

export default useGatewayForm;
