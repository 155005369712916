import React, { useState, useEffect, ChangeEvent } from "react";
import styled from "styled-components";
import { greyL1 } from "../../../themes/Colors";
import { Button } from "telstra-ui/components/button/Button";
import { Heading34, Modal } from "telstra-components";
import { Stepper } from "telstra-ui/components/path-stepper/PathStepper";
import { Device, DropdownOption } from "@sv/types";
import ConnectCamera from "./ConnectCamera";
import useDevice from "../../../smart-video/hooks/Device/useDevice";
import DetectObjects, { DetectObjectsOptions, defaultOption } from "./DetectObjects";
import ConfigureCamera, { ErrorType } from "./ConfigureCamera";

export interface ConfigureCameraModalProps {
    isOpen: boolean;
    gatewayId?: string;
    deviceId?: string;
    onCancel: () => void;
    onSubmit?: () => void;
}

export enum ConfigureCameraStep {
    CONNECT_CAMERA = 0,
    DETECTING_OBJECTS,
    DRAW_TRIPLINES_AND_POLYGONS,
}

export type FormData = {
    gatewayDevice: string;
    deviceName: string;
    rtspStream: string;
    username: string;
    password: string;
    detectionModel: DetectObjectsOptions;
    detectionObjects: Array<DetectObjectsOptions>;
};

const ConfigureCameraModal: React.FC<ConfigureCameraModalProps> = ({
    isOpen,
    gatewayId,
    deviceId,
    onCancel,
    onSubmit,
}: ConfigureCameraModalProps) => {
    const [modalStep, setModalStep] = useState<ConfigureCameraStep>(0);
    const { devices, device, getDevice, getDevices } = useDevice();
    const [gatewayError, setGatewayError] = useState(false);
    const mapGatewayToDropdownOptions = (devices: Array<Device>): Array<DropdownOption> => {
        return devices?.map((g) => ({ id: g.id, label: g.name, value: g.id } as DropdownOption));
    };
    const [testCameraConfiguration, setTestCameraConfiguration] = useState(false);
    const [isTestingCameraConfig, setIsTestingCameraConfig] = useState(false);

    const defaultFormData: FormData = {
        gatewayDevice: "",
        deviceName: "",
        rtspStream: "",
        username: "",
        password: "",
        detectionModel: defaultOption,
        detectionObjects: [],
    };

    const [formData, setFormData] = useState<FormData>(defaultFormData);

    const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (!deviceId || !gatewayId) return;
        getDevices("", gatewayId);
        getDevice(deviceId);
    }, [deviceId, gatewayId]);

    useEffect(() => {
        if (devices.length > 0) {
            const mappedDevices = mapGatewayToDropdownOptions(devices);
            setFormData((prevFormData) => ({
                ...prevFormData,
                gatewayDevice: mappedDevices[0]?.label ?? "",
            }));
        }
    }, [devices]);

    useEffect(() => {
        if (modalStep === ConfigureCameraStep.DETECTING_OBJECTS) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                deviceName: `${device.name} - ${prevFormData.deviceName}`,
            }));
        }
    }, [modalStep]);

    const onConnectCameraNext = () => {
        // -- TODO update with API integration error
        //setGatewayError(true);
        setModalStep(ConfigureCameraStep.DETECTING_OBJECTS);
    };

    const onDetectObjectsNext = (selectedModel: DetectObjectsOptions, selectedObjects: Array<DetectObjectsOptions>) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            detectionModel: selectedModel,
            detectionObjects: selectedObjects,
        }));
        setModalStep(ConfigureCameraStep.DRAW_TRIPLINES_AND_POLYGONS);
    };

    return (
        <Modal isOpen={isOpen} showCloseButton={false} size="large">
            <Container>
                <CloseButtonContainer aria-label="close configure camera modal">
                    <Button
                        title="close modal"
                        iconPosition="left"
                        icon={"icon-system-close"}
                        variant="primary"
                        size="medium"
                        onClick={() => {
                            onCancel();
                        }}
                    />
                </CloseButtonContainer>
                <InnerContainer>
                    <Heading34>Configure camera</Heading34>
                    <StepperContainer>
                        <Stepper
                            activeStep={modalStep + 1}
                            step={[
                                { label: "Connect camera" },
                                { label: "Detecting objects" },
                                { label: "Draw triplines and polygons" },
                            ]}
                            showTextOnCompletion={true}
                            showCursorPointerOnStep={(stepIndex: number) => (stepIndex - 1 ? false : true)}
                        />
                    </StepperContainer>
                    <ContentContainer>
                        {modalStep === ConfigureCameraStep.CONNECT_CAMERA ? (
                            <ConnectCamera
                                formData={formData}
                                isLoading={false}
                                deviceName={`${device?.name} - `}
                                gatewayError={gatewayError}
                                onInputChange={handleFormChange}
                                onConnectCamera={onConnectCameraNext}
                            />
                        ) : modalStep === ConfigureCameraStep.DETECTING_OBJECTS ? (
                            <DetectObjects
                                isLoading={false}
                                onNext={(model, objects) => onDetectObjectsNext(model, objects)}
                            />
                        ) : modalStep === ConfigureCameraStep.DRAW_TRIPLINES_AND_POLYGONS ? (
                            <ConfigureCamera
                                error={ErrorType.NO_ERROR}
                                testConfiguration={testCameraConfiguration}
                                onTestConfig={() => setTestCameraConfiguration(!testCameraConfiguration)}
                                isTestingConfig={isTestingCameraConfig}
                                formData={formData}
                            />
                        ) : (
                            ""
                        )}
                    </ContentContainer>
                </InnerContainer>
            </Container>
        </Modal>
    );
};

export default ConfigureCameraModal;

const Container = styled.div`
    width: 1024px;
    height: 795px;
`;

const InnerContainer = styled.div`
  margin-left: 53px;
  margin-top: 31px;
  margin-right 59px;
  height: 760px;
`;

const ContentContainer = styled.div`
    margin-top: 40px;
`;

const StepperContainer = styled.div`
    margin-top: 46px;
    cursor: default;

    // -- custom completed stepper styling
    .tl-path-container-block .tl-path-complete .tl-path-stepper-bottom {
        background: #0064d2;
    }

    .tl-path-container-block .tl-path-stepper {
        width: unset;
        height: calc(64px - 2px);
    }

    .tl-path-parent {
        width: 100%;
        height: 100%;
    }

    .tl-path-container-block {
        height: 64px;
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }

    // -- Keep fontsize consistent when step selected
    .tl-path-container-block .tl-path-stepper {
        font-size: 16px;
    }

    // -- removes mobile styling to render state instead of label
    .tl-path-container-block .tl-path-stepper > .tl-path-mobileView {
        display: none;
    }

    // -- force render desktop content in mobile
    @media only screen and (max-width: 769px) {
        .tl-path-container-block .tl-path-stepper > .tl-path-desktopView {
            display: block;
            font-size: 14px;
        }
    }

    .tl-path-container-block .tl-path-clickable {
        cursor: default;
    }
`;

const CloseButtonContainer = styled.div`
    position: absolute;
    top: 18px;
    right: 18px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        fill: ${greyL1};
        margin: 0px !important;
        left: 0px !important;
    }
    button {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    span {
        width: 24px;
        height: 24px;
    }
`;
