import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { InputLabel, ListItemIcon } from "@material-ui/core";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";
import { NeverUndefined } from "../../../helpers/types";

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: 20,
        width: 300,
        padding: "0 20px 20px 0",
    },
    indeterminateColor: {
        color: "#0064D2",
    },
    selectAllText: {
        fontWeight: 500,
    },
    selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)",
        },
    },
}));

const itemHeight = 48;
const paddingTop = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: itemHeight * 4.5 + paddingTop,
        },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left",
    },
    variant: "menu",
};

export interface Props<T> {
    options: Array<T>;
    label?: string;
    displayTextExtractor: (option: T) => string;
    handleOnSelect: (value: Array<T>) => void;
}
// type Test1 = FC<{}>;
// type Test = <T extends {}>(props: Props<T>) => void;
// const MultiSelectDropdown = <T,>(): FC<Props<T>> => (props) => {
const MultiSelectDropdown = <T extends { key: string } = {key: string}>(props: Props<T>) => {
    const { options, handleOnSelect, label } = props;
    const classes = useStyles();
    const [selected, setSelected] = useState<Array<T>>(options);

    const isAllSelected = options.length > 0 && selected.length === options.length;

    const optionLookup = options.reduce((a, e) => ({ ...a, [e.key]: e }), {} as { [key: string]: T });

    const handleChange = (event: Parameters<NeverUndefined<SelectInputProps['onChange']>>[0]) => {
        const selectInputValues = event.target.value as Array<T | string>;
        // console.log("selectInputValues", selectInputValues);

        const selectedOptions: Array<T> = selectInputValues.includes("all") && selected.length === options.length
            ? []
            : selectInputValues.includes("all") && selected.length !== options.length
            ? options
            : options.filter(option =>
                selectInputValues.some(value => {
                    // Find the mapped option (may be the key or the object itself, due to a quirk with Select API in the material-ui library).
                    const targetOption: T | undefined = typeof value === 'string' ? optionLookup[value] : value;

                    return targetOption?.key === option.key;
                })
            );
        // console.log("selected", selected);
        // console.log("options", options);

        setSelected(selectedOptions);
        handleOnSelect(selectedOptions);
    };

    // console.log("props.options", props.options)

    return (
        <FormControl className={classes.formControl}>
            <InputLabel>{label}</InputLabel>
            <Select
                multiple
                value={selected.map(e => e.key)} // Note: must be string array due to bug with Select component - new selections are appended as strings.
                onChange={handleChange}
                renderValue={(selected: Array<string> | unknown) => (selected as Array<T>).join(", ")}
                MenuProps={MenuProps}
            >
                <MenuItem
                    value="all"
                    classes={{
                        root: isAllSelected ? classes.selectedAll : "",
                    }}
                >
                    <ListItemIcon>
                        <Checkbox
                            style={{
                                color: "#0064D2",
                            }}
                            classes={{ indeterminate: classes.indeterminateColor }}
                            checked={isAllSelected}
                            indeterminate={selected.length > 0 && selected.length < options.length}
                        />
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
                    {/* <ListItemText primary="Select All" /> */}
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                        <ListItemIcon>
                            <Checkbox
                                style={{
                                    color: "#0064D2",
                                }}
                                checked={selected.some(o => option.key === o.key)}
                            />
                        </ListItemIcon>
                        <ListItemText primary={props.displayTextExtractor(option)} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
export default MultiSelectDropdown;
