import React, { Component, ReactNode } from "react";
import { AppContextProvider } from "./components";
import { SecureRoute } from "./services";
import { Login, NotFound, HomePage } from "./views/Common";
import { Reporting } from "./views/Report";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Organisations, CameraConfig } from "./views/Devices";
import "telstra-ui/resources/telstraui.css";
import "telstra-ui/resources/themes/pacific.css";
import "./smart-video/api/interceptors";

class App extends Component {
    public render(): ReactNode {
        return (
            <AppContextProvider>
                <Router>
                    <Routes>
                        <Route path="/">
                            <Route index element={<Login />} />
                            <Route path="/login" element={<Login />} />
                            <Route
                                path="/home"
                                element={
                                    <SecureRoute>
                                        <HomePage />
                                    </SecureRoute>
                                }
                            />
                            <Route path="/administration">
                                <Route
                                    index
                                    element={
                                        <SecureRoute>
                                            <Organisations />
                                        </SecureRoute>
                                    }
                                />
                                <Route path="organisations">
                                    <Route
                                        index
                                        element={
                                            <SecureRoute>
                                                <Organisations />
                                            </SecureRoute>
                                        }
                                    />
                                </Route>
                                <Route path="cameraconfig">
                                    <Route
                                        index
                                        element={
                                            <SecureRoute>
                                                <CameraConfig />
                                            </SecureRoute>
                                        }
                                    />
                                </Route>
                            </Route>
                            <Route
                                path="/reporting"
                                element={
                                    <SecureRoute>
                                        {/* <Reporting /> */}
                                    </SecureRoute>
                                }
                            />
                            <Route path="*" element={<NotFound />} />
                        </Route>
                    </Routes>
                    {/* To allow persisted tab state and preloading. */}
                    <Reporting />
                </Router>
            </AppContextProvider>
        );
    }
}

export default App;
