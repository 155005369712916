import { EditedAssetDetails } from "../../../views/Management/Assets/Assets";
import { createAdminRequest } from "../../WebService/ServiceHandlers";
import { Admin } from "./ApiDefinition";

/**
 * getAdmminBuildings - gets building information from buildings within the customer tenant.
 * @param token the authorisation token
 * @param state optional - to filter results by state
 * @param city optional - to filter results by city
 * @param page optional - the page of the results. related to pageSize
 * @param pageSize optional - the number of results returned per page
 * @param fetchBookables optional - to return the asset types, total and bookable numbers
 */
export const getAdminBuildings = async (
    token: string,
    state?: string,
    city?: string,
    page?: number,
    pageSize?: number,
    fetchBookables?: boolean,
): Promise<Admin.Buildings> => {
    const requestBody = Object.assign(
        {},
        state && { state },
        city && { city },
        page && { page },
        pageSize && { pageSize },
        fetchBookables && { fetchBookables },
    );
    const { body } = await createAdminRequest({
        apiPath: `/app/customer/buildings`,
        method: "GET",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
        body: requestBody,
    });

    const responseOutput: Admin.Buildings = typeof body === "object" ? body : JSON.parse(body);
    return responseOutput;
};

/**
 * Get information on a particular building
 *
 * @param token number authorisation token
 * @param buildingId number
 */
export const getAdminBuilding = async (token: string, buildingId: number): Promise<Admin.Building> => {
    const { body } = await createAdminRequest({
        apiPath: `/app/customer/building/${buildingId}`,
        method: "GET",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
    });

    const responseOutput: Admin.Building = typeof body === "object" ? body : JSON.parse(body);

    return responseOutput;
};

/**
 * Get information on a particular building's floor(s)
 *
 * @param token number authorisation token
 * @param buildingId number
 * @param floorId optional number
 */
export const getAdminFloor = async (token: string, buildingId: number, floorId?: number): Promise<Admin.Floor> => {
    const requestBody = Object.assign({}, floorId && { floorId });

    const { body } = await createAdminRequest({
        apiPath: `/app/customer/floor/${buildingId}`,
        method: "GET",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
        body: requestBody,
    });

    const responseOutput: Admin.Floor = typeof body === "object" ? body : JSON.parse(body);

    return responseOutput;
};

/*
 * Get Customer meta data. Not passing in optional parameters will get total customer metadata.
 * @param token : Auth token
 * @param buildingId :Optional - Building id to get specific building metadata
 * @param floorId : optional - floor id to get specific floor metadata
 * @param resourceType : optional - to get specific resource type. Resource types include: "Unspecified", "SiteVisit", "Desk", "Room", "CollaborationSpace", "CarSpace"
 */
export const getCustomerMetaData = async (
    token: string,
    state?: string,
    buildingId?: number,
    floorId?: number,
    resourceType?: string,
): Promise<Array<Admin.MetaData>> => {
    const requestBody = Object.assign(
        {},
        state && { state },
        buildingId && { buildingId },
        floorId && { floorId },
        resourceType && { resourceType },
    );

    const { body } = await createAdminRequest({
        apiPath: `/app/customer/metadata`,
        method: "GET",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
        body: requestBody,
    });

    const responseOutput: Array<Admin.MetaData> = typeof body === "object" ? body : JSON.parse(body);
    return responseOutput;
};

/**
 * Gets the location (states) for the customer
 * @param token: Auth token
 */
export const getCustomerLocationStates = async (token: string): Promise<Array<string>> => {
    const { body } = await createAdminRequest({
        apiPath: `/app/customer/location-states`,
        method: "GET",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
    });

    const responseOutput: Array<string> = typeof body === "object" ? body : JSON.parse(body);
    return responseOutput;
};

/*
 * Get customer Floor data for a building
 * @param token : Auth token
 * @param buildingId : building ID to get floor data from
 * @param page : optional - for pagination the page of data to return
 * @param pageSize : optional - for pagination the number of results per page
 * @param fetchBookables : optional - return bookable data for floors
 */
export const getCustomerFloors = async (
    token: string,
    buildingId: number | undefined,
    page?: number,
    pageSize?: string,
    fetchBookables?: boolean,
): Promise<Admin.Floors> => {
    const requestBody = Object.assign(
        {},
        buildingId && { buildingId },
        page && { page },
        pageSize && { pageSize },
        fetchBookables && { fetchBookables },
    );

    const { body } = await createAdminRequest({
        apiPath: `/app/customer/floors`,
        method: "GET",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
        body: requestBody,
    });

    const responseOutput: Admin.Floors = typeof body === "object" ? body : JSON.parse(body);
    return responseOutput;
};

/**
 * Gets customer asset resources for a building
 * @param token : Auth token
 * @param buildingId : building ID to get floor data from
 * @param page : optional - for pagination the page of data to return
 * @param pageSize : optional - for pagination the number of results per page
 * @param floorId : optional - filter by building floor
 * @param resourceType : optional - Filter by asset type
 * @param features : optional - for including features
 * @param iconType : optional - to get icons from backend in specific format: SVG or Base64
 */
export const getCustomerResources = async (
    token: string,
    buildingId?: number,
    page?: number,
    pageSize?: number,
    floorId?: number,
    resourceType?: string,
    features?: boolean,
    iconType?: string,
): Promise<Admin.Resources> => {
    const requestBody = Object.assign(
        {},
        buildingId && { buildingId },
        page && { page },
        pageSize && { pageSize },
        floorId && { floorId },
        resourceType && { resourceType },
        features && { features },
        iconType && { iconType },
    );

    const { body } = await createAdminRequest({
        apiPath: `/app/customer/building/resources`,
        method: "GET",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
        body: requestBody,
    });

    const responseOutput: Admin.Resources = typeof body === "object" ? body : JSON.parse(body);
    return responseOutput;
};

/*
 * setAssetBookable: Sets an asset to bookable/non-bookable
 * @param token : Auth token
 * @param resourceType : optional - to get specific resource type. Resource types include: "Unspecified", "SiteVisit", "Desk", "Room", "CollaborationSpace", "CarSpace"
 * @param buildingId : Building id to get specific building metadata
 * @param floorId : Floor id to get specific building metadata
 * @param assetId : Asset id to get specific building metadata
 * @param status : Status of the asset
 */
export const setAssetBookable = async (
    token: string,
    resourceType: string,
    buildingId: number,
    floorId: number,
    assetId: number,
    status: boolean,
): Promise<boolean> => {
    const { body } = await createAdminRequest({
        apiPath: `/app/booking/set-bookable/${resourceType}?buildingId=${buildingId}&floorId=${floorId}&assetId=${assetId}&status=${status}`,
        method: "PUT",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
    });

    const responseOutput: boolean = typeof body === "object" ? body : JSON.parse(body);
    return responseOutput;
};

/*
 * setFloorBookable: Sets an floor to bookable/non-bookable
 * @param token : Auth token
 * @param floorId : required -  Floor id to get specific floor metadata
 * @param status : Status of the floor
 */
export const setFloorBookable = async (token: string, floorId: number, status: boolean): Promise<boolean> => {
    const { body } = await createAdminRequest({
        apiPath: `/app/booking/set-bookable/floor/${floorId}?status=${status}`,
        method: "PUT",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
    });

    const responseOutput: boolean = typeof body === "object" ? body : JSON.parse(body);
    return responseOutput;
};

/**
 * Gets customer bookings
 * @param token : Auth token
 * @param buildingId : building ID to get floor data from
 * @param floorId : optional - filter by building floor
 * @param assetId : optional - filter by building Asset
 * @param page : optional - for pagination the page of data to return
 * @param pageSize : optional - for pagination the number of results per page
 */
export const getCustomerBookings = async (
    token: string,
    buildingId: number,
    floorId?: number,
    assetId?: number,
    page?: number,
    pageSize?: number,
): Promise<Admin.Bookings> => {
    const requestBody = Object.assign(
        {},
        buildingId && { buildingId },
        floorId && { floorId },
        assetId && { assetId },
        page && { page },
        pageSize && { pageSize },
    );

    const { body } = await createAdminRequest({
        apiPath: `/app/booking/all`,
        method: "GET",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
        body: requestBody,
    });

    const responseOutput: Admin.Bookings = typeof body === "object" ? body : JSON.parse(body);
    return responseOutput;
};

export const getCsvBookings = async (
    token: string,
    buildingId: number,
    floorId?: number,
    resourceId?: number,
): Promise<Array<Admin.UserBooking>> => {
    const requestBody = Object.assign(
        {},
        buildingId && { buildingId },
        floorId && { floorId },
        resourceId && { resourceId },
    );

    const { body } = await createAdminRequest({
        apiPath: `/app/booking/users`,
        method: "GET",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
        body: requestBody,
    });

    const responseOutput: Array<Admin.UserBooking> = typeof body === "object" ? body : JSON.parse(body);
    return responseOutput;
};

export const getCustomerBookingsCount = async (
    token: string,
    buildingId: number,
    floorId?: number,
    assetId?: number,
): Promise<number> => {
    const requestBody = Object.assign({}, buildingId && { buildingId }, floorId && { floorId }, assetId && { assetId });

    const { body } = await createAdminRequest({
        apiPath: `/app/booking/count`,
        method: "GET",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
        body: requestBody,
    });

    const responseOutput: number = typeof body === "object" ? body : JSON.parse(body);
    return responseOutput;
};

/**
 *
 * @param token : Auth token
 * @param iconType : denotes icon type response, can be None/SVG/Base64
 */
export const getAssetFeatures = async (token: string, iconType: string): Promise<Array<Admin.AssetFeatureGroups>> => {
    const requestBody = Object.assign({}, iconType && { iconType });

    const { body } = await createAdminRequest({
        apiPath: `/app/customer/building/resource/feature-groups`,
        method: "GET",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
        body: requestBody,
    });

    const responseOutput: Array<Admin.AssetFeatureGroups> = typeof body === "object" ? body : JSON.parse(body);
    return responseOutput;
};

/**
 *
 * @param token : Auth token
 * @param editedAssetDetails : asset details to be edited
 * @returns : updated asset details
 */
export const submitEditedAssetDetails = async (
    token: string,
    editedAssetDetails: EditedAssetDetails,
): Promise<Admin.Resource> => {
    const { resourceId, assetName, features } = editedAssetDetails;
    const assetNameParam = assetName ? `?newName=${assetName}` : "";

    const { body } = await createAdminRequest({
        apiPath: `/app/customer/building/resource-feature/${resourceId}${assetNameParam}`,
        method: "PUT",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
        body: features,
    });

    const responseOutput: Admin.Resource = typeof body === "object" ? body : JSON.parse(body);
    return responseOutput;
};
