import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Button } from "telstra-ui/components/button/Button";
import { greyL1 } from "telstra-components";
import Modal from "telstra-ui/components/modal/Modal";
import { useToTitleCase } from "../../../hooks/Utils";

export type TagInfoModalProps = {
    heading: string;
    description: React.ReactNode;
    exampleText: string;
    imgSrc: string;
    isOpen: boolean;
    onShowModal: (shouldShow: boolean) => void;
};

const TagInfoModal: React.FC<TagInfoModalProps> = ({
    heading,
    description,
    exampleText,
    imgSrc,
    isOpen,
    onShowModal,
}) => {
    const infoModalRef = useRef<HTMLDivElement | null>(null);
    const focusableElements = useRef<Array<HTMLElement | null>>([]);

    useEffect(() => {
        if (infoModalRef.current) {
            focusableElements.current = Array.from(
                infoModalRef.current.querySelectorAll(
                    'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select',
                ),
            );
        }

        if (isOpen && focusableElements.current.length > 0) {
            focusableElements.current[0]?.focus();
        }
    }, [isOpen]);

    useEffect(() => {
        const handleEscapeKeyPress = (e: KeyboardEvent) => {
            if (e.key === "Escape" && isOpen) {
                onShowModal(false);
            }
        };

        const handleTabKeyPress = (e: KeyboardEvent) => {
            if (e.key === "Tab" && infoModalRef.current) {
                const focusableElements = infoModalRef.current.querySelectorAll(
                    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
                );
                const firstFocusable = focusableElements[0] as HTMLElement;
                const lastFocusable = focusableElements[focusableElements.length - 1] as HTMLElement;

                // -- trap focus in info modal for accessibility
                if (e.target === lastFocusable && !e.shiftKey) {
                    e.preventDefault();
                    firstFocusable.focus();
                } else if (e.target === firstFocusable && e.shiftKey) {
                    e.preventDefault();
                    lastFocusable.focus();
                }
            }
        };

        if (infoModalRef.current) {
            document.addEventListener("keydown", handleEscapeKeyPress);
            document.addEventListener("keydown", handleTabKeyPress);
        }

        return () => {
            document.removeEventListener("keydown", handleEscapeKeyPress);
            document.removeEventListener("keydown", handleTabKeyPress);
        };
    }, [infoModalRef.current]);

    return (
        <Container>
            <InfoModalContainer aria-modal="true" role="dialog" ref={infoModalRef}>
                <Modal isOpen={isOpen} onShowModal={(_: string, shouldShow: boolean) => onShowModal(shouldShow)}>
                    <Modal.Header>
                        <div tabIndex={-1} className="info-modal">
                            {useToTitleCase(heading)}
                        </div>
                        <CloseButtonContainer aria-label={`close ${heading} information modal`}>
                            <Button
                                title="close modal"
                                iconPosition="left"
                                icon={"icon-system-close"}
                                variant="primary"
                                size="medium"
                                onClick={() => {
                                    onShowModal(false);
                                }}
                            />
                        </CloseButtonContainer>
                    </Modal.Header>
                    <Modal.Body>
                        <div tabIndex={0}>
                            <InfoModalDescription>{description}</InfoModalDescription>
                            <InfoModalExample>{exampleText}</InfoModalExample>
                            <InfoModalVisualContainer>
                                <InfoModalVisual src={imgSrc} alt=""></InfoModalVisual>
                            </InfoModalVisualContainer>
                        </div>
                    </Modal.Body>
                </Modal>
            </InfoModalContainer>
        </Container>
    );
};

export default TagInfoModal;

const Container = styled.div``;

const InfoModalExample = styled.div`
    font-style: italic;
    margin-top: 25px;
`;

const InfoModalDescription = styled.div`
    width: 520px;
`;

const InfoModalContainer = styled.div`
    .tl-modalbox-container .tl-modal {
        height: 566px;
    }
    .tl-modalbox-container .tl-modal-icon-svg {
        display: none;
    }
`;

const InfoModalVisualContainer = styled.div`
    padding-top: 25px;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const InfoModalVisual = styled.img`
    width: 100%;
    height: 100%;
`;

const CloseButtonContainer = styled.div`
    position: absolute;
    top: 18px;
    right: 18px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        fill: ${greyL1};
        margin: 0px !important;
        left: 0px !important;
    }
    button {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    span {
        width: 24px;
        height: 24px;
    }
`;
