import React, { FC, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import ErrorIcon from "@material-ui/icons/Error";
import { media } from "../../../config/Config";

export interface Props {
    label: string;
    value: string;
    type: string;
    helperText: string;
    isError: boolean;
    debounce?: number;
    required?: boolean;
    onChange: (text: string) => void;
    onEnterKey?: () => void;
    onFocusChange: (focused: boolean) => void;
}

const TextInput: FC<Props> = (props: Props) => {
    // Define theme
    const theme = {
        primaryBlack: "#000000",
        secondaryBlack: "#666666",
        primaryWhite: "#ffffff",
        palette: {
            errorRed: "#b00020",
            secondary: "#0064d2",
        },
    };

    const { value, label, type, helperText, isError, debounce, required, onChange, onEnterKey, onFocusChange } = props;

    // Debounce timeout in ms
    const debounceTimeout = Number.isInteger(debounce) ? debounce : 200;

    // Debounce timer
    const [debounceTimer, setDebounceTimer] = useState<number | undefined>();

    // Initial text input value
    const [inputValue, setInputValue] = useState(value);

    // Stop layout jumps by adjusting margin when helper text is visible
    const isHelperTextVisible = (helperText && helperText?.length > 0) || false;

    // Custom styling used for MUI textfield
    const useStyles = makeStyles(() =>
        createStyles({
            textField: {
                background: theme.primaryWhite,
                width: 350,
                [media.mobile]: {
                    width: 256,
                },
                height: 46,
                marginTop: 5,
                marginBottom: isHelperTextVisible ? 0 : 22,
            },
            underline: {
                color: theme.secondaryBlack,
                "&::before": {
                    borderBottom: `1px solid ${theme.secondaryBlack}`,
                },
                "&$error:after": {
                    borderBottom: `2px solid ${theme.palette.errorRed}`,
                },
            },
            inputFocused: {
                "&::after": {
                    borderBottom: `2px solid ${theme.palette.secondary}`,
                },
            },
            floatingLabelFocusStyle: {
                "&.Mui-focused": {
                    color: theme.palette.secondary,
                    fontWeight: "bold",
                },
                "&.Mui-error": {
                    color: theme.palette.errorRed,
                    fontWeight: "bold",
                },
            },
            error: {
                "&.MuiFormHelperText-root.Mui-error": {
                    color: theme.palette.errorRed,
                },
            },
        }),
    );

    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const targetValue = event.target.value;

        // Cancel previous timer if one is running
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }

        // Start debounce timer
        const timer = setTimeout(() => {
            // Save the input value in state variable
            setInputValue(targetValue.trim());

            // Clear state variable
            setDebounceTimer(undefined);
        }, debounceTimeout);

        // Start debounce timer
        setDebounceTimer(timer);
    };

    const handleKeyPress = (event: React.KeyboardEvent): void => {
        if (event.key === "Enter") {
            onEnterKey && onEnterKey();
        }
    };

    const handleFocusChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        onFocusChange(event.type === "focus");
    };

    useEffect(() => {
        if (value !== inputValue) {
            onChange(inputValue);
        }

        return () => {
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }
        };
    }, [inputValue]);

    const fieldId = (text: string): string => `${text?.replace(/\s/g, "") ?? ""}TextInput`;

    return (
        <TextField
            id={fieldId(label)}
            label={label}
            type={type}
            required={required}
            helperText={helperText}
            error={isError}
            margin="normal"
            variant="filled"
            color="primary"
            InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
            }}
            FormHelperTextProps={{
                classes: {
                    error: classes.error,
                },
            }}
            InputProps={{
                className: classes.textField,
                classes: {
                    underline: classes.underline,
                    focused: classes.inputFocused,
                    error: classes.error,
                },
                style: {
                    color: theme.primaryBlack,
                    backgroundColor: theme.primaryWhite,
                },
                endAdornment: (
                    <InputAdornment position="end" style={{ color: theme.primaryBlack }}>
                        {isError && <ErrorIcon />}
                    </InputAdornment>
                ),
            }}
            onKeyDown={handleKeyPress}
            onChange={handleChange}
            onBlur={handleFocusChange}
            onFocus={handleFocusChange}
            defaultValue={inputValue}
        />
    );
};

export default TextInput;
