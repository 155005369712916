import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
    fill?: string;
}

export default class SVGIconUsageMonitor extends React.Component<Props> {
    /**
     * Sets colour depending on user input, if no colour given then set to a default of black.
     *
     * @private
     * @memberof SVGIconUsageMonitor
     */
    private fillColor = (): string => {
        // If no colour provided then default to black.
        if (!this.props.fill) {
            return "#000000";
        }
        // If we have a valid colour pass down.
        return this.props.fill;
    };

    /**
     * Render
     */
    public render(): ReactNode {
        return (
            <SVGWrapper
                width="100%"
                height="100%"
                fillColor={this.fillColor()}
                id="UsageMonitorIcon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18.86 17.79"
            >
                <title>Usage Monitor Icon</title>
                <path
                    d="M7.63,18.38h-4a1,1,0,0,1-1-1V10.86a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1V17.4A1,1,0,0,1,7.63,18.38ZM4.2,16.88H7.1V11.39H4.2Z"
                    transform="translate(-2.7 -2.91)"
                />
                <path
                    d="M20.59,18.38h-4a1,1,0,0,1-1-1V10.86a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1V17.4A1,1,0,0,1,20.59,18.38Zm-3.43-1.5h2.9V11.39h-2.9Z"
                    transform="translate(-2.7 -2.91)"
                />
                <path
                    d="M14.11,18.38h-4a1,1,0,0,1-1-1V3.88a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1V17.4A1,1,0,0,1,14.11,18.38Zm-3.43-1.5h2.9V4.41h-2.9Z"
                    transform="translate(-2.7 -2.91)"
                />
                <path
                    d="M20.81,20.69H3.45a.75.75,0,1,1,0-1.5H20.81a.75.75,0,0,1,0,1.5Z"
                    transform="translate(-2.7 -2.91)"
                />
            </SVGWrapper>
        );
    }
}

const SVGWrapper = styled.svg<{ fillColor: string }>`
    fill: ${(props): string => props.fillColor};
`;
