import GatewayForm from "../../../components/Forms/GatewayForm/GatewayForm";
import useDevice from "../../../hooks/Device/useDevice";
import useSite from "../../../hooks/Site/useSite";
import React, { FC, useEffect, useState } from "react";
import { DropdownOption, SiteType } from "@sv/types";
import { Box, FormikProvider, Heading20, Modal, useFormik, Row, Col, Body16 } from "telstra-components";
import { Button } from "telstra-ui/components/button/Button";
import strings from "../../../strings/strings.json";
import * as yup from "yup";
import { validationSchemas } from "../../../helpers/formValidation";

// TODO: refactor redundant strings
const { gtwEditGatewayHeading, gtwEditGatewayDescription, gtwCancelButtonLabel, gtwSaveButtonLabel } =
    strings.components.Modals.GatewayEditModal;
const { defaultErrorMsg } = strings.hooks.Device;

const defaultValues = {
    gatewayName: "",
    isGatewayCreateButtonClicked: false,
};

export const mapSitesToDropdownOptions = (sites: Array<SiteType>): Array<DropdownOption> =>
    sites?.map((s) => ({ id: s.siteId, label: s.name, value: s.siteId } as DropdownOption));

interface GatewayEditModalProps {
    isOpen: boolean;
    deviceId?: string;
    onReset?: () => void;
    onSubmit?: () => void;
}

const GatewayEditModal: FC<GatewayEditModalProps> = ({
    isOpen,
    deviceId,
    onReset,
    onSubmit,
}: GatewayEditModalProps) => {
    const { sites, getSites } = useSite();
    const { error: deviceError, device, getDevice, editDevice, resetError } = useDevice();
    const [initialSiteValue, setInitialSiteValue] = useState<string>();

    const formik = useFormik({
        initialValues: defaultValues,
        validationSchema: validationSchemas.editGateway.concat(
            yup.object().shape({
                gatewayName: yup.string().test("errorEditGateway", defaultErrorMsg, () => !deviceError?.status),
            }),
        ),
        onSubmit: async ({ gatewayName }, { resetForm }) => {
            try {
                await editDevice(gatewayName, device?.siteId, device?.id, "gateway", device?.moduleName);
                onSubmit && onSubmit();
                resetForm();
            } catch (error) {
                await formik.validateForm();
            }
        },
        onReset: () => {
            onReset && onReset();
        },
    });

    useEffect(() => {
        getSites();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!deviceId) return;
        getDevice(deviceId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceId]);

    useEffect(() => {
        if (!device) return;
        setInitialSiteValue(sites.filter((s) => s.siteId === device.siteId).pop()?.siteId);
        formik.setFieldValue("gatewayName", device.name, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device, sites]);

    useEffect(() => {
        formik.validateForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.gatewayName]);

    const handleGatewayNameChange = () => resetError();

    return (
        <Modal isOpen={isOpen} showCloseButton={false} size="medium">
            <Box margin="s">
                <Heading20>{gtwEditGatewayHeading}</Heading20>
                <Body16>{gtwEditGatewayDescription}</Body16>
                <FormikProvider value={formik}>
                    <Box marginLeft="xxxs" marginTop="xs">
                        <Row>
                            <Col style={{ paddingLeft: "1rem" }}>
                                <GatewayForm
                                    siteOptions={mapSitesToDropdownOptions(sites)}
                                    initialSiteValue={initialSiteValue}
                                    deviceId={deviceId}
                                    connectionString={device?.connectionString}
                                    isEditModal={true}
                                    onNameChange={handleGatewayNameChange}
                                />
                            </Col>
                        </Row>
                    </Box>
                    <Box display="flex" marginTop="xs" justifyContent="space-between">
                        <Box display="inline-flex">
                            <Box marginRight="xxs">
                                <Button
                                    variant="secondary"
                                    size="medium"
                                    label={gtwCancelButtonLabel}
                                    onClick={formik.resetForm}
                                    id="modal-gateway_edit_cancel"
                                />
                            </Box>
                        </Box>
                        <Box>
                            <Button
                                variant="primary"
                                size="medium"
                                label={gtwSaveButtonLabel}
                                onClick={formik.submitForm}
                                id="modal-gateway_edit_submit"
                                disabled={formik.isSubmitting}
                            />
                        </Box>
                    </Box>
                </FormikProvider>
            </Box>
        </Modal>
    );
};

export default GatewayEditModal;
