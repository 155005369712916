import Konva from "konva";
import { CameraObjectTripwire, CameraObjectZone } from "./CameraConfigView";

export const handleCursorGrab = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const container = e?.target?.getStage()?.container();
    if (container) {
        container.style.cursor = "grab";
    }
};

export const handleCursorGrabbing = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const container = e?.target?.getStage()?.container();
    if (container) {
        container.style.cursor = "grabbing";
    }
};

export const handleCursorDefault = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const container = e?.target?.getStage()?.container();
    if (container) {
        container.style.cursor = "default";
    }
};

export const handleCursorPointer = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const container = e?.target?.getStage()?.container();
    if (container) {
        container.style.cursor = "pointer";
    }
};

export const handleCursorAllScroll = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const container = e?.target?.getStage()?.container();
    if (container) {
        container.style.cursor = "all-scroll";
    }
};

export const getCircleFill = (
    cameraObjectId: string,
    key: number,
    selectedCameraObjectId: string,
    focusedPointIndex: number,
    highlightFillColour: string,
    defaultFillColour: string,
) => {
    if (cameraObjectId === selectedCameraObjectId) {
        return focusedPointIndex === key ? highlightFillColour : defaultFillColour;
    }
    return defaultFillColour;
};

export const handleArrowPress = (
    event: KeyboardEvent,
    cameraObject: CameraObjectTripwire | CameraObjectZone,
    focusedPointIndex: number,
    maxPositionX: number,
    maxPositionY: number,
    xRate: number,
    yRate: number,
    selectedCameraObjectId: string,
    onCameraObjectMove: ((cameraObject: CameraObjectTripwire | CameraObjectZone) => void) | undefined,
) => {
    let deltaX = 0;
    let deltaY = 0;

    switch (event.key) {
        case "ArrowLeft":
            deltaX = -xRate;
            break;
        case "ArrowUp":
            deltaY = -yRate;
            break;
        case "ArrowRight":
            deltaX = xRate;
            break;
        case "ArrowDown":
            deltaY = yRate;
            break;
        default:
            return;
    }

    const newPoints = [...cameraObject.points];
    const currentPoint = newPoints[focusedPointIndex];

    // Calculate the new x and y values while respecting the bounds
    const newX = Math.max(0, Math.min(maxPositionX, currentPoint.x + deltaX));
    const newY = Math.max(0, Math.min(maxPositionY, currentPoint.y + deltaY));

    if (cameraObject.id === selectedCameraObjectId) {
        newPoints[focusedPointIndex] = {
            x: newX,
            y: newY,
        };

        onCameraObjectMove && onCameraObjectMove({ ...cameraObject, ...{ points: newPoints } });
    }
};
