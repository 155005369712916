import React, { FC, useEffect, useState } from "react";
import { Tag } from "@sv/types";
import { Box, Row, Col, Body18 } from "telstra-components";
import strings from "../../../strings/strings.json";
import TagList from "../../Tag/TagList";
import styled from "styled-components";
import { SiteAnalyticsTagsFormFlowPage } from "../../Wizard/WizardModal";
import { Dropdown } from 'telstra-ui/components/drop-down/Dropdown';
import { TagManager } from "../../../../services/WebService/TagManagerService/Types";
import { TagManagerService } from "../../../../services/WebService/TagManagerService";
import { MessageInline } from "@able/react";

const {
    siteAnalyticsTagSiteTypeTextFieldLabel,
    siteAnalyticsTagSiteRegionTextFieldLabel,
} = strings.components.Forms.SiteAnalyticsTagsForm;

const MAX_SITE_TYPE_TAG_COUNT: number = 10;
const MAX_SITE_REGION_TAG_COUNT: number = 2;
const MAX_SITE_TYPE_TAG_DISPLAY_LENGTH: number = 22;
const MAX_SITE_REGION_TAG_DISPLAY_LENGTH: number = 22;

interface SiteAnalyticsTagsFormProps {
    navigationState: SiteAnalyticsTagsFormFlowPage;
    selectedTags: Array<Tag>;
    onTagSelect: (tagToSelect: Tag) => void;
    onTagDeselect: (tag: Tag) => void;
}

const SiteAnalyticsTagsForm: FC<SiteAnalyticsTagsFormProps> = ({
    navigationState,
    selectedTags,
    onTagSelect: handleTagSelect,
    onTagDeselect: handleTagDeselect,
}: SiteAnalyticsTagsFormProps) => {
    const [tags, setTags] = useState<Array<TagManager.Web.Tag>>([]);

    const fetchTags = () => {
        TagManagerService().getTags().then(tags => {
            setTags(tags);
        });
    };

    useEffect(fetchTags, []);

    const siteTypeTags = selectedTags
        .filter(e => e.type === "site" && e.category === "type")
        .map(e => ({
            ...e,
            displayValue: e.name.length > MAX_SITE_TYPE_TAG_DISPLAY_LENGTH - 3
                ? e.name.split("").slice(0, MAX_SITE_TYPE_TAG_DISPLAY_LENGTH - 3).join("").trim() + "..."
                : e.name,
        }));
    const siteRegionTags = selectedTags
        .filter(e => e.type === "site" && e.category === "region")
        .map(e => ({
            ...e,
            displayValue: e.name.length > MAX_SITE_REGION_TAG_DISPLAY_LENGTH - 3
                ? e.name.split("").slice(0, MAX_SITE_REGION_TAG_DISPLAY_LENGTH - 3).join("").trim() + "..."
                : e.name,
        }));

    const [siteTypeMaxTagsError, setSiteTypeMaxTagsError] = useState("");
    const [siteRegionMaxTagsError, setSiteRegionMaxTagsError] = useState("");

    const handleRemoveTag = (tag: Tag) => {
        handleTagDeselect(tag);
        if (siteTypeTags.length <= MAX_SITE_TYPE_TAG_COUNT && siteTypeMaxTagsError) {
            setSiteTypeMaxTagsError("");
        }
        if (siteRegionTags.length <= MAX_SITE_REGION_TAG_COUNT && siteRegionMaxTagsError) {
            setSiteRegionMaxTagsError("");
        }
    }

    const errorText = (
        navigationState === SiteAnalyticsTagsFormFlowPage.CONFIGURE_SITE_REGION_TAGS && siteRegionTags.length === 0 ||
        navigationState === SiteAnalyticsTagsFormFlowPage.CONFIGURE_SITE_TYPE_TAGS && siteTypeTags.length === 0
    )
        ? `At least one tag needs to be selected in order to proceed. You can create tags through Administration Menu, Manage Tags.`
        : (
            navigationState === SiteAnalyticsTagsFormFlowPage.CONFIGURE_SITE_REGION_TAGS && siteRegionMaxTagsError ||
            navigationState === SiteAnalyticsTagsFormFlowPage.CONFIGURE_SITE_TYPE_TAGS && siteTypeMaxTagsError
        )

    return (
        <Container>
            { navigationState === SiteAnalyticsTagsFormFlowPage.CONFIGURE_SITE_TYPE_TAGS &&
                <Box marginTop="xxs">
                    <Row>
                        <Col md={3} justify="center" noPadding>
                            <Body18 regular>
                                {siteAnalyticsTagSiteTypeTextFieldLabel}
                            </Body18>
                        </Col>
                        <Col md={5} justify="center" noPadding>
                            <DropdownContainer>
                                <Dropdown
                                    dropdownId="tagDropdown"
                                    placeholder={"Select tag..."}
                                    options={tags
                                        .filter(e => e.type === "site" && e.category === "type")
                                        .map(e => ({
                                            tag: e,
                                            displayText: e.name.toUpperCase(),
                                            itemId: e.id,
                                            optionId: `id_option-${e.id}`,
                                            disabled: false,
                                            subheader: false,
                                        }))
                                    }
                                    onOptionClick={(event: Event) => {
                                        const tagId = (event.target as any).id;
                                        const tagDisplayText = (event.target as any).textContent;
                                        
                                        const tagValue = tagDisplayText.toUpperCase();
                                        if (tagValue === "") {
                                            return;
                                        }
                                        if (siteTypeTags.length >= MAX_SITE_TYPE_TAG_COUNT) {
                                            setSiteTypeMaxTagsError(`You can have a maximum of ${MAX_SITE_TYPE_TAG_COUNT} tag${MAX_SITE_TYPE_TAG_COUNT === 1 ? "" : "s"} for each site.`);
                                            return;
                                        }
                                        if (siteTypeTags.find(e => e.name.toUpperCase() === tagValue)) {
                                            setSiteTypeMaxTagsError("This tag already exists.");
                                            return;
                                        }
                                        setSiteTypeMaxTagsError("");

                                        const targetTag = tags.filter(e => e.id === Number(tagId))[0];
                                        handleTagSelect(targetTag);
                                    }}
                                    assistiveText={"New tags can be created in the Administration Menu"}
                                    showAssistiveTextAlways={true}
                                />
                            </DropdownContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col noPadding md={3}>
                        </Col>
                        <Col noPadding md={5} justify="center">
                            <ScrollContainer>
                                <Box display="flex" flexDirection="column" marginTop="xxxs" style={{zIndex: 1, overflowX: "hidden", height: 220}}>
                                    <TagList
                                        tagLineWidth={380}
                                        tags={siteTypeTags}
                                        onTagClick={handleRemoveTag as any}
                                    />
                                </Box>
                            </ScrollContainer>
                        </Col>
                    </Row>
                </Box>
            }
            { navigationState === SiteAnalyticsTagsFormFlowPage.CONFIGURE_SITE_REGION_TAGS &&
                <Box marginTop="xxs">
                    <Row>
                        <Col md={3} justify="center" noPadding>
                            <Body18 regular>
                                {siteAnalyticsTagSiteRegionTextFieldLabel}
                            </Body18>
                        </Col>
                        <Col md={5} justify="center" noPadding>
                            <DropdownContainer>
                                <Dropdown
                                    dropdownId="tagDropdown"
                                    placeholder={"Select tag..."}
                                    options={tags
                                        .filter(e => e.type === "site" && e.category === "region")
                                        .map(e => ({
                                            displayText: e.name.toUpperCase(),
                                            itemId: e.id,
                                            optionId: `id_option-${e.id}`,
                                            disabled: false,
                                            subheader: false,
                                        }))
                                    }
                                    onOptionClick={(event: Event) => {
                                        const tagId = (event.target as any).id;
                                        const tagDisplayText = (event.target as any).textContent;
                                        
                                        const tagValue = tagDisplayText.toUpperCase();
                                        if (tagValue === "") {
                                            return;
                                        }
                                        if (siteRegionTags.length >= MAX_SITE_REGION_TAG_COUNT) {
                                            setSiteRegionMaxTagsError(`You can have a maximum of ${MAX_SITE_REGION_TAG_COUNT} tag${MAX_SITE_REGION_TAG_COUNT === 1 ? "" : "s"} for each site.`);
                                            return;
                                        }
                                        if (siteRegionTags.find(e => e.name.toUpperCase() === tagValue)) {
                                            setSiteRegionMaxTagsError("This tag already exists.");
                                            return;
                                        }
                                        setSiteRegionMaxTagsError("");

                                        const targetTag = tags.filter(e => e.id === Number(tagId))[0];
                                        handleTagSelect(targetTag);
                                    }}
                                    assistiveText={"New tags can be created in the Administration Menu"}
                                    showAssistiveTextAlways={true}
                                />
                            </DropdownContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col noPadding md={3}>
                        </Col>
                        <Col noPadding md={5} justify="center">
                            <ScrollContainer>
                                <Box display="flex" flexDirection="column" marginTop="xxxs" style={{zIndex: 1, overflowX: "hidden", height: 220}}>
                                    <TagList
                                        tagLineWidth={380}
                                        tags={siteRegionTags}
                                        backgroundColor={"#8E0090"}
                                        onTagClick={handleRemoveTag as any}
                                    />
                                </Box>
                            </ScrollContainer>
                        </Col>
                    </Row>
                </Box>
            }

            { errorText && (
                <MessageInline
                    variant="Error"
                    iconName={"Error"}
                >
                    {errorText}
                </MessageInline>
            ) }
        </Container>
    );
};

export default SiteAnalyticsTagsForm;

const Container = styled.div`
    margin-left: 0.75rem;
    margin-right: 0.75rem;

    .able-MessageInline--Error {
        position: absolute;
        bottom: 80px;
        left: 50px;
        right: 50px;
    }

    .tl-ui {
        width: 400px;
        display: flex;
        justify-content: space-between;
        margin-right: 0px;
    }
`;

const DropdownContainer = styled.div`
    margin-top: 40px;

    .tl-dropdown-assistive-text {
        padding-top: 8px;
    }

    .tl-dropdown .tl-dropdown-header {
        margin-top: 0px;
    }

    #tagDropdown-container {
        z-index: 2;
    }
`;

const ScrollContainer = styled.div`
    > div {
        overflow-y: auto;
    }
`;
