import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
    fill?: string;
}

export default class SVGIconFeedback extends React.Component<Props> {
    /**
     * Sets colour depending on user input, if no colour given then set to a default of black.
     *
     * @private
     * @memberof SVGIconFeedback
     */
    private fillColor = (): string => {
        // If no colour provided then default to black.
        if (!this.props.fill) {
            return "#000000";
        }
        // If we have a valid colour pass down.
        return this.props.fill;
    };

    /**
     * Render
     */
    public render(): ReactNode {
        return (
            <SVGWrapper
                width="100%"
                height="100%"
                fillColor={this.fillColor()}
                id="FeedbackIcon"
                data-name="Outlined for feedback"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22.52 21.4"
            >
                <title>Feedback Icon</title>
                <path
                    d="M23.25,1.57v18H15.37L12,23,8.61,19.59H.73v-18ZM21.9,2.92H2.08V18.24H9.17L12,21.06l2.82-2.82H21.9ZM6.24,9.12a1.35,1.35,0,1,1-1.35,1.35A1.34,1.34,0,0,1,6.24,9.12Zm5.63,0a1.35,1.35,0,1,1-1.35,1.35A1.35,1.35,0,0,1,11.87,9.12Zm5.63,0a1.35,1.35,0,1,1-1.35,1.35A1.35,1.35,0,0,1,17.5,9.12Z"
                    transform="translate(-0.73 -1.57)"
                />
            </SVGWrapper>
        );
    }
}

const SVGWrapper = styled.svg<{ fillColor: string }>`
    fill: ${(props): string => props.fillColor};
`;
