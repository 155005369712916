import React from "react";
import styled from "styled-components";

import { DropdownFilter } from 'telstra-ui/components/dropdown-filter/DropdownFilter';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { SvgIcon } from "telstra-ui/components/svg/Icon";

export interface IDropdownOption {
    displayText: string;
    itemId: number | string;
    active: boolean;
    disabled?: boolean;
}

type Props = {
    id: string;
    label?: string;
    subLabel?: string;
    height?: number;
    options: Array<IDropdownOption>;
    onUpdateOptions: (updatedOptions: Array<IDropdownOption>) => void;
};

export function DropdownMultiSelect({ id, label, subLabel, height, options, onUpdateOptions }: Props) {
    const [open, setOpen] = React.useState(false);
    const [ddOptions, setOptions] = React.useState<Array<IDropdownOption & { active: boolean }>>([]);
    const toggle = React.useRef(null);

    const optionClick = (e: React.UIEvent, option: IDropdownOption) => {
        let cop: Array<IDropdownOption> = [...ddOptions];
        cop = cop.map((item: IDropdownOption) => {
            let active = item.active;
            if (item.itemId === option.itemId) {
                active = !option.active;
            }
            return {
                ...item,
                active,
            };
        });
        setOptions(cop as any);
        onUpdateOptions(cop);
    };
    const onOpen = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const makeOptions = (_options_: Array<IDropdownOption>) => {
        return _options_.map((op) => ({
            ...op,
        }));
    };

    React.useEffect(() => {
        setOptions(makeOptions(options));
    }, [options, options.length]);

    const selectedItems: Array<IDropdownOption> = ddOptions.filter((item: IDropdownOption) => item.active);
    const BodyContent = ddOptions.length === 0
        ? (
            <div className="tl-filter-option" style={{padding: 3, color: "black", paddingTop: 0, paddingBottom: 0, textTransform: "none"}}>
                <div className="tl-filter-option-name">There are no results</div>
            </div>
        )
        : ddOptions.map((option: any) => {
            return (
                <div
                    className={["tl-filter-option", option.active ? "active" : ""].join(" ")}
                    key={option.itemId}
                    onClick={(e) => optionClick(e, option)}
                    onKeyUp={(e) => optionClick(e, option)}
                    tabIndex={0}
                >
                    <div className="tl-filter-tick-icon-container">
                        <SvgIcon name="icon-system-ui-tick" className="tl-filter-tick-icon" />
                    </div>
                    <div className="tl-filter-option-name">{option.displayText}</div>
                </div>
            );
        });

    return (
        <DropdownContainer height={height}>
            <span>{label}</span>
            {subLabel && <SubLabel>{subLabel}</SubLabel>}
            <DropdownFilter
                bodyContent={BodyContent}
                id={id}
                selectionOverride={
                    <div className="tl-dropdown-filter-label body16">
                        <span>{selectedItems.length === 0 ? "Select..." : `${selectedItems.length} selected`}</span>
                        <SvgIcon
                            name="icon-system-chevron-down"
                            className={`tl-default-svg-class${open ? " opened" : ""}`}
                        />
                    </div>
                }
                optionsLength={ddOptions.length}
                onOpen={onOpen}
                onClose={onClose}
                ref={toggle}
            />
        </DropdownContainer>
    );
}

const SubLabel = styled.div`
    color: #707070;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
`;

const DropdownContainer = styled.div<{ height?: number }>`
    display: flex;
    flex-direction: column;
    width: 95.5%;
    margin-right: 0.3%;

    > span {
        height: 20px;
        font-family: TelstraAkkurat-Bold;
        font-size: 16px;
        margin-bottom: 0.5em;
    }

    .tl-dropdown-v1,
    div:has(.tl-dropdown-v1) {
        width: 98% !important;

        div {
            width: 98% !important;
        }
    }

    .tl-dropdown-filter {
        width: 98%;
        ${(props) => (props.height ? ` height: ${props.height}px;` : `height: 48px;`)}

        box-shadow: inset 0 0 0 0.0625rem #707070;
        transition: background-color 250ms linear, box-shadow 250ms linear, outline 250ms linear;
        border-radius: 3px;
        cursor: pointer;

        :active {
            box-shadow: inset 0 0 0 0.125rem #0064d2, 0 0 0 0.125rem rgba(0, 100, 210, 0.1607843137) !important;
        }

        :focus {
            box-shadow: inset 0 0 0 0.125rem #0064d2, 0 0 0 0.125rem rgba(0, 100, 210, 0.1607843137) !important;
        }

        :hover {
            background-color: rgba(0, 100, 210, 0.06);
            box-shadow: inset 0 0 0 0.0625rem #0064d2;
        }

        ::selection {
            background-color: transparent;
        }
        caret-color: transparent;
    }

    .fWihya .tl-filter-options {
        width: 100%;
    }

    .tl-dropdown-filter .tl-filter-option .tl-filter-tick-icon-container {
        border: 2px solid #aaaaaa;
    }

    .tl-dropdown-filter .tl-filter-option.active .tl-filter-tick-icon-container {
        background: #0064d2;
        border: none;

        .tl-filter-tick-icon {
            margin-left: -7px;
            margin-top: -7px;
            fill: white;
        }
    }

    .tl-filter-options {
        width: 100%;

        .tl-filter-option-name {
            font-size: 16px;
            font-family: TelstraAkkurat-Regular;
            letter-spacing: -0.15px;
        }
    }

    .tl-dropdown-filter-header {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tl-selection-override {
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.5em;
        }

        .tl-dropdown-filter-label {
            font-family: TelstraAkkurat-Regular;

            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            color: #414141;
            fill: #414141;
        }

        svg {
            fill: #414141;

            transition: transform 0.3s ease-in;
        }
        svg.opened {
            transform: rotate(180deg);
        }
    }

    button {
        margin-top: 28px;
        min-width: 80px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
